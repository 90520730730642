import classes from "../DetailedInfo/DetailedInfo.module.css";
import React, {createContext,useContext, useState} from 'react';
import {Collapse} from '@mui/material';
import {LanguageContext} from '../LanguageContext/LanguageContext';

function Faq() {

    const [expanded, setExpanded] = useState(false);
    const {language} = useContext(LanguageContext);

    const translations = {
        ru: {
            title: "FAQ",
        },
        en: {
            title: "FAQ",
        },
        zh: {
            title: "常见问题",
        }
    }

    const handleToggle = () => {
        setExpanded(!expanded);
    };
    window.addEventListener('scrollToFaq', (event) => {
        handleToggle()
    });
    return (
        <div className={classes.container} id="FAQ" onClick={handleToggle}>
            <div className={classes.holder}>
                <div className={classes.content}>
                    <div>
                        {/*<span className={classes.title}>{translations[language].title}*/}
                        {/*    {expanded*/}
                        {/*        ? (<span style={{transitionDelay: "0s",color: 'rgb(7,188,12)',fontSize: 30,float: "right",transform: "rotate(180deg)"}}> ✓</span>)*/}
                        {/*        : (<span style={{ transitionDelay: "0s",color: 'rgb(7,188,12)',fontSize: 30,float: "right"}}>✓</span>)}*/}
                        {/*</span>*/}
                        <span className={classes.title}>{translations[language].title}
                            <span style={{
                                transitionDelay: "0s",
                                color: 'rgb(7,188,12)',
                                fontSize: 30,
                                float: "right"
                            }}>✓</span>
                        </span>
                    </div>
                    <div>
                        <Collapse in={expanded} timeout="auto">
                            <div>
                                {/* Содержимое, которое будет раскрываться */}
                                <div>
                                    <span style={{
                                        transitionDelay: "0s",
                                        color: 'rgb(7,188,12)',
                                        fontSize: 30
                                    }}>
                                        ✓
                                    </span>
                                    dfvfdvfdvdfvf
                                </div>
                                <div>
                                    <span style={{
                                        transitionDelay: "0s",
                                        color: 'rgb(7,188,12)',
                                        fontSize: 30
                                    }}>
                                        ✓
                                    </span>
                                    dfvfdvfdvdfvf
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;

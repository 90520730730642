import React from 'react';

function Logo(props) {
    return (
        <svg width="46px" height="46px" viewBox="0 0 640.000000 727.000000" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#85c131" stroke="none"><path d="M5765 7224 c-10 -45 -13 -51 -14 -26 l-1 22 -15 0 -15 0 0 -20 c0
-11 -4 -20 -10 -20 -5 0 -10 10 -10 21 l0 21 -22 -6 c-13 -3 -26 -6 -30 -6 -5
0 -8 -16 -8 -35 l0 -35 -14 0 -14 0 5 20 5 20 -15 0 c-8 0 -17 -5 -19 -10 -2
-6 -27 -11 -56 -11 l-52 -2 -1 -41 -1 -41 -9 33 -10 32 -18 0 -19 0 -7 -40
c-7 -39 -18 -50 -30 -31 -3 5 2 16 10 24 19 20 19 47 0 47 -8 0 -15 -4 -15
-10 0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 l0 -14 -37 -8 c-21 -4 -62 -7
-90 -6 -29 1 -55 -2 -58 -8 -4 -5 -19 -9 -36 -9 l-29 0 0 -19 c0 -11 6 -22 13
-25 l12 -6 -12 -6 c-7 -3 -13 -15 -14 -27 0 -12 -3 -16 -6 -9 -2 6 -16 12 -29
12 l-24 0 0 -20 0 -20 15 0 c8 0 15 -4 15 -10 0 -15 -49 -12 -66 4 -18 19 -18
26 1 26 l15 0 0 21 0 21 -20 -5 c-11 -3 -20 -1 -20 4 0 5 -6 9 -13 9 -7 0 -19
-16 -26 -35 -7 -19 -17 -35 -22 -35 -5 0 -9 14 -9 30 l0 31 -19 -11 c-11 -5
-29 -10 -40 -10 -19 0 -81 -53 -81 -70 0 -5 6 -10 12 -12 l13 -5 -17 -16 -16
-16 -6 24 -6 24 -22 7 -21 6 -14 -18 -14 -19 6 -25 c8 -31 21 -40 63 -40 l32
0 0 -20 0 -20 24 0 25 0 3 -21 c2 -12 10 -25 18 -29 8 -4 27 -13 42 -20 l27
-13 16 13 15 12 0 -16 c0 -9 6 -16 14 -16 l14 0 -5 -19 -5 -20 36 -6 36 -7 0
-19 0 -19 15 0 c8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0
-5 -9 -10 -21 -10 l-21 0 6 -24 6 -24 -15 -6 -15 -5 0 14 c0 24 -30 17 -42
-10 -6 -14 -17 -25 -25 -25 l-13 0 0 -20 c0 -23 19 -26 39 -7 l14 12 4 -12 c6
-17 33 -17 33 0 0 18 37 47 61 47 l21 0 -6 25 -6 24 16 -6 16 -6 8 13 7 12 27
-23 26 -23 0 -23 c0 -13 11 -35 24 -48 l24 -23 -5 -26 -5 -25 15 -16 c9 -8 27
-15 41 -15 14 0 26 -4 26 -10 0 -5 9 -10 20 -10 l20 0 0 -16 c0 -20 24 -44 44
-44 9 0 16 -4 16 -10 0 -5 14 -10 30 -10 l30 0 0 80 0 80 10 0 c6 0 10 -9 10
-20 l0 -20 15 0 c9 0 18 8 21 18 3 9 10 25 15 34 5 10 9 34 9 53 l0 35 14 0
c16 0 26 21 26 56 0 13 5 24 10 24 6 0 10 7 10 15 0 8 5 15 10 15 14 0 12 27
-2 34 l-13 5 23 20 24 20 -2 23 c-3 38 -1 48 14 48 16 0 26 21 26 56 0 13 5
24 10 24 6 0 10 7 10 15 0 8 9 24 20 35 12 12 18 25 14 31 -10 17 5 57 32 85
l27 26 -11 34 -10 35 23 9 22 9 7 25 6 26 -11 21 c-6 11 -8 23 -5 27 3 3 6 0
6 -6 0 -7 9 -12 20 -12 l20 0 0 20 c0 11 -6 20 -12 20 -7 0 -23 3 -35 6 l-22
6 -6 -28z m-85 -134 c0 -5 9 -10 20 -10 l20 0 0 -20 0 -20 -20 0 c-11 0 -20 5
-20 10 0 6 -7 10 -15 10 -17 0 -18 2 -9 24 7 18 24 22 24 6z m-160 -20 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-403
-127 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-247 -13 c0 -5 -7 -7
-15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m-31 -62 c0 -18 -3
-27 -6 -20 -2 6 -11 12 -19 12 -17 0 -18 7 -1 25 19 23 27 17 26 -17z m298
-180 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m400 -5 c-4 -3 -7
0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-420 -40 c-4 -3 -7 0 -7 7 0 7 3 10 7
7 3 -4 3 -10 0 -14z m453 -13 c0 -5 -4 -10 -10 -10 -5 0 -11 -10 -11 -22 l-2
-23 -4 23 c-2 12 -9 22 -14 22 -5 0 -9 5 -9 10 0 6 11 10 25 10 14 0 25 -4 25
-10z m-170 -251 l0 -20 -15 15 c-8 8 -15 17 -15 20 0 3 7 6 15 6 l15 0 0 -21z
m-20 -49 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"></path><path d="M4624 6908 l-8 -12 12 -4 c7 -2 12 -10 12 -18 0 -8 7 -14 15 -14 19
0 19 27 1 46 -18 17 -23 17 -32 2z"></path><path d="M3155 6628 l-79 -9 -8 -12 -7 -11 -36 13 -36 13 -39 -7 c-22 -4 -40
-11 -40 -16 0 -15 -27 -10 -45 8 l-17 17 -19 -12 c-10 -7 -26 -12 -34 -12 -8
0 -15 -4 -15 -10 0 -13 -46 -13 -72 1 l-20 11 -11 -11 c-6 -6 -30 -11 -53 -11
-52 0 -74 -8 -74 -26 l0 -14 -29 0 -29 0 -6 -16 c-3 -9 -6 -22 -6 -30 l0 -14
35 0 c19 0 35 -4 35 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 -9 -10 -20
-10 -11 0 -20 5 -20 10 l0 10 -60 0 -60 0 0 -10 c0 -5 -9 -10 -20 -10 -11 0
-20 -4 -20 -10 l0 -10 -40 0 -40 0 0 10 c0 6 4 10 9 10 9 0 26 69 18 76 -2 2
-15 -2 -30 -9 -14 -6 -28 -21 -30 -32 l-4 -20 -2 23 c-1 27 -21 29 -28 2 -3
-10 -14 -22 -24 -25 -10 -4 -19 -13 -19 -21 0 -17 -21 -19 -37 -3 -6 6 -26 9
-44 7 l-34 -3 -3 -17 -3 -18 15 0 16 0 -1 -42 -2 -43 -4 23 c-2 12 -9 22 -14
22 -5 0 -9 9 -9 20 l0 20 -14 0 c-8 0 -16 6 -19 13 l-4 12 -27 -15 -28 -14 5
-22 4 -22 -35 16 -35 17 -4 -12 c-3 -7 -12 -13 -22 -13 -9 0 -27 -9 -39 -20
-12 -11 -25 -20 -31 -20 -5 0 -12 -13 -16 -30 -4 -16 -11 -30 -16 -30 -5 0 -9
5 -9 10 0 6 -14 10 -31 10 l-31 0 6 -32 6 -33 -12 23 c-14 27 -38 29 -45 2
l-5 -20 -28 0 c-16 0 -35 -3 -44 -6 l-16 -6 5 -27 c3 -14 2 -20 -1 -13 -8 18
-24 15 -45 -10 -11 -11 -19 -16 -19 -10 0 7 -7 12 -15 12 -8 0 -15 -7 -15 -15
0 -8 7 -15 15 -15 8 0 15 -4 15 -9 0 -15 -18 -23 -25 -11 -3 5 -19 10 -36 10
l-29 0 0 -15 c0 -8 7 -15 15 -15 l15 0 0 -35 c0 -19 -4 -35 -10 -35 -5 0 -10
14 -10 30 l0 30 -16 0 c-21 0 -44 -24 -45 -47 l-1 -18 -5 13 c-2 6 -13 12 -24
12 l-19 0 -6 -29 -6 -29 -19 5 -19 5 0 -16 c0 -9 -4 -16 -10 -16 -5 0 -10 5
-10 10 0 19 -28 10 -33 -10 l-5 -20 -21 0 -21 0 0 -16 c0 -9 9 -18 20 -21 l20
-5 0 -19 0 -19 -19 0 -19 0 -5 20 c-3 11 -12 20 -21 20 l-16 0 0 -20 c0 -11
-5 -20 -11 -20 -13 0 -49 -38 -49 -52 0 -4 -9 -8 -20 -8 -11 0 -20 -4 -20 -9
0 -5 -12 -11 -28 -13 l-27 -3 -3 -25 -3 -26 -22 -9 -22 -10 -3 -32 -3 -33 -19
0 c-10 0 -22 -4 -25 -10 -3 -5 -15 -10 -26 -10 l-19 0 -20 -32 c-10 -18 -24
-38 -29 -45 -6 -7 -16 -24 -23 -38 l-11 -25 -24 0 -23 0 0 -30 0 -30 14 0 c8
0 17 6 19 13 l5 12 1 -18 c1 -22 -22 -47 -42 -47 l-15 0 5 20 5 20 -12 0 c-17
0 -36 -22 -44 -53 -3 -15 -13 -27 -21 -27 -7 0 -22 -12 -33 -27 l-20 -28 0
-43 c-1 -23 -3 -51 -7 -61 l-6 -19 -19 5 -19 5 -12 -26 -12 -26 -19 0 c-10 0
-18 -4 -18 -8 0 -5 -9 -20 -21 -33 l-21 -24 6 -27 5 -27 -21 -3 c-12 -2 -23
-9 -26 -16 l-4 -12 31 -13 30 -13 -22 -13 -21 -14 -4 12 c-2 6 -10 11 -18 11
l-14 0 0 -20 0 -20 -15 0 -15 0 0 -20 c0 -11 -4 -20 -10 -20 -5 0 -10 -11 -10
-23 0 -13 -9 -32 -21 -43 l-20 -18 4 -18 c3 -10 1 -18 -4 -18 -5 0 -9 -9 -9
-20 0 -11 5 -20 10 -20 6 0 10 -4 10 -10 0 -5 -11 -10 -25 -10 l-25 0 0 -30 0
-30 15 0 15 0 0 -26 c0 -23 -20 -54 -34 -54 -3 0 -6 9 -6 20 l0 20 -15 0 -15
0 0 -35 c0 -37 -18 -85 -32 -85 l-8 0 4 -70 5 -70 -9 0 c-6 0 -10 5 -10 10 0
6 -6 10 -14 10 -15 0 -29 -41 -21 -63 3 -7 -1 -20 -10 -27 -19 -16 -19 -33 -1
-68 l14 -27 -5 -28 -5 -27 -14 0 c-14 0 -18 -14 -29 -90 -3 -25 -13 -56 -23
-70 l-16 -25 0 -107 0 -108 -9 0 -10 0 7 -52 c3 -29 9 -83 12 -120 l7 -68 -17
0 -17 0 6 -97 6 -97 12 -8 11 -7 -12 -57 c-6 -31 -9 -61 -6 -65 3 -5 8 -40 11
-79 l5 -69 19 -38 c10 -20 19 -43 19 -50 0 -7 7 -13 16 -13 l17 0 -7 40 -6 40
10 0 10 0 0 -39 c0 -22 5 -41 11 -43 l10 -3 -6 -18 -7 -17 -24 6 -24 6 0 -36
0 -36 30 0 30 0 0 -18 c0 -11 -9 -29 -20 -42 -11 -13 -20 -29 -20 -36 0 -26
13 -44 31 -44 l19 0 0 20 0 20 25 0 25 0 0 -20 0 -20 -21 0 c-26 0 -62 -38
-54 -58 3 -7 5 -16 5 -18 0 -2 6 -4 13 -4 7 0 19 9 27 20 8 11 18 20 22 20 4
0 8 -13 8 -30 l0 -30 -25 0 -25 0 0 -20 0 -20 16 0 16 0 -6 -16 c-4 -9 -4 -32
0 -50 l6 -34 19 0 c21 0 26 -16 7 -22 l-11 -4 7 -27 6 -27 15 0 c8 0 15 5 15
10 0 6 9 10 20 10 l20 0 0 -30 0 -30 30 0 30 0 0 -22 0 -23 -8 12 -8 12 -37
-6 -37 -6 0 -13 c0 -8 5 -14 11 -14 16 0 39 -26 39 -44 0 -9 4 -16 9 -16 l10
0 -6 -35 -6 -35 12 0 c6 0 11 -7 11 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20
l0 -20 15 0 c14 0 20 -12 16 -34 -4 -19 11 -46 26 -46 l15 0 -5 20 c-3 11 -1
20 4 20 5 0 9 -9 9 -20 l0 -20 14 0 14 0 7 -37 c4 -21 12 -41 18 -45 l12 -8
-17 0 -18 0 0 -15 c0 -8 5 -15 10 -15 6 0 10 -9 10 -20 l0 -20 16 0 c20 0 44
-24 44 -45 0 -8 7 -18 16 -22 l16 -6 10 16 c5 10 16 17 23 17 l15 0 0 20 c0
11 -5 20 -11 20 l-11 0 6 16 c4 9 4 32 0 50 l-6 34 -13 0 c-7 0 -15 14 -19 31
-9 48 -7 69 7 69 l14 0 -3 176 -3 176 -21 18 c-11 9 -20 25 -20 34 l0 16 34 0
34 0 7 40 c4 21 4 48 0 60 l-6 20 10 0 c6 0 11 9 11 19 0 11 5 21 12 23 l11 4
-10 42 -11 41 14 12 c8 6 14 18 14 25 l0 14 -20 0 c-11 0 -20 5 -20 10 0 6 9
10 20 10 11 0 20 -4 20 -10 0 -5 6 -10 14 -10 l15 0 11 55 c6 30 9 59 5 65
l-6 10 15 0 16 0 0 24 c0 14 4 27 9 30 4 3 11 24 15 46 l6 40 15 0 c8 0 15 7
15 15 0 8 5 15 10 15 6 0 10 16 10 36 l0 35 -15 15 c-19 20 -19 34 0 34 8 0
15 -4 15 -10 0 -5 9 -10 19 -10 21 0 31 19 31 56 l0 24 -20 0 c-11 0 -20 -4
-20 -10 0 -5 -4 -10 -10 -10 -5 0 -10 9 -10 20 l0 20 38 1 c20 1 45 1 55 0
l17 -2 0 14 c0 8 -9 17 -20 20 -11 3 -20 10 -20 16 l0 11 20 -12 21 -13 9 15
c15 23 12 45 -10 70 -24 27 -25 40 -5 40 l15 0 0 -20 0 -20 24 0 24 0 6 30 6
30 14 0 c15 0 26 29 26 73 l0 27 16 0 c21 0 47 28 41 44 l-6 14 20 -5 19 -5 0
26 0 26 -15 0 c-8 0 -15 5 -15 10 0 6 7 10 15 10 l15 0 0 30 c0 17 4 30 10 30
l10 0 -6 -30 -6 -30 21 0 21 0 0 20 0 20 16 0 16 0 -6 30 -6 30 25 -6 25 -6 0
36 0 36 15 0 c8 0 15 6 15 14 0 7 9 16 20 19 l20 5 0 50 0 51 16 16 16 16 9
68 c4 37 15 88 24 112 9 24 19 53 22 64 4 11 13 26 20 33 7 7 13 22 13 33 l0
19 15 0 15 0 0 40 0 40 20 0 20 0 6 27 c4 15 13 34 21 41 7 7 13 21 13 31 0
10 9 28 20 41 11 13 20 31 20 42 0 10 4 18 9 18 5 0 14 14 21 30 l12 30 19 0
19 0 0 20 c0 11 4 20 9 20 5 0 12 8 15 18 8 23 101 122 116 122 17 0 42 28 38
43 -4 11 14 37 26 37 3 0 25 18 47 40 23 22 45 40 49 40 4 0 26 18 49 39 23
22 56 46 72 53 16 7 29 16 29 20 0 5 9 8 20 8 l20 0 6 24 6 25 13 -6 14 -5 26
26 c14 14 30 26 34 26 4 0 18 8 29 19 l22 18 25 -5 26 -4 18 21 18 21 32 0
c17 0 31 5 31 10 0 6 7 10 15 10 8 0 15 6 15 14 l0 13 25 -5 25 -4 0 11 0 11
35 0 35 0 20 20 20 20 35 0 c19 0 35 5 35 10 l0 10 54 0 54 0 5 13 5 12 10
-13 9 -13 26 6 26 7 6 29 c4 16 4 32 1 37 l-5 9 24 -5 25 -4 0 11 c0 6 9 11
20 11 11 0 20 5 20 10 0 6 14 10 30 10 l30 0 0 15 c0 7 -8 18 -17 24 -10 5
-14 13 -10 17 4 5 17 3 27 -4 24 -15 50 -16 50 -2 0 6 7 10 15 10 l15 0 0 20
c0 11 5 20 10 20 6 0 10 9 10 20 0 21 15 27 25 10 3 -5 13 -10 20 -10 l15 0 6
27 c8 30 26 53 43 53 6 0 14 15 19 33 l9 32 7 -22 c7 -23 36 -33 36 -13 0 6
11 10 24 10 l25 0 6 20 6 20 20 0 c10 0 19 4 19 9 0 5 9 11 20 14 24 6 27 27
3 27 -30 0 -5 12 29 13 l33 2 3 24 4 24 -22 -6 -22 -6 6 16 c3 8 6 17 6 19 0
2 11 4 25 4 l25 0 0 -15 c0 -8 3 -15 7 -15 3 0 24 8 45 17 l38 17 0 18 0 18
20 0 c11 0 20 5 20 10 0 6 7 10 15 10 l15 0 0 20 0 20 -15 0 c-8 0 -15 5 -15
10 0 6 7 10 15 10 8 0 15 5 15 10 0 18 18 11 25 -10 l6 -20 19 0 19 0 6 19 c3
10 15 21 25 24 11 3 20 12 20 20 l0 15 47 13 46 14 5 18 4 17 23 0 c13 0 25 7
29 15 3 8 13 15 22 15 l16 0 -6 25 -6 25 10 0 c5 0 10 10 11 23 0 12 3 16 6
10 l5 -13 39 0 39 0 0 18 0 19 26 13 25 14 -4 18 c-3 10 -1 18 4 18 5 0 9 -4
9 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 7 10 15 10 19 0 55 31 55 47 l0
13 29 0 c17 0 32 4 35 8 9 15 -6 52 -20 52 -8 0 -14 -4 -14 -10 0 -18 -18 -10
-23 10 l-5 20 -26 0 c-14 0 -26 5 -26 10 l0 10 -55 0 c-30 0 -55 3 -55 8 0 8
-70 32 -94 32 -8 0 -18 -9 -21 -20 l-6 -20 -25 0 c-13 0 -24 5 -24 10 0 6 5
10 10 10 6 0 10 9 10 20 l0 20 -15 0 -15 0 0 -22 -1 -23 -18 23 c-11 12 -28
22 -39 22 l-20 0 -5 20 -5 20 -76 0 -76 0 0 10 0 9 -55 0 c-30 -1 -55 -5 -55
-10 0 -5 -16 -9 -35 -9 l-35 0 0 14 0 14 -32 5 c-18 3 -61 6 -95 6 l-62 1 -6
-10 c-3 -5 -15 -10 -26 -10 l-20 0 7 11 c6 10 -13 30 -26 28 -3 -1 -41 -6 -85
-11z m-575 -119 c0 -5 -7 -9 -15 -9 l-14 0 5 15 c6 15 24 11 24 -6z m1485 -51
c4 -13 9 -31 11 -40 l5 -18 -10 0 c-6 0 -11 14 -11 30 l0 30 -20 0 c-11 0 -20
5 -20 10 0 6 9 10 19 10 l20 0 6 -22z m-1768 -15 c-4 -3 -7 0 -7 7 0 7 3 10 7
7 3 -4 3 -10 0 -14z m1943 -27 c0 -3 -5 -8 -12 -12 l-11 -7 7 11 c7 12 16 16
16 8z m-2213 -53 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m2093 -9
l0 -25 -10 6 c-5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z m-2173
-31 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m133 -33 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -20 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m80 0 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1970
0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-160 -60 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-243 -167 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m80 -10
c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z m-2327 -54 c0 -10 -4 -19
-10 -19 -5 0 -10 12 -10 26 l0 25 10 -6 c6 -3 10 -15 10 -26z m2030 -69 c0 -5
-9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-2189
-57 l-10 -18 -1 17 c0 9 3 25 7 35 l7 18 4 -17 c2 -10 -1 -25 -7 -35z m2156
-10 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-2180 -20 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-97 -33 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m127 -7 c-4 -3 -7 0 -7 7 0 7 3 10
7 7 3 -4 3 -10 0 -14z m-480 -400 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0
-14z m1500 -160 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-1627 -33
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m1367 -127 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-1527 -50 c0 -5
-5 -15 -10 -23 l-10 -15 0 23 c0 12 5 22 10 22 6 0 10 -3 10 -7z m-53 -50 c-4
-3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m13 -73 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -30 c0 -11 5 -20 10
-20 6 0 10 -4 10 -10 0 -15 -19 -12 -36 4 l-14 15 6 15 c9 23 24 20 24 -4z
m-63 -17 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m153 -13 c0 -5 -9
-10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-160 -121
c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 l0 15 10 -6 c6 -3 10 -10 10 -16z m-20
-79 c0 -32 -14 -40 -24 -14 -3 8 0 21 7 29 6 8 13 14 15 15 1 0 2 -13 2 -30z
m1260 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-1414 -365 l15 -15 -15 -15 c-8 -8 -17 -15 -19 -15 -3 0 -7 7 -11 15 -5
15 2 45 11 45 2 0 11 -7 19 -15z m-19 -117 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m33 -118 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-89 -76 l-9 -39 -1 25 c-1 30 11 79 16 64 2 -6 -1
-28 -6 -50z m46 29 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m1110
-125 l-5 -13 -1 19 c-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-47 -38 l0 -20 -20 0
c-23 0 -25 5 -7 25 17 20 27 18 27 -5z m-353 -317 c-4 -3 -7 0 -7 7 0 7 3 10
7 7 3 -4 3 -10 0 -14z m-140 -320 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0
-14z m40 0 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-27 -123 c0 -11
-4 -20 -10 -20 -5 0 -10 -9 -10 -20 l0 -20 15 0 15 0 0 20 0 20 15 0 c8 0 15
-7 15 -16 0 -23 -25 -44 -54 -44 l-26 0 0 50 0 50 20 0 20 0 0 -20z m-20 -130
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-563 -27 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m43 -53 c0 -16 -4
-30 -10 -30 -5 0 -10 6 -10 14 0 8 -3 21 -6 30 l-6 16 16 0 16 0 0 -30z m360
-260 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m20 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-263 -167 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m43 -73 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0
-120 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m90 -120 c0 -5 -4 -10 -10 -10 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -9 -20 -16 0
-21 31 -8 45 14 17 37 20 37 5z m88 -10 c-7 -11 -14 -16 -16 -10 -2 5 -12 10
-23 10 -10 0 -19 5 -19 10 0 6 16 10 35 10 l35 0 -12 -20z m-58 -50 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m47 -27 c-4
-3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M2430 6525 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M2370 6518 c0 -7 3 -23 6 -35 l6 -23 24 0 24 0 0 18 c0 21 -29 52
-48 52 -7 0 -12 -6 -12 -12z"></path><path d="M4816 6435 l-28 -24 7 -28 7 -28 -21 24 -21 24 -14 -13 c-9 -6 -18
-20 -21 -31 l-6 -19 -25 0 -25 0 -5 -15 c-4 -8 -12 -15 -19 -15 -7 0 -15 -9
-18 -19 l-5 -20 -19 5 -19 5 -23 -20 c-13 -12 -28 -21 -33 -21 -4 0 -8 -13 -8
-30 0 -16 5 -30 10 -30 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 9 -10
20 l0 20 -35 0 -35 0 0 -20 0 -20 15 0 15 0 0 -20 0 -20 -14 0 c-8 0 -21 7
-30 15 -8 8 -28 15 -45 15 l-31 0 0 -11 c0 -6 10 -19 22 -29 l22 -17 -16 -17
c-9 -9 -19 -16 -22 -16 -3 0 -6 14 -6 30 l0 30 -21 0 -20 0 -10 -30 c-6 -16
-17 -30 -25 -30 -8 0 -14 -4 -14 -10 0 -5 -9 -10 -20 -10 -24 0 -70 -42 -70
-65 0 -18 30 -21 30 -2 0 6 6 3 12 -8 6 -11 16 -23 22 -27 l11 -8 -11 -7 c-7
-5 -14 -19 -17 -33 l-4 -25 -2 38 -1 37 -20 0 -20 0 0 -20 c0 -11 -4 -20 -10
-20 -5 0 -10 9 -10 20 l0 20 -19 0 c-11 0 -22 -6 -24 -12 l-5 -13 -1 13 c-1
17 -31 15 -31 -2 0 -8 6 -17 13 -19 6 -3 -7 -6 -29 -6 l-42 -1 -5 -20 c-3 -11
-12 -20 -21 -20 -20 0 -56 -30 -56 -47 0 -6 -12 -13 -27 -15 l-28 -3 -3 -27
-3 -28 -25 0 c-13 0 -24 -4 -24 -10 0 -5 -7 -10 -15 -10 l-15 0 0 -20 0 -20
-15 0 c-8 0 -15 -4 -15 -9 0 -5 -16 -15 -35 -21 l-35 -12 0 -16 c0 -18 -41
-42 -71 -42 -22 0 -42 -25 -37 -47 l4 -18 22 1 c23 1 29 -10 11 -22 -6 -3 -16
-1 -22 5 -6 6 -19 11 -29 11 l-18 0 0 -15 c0 -8 -7 -15 -15 -15 -8 0 -15 -4
-15 -10 0 -5 -16 -10 -35 -10 l-35 0 0 -15 0 -15 25 -6 25 -6 -1 -22 c0 -11
-3 -15 -6 -8 -2 6 -11 12 -18 12 -7 0 -16 -6 -18 -12 -3 -7 -6 -3 -6 10 l-1
22 -17 0 c-25 -1 -53 -24 -53 -44 0 -9 -3 -16 -7 -16 -5 0 -17 -13 -28 -30
l-20 -29 -24 6 -24 6 7 -47 7 -46 -10 0 c-6 0 -11 14 -11 30 l0 30 -12 0 c-7
0 -21 -7 -30 -14 l-18 -13 0 -32 c0 -17 5 -31 10 -31 6 0 10 -7 10 -15 l0 -15
-18 0 c-10 0 -24 9 -32 20 -17 24 -40 26 -40 4 0 -9 -13 -29 -29 -45 l-29 -28
-22 6 -21 6 -6 -22 c-3 -11 -12 -21 -20 -21 -8 0 -17 -9 -20 -20 l-5 -20 -20
0 -20 0 -5 -19 c-3 -11 -13 -21 -24 -24 l-19 -5 0 -31 0 -30 -13 5 c-22 8 -65
-13 -72 -35 -3 -12 -13 -21 -21 -21 l-14 0 0 -20 0 -20 -20 0 c-11 0 -20 -4
-20 -9 0 -5 -9 -11 -21 -14 l-21 -6 3 -18 c2 -10 -1 -39 -5 -65 l-7 -48 -30 0
-29 0 0 20 c0 25 -18 26 -27 3 -9 -23 -29 -43 -43 -43 -6 0 -10 -8 -8 -17 l4
-18 -26 -25 c-15 -14 -32 -34 -38 -45 -6 -11 -12 -14 -12 -7 0 16 -30 16 -30
-1 0 -14 -35 -47 -49 -47 -5 0 -12 -15 -16 -32 -4 -18 -9 -27 -12 -20 -9 22
-24 13 -39 -24 -18 -44 -37 -64 -58 -64 l-16 0 0 -24 0 -24 -22 -6 c-28 -7
-108 -75 -108 -92 0 -6 -7 -14 -15 -18 -8 -3 -15 -12 -15 -21 0 -8 -6 -15 -14
-15 -16 0 -31 -34 -21 -50 l6 -10 -15 0 c-9 0 -16 -4 -16 -10 0 -5 -7 -10 -15
-10 -8 0 -19 -9 -25 -20 -6 -11 -17 -20 -24 -20 -15 0 -31 -34 -42 -90 -4 -19
-9 -29 -11 -22 -3 6 -11 12 -19 12 l-14 0 0 -19 0 -19 -30 -12 -30 -12 0 -19
0 -19 18 -1 c9 0 2 -5 -15 -10 l-33 -10 0 -18 c0 -25 -38 -67 -59 -63 l-16 2
-19 -62 c-11 -35 -21 -57 -23 -50 -6 17 -33 15 -33 -3 0 -8 -7 -15 -15 -15
l-15 0 0 -25 c0 -14 -4 -25 -10 -25 -5 0 -10 -9 -10 -20 0 -11 5 -20 10 -20 6
0 10 -4 10 -10 0 -5 -12 -10 -26 -10 l-26 0 -34 -37 -34 -38 5 -23 c7 -30 -10
-60 -37 -64 l-23 -3 3 -27 3 -28 -20 0 -19 0 -7 -27 c-3 -15 -16 -35 -27 -45
l-20 -17 6 -16 c7 -18 33 -20 39 -2 2 6 8 9 12 5 9 -8 -12 -31 -37 -41 l-18
-6 -1 -28 -1 -28 -5 18 c-5 20 -33 23 -33 3 0 -8 -7 -17 -16 -20 l-15 -6 1
-22 c1 -13 2 -30 1 -40 l-1 -16 -18 5 -19 4 -26 -59 c-15 -33 -27 -65 -27 -71
l0 -11 40 0 40 0 0 -10 0 -10 -44 0 -44 0 -6 -24 c-3 -14 -3 -31 0 -39 l6 -15
-23 -4 -23 -3 -8 -39 c-5 -22 -15 -59 -23 -83 -8 -24 -15 -49 -15 -55 l0 -10
20 5 c11 3 20 2 20 -3 0 -4 -10 -10 -21 -13 l-21 -6 6 -25 6 -26 -15 0 c-8 0
-15 -7 -15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 8
0 15 5 15 10 0 6 9 10 20 10 l20 0 0 20 c0 11 5 20 10 20 l10 0 0 -40 0 -40
-20 0 c-11 0 -20 -4 -20 -9 0 -5 -12 -11 -27 -13 l-28 -3 -8 -30 c-5 -16 -13
-37 -17 -46 -4 -8 -5 -38 -2 -65 l5 -49 19 -16 c10 -9 18 -28 18 -43 l0 -26
-20 0 -20 0 0 30 0 30 -14 0 -13 0 -7 -53 c-8 -67 -8 -287 0 -354 l7 -53 12 0
13 0 6 -36 c4 -20 4 -49 1 -65 l-7 -29 16 -15 c8 -8 22 -15 30 -15 9 0 16 -4
16 -10 0 -5 -6 -10 -14 -10 -14 0 -26 -21 -26 -46 0 -8 7 -14 15 -14 l14 0 -5
-15 -6 -16 19 -17 c11 -9 23 -33 27 -52 12 -61 30 -120 37 -120 4 0 12 -13 19
-30 7 -16 19 -30 27 -30 l15 0 -7 -30 -7 -30 16 0 15 0 11 -41 11 -41 19 5 19
5 5 -14 c3 -8 3 -21 0 -29 -8 -19 19 -45 46 -45 l21 0 -6 -10 c-8 -13 4 -50
16 -50 17 0 39 -28 39 -49 l0 -21 14 0 c8 0 17 11 20 25 10 39 26 29 26 -15
l0 -40 24 -6 24 -6 -5 -18 -5 -18 21 -7 21 -6 0 -20 c0 -10 4 -19 10 -19 5 0
12 -11 16 -25 3 -14 12 -25 20 -25 l14 0 0 31 0 30 11 -6 10 -7 -6 -29 -7 -30
31 -12 c17 -6 31 -15 31 -19 0 -4 7 -8 15 -8 8 0 15 5 15 10 0 6 7 10 15 10
l15 0 -6 -25 -6 -24 13 -15 c27 -29 35 -41 42 -58 l6 -18 31 0 30 0 0 -21 0
-21 20 5 c10 3 21 0 23 -6 l3 -10 27 7 27 6 0 23 0 22 10 -15 c5 -8 10 -23 10
-33 l0 -19 -20 -5 -20 -5 0 -19 0 -19 19 0 c11 0 21 4 23 8 2 5 14 5 28 1 l25
-7 -17 -1 c-10 -1 -18 -8 -18 -16 0 -18 26 -20 33 -2 l5 12 1 -12 c1 -7 10
-13 21 -13 l20 0 -6 -25 -6 -24 41 -7 c23 -4 41 -10 41 -15 0 -5 7 -9 16 -9
18 0 44 -24 44 -40 l0 -12 21 5 21 6 20 -17 c11 -9 26 -23 33 -30 20 -18 35
-15 35 8 0 11 3 20 6 20 13 0 33 -35 34 -58 l0 -22 24 -12 25 -11 16 17 17 17
-6 14 c-3 8 -12 15 -21 15 -8 0 -15 5 -15 10 0 6 -18 29 -39 50 l-39 40 -21 0
c-12 0 -21 -6 -22 -12 0 -7 -5 -3 -11 9 -6 13 -17 23 -25 23 -7 0 -13 5 -13
10 0 6 7 10 15 10 23 0 18 24 -12 57 -16 16 -37 46 -47 66 -11 20 -25 37 -33
37 l-13 0 0 20 0 20 -16 0 -16 0 6 -34 c8 -39 -1 -66 -20 -66 l-14 0 0 24 c0
13 -3 31 -6 40 l-6 16 16 0 16 0 6 25 6 24 -16 16 c-9 9 -16 21 -16 26 0 5 -7
9 -15 9 l-14 0 5 14 c6 17 -24 86 -37 86 -5 0 -9 9 -9 20 l0 20 -19 0 -18 0 4
23 c6 29 -27 107 -44 107 -7 0 -13 -8 -13 -17 l-1 -18 -10 18 -11 19 6 42 7
42 -19 22 -19 22 -37 0 c-20 0 -36 5 -36 10 l0 10 40 0 40 0 0 16 c0 8 -7 22
-15 30 l-15 15 0 60 c0 32 -4 59 -9 59 -5 0 -12 39 -16 87 -4 49 -12 96 -17
106 l-9 18 2 272 c2 150 3 305 3 345 l-1 72 14 0 13 0 0 60 0 60 10 0 c6 0 10
11 10 25 0 14 -4 25 -9 25 -4 0 -6 11 -3 25 l4 25 16 0 15 0 -7 48 c-3 26 -11
49 -16 50 -13 5 -13 42 0 42 6 0 10 -9 10 -20 l0 -20 14 0 14 0 11 63 c6 35
11 67 11 70 0 4 -7 7 -15 7 -8 0 -15 5 -15 10 0 6 4 10 9 10 5 0 13 9 16 21 4
11 16 24 28 28 l22 9 14 46 13 46 -6 25 -6 25 10 0 c6 0 10 8 9 18 -3 33 1 42
21 42 l20 0 0 16 c0 18 -23 44 -39 44 -6 0 -11 5 -11 10 0 6 7 10 15 10 8 0
15 -4 15 -10 0 -5 7 -10 16 -10 l16 0 -5 23 c-2 12 -8 30 -12 40 l-7 17 26 0
26 0 0 15 c0 9 11 27 25 41 14 14 25 34 25 44 0 11 4 20 10 20 5 0 14 17 20
38 6 20 17 46 25 56 8 11 15 30 15 43 0 13 5 23 11 23 l11 0 -4 40 -4 40 19 0
c10 0 24 6 30 13 21 22 40 65 33 76 l-7 11 15 0 16 0 0 20 0 20 -15 0 c-8 0
-15 5 -15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 7 10 16 10 19 0 50 33 60 63
4 12 13 27 20 35 8 7 14 24 14 38 l0 24 -15 0 c-8 0 -15 5 -15 10 0 6 14 10
31 10 l32 0 13 18 c7 9 14 25 14 35 0 9 6 17 14 17 13 0 26 28 26 58 0 6 -9
12 -20 12 -11 0 -20 5 -20 10 0 6 16 10 35 10 l35 0 0 16 c0 15 23 44 35 44 4
0 13 6 21 14 16 16 19 86 4 86 -5 0 -10 5 -10 10 0 6 7 10 15 10 8 0 15 -4 15
-10 0 -5 6 -10 14 -10 l14 0 6 31 c9 45 8 49 -10 49 l-16 0 6 16 c3 9 6 23 7
32 l0 17 11 -22 c12 -25 33 -30 41 -10 l5 12 1 -12 c2 -33 50 4 51 40 0 9 5
17 10 17 6 0 10 7 10 15 0 8 5 15 11 15 l12 0 -6 30 -6 30 25 0 26 0 -4 24 -5
25 13 5 c8 3 14 1 14 -4 0 -6 6 -10 13 -10 15 0 47 55 47 81 l0 19 25 6 25 6
0 23 0 23 30 11 30 10 0 20 0 21 -16 0 c-18 0 -54 36 -54 54 0 6 5 3 11 -6 14
-25 46 -40 69 -33 l20 6 0 20 0 19 20 0 c11 0 20 5 20 10 0 6 7 10 15 10 19 0
30 43 16 68 -16 29 -14 33 13 26 l23 -5 8 43 c4 24 9 37 12 31 3 -7 13 -13 24
-13 l19 0 0 15 c0 8 7 15 16 15 l16 0 -5 20 -5 20 29 0 29 0 0 15 c0 8 -5 15
-11 15 l-10 0 15 15 c8 8 21 15 30 15 8 0 18 9 21 20 l6 20 25 0 24 0 0 38 c0
20 4 42 8 49 4 6 21 13 37 15 l30 3 3 28 3 27 24 0 c33 0 55 10 55 25 0 7 6
15 14 18 8 3 17 19 21 36 l7 31 24 0 24 0 0 25 c0 14 5 25 10 25 6 0 10 9 10
20 l0 20 24 0 25 0 6 20 c3 11 3 24 0 30 l-6 10 30 0 31 0 0 20 c0 11 -4 20
-10 20 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -9 10 -20 l0 -20 31 0 31 0
-5 20 -5 20 24 0 24 0 0 19 0 20 22 7 c44 14 65 54 28 54 l-20 0 0 14 c0 8 3
22 6 30 l6 16 19 -21 c11 -12 19 -26 19 -31 0 -4 5 -8 11 -8 16 0 39 26 39 44
0 9 7 16 15 16 l15 0 0 26 0 25 21 -6 20 -7 18 21 c10 12 23 21 29 21 14 0 33
67 27 94 -3 12 -1 28 5 36 l10 15 10 -15 9 -15 21 13 c11 6 31 12 46 12 l26 0
11 30 c6 17 17 30 24 30 6 0 15 9 18 20 3 11 13 20 22 20 l15 0 -5 20 -5 20
29 0 c16 0 29 4 29 10 0 5 11 12 25 16 14 3 25 12 25 20 0 8 7 14 15 14 l15 0
0 19 c0 11 5 23 10 26 6 3 10 17 10 31 l0 24 15 0 c8 0 15 -7 15 -15 0 -22 23
-18 45 7 l19 23 1 52 0 53 14 0 13 0 -5 21 -6 21 16 -6 c8 -3 17 -6 19 -6 2 0
4 -7 4 -15 0 -8 -6 -15 -14 -15 -15 0 -32 -37 -23 -47 3 -3 15 -2 26 2 l21 6
1 22 c0 12 3 16 6 10 2 -7 11 -13 19 -13 17 0 19 26 2 33 l-13 5 17 1 17 1 4
23 4 22 2 -22 c1 -27 16 -29 39 -7 l16 17 0 33 0 34 13 0 c7 0 13 7 13 15 l0
15 21 -12 22 -13 4 13 c3 6 13 12 24 12 l19 0 0 15 0 15 -20 0 c-11 0 -20 5
-20 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 16 -13 12 -44 -15z m-89 -132
c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-393 -335 l6 -28 -9 0 c-13
0 -21 27 -14 45 7 19 8 18 17 -17z m-53 -101 l-11 -11 -7 9 c-5 4 -2 10 5 12
6 3 13 14 13 26 l2 22 4 -24 c3 -13 0 -28 -6 -34z m-151 -37 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-90 -40 c0 -5 -9
-10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m80 -24 c0
-8 8 -17 18 -19 9 -3 0 -5 -20 -6 l-38 -1 0 -22 c0 -27 -5 -21 -14 15 l-6 27
15 0 c8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 -6 15 -14z m-283 -103 c-4 -3 -7
0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m43 -13 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-198 -145 l-4 -25 21 0 c11 0 21
-4 21 -10 0 -5 -9 -10 -20 -10 l-20 0 -6 24 c-3 14 -3 30 1 35 10 17 13 13 7
-14z m-215 -202 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-107 -73
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m227 -7 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m20 -20 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-280 -20 c-4 -3 -7 0 -7 7 0 7 3 10 7 7
3 -4 3 -10 0 -14z m210 -70 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z
m-480 -70 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-40 -40 c-4 -3
-7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m283 -47 c0 -16 -14 -36 -26 -36
l-14 0 0 20 0 20 20 0 c11 0 20 -2 20 -4z m-430 -106 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m240 -200 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-83 -67 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-490 -80 c-4 -3 -7 0 -7 7 0 7 3 10 7 7
3 -4 3 -10 0 -14z m400 -20 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z
m-667 -253 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-43 -47 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m403 -73
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-550 -80 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m467 -67 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-60 -75
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m59 5 c-10 -10 -19 5
-10 18 l8 13 4 -13 c2 -7 1 -15 -2 -18z m-608 -30 l-18 -4 0 -22 0 -22 -9 14
c-13 21 -2 41 23 40 l21 -1 -17 -5z m-31 -100 c-4 -3 -7 0 -7 7 0 7 3 10 7 7
3 -4 3 -10 0 -14z m-37 -13 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m-80 -90 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0
11 5 20 10 20 6 0 10 -9 10 -20z m560 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 -15
-11 -32 l-1 -33 -8 29 -9 28 12 14 c14 16 27 18 27 4z m-683 -67 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m580 -210 c-4 -3 -10 -3 -14 0 -3 4 0 7
7 7 7 0 10 -3 7 -7z m-110 -150 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0
-14z m-667 -13 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-43 -107 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m567
-119 c9 -22 8 -24 -9 -24 l-15 0 0 20 c0 24 15 27 24 4z m-714 -74 c0 -5 -7
-10 -16 -10 l-15 0 6 10 c3 6 10 10 16 10 5 0 9 -4 9 -10z m30 -144 c0 -3 -7
-6 -15 -6 l-15 0 0 21 0 20 15 -15 c8 -8 15 -17 15 -20z m560 -246 l0 -20 -15
0 -15 0 0 20 0 20 15 0 15 0 0 -20z m-40 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0 -80 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-700 -70 l0 -20 -16 0 c-15 0
-44 23 -44 34 0 3 14 6 30 6 l30 0 0 -20z m720 0 l0 -20 -15 0 c-8 0 -15 2
-15 4 0 2 -3 11 -6 20 l-6 16 21 0 21 0 0 -20z m-720 -90 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-30 -479 c0 -5 -7
-16 -15 -25 -8 -8 -15 -23 -16 -33 l-1 -18 -5 13 c-2 6 -11 12 -19 12 l-14 0
0 30 0 30 35 0 c19 0 35 -4 35 -9z m20 -161 c0 -5 7 -10 15 -10 8 0 15 -4 15
-10 0 -15 -19 -12 -36 4 -16 17 -18 26 -4 26 6 0 10 -4 10 -10z m97 -327 c-4
-3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m50 0 c-4 -3 -7 0 -7 7 0 7 3 10
7 7 3 -4 3 -10 0 -14z m43 -117 c0 -3 -6 -6 -14 -6 -8 0 -17 -6 -19 -12 l-5
-13 -11 15 c-15 21 -14 30 4 30 8 0 15 5 15 11 l0 10 15 -15 c8 -8 15 -17 15
-20z m540 -256 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m16 -67 c-11 -11 -17 4 -9 23 l7 19 4 -18 c2 -10 1 -21 -2 -24z
m-236 -33 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m380 -120 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0
15 -4 15 -10z m20 -35 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 -7 10 -15z m-40 -25 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m6 -75 c4 -14 4 -29 0 -32 -3 -4 -6 3 -6 15 0 12 -4
22 -10 22 -5 0 -10 5 -10 10 0 21 20 9 26 -15z m44 -45 c0 -5 -7 -10 -15 -10
-8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-123 -27 c-4 -3 -7 0 -7
7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m133 -33 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 -60 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m107 -87 c-4 -3 -7 0 -7 7 0 7
3 10 7 7 3 -4 3 -10 0 -14z m40 -29 c-3 -3 -12 -4 -19 -1 l-13 5 19 1 c11 1
17 -2 13 -5z"></path><path d="M5275 6245 l-6 -55 15 -15 c9 -9 20 -34 26 -55 l11 -40 16 0 c8 0 24
10 34 22 20 22 23 53 13 107 l-6 31 -19 0 -19 0 -1 -22 c-1 -26 -3 -20 -19 40
l-11 42 -13 0 -14 0 -7 -55z"></path><path d="M5000 6050 c0 -5 -7 -10 -15 -10 -15 0 -35 -37 -35 -66 0 -8 7 -17
16 -21 l17 -6 12 16 c7 10 20 17 29 17 20 0 20 -7 2 -26 -18 -17 -66 -18 -101
-2 l-26 12 -7 -10 c-4 -5 -16 -14 -27 -20 l-20 -11 -9 -44 c-5 -24 -11 -38
-13 -31 -3 6 -9 12 -15 12 -13 0 -38 -26 -38 -39 0 -6 -6 -11 -14 -11 -8 0
-21 -6 -28 -14 -8 -8 -23 -16 -34 -18 -10 -2 -20 -10 -20 -18 -1 -8 -2 -20 -3
-27 l-1 -12 -16 6 c-9 3 -19 2 -23 -3 -3 -5 -15 -14 -25 -20 -11 -6 -22 -16
-24 -22 -5 -17 24 -15 42 4 l16 15 0 -18 c0 -10 -5 -25 -10 -33 l-10 -15 0 13
c0 18 -27 15 -39 -5 l-10 -18 -1 18 c0 24 -21 22 -27 -3 -3 -11 -9 -20 -14
-20 -5 0 -9 -9 -9 -20 l0 -20 -16 0 c-18 0 -44 -23 -44 -39 0 -6 7 -11 15 -11
l14 0 -6 -17 -7 -17 -4 12 c-6 20 -22 14 -53 -19 -16 -17 -29 -26 -29 -20 0
20 -25 12 -44 -14 -10 -14 -25 -25 -32 -25 l-14 0 0 -35 0 -35 -40 0 -40 0 0
-20 c0 -11 -4 -20 -10 -20 -5 0 -10 -12 -10 -26 0 -28 -21 -54 -43 -54 -9 0
-17 5 -19 12 -7 19 -26 0 -33 -32 -4 -17 -11 -30 -16 -30 -5 0 -9 -7 -9 -15
l0 -15 29 0 29 0 5 -20 5 -20 41 0 41 0 10 -22 c15 -38 36 -58 58 -58 l21 0 6
-20 c3 -11 11 -20 16 -20 5 0 19 -9 31 -20 12 -11 28 -20 35 -20 15 0 53 -31
53 -43 0 -16 28 -37 49 -37 12 0 21 -4 21 -10 0 -5 7 -10 15 -10 8 0 15 -3 15
-7 0 -5 13 -22 28 -40 43 -51 62 -76 62 -85 0 -4 4 -8 10 -8 9 0 35 -19 65
-47 7 -7 11 -21 8 -31 l-5 -19 21 -6 21 -5 0 -24 0 -23 25 -19 c14 -10 25 -27
25 -37 0 -11 5 -19 10 -19 6 0 10 -9 10 -20 0 -11 7 -23 15 -26 8 -3 20 -24
26 -45 l12 -39 14 0 13 0 0 -20 c0 -11 4 -20 8 -20 5 0 17 -18 27 -40 10 -22
21 -40 25 -40 4 0 11 -16 15 -36 3 -20 13 -43 20 -50 14 -14 45 -109 45 -138
0 -9 7 -33 16 -54 9 -20 16 -56 15 -79 l0 -43 24 0 25 0 0 -19 0 -19 -22 6
-21 5 7 -24 c19 -69 36 -228 36 -341 l0 -128 10 0 c6 0 10 -4 10 -10 0 -5 -7
-10 -15 -10 l-15 0 0 -42 c-1 -39 -5 -66 -26 -150 l-6 -28 16 0 c9 0 16 4 16
10 0 5 7 7 15 4 8 -4 15 -12 15 -20 0 -14 -10 -17 -45 -15 l-21 2 0 -53 0 -53
-17 -34 c-9 -19 -17 -49 -17 -68 0 -18 -4 -33 -10 -33 -5 0 -10 -8 -10 -18 0
-11 -9 -29 -20 -42 -11 -13 -20 -36 -20 -52 0 -15 -4 -28 -10 -28 -5 0 -14
-18 -20 -40 -6 -21 -16 -45 -23 -52 -12 -13 -27 -49 -51 -115 l-11 -33 -18 0
-17 0 -6 -30 c-4 -16 -10 -30 -14 -30 -4 0 -13 -14 -20 -30 -7 -17 -20 -36
-29 -43 -10 -7 -23 -28 -31 -47 -17 -42 -33 -60 -54 -60 -8 0 -17 -8 -21 -19
-3 -10 -17 -31 -31 -47 l-25 -29 6 -22 5 -23 -10 0 c-5 0 -10 9 -10 20 l0 20
-15 0 -15 0 0 -30 0 -30 -30 0 -30 0 0 -19 c0 -11 6 -22 13 -24 6 -3 0 -6 -14
-6 l-25 -1 -48 -45 c-27 -25 -53 -45 -57 -45 -5 0 -9 -4 -9 -10 0 -5 -9 -10
-20 -10 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -21 -10 l-21 0 6 -25 6 -24 -16 6
c-8 3 -25 1 -37 -5 -12 -6 -36 -16 -54 -23 -17 -7 -34 -21 -36 -31 l-5 -18
-21 0 c-12 0 -21 -3 -21 -8 0 -10 -62 -37 -77 -34 -7 2 -23 -6 -35 -18 -12
-11 -32 -20 -45 -20 -13 0 -23 -4 -23 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4
-15 -10 0 -5 -17 -10 -38 -10 l-39 0 -23 -20 -23 -20 -34 0 c-18 0 -33 -4 -33
-10 0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 l0 -10 -50 0 -50 0 0 -10 0
-10 -69 0 -69 0 -6 -12 -6 -13 -6 13 c-7 16 -51 16 -56 0 l-4 -12 -130 0 c-71
0 -150 4 -176 8 l-48 9 0 -17 c0 -9 5 -16 11 -16 5 0 7 -4 4 -10 l-6 -10 -70
0 -71 0 4 13 c3 6 14 13 26 15 12 2 22 10 22 18 l0 14 -20 0 c-11 0 -20 5 -20
10 0 6 -16 10 -35 10 -19 0 -35 -4 -35 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 -11 10 -25 10 -14 0 -25 5 -25 10 0 6 -11 10 -25 10 -14 0 -25 5
-25 10 l0 10 -40 0 -40 0 0 -11 0 -10 -15 15 c-8 8 -15 21 -15 28 l0 13 -40
13 c-22 6 -47 12 -55 12 -8 0 -15 5 -15 10 0 6 -12 10 -26 10 -14 0 -37 9 -51
20 -14 11 -32 20 -39 20 l-14 0 1 -50 c1 -28 4 -50 8 -50 3 0 7 -39 9 -88 2
-48 7 -88 13 -90 5 -2 9 -17 9 -33 l0 -29 29 0 29 0 7 -31 7 -31 -13 -9 c-8
-5 -20 -9 -26 -9 -7 0 -13 -9 -13 -20 0 -11 6 -20 13 -20 l13 0 12 -45 c7 -25
12 -61 12 -80 l0 -35 21 0 21 0 -6 -16 c-3 -8 -3 -26 0 -40 l6 -24 19 0 19 0
1 18 0 17 9 -20 c13 -29 12 -35 -5 -35 -19 0 -19 -29 0 -36 l15 -6 0 -29 c0
-16 5 -29 10 -29 6 0 10 -13 10 -30 l0 -30 19 -11 18 -12 -5 -19 -4 -18 11 0
c6 0 11 -6 11 -14 0 -13 28 -26 57 -26 6 0 15 9 18 20 l6 20 25 0 c13 0 24 -4
24 -10 0 -5 -9 -10 -20 -10 l-20 0 0 -19 0 -19 -20 5 -20 5 0 -12 c0 -14 25
-40 39 -40 6 0 11 -9 11 -20 l0 -20 14 0 c8 0 17 6 20 13 3 6 3 0 0 -15 l-6
-26 13 -23 c26 -49 59 -65 59 -29 0 11 3 20 6 20 12 0 34 -29 34 -44 0 -32 25
-96 37 -96 l13 0 0 -30 c0 -16 4 -30 9 -30 5 0 11 -9 14 -20 3 -11 12 -20 20
-20 8 0 20 -14 27 -30 13 -31 34 -40 43 -17 l5 12 10 -14 10 -14 28 8 c16 4
86 10 157 12 l127 4 0 -10 c0 -6 7 -11 15 -11 8 0 15 5 15 10 l0 10 25 -6 25
-6 0 26 0 26 33 -1 32 -2 -27 -4 c-16 -2 -28 -8 -28 -13 0 -21 13 -40 28 -40
18 0 78 18 104 31 25 13 46 11 51 -3 l4 -13 24 12 c36 18 59 23 130 28 35 3
78 9 94 13 l30 8 3 21 c2 12 10 24 18 27 l14 5 0 -14 0 -15 24 0 c13 0 26 5
28 11 l4 11 57 0 57 1 0 28 c0 16 4 29 8 29 4 0 14 -9 22 -20 8 -11 21 -20 28
-20 l14 0 -5 20 -5 20 29 0 29 0 0 20 c0 22 16 27 23 8 l4 -13 26 14 26 14 36
-6 36 -6 5 14 c3 7 21 16 40 19 l34 7 0 14 0 15 24 0 25 0 6 20 6 19 17 -10
17 -11 28 11 c15 6 27 15 27 21 0 5 11 10 25 10 l25 0 0 20 c0 11 5 20 10 20
6 0 10 -4 10 -10 0 -5 7 -10 15 -10 8 0 15 7 15 16 0 22 25 44 49 44 l20 0 4
22 4 23 2 -22 c1 -28 21 -30 28 -3 3 11 9 20 14 20 17 0 9 36 -11 50 -23 17
-26 30 -6 30 7 0 19 -13 26 -30 12 -28 40 -42 40 -20 0 6 10 10 23 10 l22 0
13 20 12 20 24 0 c14 0 32 7 40 16 l16 15 1 47 c1 26 4 37 6 25 l4 -23 31 0
30 0 -4 25 -5 25 19 -6 19 -6 18 21 c10 12 23 21 29 21 6 0 16 9 22 20 l11 20
22 0 c29 0 43 10 51 38 l7 22 20 0 c24 0 54 27 63 58 l7 22 20 0 20 0 10 30
11 30 25 0 24 0 -2 18 c-1 9 -1 27 0 40 l1 22 15 0 c8 0 15 -4 15 -10 0 -5 7
-10 15 -10 l15 0 0 19 c0 11 -7 26 -15 35 l-15 15 10 6 c5 3 10 15 10 26 l0
19 -15 0 -15 0 0 30 c0 17 5 30 10 30 6 0 10 -9 10 -20 l0 -20 25 0 c24 0 35
-15 16 -22 -4 -1 -11 -20 -15 -40 l-7 -38 11 0 c6 0 22 11 35 25 l25 24 1 38
c1 21 3 30 6 21 2 -10 11 -18 19 -18 l14 0 0 23 c0 25 28 57 49 57 l13 0 -6
25 -7 26 27 -7 27 -7 -6 22 -5 21 13 0 c7 0 18 7 26 16 l13 16 -14 8 -13 8 24
4 24 3 5 35 5 35 20 0 20 0 0 20 c0 11 8 23 18 27 l17 7 0 36 0 35 11 0 c6 0
17 7 26 15 l15 16 -5 32 -4 31 11 11 c7 5 21 15 31 21 l20 11 6 37 6 36 21 19
c12 10 22 23 22 29 0 6 8 15 17 21 l18 9 -13 35 -12 34 9 24 10 24 0 -22 1
-23 14 0 c7 0 19 7 26 15 l12 15 -6 24 -6 24 21 5 22 6 -6 15 -6 15 14 15 c19
19 19 46 1 46 l-14 0 5 20 5 20 19 0 19 0 0 15 c0 9 -7 18 -15 21 -24 9 -17
24 10 24 l25 0 0 20 0 20 -15 0 c-24 0 -17 14 14 28 l28 13 7 49 c4 26 11 50
17 52 l11 4 -7 27 c-4 15 -13 27 -21 27 l-14 0 0 20 0 20 30 0 30 0 0 40 0 40
11 0 11 0 -6 35 -7 35 16 16 c8 8 15 28 15 44 l0 28 -15 6 -15 5 0 26 c0 14
-4 25 -10 25 l-10 0 0 40 0 40 10 0 c6 0 10 -13 10 -30 0 -16 5 -30 10 -30 6
0 10 -9 10 -20 l0 -20 16 0 16 0 -6 35 -7 34 15 88 c8 48 17 118 20 156 l5 67
-11 0 -11 0 7 115 7 115 -16 0 c-8 0 -15 7 -15 15 0 8 5 15 10 15 6 0 10 14
10 30 l0 30 -14 0 c-8 0 -17 -10 -19 -22 -2 -13 -5 0 -6 27 l-1 51 -20 14 -20
14 0 28 c0 15 5 28 10 28 6 0 10 -12 10 -26 0 -27 20 -54 40 -54 l12 0 -6 28
c-3 15 -8 70 -11 122 l-5 95 -13 10 -13 10 -12 85 c-7 47 -15 100 -18 118 l-6
32 -14 0 -14 0 0 -20 c0 -11 -3 -20 -6 -20 -14 0 -34 31 -34 54 l0 26 -14 0
c-8 0 -18 -9 -21 -20 l-6 -20 -20 0 c-10 0 -19 5 -19 10 0 6 5 10 10 10 6 0
10 14 10 30 0 17 -4 30 -10 30 -5 0 -10 9 -10 20 0 11 3 20 6 20 11 0 34 -29
34 -44 0 -20 26 -21 34 -1 3 8 12 15 21 15 8 0 15 7 15 15 l0 15 -20 0 c-11 0
-20 5 -20 10 0 6 -7 10 -15 10 l-15 0 0 30 c0 17 5 30 10 30 6 0 10 9 10 20
l0 20 -14 0 -14 0 10 37 10 37 -11 4 c-6 2 -11 12 -11 21 0 10 -9 28 -20 41
-11 13 -20 31 -20 42 l0 18 -21 0 -20 0 6 20 c3 10 1 28 -5 40 l-11 20 -34 0
c-37 0 -41 5 -25 30 l10 15 0 -12 c0 -7 9 -13 20 -13 l20 0 0 30 0 30 -14 0
-14 0 -6 36 c-4 19 -14 38 -21 41 -8 3 -12 9 -9 15 3 5 -3 18 -15 29 l-20 19
-30 0 -29 0 -5 13 c-3 8 -2 19 3 26 l7 13 6 -21 c5 -20 37 -30 37 -12 0 20
-13 41 -25 41 -15 0 -25 22 -25 54 l0 23 -35 36 c-19 20 -35 44 -35 54 0 25
-46 73 -71 73 l-19 0 -6 30 -6 30 -24 0 -23 0 6 30 6 30 -12 0 c-6 0 -11 9
-11 20 0 11 -4 20 -9 20 -5 0 -11 9 -14 21 l-6 21 -18 -2 c-10 -1 -26 -2 -35
-1 l-18 1 0 25 c0 26 -4 29 -52 39 l-28 6 0 31 0 30 -15 5 c-8 4 -15 15 -15
25 l0 19 -15 0 c-8 0 -20 9 -27 20 -7 11 -18 20 -25 20 -7 0 -13 5 -13 10 0 6
-8 10 -18 10 l-19 0 -23 50 c-13 28 -29 50 -37 50 -7 0 -13 -6 -14 -12 0 -7
-5 -3 -11 8 -5 12 -20 27 -33 34 l-23 12 5 19 5 19 -20 0 -20 0 -5 19 -5 19
-28 4 -29 3 -13 28 c-13 27 -32 37 -32 16 l0 -10 -13 13 c-7 7 -16 21 -20 31
-7 18 -37 24 -37 7z m220 -234 c0 -2 -3 -11 -6 -20 -9 -23 -24 -20 -24 4 l0
20 15 0 c8 0 15 -2 15 -4z m27 -33 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10
0 -14z m-570 -120 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m683 -33
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m176 -147 c-11 -11 -17 4 -9 23 l7 19 4 -18 c2 -10 1 -21 -2 -24z m-1029 -20
c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m1320 -320 c-4 -3 -7 0 -7 7
0 7 3 10 7 7 3 -4 3 -10 0 -14z m-1520 -60 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3
-4 3 -10 0 -14z m1533 -43 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20
10 20 6 0 10 -9 10 -20z m-1163 -257 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3
-10 0 -14z m1323 -59 c0 -9 10 -25 22 -35 11 -11 19 -22 15 -25 -9 -9 -57 42
-57 61 0 8 5 15 10 15 6 0 10 -7 10 -16z m-1080 -306 l0 -23 -10 15 c-13 19
-13 30 0 30 6 0 10 -10 10 -22z m47 -15 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3
-10 0 -14z m1361 -863 c6 -22 12 -67 12 -100 l0 -60 -10 0 c-5 0 -10 -13 -10
-30 0 -16 -4 -30 -10 -30 l-10 0 0 130 c0 72 3 130 8 130 4 0 13 -18 20 -40z
m39 -212 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-27 -98 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1233
-367 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m1110 -160 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-17 -53 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-10 -60 c0 -5 7 -10 15 -10 8 0
15 -2 15 -4 0 -2 3 -11 6 -20 l6 -16 -16 0 c-9 0 -16 -4 -16 -10 0 -5 -4 -10
-10 -10 -5 0 -10 14 -10 30 l0 30 -20 0 c-11 0 -20 5 -20 10 0 6 11 10 25 10
14 0 25 -4 25 -10z m-1243 -107 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0
-14z m-40 -60 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m1133 -193 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-36
-106 c9 -22 8 -24 -9 -24 l-15 0 0 20 c0 24 15 27 24 4z m26 -50 c0 -3 -7 -12
-15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24 10z m-1513 -131 c-4 -3 -7 0 -7
7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m1384 -33 c5 0 9 -8 8 -17 l-1 -18 -5 13
c-2 6 -11 12 -19 12 l-14 0 0 -20 0 -20 15 0 15 0 0 -20 0 -20 -15 0 -15 0 0
19 c0 11 -4 21 -9 23 -5 2 -7 16 -4 31 l6 28 14 -5 c8 -3 19 -6 24 -6z m-61
-60 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-7 -102 c-8 -46 -13 -48 -13 -5 0 21 4 37 10 37 l10 0 -7 -32z m-1686 -65
c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-1717 -93 c0 -16 -4 -30 -9
-30 -13 0 -21 27 -15 45 10 25 24 17 24 -15z m3270 0 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3306 -106 c3 -9 6 -27 6
-40 l0 -24 -15 0 -15 0 0 40 c0 40 13 53 24 24z m2966 -214 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2770 -160 c0 -5
-16 -10 -35 -10 -19 0 -35 5 -35 10 0 6 16 10 35 10 19 0 35 -4 35 -10z m2620
-20 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m-2660 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m2667 -7 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-140 -100
c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-2449 -163 c-7 -10 -14 -18
-15 -16 -6 6 9 36 18 36 5 0 4 -9 -3 -20z m49 3 c-4 -3 -7 0 -7 7 0 7 3 10 7
7 3 -4 3 -10 0 -14z m2083 7 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m-310 -156 c0 -11 -29 -34 -44 -34 -9 0 -16 -5 -16
-12 0 -6 -2 -9 -5 -6 -3 3 0 17 6 32 l12 26 24 0 c12 0 23 -3 23 -6z m-1640
-24 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m1690 4 c0 -3 -7 -12 -15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24 10z m-500
-144 l0 -10 -50 0 -50 0 0 10 0 10 50 0 50 0 0 -10z m160 0 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1263 -7 c-4 -3 -10
-3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z m173 -153 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"></path><path d="M4140 5955 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M4210 5775 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M3260 5654 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M420 1880 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M580 1680 l-11 -40 11 -21 12 -21 15 6 c8 3 19 6 24 6 5 0 9 7 9 15
0 8 -5 15 -11 15 l-11 0 6 24 c7 30 -2 56 -20 56 l-13 0 -11 -40z"></path><path d="M650 1563 c0 -34 12 -63 26 -63 15 0 18 21 8 58 -7 26 -34 30 -34 5z"></path><path d="M2800 505 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M2450 99 c0 -14 30 -38 48 -39 19 0 14 28 -7 40 -26 13 -41 13 -41
-1z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#5f892c" stroke="none"><path d="M2763 5325 c-7 -8 -13 -19 -13 -25 0 -5 -7 -10 -15 -10 -8 0 -15 7
-15 15 l0 15 -19 0 c-11 0 -23 -4 -26 -10 -3 -5 -26 -10 -50 -10 -24 0 -47 -5
-50 -10 -11 -17 -87 -50 -117 -50 l-28 0 0 -20 0 -20 20 0 c11 0 20 -4 20 -10
0 -5 -11 -10 -25 -10 l-25 0 0 20 0 20 -23 0 -22 0 -13 -20 -12 -20 -30 0 -30
0 0 -14 c0 -8 6 -17 13 -19 6 -3 -7 -6 -30 -6 -51 -1 -73 -9 -73 -27 0 -8 -7
-14 -15 -14 -8 0 -15 -4 -15 -10 0 -5 -11 -10 -24 -10 l-25 0 -6 -20 -6 -20
-25 0 c-13 0 -24 -4 -24 -10 0 -5 -6 -10 -14 -10 -8 0 -18 -9 -21 -20 -3 -11
-11 -20 -16 -20 -5 0 -9 -9 -9 -20 l0 -20 -20 0 c-11 0 -20 -3 -20 -8 0 -4
-13 -13 -30 -20 -16 -7 -32 -22 -35 -33 -4 -10 -12 -19 -18 -19 -7 0 -19 -9
-27 -20 -8 -11 -20 -20 -27 -20 -7 0 -21 -12 -30 -27 -10 -16 -28 -38 -40 -51
l-23 -23 0 -29 c0 -16 4 -32 10 -35 5 -3 10 -20 10 -36 l0 -30 -25 -24 -24
-25 -26 0 c-14 0 -25 5 -25 10 0 6 13 10 30 10 l29 0 11 40 c12 45 6 80 -15
80 -7 0 -18 -13 -24 -30 l-10 -30 -20 0 c-12 0 -21 -5 -21 -10 0 -6 -13 -24
-30 -41 -16 -17 -32 -40 -35 -50 -4 -11 -11 -19 -16 -19 -5 0 -9 -9 -9 -20 0
-11 -4 -20 -10 -20 -5 0 -10 -10 -10 -22 0 -13 -11 -36 -25 -52 -14 -17 -28
-41 -32 -55 -3 -14 -19 -55 -35 -91 -17 -36 -38 -84 -48 -106 -10 -23 -22 -59
-25 -80 -4 -22 -14 -44 -22 -51 l-16 -12 6 -21 c3 -11 1 -20 -4 -20 -14 0 -11
-20 5 -36 17 -16 36 -19 36 -4 0 6 6 10 14 10 l14 0 6 30 6 30 20 0 21 0 6 31
6 32 28 11 29 10 16 34 c9 19 24 37 35 39 10 3 21 13 24 24 8 23 60 79 74 79
6 0 11 6 11 14 0 8 9 18 21 21 l21 7 -5 19 -5 19 15 0 c21 0 43 25 43 50 l0
22 20 5 c11 3 20 8 20 12 0 11 32 52 83 104 26 27 47 53 47 58 0 5 6 9 14 9 8
0 18 9 21 20 3 11 11 20 16 20 16 0 69 62 69 81 l0 19 35 0 c19 0 35 5 35 10
0 6 7 10 16 10 l16 0 -5 20 -5 20 29 0 c16 0 29 5 29 10 0 6 6 10 13 10 7 0
29 18 48 40 19 22 39 40 44 40 6 0 30 17 53 38 40 37 53 62 32 62 -19 0 -10
15 18 28 l27 12 3 -10 c5 -14 21 -12 38 4 17 18 18 33 2 39 -7 3 -1 6 15 6 15
1 27 6 27 11 0 6 9 10 20 10 l20 0 0 19 0 20 23 3 22 3 3 23 3 22 21 0 20 0
-6 26 -7 26 36 -6 35 -7 1 38 c0 21 3 32 6 26 6 -15 33 -17 33 -3 0 6 9 10 19
10 19 0 31 18 31 46 0 18 -21 17 -37 -1z m-156 -102 c-4 -3 -7 0 -7 7 0 7 3
10 7 7 3 -4 3 -10 0 -14z m-250 -100 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3
-10 0 -14z m73 7 c0 -5 -7 -10 -16 -10 l-15 0 6 10 c3 6 10 10 16 10 5 0 9 -4
9 -10z m117 3 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z m-157 -23 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-140
-35 c0 -8 -7 -15 -15 -15 -26 0 -18 20 13 29 1 1 2 -6 2 -14z m60 -45 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-170 -65
c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m-70
-30 l0 -26 -25 -24 -24 -25 -30 0 c-16 0 -33 5 -36 10 l-6 10 31 0 32 0 5 20
c3 11 9 20 13 20 5 0 12 9 15 20 9 28 25 25 25 -5z m210 -5 c0 -5 -13 -10 -30
-10 -16 0 -30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m-99 -38 c-6 -12
-11 -33 -11 -49 l-1 -28 -9 17 -10 17 17 35 c9 20 18 34 21 32 2 -2 -1 -13 -7
-24z m79 -16 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15
-2 15 -4z m-150 -66 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m-40 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-52 -50 c-7 -10 -14 -18 -15 -16 -6 6 9 36 18 36 5 0 4
-9 -3 -20z m-231 -17 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m83
-97 c0 -8 3 -23 7 -33 l7 -18 -17 17 c-19 18 -23 48 -7 48 6 0 10 -6 10 -14z
m-80 -112 c0 -3 -7 -12 -15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24 10z
m-200 -70 c0 -20 -27 -47 -40 -39 l-9 6 11 24 c12 28 38 34 38 9z m100 6 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-35
-90 c13 0 26 6 28 13 l5 12 1 -16 1 -17 -27 -6 -26 -7 -8 13 c-5 7 -8 18 -8
23 0 6 3 4 6 -2 2 -7 15 -13 28 -13z m-45 -50 c0 -5 -9 -10 -20 -10 -11 0 -20
-4 -20 -10 0 -5 -6 -10 -14 -10 -17 0 -26 -21 -27 -68 l-1 -37 -8 29 -9 29 16
30 c8 17 20 36 27 43 13 16 56 18 56 4z m-143 -67 c-4 -3 -7 0 -7 7 0 7 3 10
7 7 3 -4 3 -10 0 -14z m123 2 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15
10 15 2 0 4 -7 4 -15z m-154 -110 c-29 -30 -36 -12 -8 23 l17 21 3 -14 c2 -8
-3 -22 -12 -30z m-49 -112 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M2810 5325 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M4060 5145 l0 -25 20 0 c11 0 20 -4 20 -10 0 -5 -9 -10 -20 -10 l-20
0 -6 25 c-3 14 -11 25 -16 25 -13 0 -38 -26 -38 -39 0 -6 -5 -11 -11 -11 -13
0 -49 -38 -49 -52 0 -4 -6 -8 -14 -8 -8 0 -18 -9 -21 -20 -3 -11 -11 -20 -16
-20 -5 0 -9 -7 -9 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -7 -15
-15 -15 -8 0 -15 -4 -15 -8 0 -14 -36 -52 -49 -52 -6 0 -11 -11 -11 -25 l0
-25 -20 0 -20 0 0 -15 c0 -8 5 -15 10 -15 6 0 10 -4 10 -9 0 -5 -9 -7 -19 -4
l-19 5 -7 -22 -7 -22 23 -24 c12 -13 31 -24 41 -24 l19 0 17 -30 c9 -16 23
-30 31 -30 14 0 51 -37 51 -52 0 -4 9 -8 20 -8 11 0 20 -4 20 -8 0 -13 36 -52
47 -52 5 0 20 10 33 22 l24 22 -7 -18 c-4 -10 -7 -20 -7 -22 0 -2 -7 -4 -15
-4 l-15 0 0 -20 0 -20 14 0 14 0 -5 -20 -5 -20 16 0 c9 0 16 -6 16 -12 1 -16
46 -68 59 -68 5 0 13 -9 16 -20 3 -11 11 -20 16 -20 5 0 9 -13 9 -30 0 -16 4
-30 10 -30 5 0 14 -18 20 -40 6 -22 14 -40 19 -40 4 0 16 -18 26 -40 10 -23
27 -50 37 -62 10 -12 18 -29 18 -40 0 -10 4 -18 10 -18 l10 0 -6 -30 -6 -30 9
0 c5 0 15 -22 22 -50 l13 -50 13 0 c7 0 15 9 18 20 3 11 10 20 16 20 6 0 11
-9 11 -20 l0 -20 -15 0 c-8 0 -15 -4 -15 -10 0 -5 -7 -10 -16 -10 l-16 0 6
-26 c4 -15 14 -33 21 -41 8 -8 15 -20 16 -26 1 -26 8 -97 18 -182 l11 -90 13
-10 c8 -5 17 -31 21 -57 l7 -48 25 -6 24 -6 0 -64 0 -64 -25 0 -25 0 0 -29 c0
-16 -6 -36 -13 -43 -8 -7 -17 -27 -22 -43 -9 -35 -24 -152 -31 -249 l-6 -68
-17 -5 -18 -4 -17 -73 c-9 -40 -14 -80 -10 -89 3 -9 15 -17 25 -17 10 0 19 -4
19 -10 0 -5 -6 -10 -14 -10 -8 0 -17 -10 -19 -22 l-4 -23 -2 33 -1 32 -15 0
-15 0 0 -31 c0 -38 -16 -69 -35 -69 l-15 0 0 -20 c0 -11 -4 -20 -8 -20 -5 0
-14 -25 -21 -55 -7 -30 -17 -55 -22 -55 -5 0 -9 -7 -9 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -9 -10 -20 l0 -20 -16 0 -15 0 2 -17 c2 -10 -1 -28 -5 -40 l-7
-23 -30 0 -29 0 0 -16 c0 -8 4 -23 10 -33 l9 -18 -24 5 -25 4 0 -16 c0 -9 -6
-16 -12 -16 -7 -1 -22 -12 -33 -27 -11 -14 -28 -33 -39 -42 -60 -52 -86 -80
-86 -90 0 -6 -9 -11 -20 -11 -11 0 -20 -4 -20 -8 0 -14 -36 -52 -49 -52 -6 0
-25 -13 -43 -30 -17 -16 -39 -30 -49 -30 -11 0 -24 -9 -31 -20 -7 -11 -21 -20
-30 -20 -10 0 -18 -4 -18 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5
-11 -10 -25 -10 l-25 0 0 -13 c0 -14 -35 -47 -49 -47 -5 0 -11 6 -14 13 l-4
12 -22 -12 c-11 -7 -28 -13 -36 -13 -8 0 -15 -4 -15 -10 0 -5 -10 -10 -23 -10
-12 0 -29 -9 -37 -20 -8 -11 -20 -20 -27 -20 -7 0 -13 -4 -13 -10 0 -5 -4 -10
-10 -10 -5 0 -10 9 -10 20 0 11 -4 20 -9 20 -21 0 -41 -13 -41 -26 l0 -14 -67
-2 c-119 -2 -131 -3 -136 -8 -3 -3 -8 -17 -12 -31 l-7 -27 33 -11 c17 -6 43
-11 57 -11 l24 0 -5 21 -6 21 16 -6 c8 -3 52 -6 98 -6 l83 0 -5 -20 -5 -20 51
0 51 0 0 14 c0 8 -6 17 -12 19 -7 3 -3 6 10 6 12 1 22 -3 22 -9 l0 -10 50 0
50 0 0 20 0 20 -20 0 c-11 0 -20 5 -20 10 0 6 14 10 30 10 l30 0 0 -14 c0 -17
31 -26 93 -26 l47 0 0 10 c0 6 9 10 20 10 l20 0 0 20 0 20 25 0 c14 0 25 -4
25 -10 0 -5 13 -10 30 -10 l29 0 7 50 c4 28 10 50 15 50 5 0 9 -18 9 -40 l0
-40 20 0 20 0 11 24 11 24 39 -5 39 -6 0 10 c0 5 16 15 35 23 19 8 35 17 35
22 0 4 11 8 25 8 14 0 25 5 25 10 0 6 11 10 25 10 14 0 25 5 25 10 0 6 7 10
15 10 8 0 15 5 15 10 0 6 9 10 20 10 l20 0 0 20 0 20 28 0 28 0 14 20 c8 11
20 20 27 20 19 0 53 32 64 58 l9 22 35 0 35 0 0 20 c0 11 5 20 11 20 12 0 78
70 79 83 0 4 6 7 14 7 8 0 21 6 28 13 7 7 21 16 32 20 l18 7 -6 15 c-3 8 -1
15 5 15 14 0 39 26 39 40 0 17 26 40 44 40 l16 0 0 20 0 20 19 0 19 0 13 31
c7 18 23 42 36 55 13 13 23 30 23 38 0 8 11 26 25 40 14 14 25 32 25 40 0 8 4
16 8 18 12 4 62 106 62 124 0 8 4 14 8 14 10 0 42 65 42 84 0 7 9 25 20 39
l20 25 0 31 c0 16 5 42 12 56 l12 26 -15 10 -14 9 18 0 17 0 0 25 c0 14 9 43
20 65 11 22 20 51 20 65 l0 25 -15 0 -15 0 6 28 c8 33 14 41 14 19 0 -9 6 -17
13 -17 l14 0 13 84 13 85 -7 188 c-4 103 -6 208 -6 232 1 25 -2 62 -5 83 l-7
38 -13 0 c-7 0 -16 -6 -18 -12 l-5 -13 -1 17 c-1 9 -4 23 -7 32 l-6 16 11 0
c6 0 11 -7 11 -15 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0
-10 11 -10 25 0 14 -9 43 -20 65 -11 22 -20 50 -20 62 0 13 -7 36 -15 52 -8
15 -15 42 -15 58 0 31 -14 63 -37 86 -7 7 -13 20 -13 27 0 19 -32 85 -41 85
-4 0 -5 9 -2 20 l5 20 -15 0 -16 0 -11 41 c-15 55 -31 79 -52 79 -17 0 -23 11
-19 34 4 19 -11 46 -25 46 l-14 0 -6 30 -6 30 -14 0 c-8 0 -14 5 -14 10 0 6
-9 10 -19 10 l-20 0 -3 38 -3 37 -23 3 c-13 2 -26 11 -29 20 -4 9 -22 30 -40
48 -18 18 -33 37 -33 43 0 6 -7 11 -15 11 l-15 0 0 20 0 20 -16 0 c-9 0 -28
13 -43 29 -14 15 -36 31 -49 35 l-22 7 -6 24 -6 24 -39 15 -39 16 0 15 0 15
-20 0 c-11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -7 10 -15
10 -8 0 -15 5 -15 10 0 6 -10 10 -22 10 -20 0 -37 11 -95 60 -7 5 -19 10 -28
10 l-15 0 0 -25z m81 -65 c10 0 19 -4 19 -10 0 -5 -11 -10 -25 -10 l-25 0 1
23 c0 12 3 16 6 10 2 -7 13 -13 24 -13z m-126 -80 c7 0 16 6 18 13 3 6 6 2 6
-10 l1 -23 -24 0 -24 0 -6 15 c-3 8 -2 23 2 33 l7 17 3 -22 c2 -13 9 -23 17
-23z m265 10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m120 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m42 -55 c3 -14 1 -28 -3 -31 -5 -3 -9 9 -9 25 0 17 2 31 4
31 2 0 6 -11 8 -25z m-592 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m-50 -80 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25
10 0 6 11 10 25 10 14 0 25 -4 25 -10z m800 -71 c0 -23 -7 -24 -26 -5 -20 21
-17 35 6 29 l20 -5 0 -19z m-723 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m110 -105 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m763
-33 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m37 -47 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z m-590 -40 c-4 -3
-7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m563 -3 c0 -11 4 -20 9 -20 5 0 13
-9 16 -20 l6 -20 35 0 c19 0 34 -4 34 -10 0 -15 -69 -12 -84 3 -31 30 -47 87
-26 87 6 0 10 -9 10 -20z m-640 -10 c0 -5 -13 -10 -30 -10 -16 0 -30 5 -30 10
0 6 14 10 30 10 17 0 30 -4 30 -10z m17 -27 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3
-4 3 -10 0 -14z m34 -75 l-10 -23 0 32 c-1 17 -4 40 -7 50 -3 10 2 6 10 -9
l16 -27 -9 -23z m-64 35 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m86
-105 c6 -4 8 -10 4 -14 -11 -11 -36 28 -29 46 l6 15 4 -20 c3 -11 9 -23 15
-27z m704 5 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m10 -40 c-4 -3
-7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-587 -105 l0 -23 -10 15 c-13 19
-13 30 0 30 6 0 10 -10 10 -22z m580 16 c0 -3 -6 -12 -14 -20 -20 -19 -26 -18
-26 6 l0 20 20 0 c11 0 20 -3 20 -6z m-560 -94 l0 -40 -16 0 -16 0 6 16 c3 9
6 27 6 40 0 13 5 24 10 24 l10 0 0 -40z m660 -20 l0 -20 -20 0 c-23 0 -25 5
-7 25 17 20 27 18 27 -5z m87 -337 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10
0 -14z m-3 -80 c3 -10 1 -34 -3 -53 l-9 -35 -1 21 c-1 12 -4 36 -7 53 l-6 31
10 0 c6 0 13 -8 16 -17z m-598 -119 c-3 -9 -6 -28 -7 -42 l-1 -27 -8 25 c-4
14 -8 33 -9 43 l-1 17 16 0 16 0 -6 -16z m611 -6 l-5 -13 -1 19 c-1 11 2 17 5
13 3 -3 4 -12 1 -19z m0 -135 c-2 -16 -4 -3 -4 27 0 30 2 43 4 28 2 -16 2 -40
0 -55z m-620 -200 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m3 -75 c0
-36 -4 -35 -14 5 l-6 27 10 0 c5 0 10 -14 10 -32z m580 -62 c0 -8 -4 -18 -10
-21 l-10 -6 0 20 c0 12 5 21 10 21 6 0 10 -6 10 -14z m-700 -316 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m600 4 c0 -9 -23
-48 -37 -62 -7 -7 -13 -9 -13 -3 0 5 7 16 15 25 8 8 15 22 15 30 0 9 5 16 10
16 6 0 10 -3 10 -6z m-600 -64 c0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 l0
-10 -15 15 c-8 8 -15 17 -15 20 0 3 14 6 30 6 17 0 30 -4 30 -10z m540 -50 l0
-20 -20 0 c-11 0 -20 5 -20 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 2 10 5 10 3
0 5 -9 5 -20z m-574 -126 c-19 -19 -20 -11 -3 58 l12 53 3 -48 3 -47 -15 -16z
m445 -97 l-12 -12 7 18 c3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-574 -94 c-4 -3
-7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m0 -100 c-4 -3 -7 0 -7 7 0 7 3 10
7 7 3 -4 3 -10 0 -14z m406 -3 c-5 -20 -15 -25 -26 -14 -6 6 13 33 25 34 3 0
4 -9 1 -20z m-483 -70 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m427 -7 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z
m-527 -55 c0 -16 -4 -28 -10 -28 l-10 0 0 43 0 42 10 -15 c5 -8 10 -27 10 -42z
m487 15 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-57 -32 c0 -5 -9
-11 -20 -14 l-20 -5 0 14 0 14 20 0 c11 0 20 -4 20 -9z m-490 -61 c0 -5 -11
-10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m-100 -77
c-1 -5 -8 -19 -16 -33 l-15 -25 5 18 c3 10 6 25 6 33 0 8 5 14 10 14 6 0 10
-3 10 -7z m420 1 c0 -3 -7 -12 -15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24
10z m-80 -24 c0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -9 0 -5 -9 -13 -20 -16
l-20 -6 0 -25 c0 -13 -4 -24 -10 -24 -21 0 -6 52 24 86 13 16 66 18 66 4z m27
-27 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-410 -20 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-17 -13 c0 -5 -9 -10 -20 -10 -11 0 -20
5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m180 -79 c0 -5 -7 -14 -15 -21
-16 -14 -18 -10 -9 14 6 17 24 22 24 7z m-100 -21 c0 -5 -9 -10 -20 -10 -11 0
-20 -4 -20 -9 0 -5 -9 -7 -19 -4 l-20 5 12 14 c14 15 67 19 67 4z m-380 -57
c0 -10 -20 -40 -20 -31 0 3 -3 13 -6 22 l-6 16 16 0 c9 0 16 -3 16 -7z m-84
-30 c-10 -10 -19 5 -10 18 l8 13 4 -13 c2 -7 1 -15 -2 -18z m178 -38 c-4 -8
-8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-294 -35 c0
-5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z"></path><path d="M1300 3859 l0 -21 13 4 c6 3 13 12 15 21 l3 17 -15 0 -16 0 0 -21z"></path><path d="M4380 3280 l0 -40 15 0 15 0 0 40 0 40 -15 0 -15 0 0 -40z"></path><path d="M2310 1725 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M2350 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M2430 1665 l0 -15 25 0 25 0 0 15 0 15 -25 0 -25 0 0 -15z"></path><path d="M2490 1641 l0 -21 21 0 20 0 -3 17 c-2 10 -11 19 -21 21 l-17 3 0
-20z"></path><path d="M2550 1619 l0 -20 17 3 c10 2 19 11 21 21 l3 17 -20 0 -21 0 0 -21z"></path><path d="M2597 1613 c-4 -3 -7 -12 -7 -20 l0 -13 45 0 45 0 0 20 0 20 -38 0
c-21 0 -42 -3 -45 -7z"></path><path d="M2690 1580 l0 -20 30 0 c17 0 30 -4 30 -9 0 -4 26 -11 58 -14 31 -4
69 -10 84 -13 l27 -6 7 26 c3 14 10 26 15 26 5 0 9 7 9 15 l0 15 -19 0 c-11 0
-22 -6 -25 -12 l-6 -13 -6 13 -6 12 -53 0 -53 0 -5 -12 -5 -13 -1 13 -1 12
-40 0 -40 0 0 -20z"></path><path d="M3070 1515 l0 -15 50 0 50 0 0 15 0 15 -50 0 -50 0 0 -15z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#86bf39" stroke="none"><path d="M5805 7241 c-11 -5 -26 -6 -32 -4 -7 3 -13 0 -13 -6 l0 -11 -25 6
-25 6 0 -17 0 -17 15 6 15 5 0 -19 0 -20 14 0 c8 0 16 6 19 13 l5 12 13 -12
c21 -21 34 -15 47 20 7 18 12 36 12 40 0 9 -19 8 -45 -2z m25 -21 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"></path><path d="M5570 7176 c0 -9 -5 -16 -11 -16 -5 0 -7 -5 -4 -10 l7 -11 14 12 14
11 0 -11 c0 -17 30 -13 37 4 3 9 4 18 2 20 -2 2 -16 6 -32 10 l-27 7 0 -16z"></path><path d="M5494 7154 l6 -17 -40 7 -40 6 0 -30 0 -30 11 0 c6 0 9 -6 6 -13 l-5
-13 29 4 29 4 0 -11 c0 -6 9 -11 20 -11 l20 0 0 14 c0 7 -9 16 -20 19 l-20 5
0 22 0 22 20 5 c11 3 20 12 20 19 l0 14 -21 0 -21 0 6 -16z m-40 -40 c9 -22
-3 -32 -14 -14 -12 19 -12 30 -1 30 5 0 12 -7 15 -16z"></path><path d="M5707 7144 c7 -20 -6 -84 -17 -84 -6 0 -10 11 -10 25 l0 25 -13 0
c-16 0 -37 -34 -29 -47 4 -5 22 -16 39 -24 18 -7 35 -19 37 -26 3 -7 0 -25 -7
-41 l-12 -28 -19 4 -18 4 6 -11 c4 -6 13 -11 21 -11 7 0 16 -6 18 -12 l5 -13
11 15 c6 8 8 24 5 36 l-5 21 15 6 c9 4 16 14 16 22 0 9 -4 14 -8 11 -4 -2 -9
18 -10 45 l-2 50 9 -5 c5 -3 4 5 -2 19 -7 13 -17 27 -24 29 l-12 5 6 -15z"></path><path d="M5786 7145 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M5379 7138 c-1 -2 -2 -15 -3 -30 l-2 -28 13 0 12 0 -2 30 c-1 16 -5
30 -9 30 -5 0 -9 -1 -9 -2z"></path><path d="M5760 7105 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M5280 7095 l0 -16 -35 3 c-19 2 -34 -1 -34 -6 1 -5 -5 -13 -13 -18
l-13 -9 25 6 c52 11 94 12 121 1 l29 -10 12 12 13 13 -19 -7 -19 -7 7 11 7 12
-15 -6 -14 -6 -12 21 c-13 24 -40 28 -40 6z"></path><path d="M5560 7080 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M5160 7070 c-8 -5 -11 -12 -7 -16 4 -4 15 0 24 9 18 18 8 23 -17 7z"></path><path d="M5097 7053 c-12 -11 -8 -33 6 -33 7 0 13 9 13 20 0 20 -7 25 -19 13z"></path><path d="M5027 7032 l-19 -4 7 -26 c8 -32 21 -41 47 -34 l21 5 -5 -14 -5 -14
-39 -5 -39 -5 -1 -15 -1 -15 53 -1 53 0 -11 19 c-5 10 -6 22 -2 25 4 4 9 1 11
-5 l5 -13 34 0 c19 0 34 5 34 10 0 6 -7 10 -16 10 -20 0 -47 27 -39 40 l6 10
-30 0 -31 0 0 14 c0 18 -10 24 -33 18z"></path><path d="M5150 7020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4930 6995 l0 -15 -19 0 -19 0 5 -20 5 -20 19 0 c17 0 40 30 45 58
l2 12 -19 0 -19 0 0 -15z"></path><path d="M5670 6991 c0 -30 14 -32 27 -5 l11 24 -19 0 -19 0 0 -19z"></path><path d="M4813 6963 l-6 -16 21 -18 22 -17 20 5 20 5 -11 19 c-5 10 -12 19
-15 19 -3 0 -14 -5 -25 -11 l-21 -11 12 21 11 21 -11 0 c-6 0 -13 -7 -17 -17z"></path><path d="M4960 6940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4728 6900 l23 -22 -12 -14 -11 -14 -19 10 -18 10 5 15 6 15 -23 1
c-13 0 -32 2 -41 4 l-18 3 0 -13 c0 -20 21 -30 31 -14 l9 14 0 -17 c0 -10 6
-18 14 -18 7 0 16 -9 19 -20 l5 -20 21 0 21 0 0 -15 c0 -18 14 -19 42 -4 l21
11 -6 -16 -6 -16 14 0 15 0 0 -20 c0 -11 6 -20 13 -20 l13 0 -2 28 c-1 15 -3
42 -3 60 l-1 32 -15 0 -16 0 11 -19 c13 -25 13 -31 0 -31 -5 0 -10 6 -10 14
l0 15 -30 -9 -30 -9 -6 10 c-3 5 -11 6 -17 3 -7 -4 -5 1 3 10 9 9 28 17 43 18
25 2 31 10 28 31 0 4 2 6 7 6 17 -3 35 1 29 7 -3 4 -34 8 -69 11 l-63 4 23
-21z"></path><path d="M5671 6912 c-1 -7 -5 -11 -9 -8 -4 2 -9 -5 -9 -17 l-2 -22 9 15 10
15 0 -16 c0 -8 -7 -36 -16 -61 l-15 -46 -12 2 c-7 1 -18 5 -24 9 -7 5 -14 5
-14 0 -3 -20 4 -49 14 -63 l12 -15 -17 14 -18 13 0 -16 c0 -9 7 -16 16 -16
l15 0 -7 -11 -7 -12 14 5 c8 3 13 13 13 22 -1 9 2 16 6 16 5 0 16 21 26 48 9
26 21 51 26 57 4 5 8 20 7 35 0 27 -17 74 -18 52z"></path><path d="M4882 6718 c2 -6 13 -16 24 -22 10 -6 22 -15 25 -20 4 -4 16 -5 27
-2 l20 7 7 -11 c9 -15 25 -12 25 5 0 8 -5 15 -11 15 l-11 0 6 15 c7 18 -5 20
-23 3 l-14 -13 6 18 6 17 -17 0 c-10 0 -23 -5 -29 -11 l-11 -11 -6 11 c-10 15
-29 14 -24 -1z"></path><path d="M5040 6720 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 -7 -10 -15 -10
-8 0 -15 -4 -15 -10 0 -5 9 -10 21 -10 l21 0 -6 -16 -6 -16 15 6 c8 3 15 1 15
-4 0 -6 7 -10 15 -10 l15 0 0 20 0 19 13 -4 12 -5 1 35 1 35 -23 0 -22 0 -6
-16 c-4 -9 -4 -19 -1 -22 3 -3 5 1 5 8 0 7 2 11 5 8 8 -8 -6 -38 -17 -38 -5 0
-14 14 -19 30 -6 17 -17 30 -25 30 -8 0 -14 -4 -14 -10z"></path><path d="M5550 6720 c0 -5 -6 -9 -12 -7 l-13 2 1 -32 c1 -18 -2 -33 -7 -33 -5
0 -9 -9 -9 -20 0 -11 5 -20 10 -20 6 0 10 -4 10 -10 0 -5 -9 -10 -21 -10 l-21
0 5 -12 4 -13 29 2 c16 0 23 -1 16 -4 l-13 -5 12 -14 c6 -8 15 -11 20 -8 l10
6 -7 34 -6 34 15 -6 15 -6 11 21 c6 11 11 25 11 32 l0 12 -31 -7 -31 -6 7 40
c4 22 4 40 1 40 -3 0 -6 -4 -6 -10z"></path><path d="M5490 6680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5148 6611 l-4 -29 13 5 c14 6 18 53 4 53 -5 0 -11 -13 -13 -29z"></path><path d="M3020 6614 l0 -17 46 7 c26 3 53 6 60 6 8 0 14 5 14 10 l0 10 -60 0
-60 0 0 -16z"></path><path d="M3163 6623 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3255 6619 c-4 -6 -5 -13 -2 -16 9 -8 127 8 127 18 0 5 -27 9 -59 9
l-60 0 -6 -11z"></path><path d="M2803 6613 l-33 -4 0 -13 0 -13 34 1 34 1 -1 18 c-1 9 -1 16 -2 15 0
0 -15 -3 -32 -5z"></path><path d="M2910 6610 c0 -5 -12 -10 -26 -10 -34 0 -22 -17 16 -22 l31 -5 -5 14
-5 13 19 0 c10 0 22 5 25 10 l6 10 -30 0 c-17 0 -31 -4 -31 -10z"></path><path d="M3445 6610 c3 -5 15 -10 25 -10 10 0 20 -4 22 -8 2 -5 26 -12 53 -15
l50 -7 -17 14 c-10 7 -18 18 -18 25 l0 11 -61 0 -60 0 6 -10z"></path><path d="M5060 6604 c0 -8 -7 -14 -15 -14 -8 0 -15 -4 -15 -10 0 -5 -7 -10
-15 -10 l-15 0 0 -25 c0 -26 -15 -35 -22 -13 l-4 12 -12 -8 c-7 -4 -12 -12
-12 -17 0 -6 4 -8 9 -5 5 3 8 -2 7 -12 l-1 -17 12 2 12 3 -5 -15 -6 -15 15 0
c20 0 41 26 34 43 l-5 14 21 -2 c32 -4 67 12 67 30 0 9 -7 18 -15 21 -22 9
-18 27 4 21 l20 -5 -6 14 c-3 8 -8 14 -12 15 -3 0 -14 2 -23 4 l-18 3 0 -14z
m-12 -69 c-3 -3 -13 -2 -22 1 l-18 7 14 8 13 8 9 -9 c5 -5 7 -12 4 -15z"></path><path d="M3592 6598 c5 -15 37 -18 41 -3 1 6 -9 12 -22 13 l-23 4 4 -14z"></path><path d="M2668 6593 c-21 -5 -24 -23 -4 -23 8 0 16 7 20 15 3 8 4 14 3 14 -1
-1 -10 -3 -19 -6z"></path><path d="M3740 6580 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3832 6540 l10 -38 33 3 33 3 6 -15 c3 -8 4 -19 1 -23 l-5 -9 35 6
35 6 0 -11 c0 -6 -9 -16 -20 -22 -11 -6 -20 -15 -20 -20 0 -17 25 -11 38 10
l12 20 31 0 30 0 -6 -20 -6 -20 25 -10 c14 -5 26 -16 26 -24 l0 -14 26 -5 25
-4 -5 14 c-3 8 -6 17 -6 19 0 2 -9 4 -20 4 l-20 0 0 20 c0 10 -8 24 -17 30
l-18 11 12 5 c7 3 9 12 6 20 l-5 14 16 0 c20 0 21 16 1 24 l-15 5 0 -12 0 -12
-8 11 c-4 6 -22 16 -39 22 l-33 11 0 -20 0 -20 -27 3 c-14 2 -29 9 -31 17 l-5
14 12 -7 11 -7 0 14 c0 8 -9 17 -20 20 l-20 5 0 -11 0 -12 -8 11 c-4 7 -23 16
-43 22 l-36 10 9 -38z m208 -40 l14 -9 -28 -11 c-16 -6 -31 -9 -33 -7 -2 2 -1
12 3 21 7 18 22 20 44 6z"></path><path d="M2541 6548 c2 -13 0 -29 -4 -35 l-8 -13 30 0 c16 0 32 4 36 10 6 10
-33 60 -48 60 -4 0 -7 -10 -6 -22z"></path><path d="M5143 6553 c-18 -7 -16 -30 3 -37 24 -9 29 0 13 21 l-14 17 13 -8 12
-7 0 10 c0 12 -7 13 -27 4z"></path><path d="M2444 6535 l5 -15 -31 0 -31 0 16 -18 c21 -23 21 -32 3 -32 l-14 0
-7 30 c-7 30 -25 42 -25 16 0 -7 -9 -16 -20 -19 -11 -3 -20 -12 -20 -21 l0
-16 35 0 c19 0 35 -4 35 -10 0 -5 -15 -10 -33 -10 l-33 0 -17 20 c-21 24 -21
30 -3 30 8 0 16 6 19 13 l5 14 -24 -5 c-13 -2 -24 -11 -24 -18 0 -8 -4 -14
-10 -14 -17 0 -11 -18 15 -45 l26 -25 41 0 41 0 13 18 12 17 72 0 72 0 12 -17
c7 -10 16 -18 20 -18 3 0 6 13 6 29 l0 29 -52 9 -53 9 1 17 2 17 -14 0 c-8 0
-14 7 -14 15 0 8 -7 15 -16 15 l-16 0 6 -15z"></path><path d="M4910 6495 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M5510 6510 c-6 -20 -4 -70 3 -70 14 0 18 26 8 51 -5 13 -10 22 -11
19z"></path><path d="M2230 6475 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4126 6469 c3 -6 0 -15 -7 -20 l-14 -9 14 -9 14 -9 14 14 c8 8 19 14
26 14 6 0 2 -7 -9 -15 l-19 -14 23 -1 23 0 -3 18 -4 17 -23 -3 -23 -2 6 15 5
15 -15 0 -15 0 7 -11z"></path><path d="M5160 6470 c0 -20 22 -64 37 -76 18 -13 43 -8 43 8 l0 10 -13 -10
-13 -11 2 29 2 30 -14 0 c-8 0 -14 4 -14 9 0 5 -7 11 -15 15 -8 3 -15 1 -15
-4z"></path><path d="M4925 6460 c-4 -6 -11 -8 -16 -5 -5 4 -9 -3 -9 -14 l0 -21 20 0 20 0
0 25 c0 27 -5 31 -15 15z"></path><path d="M4200 6445 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"></path><path d="M4247 6448 c4 -7 8 -20 8 -28 l0 -15 18 1 c9 1 17 -3 17 -8 l0 -10
-29 4 -30 4 10 -18 c10 -19 39 -25 39 -8 0 6 7 10 16 10 l15 0 -7 11 -7 11 22
-7 21 -6 0 15 c0 9 -4 17 -10 17 -5 1 -14 2 -18 3 -5 0 -16 9 -26 19 -10 9
-24 17 -32 17 l-15 0 8 -12z"></path><path d="M2067 6432 c-7 -10 -4 -82 2 -82 4 0 16 -3 29 -6 l22 -6 0 30 0 30
-16 6 -16 6 12 15 12 15 -20 0 c-12 0 -23 -3 -25 -8z"></path><path d="M2150 6425 l12 -15 44 0 c24 0 44 4 44 10 0 5 -6 7 -14 4 -8 -3 -17
-1 -21 5 -3 6 -22 11 -42 11 l-35 0 12 -15z"></path><path d="M4807 6433 c-11 -10 -8 -20 9 -38 9 -9 13 -19 9 -23 -3 -4 -11 1 -16
11 -13 23 -45 22 -81 -4 l-30 -21 6 -24 6 -24 -9 0 c-6 0 -14 7 -19 16 l-9 17
-13 -8 -12 -7 5 -20 6 -20 -15 6 c-8 3 -14 1 -14 -4 0 -6 -10 -10 -22 -10
l-23 -1 16 -12 c9 -7 20 -31 23 -54 l7 -41 -12 -8 -12 -7 7 11 7 12 -14 0 c-8
0 -22 9 -32 20 -10 11 -25 20 -34 20 l-16 1 18 10 18 11 20 -23 c10 -12 22
-19 25 -16 4 3 3 18 0 32 l-7 27 -15 -6 -15 -6 7 12 c4 6 -12 0 -34 -12 -23
-13 -42 -30 -42 -37 0 -7 -9 -18 -19 -25 -10 -6 -17 -17 -14 -24 l4 -11 -25 4
-26 5 0 -11 c0 -6 -9 -11 -19 -11 -11 0 -22 -4 -25 -9 -7 -11 22 -23 35 -16 5
4 7 0 4 -7 l-5 -13 -43 3 -42 4 -28 -26 c-15 -14 -32 -26 -38 -26 -5 0 -9 -7
-9 -16 l0 -16 20 5 c11 3 20 2 20 -3 0 -15 -30 -32 -49 -27 l-20 5 6 -14 c6
-16 -13 -19 -22 -4 -11 17 -32 11 -38 -11 l-6 -21 -12 8 -12 7 6 -36 6 -37
-11 0 c-5 0 -12 9 -15 20 -3 11 -12 20 -19 20 -21 0 -17 -18 7 -31 l21 -11 -5
-24 -4 -24 13 0 c8 0 14 7 14 15 0 21 23 19 52 -5 l25 -19 14 12 c7 7 5 2 -5
-10 l-18 -22 -19 12 -20 12 -9 -15 c-5 -8 -10 -22 -10 -31 0 -9 -7 -19 -14
-22 l-15 -6 6 15 5 14 -26 0 c-14 0 -34 -8 -44 -18 l-18 -18 -1 -37 -1 -38 11
4 c7 2 16 13 22 25 11 25 30 28 51 8 l14 -14 20 5 20 5 -6 34 -6 34 14 0 c8 0
19 -5 26 -12 l11 -11 16 13 c8 7 15 20 15 30 l0 18 19 -5 19 -5 7 21 c3 12 12
21 18 21 14 0 57 44 57 58 0 5 14 15 30 21 l30 10 0 15 c0 9 7 16 16 16 l15 0
-3 18 -3 17 -33 1 c-31 1 -47 -9 -47 -31 0 -5 -19 -11 -41 -13 l-41 -3 3 -42
4 -42 -10 15 -10 15 -9 -14 -8 -13 -7 10 c-3 6 -3 17 0 26 l6 16 -16 0 c-9 0
-16 -7 -16 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10 -15
-5 0 -10 8 -10 18 1 21 23 52 38 52 l11 0 -5 15 -6 15 20 0 c11 0 22 -6 25
-12 l5 -13 13 16 c8 9 26 19 42 23 26 6 39 26 16 26 -11 0 -39 24 -39 34 0 3
7 6 15 6 8 0 15 5 15 10 0 6 -5 10 -11 10 -5 0 -7 5 -4 10 11 18 22 11 31 -22
9 -32 34 -51 34 -26 1 7 12 27 26 43 15 17 23 24 19 17 l-7 -13 18 10 c11 5
27 12 36 15 l18 5 -6 31 -6 30 10 0 c22 0 62 -20 62 -30 0 -6 -4 -9 -10 -5
l-9 6 -9 -33 -8 -33 -19 0 c-10 0 -20 -6 -22 -12 l-5 -13 16 0 c9 0 16 -4 16
-10 0 -5 5 -10 10 -10 6 0 10 7 10 15 l0 15 18 0 18 0 13 45 c13 44 24 55 36
35 3 -5 2 -10 -3 -10 -6 0 -12 -7 -16 -15 -10 -28 15 -15 27 13 l12 29 13 -5
c16 -5 42 16 42 33 0 8 -7 15 -15 17 l-14 3 9 15 10 15 14 -17 c7 -10 19 -18
26 -18 l12 0 -4 18 c-5 19 13 42 35 42 l13 0 -2 28 c-1 15 4 36 11 45 l13 18
6 -16 5 -15 -17 -1 -17 -1 13 -5 c6 -2 12 -9 12 -14 0 -6 -4 -7 -10 -4 -5 3
-10 1 -10 -5 l0 -12 16 6 c9 3 20 6 25 6 5 0 9 7 9 15 0 8 5 15 11 15 14 0 39
26 39 41 0 7 -8 21 -17 31 l-17 19 15 -6 15 -5 0 20 c0 25 -13 26 -39 3 l-20
-18 6 18 c6 17 -3 23 -16 10z m-45 -97 l-20 -26 -6 16 c-9 23 -8 24 14 24 11
0 20 5 20 12 0 6 3 9 6 5 4 -3 -3 -17 -14 -31z m-76 -41 c1 -8 -2 -13 -7 -9
-5 3 -9 12 -9 21 0 19 13 9 16 -12z m54 -15 c0 -5 -7 -10 -15 -10 -8 0 -15 -5
-15 -12 0 -6 -2 -9 -5 -6 -3 3 -3 13 1 22 7 17 34 22 34 6z m-95 -20 c3 -5 4
-10 1 -10 -3 0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z m-62 -103 l-5
-9 -24 26 c-13 14 -24 28 -24 31 0 3 13 -4 30 -16 16 -12 27 -26 23 -32z
m-286 -174 c-9 -9 -20 -13 -24 -9 -4 4 1 14 11 22 25 18 35 8 13 -13z m133 -3
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-233
-27 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m174 -16 c-1 -12 -15 -9
-19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-21 -14 c0 -5 -4 -14 -9 -21 l-8 -14
-6 15 c-3 8 -3 18 0 21 9 8 23 7 23 -1z m-90 -54 c0 -6 -4 -7 -10 -4 -5 3 -10
11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m41 -12 c-6 -8 -8 -17 -5 -20 4
-3 -3 -5 -15 -4 l-22 1 23 18 c28 21 34 23 19 5z m-144 -144 c-4 -3 -7 0 -7 7
0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M1998 6403 c-10 -2 -18 -13 -18 -23 0 -22 -29 -35 -44 -20 l-11 11
18 -6 17 -7 0 11 c0 14 -37 14 -46 0 -6 -10 2 -19 31 -34 l10 -5 -12 -6 c-7
-3 -9 -12 -6 -20 l5 -14 -22 2 c-13 2 -25 -3 -27 -9 l-5 -13 22 0 c13 0 31 9
41 21 10 11 23 18 28 14 l11 -6 -6 15 c-3 9 -11 16 -16 16 l-10 0 12 15 c7 8
19 15 26 15 l13 0 -5 14 -6 15 24 -6 23 -6 -14 16 c-7 9 -14 16 -15 16 0 -1
-9 -3 -18 -6z"></path><path d="M4908 6403 c-10 -2 -18 -9 -18 -14 0 -15 43 -7 48 9 4 12 0 13 -30 5z"></path><path d="M5320 6400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5379 6393 c-1 -10 -1 -24 0 -32 l2 -13 -10 7 -11 6 0 -25 0 -26 15
0 15 0 0 20 c0 11 5 20 10 20 6 0 10 -9 10 -21 l0 -20 10 6 10 6 0 -35 c0 -42
15 -48 24 -11 l7 26 -14 19 c-8 11 -16 17 -19 14 -10 -9 -20 35 -13 56 l6 20
-20 0 -21 0 -1 -17z"></path><path d="M5270 6375 l0 -25 -14 0 c-7 0 -16 -10 -20 -22 -9 -36 -7 -48 9 -48
l15 0 -7 -31 -6 -30 37 6 36 7 0 -24 0 -25 30 2 30 2 -2 -16 -3 -15 19 -4 19
-4 -6 23 -6 22 14 11 15 11 -6 22 c-4 13 -10 20 -15 18 -16 -10 -89 41 -89 62
0 7 -7 13 -15 13 l-15 0 0 35 c0 19 -4 35 -10 35 -5 0 -10 -11 -10 -25z m40
-117 c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 l0 22 10 -15 c5 -8 10 -18 10 -22z
m60 -38 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"></path><path d="M5440 6379 l0 -20 27 3 c14 2 29 10 31 18 l5 15 -18 -15 -19 -15 7
18 6 17 -19 0 -20 0 0 -21z"></path><path d="M4050 6359 l0 -21 14 5 14 5 28 -31 c16 -17 20 -24 8 -14 -11 9 -26
17 -32 17 l-11 0 6 -17 6 -16 -11 7 -12 7 -6 -15 c-3 -9 -3 -23 0 -32 l6 -16
13 10 c7 6 23 9 35 5 l22 -5 0 11 c0 6 7 11 15 11 8 0 15 6 16 13 2 36 6 58
12 63 9 9 27 -5 27 -22 0 -8 6 -14 14 -14 17 0 39 26 34 41 l-4 12 -17 -17
c-19 -20 -22 -14 -6 15 l10 20 -28 -7 c-15 -4 -29 -11 -31 -16 -2 -4 -10 -8
-18 -8 -8 0 -14 -4 -14 -9 0 -15 -50 1 -61 20 -13 25 -29 24 -29 -2z m60 -73
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"></path><path d="M5230 6370 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M1857 6347 l-28 -11 3 -15 c2 -8 9 -16 16 -19 l12 -4 0 16 c0 9 6 16
13 16 8 0 14 7 14 15 0 8 -1 15 -1 14 -1 -1 -14 -6 -29 -12z"></path><path d="M2063 6313 c-12 -2 -24 -11 -27 -19 l-6 -15 12 7 11 7 -5 -14 -6 -15
39 -1 39 -2 0 15 c0 7 -7 14 -16 14 l-16 0 6 15 c3 8 2 14 -2 14 -4 -1 -17 -4
-29 -6z"></path><path d="M1866 6295 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"></path><path d="M1790 6281 c0 -11 -4 -21 -10 -23 l-9 -3 10 -15 11 -15 5 13 c2 6 19
12 36 13 l32 0 -22 9 c-13 5 -23 12 -23 15 0 4 4 3 10 0 l10 -6 -6 15 c-3 9
-15 16 -25 16 l-19 0 0 -19z"></path><path d="M1720 6275 c-7 -8 -20 -15 -29 -15 -10 0 -27 -9 -39 -20 -12 -11 -26
-20 -32 -20 -7 0 -10 -6 -8 -12 6 -16 48 -23 48 -9 0 6 6 11 14 11 7 0 16 9
19 19 l5 20 21 -6 22 -6 20 19 19 18 -12 8 c-19 12 -34 10 -48 -7z m37 -12
c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M1973 6273 c-17 -6 -16 -23 1 -23 8 0 16 7 20 15 6 15 1 17 -21 8z"></path><path d="M3920 6240 c0 -5 -7 -7 -15 -4 l-15 6 0 -15 c0 -25 11 -47 21 -41 5
3 16 -2 25 -10 20 -20 35 -21 28 -1 -4 8 -12 15 -20 15 -18 0 -18 15 1 34 20
20 19 26 -5 26 -11 0 -20 -4 -20 -10z"></path><path d="M4024 6241 l6 -9 -20 5 -20 5 0 -16 c0 -9 -7 -16 -15 -16 -20 0 -19
-19 2 -27 l17 -7 4 12 c2 7 12 12 22 12 16 0 30 18 30 41 0 5 -7 9 -16 9 -8 0
-13 -4 -10 -9z"></path><path d="M1590 6202 c0 -7 -7 -12 -15 -12 -8 0 -15 -6 -15 -14 l0 -14 -20 5
-20 5 0 -16 c0 -9 -6 -15 -12 -13 -7 1 -14 -8 -16 -21 l-3 -22 -25 4 -24 5 0
-14 0 -13 -21 5 -21 6 4 -24 3 -23 27 -15 c15 -9 30 -12 34 -9 3 4 9 22 13 40
l7 33 47 -1 47 0 0 15 c0 23 18 47 27 38 4 -5 1 -12 -6 -16 -14 -9 -7 -31 10
-31 l10 0 -4 46 c-2 25 -9 50 -16 57 l-11 11 0 -12z m-13 -49 c-4 -3 -7 0 -7
7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-26 -30 l-10 -18 -1 16 c0 17 10 32 18 25
2 -3 -1 -13 -7 -23z m-111 -63 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"></path><path d="M5286 6194 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M1703 6173 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5300 6140 c0 -27 17 -60 32 -60 6 0 8 3 5 7 -4 3 -2 12 5 20 l11 13
-17 -6 -16 -6 0 20 c0 12 -4 24 -10 27 l-10 6 0 -21z"></path><path d="M3908 6130 c-20 -18 -24 -40 -8 -40 6 0 10 5 10 10 0 6 7 10 15 10
16 0 19 9 7 26 l-7 11 -17 -17z"></path><path d="M5376 6131 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M1640 6100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1770 6100 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3720 6078 c0 -4 7 -8 15 -8 8 0 15 4 15 8 0 5 -7 9 -15 9 -8 0 -15
-4 -15 -9z"></path><path d="M3837 6083 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M3660 6060 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 6 -10 13
-10 6 0 22 -3 33 -6 l22 -6 -5 26 -5 26 -14 0 c-8 0 -14 -4 -14 -10z"></path><path d="M4989 6048 c-2 -20 -2 -21 4 -55 l6 -32 -19 4 -20 4 0 -20 0 -19 28
0 c15 0 33 5 40 12 l12 12 0 -12 c0 -13 28 -16 54 -6 l17 6 -8 12 c-5 7 -1 4
9 -6 15 -17 48 -25 48 -12 0 3 -5 14 -10 25 l-11 19 -23 0 c-13 0 -29 -7 -36
-15 -16 -20 -30 -19 -30 1 0 9 -9 18 -19 21 -11 3 -22 12 -25 20 l-6 14 12 -7
11 -7 -6 24 -6 24 13 -17 c16 -22 26 -23 26 -4 0 7 -10 16 -22 20 -33 8 -38 8
-39 -6z m158 -105 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M3735 6039 l-8 -11 14 -9 c8 -5 22 -9 32 -9 l18 0 -3 18 -3 17 -22 3
c-11 2 -24 -2 -28 -9z"></path><path d="M1365 6028 c3 -7 0 -20 -5 -28 l-10 -15 -14 18 c-17 23 -33 22 -41
-3 l-6 -20 -19 0 -19 0 5 -14 6 -15 -20 6 -20 5 -7 -12 c-4 -6 -2 -17 4 -23
l12 -12 8 14 c5 7 13 11 19 7 6 -3 12 0 15 6 l5 13 15 -20 15 -20 9 9 8 9 -13
4 -13 5 12 14 c6 8 20 14 30 14 11 0 19 5 19 10 0 6 7 10 15 10 8 0 15 4 15 9
0 15 50 -1 61 -20 13 -25 29 -24 29 2 l0 21 -12 -5 c-7 -2 -26 7 -41 19 -16
13 -37 24 -47 24 l-19 0 4 -12z"></path><path d="M3700 6003 c-24 -10 -37 -27 -32 -42 l5 -14 -11 7 -11 6 -10 -17 -10
-18 -1 13 c0 17 -17 15 -24 -3 l-5 -15 27 0 27 0 28 24 c15 13 27 28 27 34 0
6 8 16 18 21 l17 10 -15 0 c-8 0 -22 -3 -30 -6z"></path><path d="M1390 5981 c0 -14 16 -28 36 -33 l21 -5 -4 17 c-3 10 -16 20 -29 23
-13 2 -24 1 -24 -2z"></path><path d="M4846 5924 c-14 -15 -26 -35 -26 -45 0 -11 -4 -19 -9 -19 -4 0 -7
-16 -5 -35 2 -35 -12 -48 -22 -20 -3 8 -14 17 -24 20 l-19 6 -25 -22 c-14 -13
-26 -26 -26 -31 0 -4 12 -8 26 -8 14 0 23 4 20 9 -3 5 1 12 10 15 21 8 24 8
24 -5 l0 -10 -12 7 -13 7 12 -13 11 -12 31 7 c17 3 33 10 35 13 2 4 7 32 11
62 7 58 19 80 41 80 8 0 14 5 14 10 0 18 -27 10 -54 -16z m-19 -111 c-4 -3 -7
0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M1179 5912 c-25 -27 -72 -62 -85 -62 -11 0 -24 -30 -24 -57 l0 -12
14 5 c7 3 18 15 23 26 l9 22 7 -11 c4 -6 0 -18 -8 -26 -8 -9 -15 -18 -15 -21
0 -8 34 -8 53 0 l15 5 -5 20 c-3 10 -9 17 -13 14 -4 -3 -10 4 -13 15 -3 11 -2
18 2 17 10 -3 70 36 75 49 3 7 -1 17 -8 23 l-12 10 -15 -17z m-4 -32 c3 -5 4
-10 1 -10 -3 0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z"></path><path d="M3410 5917 c0 -7 6 -19 13 -26 l12 -13 -12 -5 c-17 -6 -16 -28 1 -42
l14 -12 4 18 4 18 6 -17 7 -18 -24 -6 c-14 -3 -25 -12 -25 -20 l0 -14 30 0
c17 0 30 5 30 10 0 6 7 10 15 10 l15 0 0 29 0 30 -22 3 c-12 2 -23 9 -26 16
l-4 12 15 -6 16 -6 -6 20 c-3 11 -15 23 -26 26 -32 8 -37 7 -37 -7z m71 -100
l-12 -12 7 18 c3 10 9 15 12 12 3 -3 0 -11 -7 -18z"></path><path d="M3483 5918 c2 -7 10 -12 17 -10 l12 3 -7 -21 -6 -20 15 -15 c19 -19
36 -19 36 1 l0 15 -12 -7 -11 -7 7 12 8 12 13 -11 c15 -12 35 -6 35 11 0 6 -4
8 -9 4 -5 -3 -12 -1 -15 4 -3 5 -15 7 -25 4 l-20 -5 6 21 5 21 -27 0 -27 0 5
-12z"></path><path d="M5170 5890 c0 -16 4 -30 9 -30 5 0 11 14 13 30 l5 30 -14 0 -13 0 0
-30z"></path><path d="M1251 5878 c-5 -18 -10 -25 -10 -15 -1 9 -6 17 -12 17 l-11 0 6 -25
6 -25 -30 0 c-16 0 -30 -4 -30 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -9 0 -5 7
-11 14 -14 l15 -6 -6 16 -6 16 25 -5 26 -5 -6 15 -5 15 14 -5 14 -5 3 43 3 44
-15 0 -15 0 -10 -32z"></path><path d="M5208 5904 c-5 -4 -8 -16 -8 -28 l0 -20 11 11 c6 6 9 19 7 28 -1 9
-6 13 -10 9z"></path><path d="M4030 5885 c0 -3 6 -14 12 -25 l13 -20 -17 -17 c-23 -22 -36 -8 -15
15 18 19 14 32 -10 32 -7 0 -13 -7 -13 -15 0 -8 -4 -15 -8 -15 -4 0 -7 -12 -6
-27 l1 -27 28 -9 27 -9 9 13 8 13 -11 7 c-13 8 -2 29 17 29 7 0 15 6 18 14 l5
14 -16 -2 -17 -1 3 18 3 17 -16 0 c-8 0 -15 -2 -15 -5z"></path><path d="M3942 5818 c-16 -16 -15 -48 2 -48 l14 0 -2 18 -1 17 13 -2 c6 -2 12
4 12 12 0 18 -21 20 -38 3z"></path><path d="M5177 5817 c-3 -8 -2 -20 3 -27 l8 -13 21 6 c12 3 21 1 21 -4 0 -16
36 -10 57 11 l21 19 6 -14 c2 -7 0 -16 -7 -19 l-12 -4 16 -1 17 -1 15 -38 15
-38 21 -1 c12 -1 19 2 16 7 -3 5 -1 11 5 15 19 11 10 35 -15 41 l-25 6 0 19 0
19 -20 0 -20 0 0 15 0 15 -22 -4 c-13 -3 -27 -5 -31 -5 -5 -1 -5 -5 -2 -11 l6
-10 -16 0 c-8 0 -18 7 -21 15 -8 19 -50 20 -57 2z"></path><path d="M1027 5793 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M3290 5760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3396 5755 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M4652 5745 c-22 -23 -30 -41 -13 -31 5 3 14 -1 20 -8 l12 -14 -11 -7
c-6 -3 -8 -10 -5 -15 3 -4 2 -15 -1 -24 l-6 -16 -28 17 -28 16 -17 -16 c-9
-10 -21 -15 -26 -12 -5 4 -9 -1 -9 -9 0 -19 4 -19 47 -7 l32 9 3 -10 4 -11 27
7 28 7 -6 10 c-4 5 -2 15 3 22 10 12 11 101 2 110 -3 3 -15 -5 -28 -18z m-42
-99 c0 -3 -5 -8 -12 -12 l-11 -7 7 11 c7 12 16 16 16 8z"></path><path d="M3356 5745 l6 -15 -12 0 c-6 0 -8 -4 -5 -10 l6 -10 -15 0 c-9 0 -16
-4 -16 -10 0 -16 26 -11 43 8 21 23 22 52 2 52 l-14 0 5 -15z"></path><path d="M970 5735 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -16 l0 -16 15 12 15
12 0 -11 c0 -6 4 -11 8 -11 4 0 9 14 10 30 l2 30 -15 0 c-8 0 -15 -7 -15 -15z"></path><path d="M3834 5725 c-3 -14 -10 -25 -15 -25 -5 0 -6 -8 -2 -17 l6 -18 -14 13
c-20 19 -36 14 -43 -13 l-6 -25 -26 0 -25 0 -5 -15 c-4 -8 -10 -15 -15 -15 -5
0 -17 -6 -26 -14 -10 -8 -33 -27 -50 -41 -18 -15 -33 -33 -33 -41 0 -7 -11
-16 -25 -20 -27 -7 -32 -23 -13 -42 18 -18 28 -15 28 8 0 23 18 27 26 6 3 -7
17 -13 31 -13 28 0 63 29 63 52 l0 15 -19 0 -19 0 2 -17 c2 -25 -27 -27 -42
-2 l-13 21 12 14 13 15 20 -5 19 -5 14 17 c8 9 12 11 8 5 -8 -17 10 -16 24 1
6 7 9 21 5 30 l-6 16 11 1 c6 1 14 3 19 4 4 2 17 6 29 10 13 4 21 11 18 14 -2
4 2 8 9 8 l14 0 -5 -14 -5 -13 21 0 20 0 -5 15 -6 15 11 0 c6 0 11 -9 11 -20
0 -24 6 -25 27 -5 17 18 11 45 -11 45 -7 0 -17 7 -20 16 -9 23 -8 24 14 24
l20 0 0 20 0 20 -20 0 -20 0 -6 -25z"></path><path d="M3249 5700 c-6 -16 -16 -30 -21 -30 -15 0 -49 -41 -42 -51 3 -5 0 -9
-6 -9 l-11 0 7 -12 7 -13 -14 13 c-18 16 -49 15 -49 -2 0 -8 12 -17 26 -20
l26 -7 22 18 c11 10 23 21 26 24 3 3 13 8 23 12 9 3 17 12 17 19 0 8 9 20 19
27 l19 14 -11 24 c-5 12 -14 23 -19 23 -5 0 -14 -13 -19 -30z"></path><path d="M5425 5680 c10 -11 22 -17 26 -14 5 3 9 0 9 -5 0 -15 -23 -14 -38 1
l-12 12 0 -16 0 -17 32 -15 c38 -19 41 -20 32 -3 l-8 12 17 -14 c18 -14 20 -9
11 26 l-6 21 -24 6 c-13 4 -24 11 -24 16 0 6 -7 10 -17 10 l-16 0 18 -20z"></path><path d="M4087 5675 c-9 -8 -20 -14 -24 -13 -4 2 -9 -7 -11 -19 l-3 -23 11 0
c6 0 22 10 35 23 l24 22 -8 13 -8 13 -16 -16z"></path><path d="M4120 5680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M903 5665 c-7 -8 -13 -21 -13 -30 l0 -15 26 6 26 7 24 -13 c26 -15
48 -5 24 11 l-15 9 15 9 c8 6 11 13 7 18 -4 4 -17 2 -27 -5 l-20 -12 0 15 0
15 -17 0 c-10 -1 -24 -7 -30 -15z"></path><path d="M5332 5648 l3 -33 45 -3 45 -3 -10 9 c-5 6 -19 12 -30 14 -11 2 -24
13 -30 26 -14 32 -27 27 -23 -10z"></path><path d="M830 5598 c-11 -12 -20 -30 -20 -40 l0 -19 12 7 11 7 -6 -17 -7 -17
11 6 c5 4 7 15 4 24 l-5 18 21 26 c25 32 8 36 -21 5z"></path><path d="M3930 5599 l0 -11 -15 6 -15 6 10 -20 c11 -21 30 -27 30 -10 0 6 4
10 9 10 14 0 25 -40 14 -51 l-10 -10 -27 26 c-16 14 -31 23 -34 20 -4 -3 -1
-10 6 -14 l13 -8 -7 -16 -6 -17 13 0 14 -1 -17 -13 c-23 -17 -23 -36 0 -36 19
0 62 42 62 60 0 6 7 10 16 10 l16 0 -6 16 -6 15 10 -6 10 -6 0 20 0 20 15 -5
15 -6 0 16 c0 23 -18 20 -42 -6 l-20 -23 6 19 6 18 -15 -6 c-8 -3 -15 -1 -15
4 0 6 -7 10 -15 10 -8 0 -15 -5 -15 -11z"></path><path d="M4470 5576 c0 -22 17 -28 24 -10 9 22 8 24 -9 24 -8 0 -15 -6 -15
-14z"></path><path d="M5494 5576 c-2 -6 -2 -23 0 -36 2 -14 5 -27 5 -30 1 -3 6 2 11 10
l10 15 0 -12 c0 -6 -7 -18 -15 -27 -19 -18 -19 -26 0 -26 15 0 55 38 55 52 0
4 -7 8 -15 8 -22 0 -18 21 5 27 11 3 20 11 20 18 l0 13 -36 0 -35 0 -5 -12z
m13 -33 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M3080 5569 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3000 5561 c0 -5 7 -12 16 -15 22 -9 24 -8 24 9 l0 15 -20 0 c-11 0
-20 -4 -20 -9z"></path><path d="M3100 5544 c0 -8 -4 -13 -9 -9 -6 3 -15 -4 -21 -15 l-11 -20 11 0
c15 0 50 33 50 47 0 7 -4 13 -10 13 -5 0 -10 -7 -10 -16z"></path><path d="M2950 5540 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4460 5533 c0 -7 6 -13 13 -13 6 0 23 -3 36 -7 l23 -6 -4 19 -3 19
-33 0 -32 1 0 -13z"></path><path d="M3707 5520 c3 -11 9 -20 14 -20 5 0 9 9 9 20 l0 20 -14 0 -14 0 5
-20z"></path><path d="M3020 5500 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M4394 5506 c-3 -8 -2 -22 3 -31 l9 -16 23 20 c13 11 20 25 16 30 -9
16 -45 13 -51 -3z"></path><path d="M5570 5499 l0 -21 15 6 c8 3 15 1 15 -4 0 -6 -4 -10 -10 -10 -5 0
-10 -5 -10 -11 l0 -10 33 3 32 3 -1 18 c-1 9 -2 22 -3 28 l-1 11 -20 -5 c-11
-3 -20 -1 -20 4 0 5 -7 9 -15 9 l-15 0 0 -21z m55 -29 c3 -5 4 -10 1 -10 -3 0
-8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z"></path><path d="M820 5486 c18 -18 11 -27 -12 -15 l-23 12 14 -16 c7 -10 20 -17 27
-17 l14 0 0 23 0 24 -15 0 -16 0 11 -11z"></path><path d="M4457 5493 c-10 -9 -8 -33 2 -33 5 0 11 9 14 20 5 20 -3 26 -16 13z"></path><path d="M4492 5478 c-17 -17 -15 -28 7 -28 16 0 31 18 31 36 0 9 -27 3 -38
-8z"></path><path d="M764 5461 c0 -11 4 -17 8 -15 10 7 10 34 0 34 -4 0 -8 -9 -8 -19z"></path><path d="M4340 5465 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M720 5451 l0 -18 15 0 c8 0 15 6 15 13 0 6 -7 14 -15 18 l-15 5 0
-18z"></path><path d="M2960 5460 c0 -5 -9 -10 -19 -10 -11 0 -21 -6 -24 -12 l-4 -13 -14
13 c-15 13 -69 17 -69 4 0 -5 6 -9 13 -10 60 -8 87 -16 87 -23 0 -5 7 -9 15
-9 l15 0 0 20 0 20 25 0 25 0 0 15 0 15 -25 0 c-14 0 -25 -4 -25 -10z"></path><path d="M3837 5443 c-4 -3 -3 -11 3 -17 l9 -12 -20 -1 c-11 -1 -23 5 -26 13
-3 8 -11 14 -19 14 l-14 0 0 -26 0 -26 -15 6 c-8 3 -15 2 -16 -2 -1 -4 -2 -26
-4 -49 l-2 -43 19 0 c23 0 35 27 19 43 -6 6 -11 15 -11 20 l0 9 30 -7 30 -7 0
19 0 18 30 13 c33 15 41 42 12 42 -10 0 -22 -3 -25 -7z"></path><path d="M5670 5435 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3463 5427 c-4 -7 -21 -18 -36 -25 l-28 -12 6 -21 7 -22 -11 7 c-12
8 -14 -4 -5 -28 3 -9 12 -16 20 -16 17 0 19 17 2 22 l-12 4 10 15 c5 8 9 18 9
23 0 5 6 11 13 14 l12 4 0 -21 0 -21 15 0 c8 0 15 5 15 11 0 5 13 15 29 20
l29 10 -2 25 -1 24 -32 0 c-18 0 -36 -6 -40 -13z m47 -7 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-20 -30 c0 -5 -7 -10
-15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"></path><path d="M4310 5430 c0 -5 5 -10 10 -10 6 0 10 -5 10 -11 0 -5 -4 -7 -10 -4
-5 3 -10 1 -10 -4 0 -6 10 -11 21 -11 l21 0 -5 19 -5 20 17 -6 16 -5 -15 11
c-19 14 -50 14 -50 1z"></path><path d="M780 5390 c0 -42 14 -54 25 -20 4 11 11 18 16 15 5 -4 9 1 9 9 0 9
-7 16 -15 16 -8 0 -15 5 -15 10 0 6 -4 10 -10 10 l-10 0 0 -40z"></path><path d="M675 5409 l-8 -11 14 -9 c21 -13 31 -11 27 8 -4 20 -24 28 -33 12z"></path><path d="M2760 5395 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2871 5391 c-16 -10 -6 -31 15 -31 l14 0 0 20 c0 22 -7 25 -29 11z"></path><path d="M630 5361 c-5 -11 -10 -25 -10 -31 l0 -11 20 11 c11 6 24 19 30 30
l11 20 -20 0 c-11 0 -25 -9 -31 -19z"></path><path d="M2813 5373 c-13 -2 -23 -9 -23 -14 0 -6 -4 -7 -10 -4 l-10 6 0 -15
c0 -18 26 -22 32 -6 2 6 13 12 26 13 12 0 22 7 22 14 0 7 -3 12 -7 12 -5 -1
-18 -4 -30 -6z"></path><path d="M4227 5362 c-19 -21 -22 -42 -7 -42 6 0 10 7 10 15 0 8 7 15 15 15 8
0 15 7 15 15 0 20 -13 19 -33 -3z"></path><path d="M4280 5366 c0 -7 8 -16 19 -19 l18 -4 -4 18 c-6 22 -33 26 -33 5z"></path><path d="M5676 5365 l6 -15 -13 5 -14 5 -2 -30 c-1 -16 2 -30 7 -30 6 0 10 5
10 11 0 6 6 17 14 25 8 8 19 14 25 14 6 0 11 7 11 15 l0 15 -25 0 -24 0 5 -15z"></path><path d="M3540 5360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4350 5350 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2677 5338 l-6 -20 42 6 c64 10 65 20 5 29 l-36 5 -5 -20z"></path><path d="M700 5340 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"></path><path d="M3350 5335 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M5729 5333 l-14 -16 13 -4 c6 -3 10 -8 7 -13 -3 -4 9 -10 25 -12 l30
-5 0 14 c0 7 -4 13 -10 13 -5 0 -10 9 -10 20 l0 20 -14 0 c-7 0 -20 -7 -27
-17z"></path><path d="M2596 5314 l-6 -15 27 3 c14 2 28 9 31 16 l4 12 -25 0 -25 0 -6 -16z"></path><path d="M3487 5317 l-5 -14 24 5 c13 2 24 8 24 13 0 14 -37 10 -43 -4z"></path><path d="M4242 5318 c2 -6 11 -12 20 -14 l17 -2 -6 14 c-6 17 -36 19 -31 2z"></path><path d="M4307 5304 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M594 5294 l6 -15 -10 6 c-6 3 -10 0 -10 -7 l0 -14 35 4 36 4 -4 12
c-2 6 -12 10 -21 10 -9 -1 -16 2 -16 7 0 5 -5 9 -11 9 l-11 0 6 -16z"></path><path d="M810 5300 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4170 5297 c0 -8 -7 -17 -15 -21 l-15 -5 0 -26 0 -26 17 3 18 3 10
-22 c5 -13 13 -23 17 -23 14 0 8 43 -9 62 l-16 18 17 0 17 0 -3 22 -3 23 -17
3 -18 3 0 -14z"></path><path d="M3292 5288 c-15 -15 -16 -38 -2 -38 6 0 10 7 10 16 l0 15 10 -6 10
-6 0 15 c0 19 -11 21 -28 4z"></path><path d="M3370 5283 l0 -15 -14 5 -15 6 6 -16 6 -16 -14 5 -14 5 0 -29 -1 -29
14 6 c7 3 18 5 23 5 5 0 9 14 9 30 l0 30 20 0 c11 0 20 4 20 9 0 5 -9 11 -20
14 l-20 5 0 -15z"></path><path d="M2428 5278 c12 -6 22 -14 22 -18 0 -3 -22 -13 -50 -20 -27 -7 -50
-17 -50 -22 0 -4 -8 -8 -17 -8 l-17 0 12 -12 12 -12 42 21 c24 11 67 29 96 39
l52 18 0 13 c0 7 -9 13 -21 13 l-20 0 7 -11 7 -11 -22 7 c-11 3 -33 8 -48 10
l-28 4 23 -11z"></path><path d="M3680 5270 c0 -11 -4 -20 -10 -20 -5 0 -7 7 -4 15 l6 15 -17 0 c-9 0
-32 -7 -51 -16 l-34 -16 0 -19 c0 -11 5 -19 11 -19 l11 0 -13 -16 -14 -17 22
7 21 7 7 -11 c3 -5 13 -10 21 -10 l15 0 -3 18 c-2 9 -9 16 -15 15 l-10 -2 2
22 c1 12 3 28 4 35 0 6 6 12 12 12 l10 0 -6 -20 -6 -20 13 -1 c17 -2 52 2 56
6 2 1 0 14 -4 29 -7 31 -24 35 -24 6z m-73 -37 c-4 -3 -7 0 -7 7 0 7 3 10 7 7
3 -4 3 -10 0 -14z"></path><path d="M3241 5246 l-11 -13 17 -14 17 -14 -6 16 c-4 9 -1 22 5 28 l11 11
-11 0 c-6 0 -16 -6 -22 -14z"></path><path d="M653 5235 c-7 -8 -13 -26 -13 -40 l0 -25 16 0 17 0 -6 30 -6 30 9 0
c6 0 10 5 10 10 0 14 -13 12 -27 -5z"></path><path d="M3190 5208 l0 -23 -32 -15 c-18 -7 -42 -22 -53 -33 -11 -10 -30 -26
-42 -34 -13 -9 -23 -20 -23 -25 l0 -10 13 8 c7 5 5 1 -5 -8 l-18 -16 17 -17
16 -17 -13 -8 -13 -8 6 -16 c10 -25 24 -19 24 12 0 37 12 86 20 78 3 -3 23 12
44 34 37 39 59 50 59 30 0 -11 30 -13 54 -4 l15 6 -6 14 -5 14 15 0 c8 0 18 7
21 16 l6 16 -15 -6 c-8 -3 -22 -9 -31 -12 l-16 -7 4 24 3 24 -22 3 -23 3 0
-23z"></path><path d="M4082 5214 c-8 -8 -11 -21 -9 -28 l5 -14 26 5 c15 3 29 9 32 14 l5 9
-17 -7 -16 -6 7 21 6 22 -13 0 c-7 -1 -19 -8 -26 -16z"></path><path d="M570 5210 l0 -10 -25 6 -25 6 0 -16 c0 -9 -4 -16 -10 -16 -5 0 -10
-7 -10 -16 l0 -16 24 4 24 5 -5 -14 c-3 -7 0 -13 6 -13 6 0 11 5 11 10 l0 11
19 -11 c11 -5 24 -10 30 -10 18 0 12 17 -7 22 l-19 5 5 32 4 31 -11 0 c-6 0
-11 -5 -11 -10z"></path><path d="M5823 5213 c-18 -7 -16 -23 2 -23 8 0 15 7 15 15 0 8 -1 15 -2 14 -2
0 -9 -3 -15 -6z"></path><path d="M2250 5180 c-13 -11 -27 -20 -31 -20 -4 0 -18 -10 -31 -22 l-23 -23
-20 3 -21 3 0 -21 0 -20 18 0 c10 0 27 9 38 20 11 11 25 20 31 20 9 0 92 44
109 57 6 5 -22 23 -36 23 -6 0 -21 -9 -34 -20z"></path><path d="M3390 5180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5837 5176 c-9 -7 -17 -23 -17 -34 l0 -21 15 5 15 6 0 -11 c0 -6 7
-11 15 -11 l15 0 -4 23 c-3 12 -5 24 -5 26 -1 2 -8 -3 -18 -10 l-16 -14 12 23
c7 13 11 25 9 27 -2 2 -11 -2 -21 -9z"></path><path d="M3302 5172 l-13 -9 7 -16 6 -17 24 0 c13 0 24 5 24 10 0 6 -9 10 -21
10 l-21 0 6 15 c6 17 6 18 -12 7z"></path><path d="M4120 5154 l0 -15 22 3 23 3 0 -15 c0 -8 10 -19 23 -25 12 -5 22 -13
22 -17 0 -5 6 -8 14 -8 7 0 19 -7 26 -15 l13 -16 16 6 c8 3 12 3 9 -1 -4 -4 4
-16 18 -27 l26 -20 16 18 17 18 -17 -6 -18 -6 0 9 c0 6 -7 10 -16 10 l-16 0 6
16 6 16 -19 -5 -20 -5 -13 16 c-7 8 -12 18 -10 21 2 3 -10 10 -26 15 l-30 8
-5 19 -5 19 -31 0 -31 0 0 -16z"></path><path d="M650 5140 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3590 5135 l-12 -15 10 0 c12 0 26 19 19 26 -2 2 -10 -2 -17 -11z"></path><path d="M490 5130 c0 -5 -7 -10 -15 -10 l-15 0 0 -27 0 -28 10 15 c13 21 12
6 -2 -25 -6 -14 -15 -25 -20 -25 -13 0 -10 18 5 24 6 3 3 5 -8 3 -22 -2 -30
-25 -17 -45 l9 -13 25 11 25 12 -2 44 -2 44 18 0 c9 0 19 7 23 15 l5 15 -19 0
c-11 0 -20 -4 -20 -10z"></path><path d="M2068 5118 l12 -12 12 12 12 12 -24 0 -24 0 12 -12z"></path><path d="M3510 5119 c0 -5 -4 -8 -8 -5 -4 2 -9 -8 -10 -24 l-2 -29 19 -6 20
-6 20 18 c26 21 27 38 4 28 l-18 -6 13 12 c19 20 15 29 -13 29 -14 0 -25 -5
-25 -11z m7 -26 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M4274 5118 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M3410 5100 c0 -5 4 -10 9 -10 15 0 22 -31 11 -48 l-11 -17 7 24 6 24
-11 -7 c-6 -4 -10 -21 -9 -37 l2 -29 24 0 24 0 -6 25 -6 24 15 -5 15 -6 0 16
0 16 -16 0 -16 0 5 14 6 14 -15 6 c-23 9 -34 7 -34 -4z"></path><path d="M4350 5090 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1960 5065 l0 -25 15 0 14 0 -5 15 -6 15 11 0 c6 0 11 5 11 10 0 6
-9 10 -20 10 l-20 0 0 -25z"></path><path d="M2074 5063 l6 -15 -20 5 -19 5 2 -12 c1 -6 -6 -10 -15 -10 -10 1 -16
-3 -15 -10 1 -6 -6 -10 -15 -10 -10 1 -15 -2 -13 -6 3 -5 -6 -21 -21 -35 l-26
-26 -14 5 -14 5 3 -12 2 -12 -17 1 c-10 1 -15 -2 -12 -7 8 -14 -22 -39 -48
-40 l-23 0 20 -9 20 -9 -23 0 -23 -1 6 -10 c3 -5 1 -10 -6 -10 l-11 0 5 -20 5
-20 -30 0 -29 0 6 -10 6 -10 -18 -1 -18 -1 12 -4 12 -5 -45 -47 c-24 -26 -44
-50 -44 -53 0 -3 -9 -16 -19 -28 -11 -13 -25 -20 -30 -16 l-11 6 6 -16 6 -15
-16 6 -16 6 7 -18 6 -17 -13 8 -13 8 6 -11 c10 -15 -3 -44 -18 -38 l-12 4 4
-22 4 -21 -19 -22 c-10 -13 -22 -19 -26 -16 -4 4 -4 -1 0 -11 l7 -19 -10 6
c-6 4 -13 0 -17 -8 -3 -8 -1 -17 5 -20 l9 -6 -14 -15 c-8 -8 -17 -14 -21 -14
-17 0 -35 -32 -35 -60 l0 -31 -10 6 c-5 3 -10 2 -10 -4 0 -5 5 -13 12 -17 l12
-7 -20 -7 c-10 -4 -19 -13 -20 -21 0 -8 -2 -20 -2 -28 l-2 -13 -16 6 -15 6 6
-11 c4 -5 4 -21 1 -35 l-7 -25 -11 7 -11 7 6 -10 c3 -5 0 -34 -8 -64 -17 -64
-18 -69 -6 -69 5 0 21 33 36 73 29 81 41 109 80 186 14 29 24 60 21 68 l-6 14
12 -7 11 -7 -6 17 -7 17 10 -6 10 -6 10 26 c6 15 8 31 5 36 l-6 9 15 -6 14 -5
7 30 c4 17 11 31 16 31 4 0 16 14 25 32 9 18 23 38 30 46 13 13 18 27 15 45 0
4 2 6 6 5 10 -3 53 34 53 44 0 5 25 33 55 64 l56 55 -7 11 -7 11 11 -7 11 -6
66 65 c37 36 70 65 74 65 4 0 16 10 27 23 11 12 35 33 54 47 18 14 38 30 44
36 6 5 19 13 29 17 23 9 22 23 -4 30 l-21 6 6 -16z"></path><path d="M5820 5040 l0 -30 21 0 21 0 -6 16 c-3 9 -6 22 -6 30 0 8 -7 14 -15
14 l-15 0 0 -30z"></path><path d="M5892 5040 c-15 -24 -16 -44 -2 -35 l10 6 0 -16 0 -17 14 5 c8 3 17
1 20 -6 l4 -12 11 15 c15 20 10 72 -7 78 l-14 4 12 -23 11 -24 -20 23 c-10 12
-21 22 -23 22 -2 0 -9 -9 -16 -20z"></path><path d="M3480 5035 l-12 -15 16 0 c9 0 16 7 16 15 0 8 -2 15 -4 15 -2 0 -9
-7 -16 -15z"></path><path d="M3010 5014 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M2957 5013 c-14 -13 -6 -25 8 -13 l15 13 -6 -17 -6 -16 14 0 14 0 0
20 0 20 -16 0 c-9 0 -20 -3 -23 -7z"></path><path d="M470 4992 c-20 -6 -40 -32 -40 -52 l0 -16 19 0 19 0 -5 12 c-2 6 3
19 12 28 19 19 16 35 -5 28z"></path><path d="M4384 4988 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M386 4965 c-4 -14 -11 -25 -16 -25 -16 0 -11 -37 5 -44 8 -3 15 -10
15 -16 0 -6 -3 -9 -7 -7 -5 3 -8 -7 -8 -21 l0 -27 28 -2 c15 -1 27 1 28 5 0 4
1 22 2 40 l2 33 -21 -5 -22 -6 -6 16 c-9 22 -8 24 9 18 l15 -6 0 20 c0 11 6
22 13 25 l12 5 -14 13 -14 14 8 -15 9 -15 -16 13 -16 13 -6 -26z"></path><path d="M3253 4946 c18 -52 51 -63 54 -17 l2 28 -34 10 -33 11 11 -32z"></path><path d="M3410 4960 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M1800 4960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2900 4955 c0 -8 -7 -15 -15 -15 -20 0 -19 -26 1 -34 l16 -6 -12 -15
c-7 -8 -21 -15 -31 -15 -21 0 -25 -20 -7 -38 15 -15 28 -15 28 -1 0 7 8 19 18
28 l17 16 1 48 c1 26 -3 47 -8 47 -4 0 -8 -7 -8 -15z"></path><path d="M3350 4960 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M4410 4959 c0 -13 38 -49 51 -49 19 0 16 19 -6 38 -28 24 -45 28 -45
11z"></path><path d="M4500 4961 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M5992 4961 l-14 -8 14 -22 13 -22 -15 -9 c-8 -5 -10 -10 -5 -10 6 0
15 4 20 8 l10 7 -5 32 -5 32 -13 -8z"></path><path d="M500 4920 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"></path><path d="M3334 4929 l-8 -13 12 -4 c7 -2 12 -9 12 -14 0 -6 -3 -8 -7 -5 -3 4
-15 -1 -25 -10 l-18 -17 -12 12 c-16 16 -38 15 -38 -2 l0 -14 20 5 20 5 0 -22
0 -21 -20 6 -20 6 0 -15 0 -16 -20 0 c-24 0 -27 -15 -4 -24 24 -9 36 -7 28 5
l-7 11 32 -7 31 -7 0 16 c0 9 5 16 11 16 l12 0 -6 30 -6 30 9 0 c6 0 10 -4 10
-10 0 -5 7 -10 15 -10 19 0 19 20 1 55 l-14 26 -8 -12z"></path><path d="M3386 4933 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M4490 4910 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M2823 4885 c-9 -25 4 -29 17 -6 l11 21 -11 0 c-6 0 -13 -7 -17 -15z"></path><path d="M4512 4878 l3 -23 23 -10 c12 -5 22 -14 22 -19 0 -5 7 -16 15 -25 8
-9 36 -39 62 -67 71 -77 97 -107 108 -128 6 -11 16 -21 23 -24 l12 -4 0 22 0
22 -19 5 -19 5 5 18 5 19 -22 18 c-12 10 -28 24 -35 31 -8 6 -19 12 -26 12
l-11 0 12 15 13 15 -18 -7 -18 -6 6 16 6 16 -16 -6 -16 -6 7 18 c4 11 4 16 0
12 -4 -3 -19 8 -35 26 l-28 32 12 7 12 8 -26 0 -27 0 -6 15 c-3 8 -10 15 -16
15 l-11 0 3 -22z"></path><path d="M6021 4873 l-14 -15 16 -9 c9 -5 23 -17 32 -25 9 -9 18 -14 21 -11 7
7 -12 42 -23 42 -4 0 -6 8 -5 18 4 21 -8 21 -27 0z"></path><path d="M482 4853 l-23 -5 3 -16 3 -17 23 -3 22 -3 0 25 c0 14 -1 25 -2 25
-2 -1 -13 -4 -26 -6z"></path><path d="M316 4826 c-8 -28 -7 -41 1 -33 3 4 11 3 17 -3 l12 -9 0 19 c0 10 -4
16 -8 14 -4 -3 -8 0 -8 5 0 6 5 11 10 11 6 0 10 4 10 8 0 18 -29 8 -34 -12z"></path><path d="M2790 4826 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M5998 4813 c-10 -2 -18 -11 -18 -19 0 -8 4 -13 9 -10 5 3 12 -1 15
-10 9 -23 8 -24 -14 -24 l-20 0 0 -29 0 -29 15 -6 15 -5 0 29 c0 16 5 32 12
36 l11 7 18 -21 c9 -12 19 -22 22 -22 3 0 4 11 3 23 l-1 24 -17 -5 -18 -4 0
12 0 12 15 -6 15 -5 0 23 0 24 -16 6 c-17 6 -19 6 -46 -1z"></path><path d="M1640 4800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3080 4795 c0 -17 2 -18 24 -9 23 9 20 24 -4 24 l-20 0 0 -15z"></path><path d="M2717 4793 c-4 -3 -7 -11 -7 -16 l0 -9 15 12 15 12 0 -11 c0 -6 5
-11 10 -11 6 0 10 7 10 15 l0 15 -18 0 c-10 0 -22 -3 -25 -7z"></path><path d="M3175 4769 l0 -29 13 0 c6 0 12 -5 12 -11 l0 -10 22 3 23 3 3 18 3
17 -20 0 c-12 0 -21 4 -21 10 0 5 -8 14 -17 19 l-18 9 0 -29z"></path><path d="M6070 4787 c0 -23 11 -47 22 -47 14 0 5 53 -9 58 l-13 4 0 -15z"></path><path d="M266 4727 c-14 -28 -26 -53 -26 -56 0 -4 14 -6 30 -7 l30 -1 0 16 c0
10 -5 22 -12 29 l-12 12 23 24 22 23 -15 6 -15 6 -25 -52z"></path><path d="M2717 4755 c-9 -8 -23 -15 -31 -15 l-16 0 0 -20 c0 -11 -4 -20 -10
-20 -5 0 -10 -9 -10 -20 l0 -20 19 0 19 0 12 33 c8 17 20 40 28 50 19 22 10
33 -11 12z"></path><path d="M2742 4748 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
-8z"></path><path d="M310 4740 c0 -5 5 -10 10 -10 6 0 10 -9 10 -20 l0 -20 15 0 15 0 0
18 c0 10 -5 23 -12 30 -15 15 -38 16 -38 2z"></path><path d="M3080 4690 l0 -40 -10 0 c-16 0 -12 -37 4 -43 l15 -6 -6 15 c-5 14 8
19 21 7 8 -8 36 -13 36 -7 0 2 -6 13 -12 24 l-12 20 13 12 14 13 4 -12 c3 -7
11 -13 19 -13 l14 0 0 20 c0 11 -5 20 -11 20 l-11 0 6 15 5 15 -13 0 c-8 0
-19 -13 -25 -30 -5 -16 -15 -30 -22 -30 l-11 0 6 30 6 30 -15 0 -15 0 0 -40z"></path><path d="M6092 4698 c-17 -17 -15 -38 4 -38 l16 0 -11 13 -11 14 15 0 15 0 0
-19 0 -18 15 0 c8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 18 -21
20 -38 3z"></path><path d="M2714 4680 l0 -18 17 1 17 1 -5 12 c-2 7 -10 15 -17 17 l-12 5 0 -18z"></path><path d="M6020 4673 c0 -27 15 -30 37 -8 17 18 12 28 -17 30 l-20 2 0 -24z"></path><path d="M308 4658 c-2 -6 -2 -24 0 -39 l4 -27 17 -5 18 -4 -1 43 -1 44 -17 0
c-9 0 -18 -5 -20 -12z"></path><path d="M1520 4631 c0 -10 6 -21 13 -24 l12 -4 1 23 2 24 -14 0 -14 0 0 -19z"></path><path d="M2960 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4784 4635 c19 -14 36 -12 36 6 0 5 -12 9 -27 9 l-28 -1 19 -14z"></path><path d="M2550 4620 c0 -30 41 -23 48 8 l2 12 -25 0 -25 0 0 -20z"></path><path d="M256 4604 c-3 -9 -6 -26 -6 -40 l0 -23 15 5 15 6 0 34 c0 35 -13 46
-24 18z"></path><path d="M6145 4612 c-11 -8 -16 -42 -6 -40 17 2 20 -4 11 -28 l-10 -25 14
-15 c20 -19 26 -18 26 7 l0 20 -10 -6 c-5 -3 -10 1 -10 9 l0 15 15 -5 15 -6 0
17 c0 9 -4 14 -9 10 -12 -7 -34 32 -26 45 7 12 4 13 -10 2z"></path><path d="M3004 4594 c3 -9 6 -18 6 -20 0 -2 6 -4 14 -4 12 0 26 20 26 36 0 2
-12 4 -26 4 l-26 0 6 -16z"></path><path d="M2933 4593 l-13 -5 0 -34 c0 -47 17 -43 22 6 2 22 3 40 3 39 0 0 -6
-3 -12 -6z"></path><path d="M2547 4564 l5 -14 -18 7 -17 6 7 -11 c11 -17 -6 -37 -32 -38 l-23 -1
5 14 5 13 -14 0 -15 0 0 -25 0 -26 19 10 c10 6 21 7 25 4 3 -3 20 -8 37 -10
l30 -3 -5 18 c-3 9 -1 24 4 32 l10 15 9 -15 c13 -19 24 -7 17 18 l-7 20 -23 0
-24 0 5 -14z"></path><path d="M4785 4570 c-7 -11 24 -70 37 -70 18 0 17 21 -2 40 -11 11 -18 25
-15 30 4 6 2 10 -4 10 -5 0 -13 -4 -16 -10z"></path><path d="M290 4560 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1486 4543 c10 -40 14 -41 14 -5 0 18 -5 32 -10 32 l-10 0 6 -27z"></path><path d="M186 4544 c-10 -27 -7 -44 9 -44 l15 0 0 30 c0 32 -14 40 -24 14z"></path><path d="M3036 4544 c-9 -22 -8 -24 9 -24 8 0 15 6 15 14 0 22 -17 28 -24 10z"></path><path d="M4840 4540 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2965 4525 c-3 -8 -7 -22 -10 -30 -2 -8 0 -17 6 -21 l10 -6 -10 -12
-10 -12 20 -1 c10 -1 19 2 19 8 0 5 6 9 14 9 l15 0 -2 20 c-2 25 -12 26 -27 3
l-11 -18 4 38 c3 36 -9 51 -18 22z"></path><path d="M220 4500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4895 4480 l-6 -10 21 0 21 0 -6 10 c-3 6 -10 10 -15 10 -5 0 -12 -4
-15 -10z"></path><path d="M156 4458 c-9 -34 -8 -38 9 -38 l14 0 -5 15 -6 15 11 0 c6 0 11 7 11
15 0 24 -27 18 -34 -7z"></path><path d="M2415 4453 c0 -14 6 -28 13 -31 l12 -4 0 31 0 31 -13 0 -13 0 1 -27z"></path><path d="M6183 4473 c-7 -3 -13 -15 -13 -28 0 -32 -10 -56 -21 -49 -5 3 -9 -4
-9 -15 l0 -21 20 0 19 0 -5 15 -6 15 16 0 c9 0 16 7 16 15 0 8 7 15 15 15 l15
0 0 20 0 20 -14 0 c-8 0 -16 4 -18 9 -1 4 -9 6 -15 4z"></path><path d="M4860 4438 c0 -22 17 -41 29 -34 6 4 11 14 11 22 0 8 -4 12 -10 9 -5
-3 -10 -1 -10 4 0 6 -4 11 -10 11 -5 0 -10 -6 -10 -12z"></path><path d="M4950 4420 l0 -30 15 0 c16 0 19 17 9 44 -10 26 -24 18 -24 -14z"></path><path d="M2936 4415 c-17 -13 -22 -55 -7 -55 5 0 12 9 16 19 3 11 12 22 20 25
8 3 15 9 15 14 0 13 -25 11 -44 -3z"></path><path d="M172 4398 c2 -5 8 -9 13 -9 5 0 11 4 13 9 1 5 -4 9 -13 9 -9 0 -14
-4 -13 -9z"></path><path d="M2317 4393 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M2366 4371 c1 -14 2 -29 3 -33 2 -12 31 -9 31 3 l0 11 15 -6 15 -5 0
14 c0 8 -7 15 -16 15 l-16 0 5 13 4 12 -21 1 -22 2 2 -27z m21 -18 c-4 -3 -7
0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M4890 4390 c0 -16 23 -60 32 -60 4 0 8 -7 8 -15 0 -18 52 -129 63
-133 4 -2 7 -11 7 -20 0 -23 23 -82 32 -82 4 0 8 -11 8 -25 0 -14 9 -51 20
-82 11 -32 20 -68 20 -80 0 -24 14 -31 25 -12 4 5 -2 40 -13 77 -36 118 -52
185 -45 196 3 6 3 8 -2 4 -13 -12 -34 19 -41 61 l-6 41 -11 -7 -10 -6 6 17 7
17 -12 -7 -11 -7 6 17 7 17 -12 -7 -11 -7 7 19 c4 10 4 15 0 12 -10 -11 -34
18 -34 41 l0 20 -17 -6 -18 -7 14 17 13 17 -16 0 c-9 0 -16 -4 -16 -10z"></path><path d="M6208 4360 l-16 -39 26 -3 26 -3 10 -32 9 -33 15 0 14 0 -7 18 c-3 9
-9 29 -12 45 -3 15 -10 27 -15 27 -5 0 -17 8 -26 17 l-17 17 18 -7 17 -6 0 19
c0 34 -24 22 -42 -20z"></path><path d="M150 4361 c0 -11 -5 -21 -12 -23 l-11 -4 15 -14 14 -14 -4 -53 -5
-53 17 6 16 6 -2 -18 c-3 -42 3 -84 12 -84 l10 0 0 50 c0 28 -3 50 -7 50 -5 0
-9 23 -10 50 0 28 3 50 8 50 5 0 9 7 9 16 l0 15 -10 -6 c-5 -3 -10 -1 -10 4 0
6 6 11 13 11 l12 1 -19 14 c-26 20 -36 19 -36 -4z"></path><path d="M2290 4345 l0 -16 23 3 c12 2 22 8 22 13 0 6 -10 11 -22 13 l-23 3 0
-16z"></path><path d="M2898 4340 c-1 -5 -18 -10 -35 -12 -37 -3 -49 -21 -26 -39 l15 -13
15 19 c8 11 19 19 25 17 11 -3 28 15 28 31 0 10 -18 9 -22 -3z"></path><path d="M6143 4291 l-3 -49 20 5 20 5 0 44 0 44 -17 0 -18 0 -2 -49z"></path><path d="M1360 4320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2250 4310 c0 -5 -5 -10 -10 -10 -16 0 -54 -39 -46 -47 3 -4 20 1 36
10 16 10 33 17 37 15 5 -2 9 7 11 20 l3 22 -15 0 c-9 0 -16 -4 -16 -10z"></path><path d="M2763 4290 l2 -30 14 5 13 5 -5 -14 c-6 -16 11 -36 30 -36 l13 0 0
25 c0 14 -5 25 -11 25 -6 0 -17 6 -25 14 -8 8 -14 19 -14 25 0 6 -4 11 -10 11
-5 0 -8 -14 -7 -30z"></path><path d="M6205 4289 c4 -5 4 -21 1 -35 l-6 -24 -21 0 -21 0 6 -16 c3 -9 6 -22
6 -30 0 -8 5 -14 10 -14 6 0 10 7 10 16 0 20 27 47 40 39 l10 -6 0 40 0 41
-21 0 -20 0 6 -11z"></path><path d="M98 4255 l-5 -24 11 2 c25 5 36 47 12 47 -7 0 -16 -11 -18 -25z"></path><path d="M2233 4228 c-33 -35 -33 -58 -1 -58 l19 0 -6 20 -6 20 20 0 21 0 0
25 c0 14 -4 25 -8 25 -4 0 -22 -15 -39 -32z"></path><path d="M2167 4233 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"></path><path d="M6280 4220 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M91 4183 c1 -21 1 -48 0 -60 l-1 -23 -15 0 -15 0 0 -20 c0 -24 18
-27 26 -5 l6 16 13 -11 12 -10 7 46 6 45 -15 30 c-9 16 -18 29 -21 29 -3 0 -5
-17 -3 -37z"></path><path d="M2107 4168 l-37 -42 0 -23 0 -23 -20 0 c-23 0 -25 -17 -6 -42 16 -22
46 -24 46 -3 0 8 -7 15 -16 15 l-16 0 5 13 4 12 27 -3 27 -3 -2 23 c-1 13 -1
35 -1 50 l2 27 35 7 c19 3 35 11 35 16 l0 10 -20 -5 -20 -5 6 9 c3 5 1 9 -4 9
-5 0 -25 -19 -45 -42z m-10 -57 c0 -11 -5 -18 -9 -15 -4 3 -5 11 -1 19 7 21
12 19 10 -4z"></path><path d="M2773 4194 c4 -4 -6 -18 -21 -31 l-27 -24 20 7 20 8 -14 -17 -13 -17
10 0 c12 0 52 53 52 69 0 6 -7 11 -17 11 -9 0 -13 -3 -10 -6z"></path><path d="M6240 4180 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M6295 4168 c2 -7 6 -33 8 -57 l4 -45 13 -4 13 -5 -7 54 c-4 29 -9 56
-12 61 -8 13 -24 9 -19 -4z"></path><path d="M2600 4161 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M2668 4153 l-18 -4 0 -19 0 -19 -16 6 -16 6 -9 -14 c-5 -8 -9 -22 -9
-31 l0 -18 15 0 14 0 -6 17 -6 16 16 -6 17 -6 0 9 c0 6 4 10 10 10 l10 0 -5
-41 -5 -41 17 4 18 4 -2 27 -2 27 25 0 24 0 0 15 c0 8 -7 15 -15 15 -8 0 -15
7 -15 15 l0 14 -15 -5 -15 -6 6 16 c3 9 5 15 3 15 -2 -1 -12 -3 -21 -6z"></path><path d="M155 4047 c8 -33 27 -54 33 -36 7 19 -8 57 -24 63 l-16 6 7 -33z"></path><path d="M1989 4028 c-1 -7 -1 -21 0 -30 1 -10 -2 -18 -8 -18 -6 0 -8 7 -5 15
11 26 -11 16 -29 -14 -21 -36 -22 -61 -2 -61 14 0 19 11 16 37 -1 6 9 13 21
15 l23 3 3 23 c2 12 8 20 13 17 l9 -6 -6 15 c-7 19 -34 21 -35 4z"></path><path d="M2630 4034 c0 -7 -9 -14 -20 -17 -11 -3 -20 -9 -20 -14 0 -8 47 -33
62 -33 13 0 9 35 -7 56 -8 10 -15 14 -15 8z"></path><path d="M83 4023 l-23 -4 0 -20 c0 -10 -4 -19 -9 -19 -5 0 -11 -13 -13 -29
l-4 -29 13 5 c7 3 13 9 13 15 0 5 4 7 9 4 l8 -5 -5 26 -5 26 11 -7 12 -7 -6
16 -6 15 16 -6 16 -6 0 16 c0 9 -1 15 -2 15 -2 -1 -13 -4 -25 -6z"></path><path d="M130 3996 c0 -22 11 -46 21 -46 5 0 9 14 9 30 l0 30 -15 0 c-8 0 -15
-6 -15 -14z"></path><path d="M2440 4000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2526 3988 c3 -5 -9 -22 -27 -38 l-32 -30 6 -36 6 -36 -14 6 -15 5 0
-19 0 -19 15 5 15 6 0 -20 0 -21 20 -6 c41 -13 55 23 20 52 l-20 17 0 34 c0
18 6 40 14 48 l15 14 40 0 41 0 0 10 c0 6 -11 10 -25 10 l-25 0 0 14 0 15 -19
-2 c-11 0 -17 -5 -15 -9z m-6 -188 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z"></path><path d="M6320 3964 c0 -7 5 -14 12 -16 l12 -4 1 -73 c0 -40 -3 -75 -7 -77
-15 -9 -19 22 -9 65 l9 42 -14 -12 c-8 -6 -14 -18 -14 -26 0 -8 -4 -11 -10 -8
l-10 6 0 -43 0 -43 20 -13 20 -12 0 -36 0 -36 20 5 20 5 -6 -47 -6 -48 16 0
16 0 0 48 0 48 -14 8 c-7 4 -16 27 -18 50 l-6 42 13 -5 13 -5 -5 48 c-2 26 -9
71 -14 99 l-10 51 -14 0 c-8 0 -15 -6 -15 -13z"></path><path d="M1282 3900 c-1 -38 2 -70 7 -70 11 0 20 80 14 114 -10 48 -20 26 -21
-44z"></path><path d="M2020 3961 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M1980 3950 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2600 3920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5155 3918 c-8 -27 -11 -77 -4 -72 4 2 10 -4 12 -13 3 -10 5 8 6 40
1 54 -5 73 -14 45z"></path><path d="M2566 3906 l6 -16 -18 7 -17 6 6 -17 7 -17 -10 6 c-6 3 -10 0 -10 -9
l0 -14 17 2 c9 2 19 8 21 14 2 7 10 12 19 12 l14 0 -3 17 c-2 10 -11 19 -21
21 l-17 3 6 -15z"></path><path d="M1200 3881 c0 -16 -7 -34 -15 -41 -21 -18 -19 -38 6 -45 11 -4 22 -5
24 -3 4 4 8 66 6 96 -2 33 -21 27 -21 -7z"></path><path d="M1879 3898 c-1 -7 -3 -23 -4 -35 -1 -12 1 -20 5 -18 10 6 22 -24 15
-35 -3 -4 4 -6 15 -3 l20 5 6 -31 c8 -40 24 -40 24 -1 l0 30 -11 0 -11 0 6 16
6 15 -10 -6 c-6 -3 -10 -1 -10 4 0 6 7 11 15 11 8 0 15 7 15 15 l0 15 -20 0
c-11 0 -23 7 -26 15 -7 18 -33 20 -35 3z"></path><path d="M20 3838 c0 -36 4 -35 14 5 l6 27 -10 0 c-5 0 -10 -14 -10 -32z"></path><path d="M80 3860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1250 3850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1813 3804 c-3 -8 -1 -20 6 -27 6 -6 11 -19 11 -29 l0 -18 24 0 25 0
6 -20 c3 -11 13 -20 21 -20 20 0 18 10 -7 31 l-21 18 4 19 5 18 -27 -4 c-15
-2 -30 1 -34 6 l-6 11 20 6 c11 3 20 11 20 16 0 15 -40 10 -47 -7z"></path><path d="M5100 3807 c0 -8 7 -14 15 -14 8 0 15 6 15 14 0 7 -7 13 -15 13 -8 0
-15 -6 -15 -13z"></path><path d="M5140 3800 l0 -24 12 12 12 12 -12 12 -12 12 0 -24z"></path><path d="M2445 3760 c-4 -16 -3 -35 0 -41 l7 -11 12 12 11 11 -12 7 -12 8 9
14 c12 19 12 30 1 30 -5 0 -12 -13 -16 -30z"></path><path d="M1233 3761 c-3 -10 -13 -23 -23 -27 l-18 -9 14 -13 14 -13 7 18 c4
10 12 22 20 26 16 10 17 37 2 37 -6 0 -13 -8 -16 -19z"></path><path d="M1160 3758 c0 -4 7 -8 15 -8 8 0 15 4 15 8 0 5 -7 9 -15 9 -8 0 -15
-4 -15 -9z"></path><path d="M2470 3761 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M5110 3741 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21
l-10 6 0 -20z"></path><path d="M1746 3734 c-3 -9 -3 -21 1 -27 4 -6 8 -21 9 -34 l3 -23 -14 0 c-7 0
-16 -7 -19 -16 l-6 -16 -27 7 -26 6 -8 -13 c-5 -7 -9 -23 -9 -35 l0 -23 -19 0
-19 0 -7 -36 -6 -37 20 -20 20 -21 -5 29 c-3 16 -7 32 -10 37 -7 11 21 38 41
38 9 0 14 -4 10 -9 l-5 -10 19 2 c11 0 19 4 18 8 -1 4 19 28 46 53 l47 45 0
20 c0 11 -5 23 -10 26 -6 4 -10 12 -9 18 4 29 -2 47 -15 47 -8 0 -17 -7 -20
-16z m-20 -150 l-36 -37 -10 28 c-6 15 -8 30 -5 33 3 3 5 0 5 -6 0 -7 7 -12
15 -12 8 0 15 5 15 10 0 10 41 31 49 24 2 -1 -13 -19 -33 -40z"></path><path d="M2472 3718 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
-8z"></path><path d="M1160 3702 c0 -22 13 -42 27 -42 l14 0 -3 22 -3 23 -17 3 c-10 2 -18
-1 -18 -6z"></path><path d="M0 3565 l0 -124 15 5 15 6 0 73 c0 89 -10 165 -21 165 l-9 0 0 -125z"></path><path d="M1000 3680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1102 3678 c-7 -7 -12 -18 -12 -25 0 -8 -7 -16 -15 -19 l-15 -6 0
-35 0 -35 13 5 12 4 -1 29 -1 29 28 1 c16 1 29 -3 29 -7 0 -5 5 -9 10 -9 6 0
10 7 10 15 0 8 -7 15 -16 15 l-16 0 6 15 5 15 -12 0 -12 0 15 10 15 10 -15 0
c-9 0 -21 -5 -28 -12z"></path><path d="M2320 3680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2390 3679 c0 -5 -4 -7 -10 -4 -10 6 -14 -7 -11 -34 2 -7 -2 -10 -7
-7 -6 3 -13 0 -16 -6 l-6 -13 -6 12 -5 12 -16 -6 -16 -6 5 25 5 26 -14 -5
c-15 -6 -18 -43 -3 -43 l10 0 -6 -26 -7 -27 11 7 11 6 3 -22 c2 -13 10 -24 19
-26 l16 -3 -2 35 -3 35 14 5 c8 3 14 1 14 -4 0 -6 5 -10 11 -10 l11 0 -6 25
-6 25 20 0 20 0 0 -16 0 -15 -10 6 c-11 7 -14 -8 -3 -18 10 -10 33 13 33 34 0
19 -25 49 -41 49 -5 0 -9 -5 -9 -11z"></path><path d="M5124 3638 c3 -29 6 -74 6 -100 l0 -48 11 0 11 0 -4 70 c-2 39 0 70
4 70 4 0 8 7 8 16 l0 15 -10 -6 -10 -6 0 20 c0 12 -5 21 -11 21 l-12 0 7 -52z"></path><path d="M1710 3660 c0 -11 2 -20 5 -20 2 0 9 9 15 20 l11 20 -16 0 -15 0 0
-20z"></path><path d="M6321 3631 l-14 -9 -8 -153 -8 -154 12 -7 12 -7 3 27 c2 15 8 32 12
37 5 6 9 49 8 95 -1 160 -1 180 -2 180 -1 0 -7 -4 -15 -9z"></path><path d="M1000 3585 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"></path><path d="M1770 3575 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M1065 3542 c-10 -11 -4 -32 9 -32 11 0 26 20 26 34 0 5 -30 3 -35 -2z"></path><path d="M2280 3494 l0 -56 15 6 c8 3 15 1 15 -4 0 -6 5 -10 10 -10 13 0 13
27 -1 32 -5 2 -13 22 -16 46 -3 23 -10 42 -14 42 -5 0 -9 -25 -9 -56z"></path><path d="M1005 3521 c-3 -2 -5 -27 -5 -54 0 -28 -3 -47 -7 -44 -3 4 -14 0 -24
-9 l-18 -16 6 -15 6 -16 -14 5 c-8 3 -13 12 -13 21 1 9 -2 14 -7 11 -12 -7 -4
-49 9 -48 7 1 21 2 33 3 l20 1 21 46 20 46 -6 29 -5 28 11 7 11 7 -8 -13 -8
-13 11 7 c19 11 14 25 -8 23 -12 -1 -23 -3 -25 -6z"></path><path d="M1540 3446 c0 -8 -6 -16 -12 -19 l-13 -4 11 -12 10 -11 14 25 c7 14
17 25 22 25 13 0 10 -8 -14 -35 l-22 -26 -13 6 -14 5 -19 -33 c-11 -17 -20
-38 -20 -45 l0 -13 23 3 22 3 10 33 c5 17 13 32 18 32 4 0 5 -5 1 -12 l-7 -11
12 7 c6 4 9 12 6 17 l-6 9 15 -6 16 -6 0 16 c0 9 4 16 9 16 5 0 12 8 15 17 l7
16 -13 9 c-7 4 -23 8 -35 8 l-23 0 0 -14z"></path><path d="M5130 3357 l0 -74 16 0 16 0 -6 36 c-3 20 -6 53 -6 74 0 20 -4 37
-10 37 l-10 0 0 -73z"></path><path d="M0 3358 c0 -36 3 -86 6 -112 l7 -46 13 0 14 0 0 35 c0 19 -4 35 -9
35 l-10 0 2 65 2 64 -13 13 -12 12 0 -66z m17 -5 c-4 -3 -7 0 -7 7 0 7 3 10 7
7 3 -4 3 -10 0 -14z"></path><path d="M1610 3410 l-15 -9 12 -12 12 -12 7 17 c8 22 2 27 -16 16z"></path><path d="M2280 3411 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M850 3353 c0 -9 3 -13 7 -10 6 7 53 -50 53 -65 0 -4 -8 -4 -17 -1
l-18 7 13 -17 c8 -8 20 -13 28 -10 l14 5 0 32 0 32 -20 22 c-12 12 -30 22 -40
22 l-20 0 0 -17z"></path><path d="M2160 3360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2220 3350 l0 -20 -15 0 c-16 0 -20 -12 -7 -24 4 -4 8 -12 8 -17 1
-5 2 -13 3 -19 0 -5 8 -10 17 -10 l16 0 -7 40 -7 40 11 0 c6 0 11 7 11 15 0 8
-7 15 -15 15 l-15 0 0 -20z"></path><path d="M2120 3294 l0 -36 13 5 c6 2 12 9 11 15 -1 7 -2 21 -2 32 -3 35 -22
21 -22 -16z"></path><path d="M6378 3292 c2 -16 -1 -51 -6 -80 l-9 -52 13 0 14 0 0 74 c0 41 -4 77
-8 80 -5 3 -7 -7 -4 -22z"></path><path d="M849 3286 l12 -14 -12 -4 -12 -4 11 -21 c6 -11 8 -23 5 -27 -3 -3
-24 -6 -46 -6 l-39 0 6 -16 c3 -9 6 -22 6 -30 0 -20 30 -18 46 3 18 26 26 11
13 -26 l-11 -32 -14 5 -13 5 -6 -20 c-4 -10 -11 -19 -16 -19 -5 0 -9 -7 -9
-15 0 -16 -4 -18 -37 -9 l-21 6 -6 24 c-4 13 -11 24 -16 24 l-10 0 0 -70 0
-70 15 0 c17 0 21 -16 5 -22 -5 -1 -12 -21 -15 -43 l-5 -40 9 14 9 13 6 -11
c11 -18 34 -13 41 10 9 29 -2 36 -21 13 l-16 -19 7 20 c4 11 10 29 12 41 l5
21 24 -5 24 -4 0 16 c0 10 -4 15 -8 12 -5 -3 -6 8 -4 25 l4 29 18 0 19 0 6 20
c3 11 11 20 16 20 5 0 9 6 9 13 0 6 14 39 30 72 17 33 30 63 30 68 0 13 -43
67 -53 67 l-9 0 11 -14z m-62 -233 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10
0 -14z m-60 -40 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M1450 3285 c0 -8 -6 -15 -13 -15 -6 0 -18 -15 -26 -32 -8 -18 -19
-36 -25 -40 l-11 -7 14 -23 13 -23 -12 12 -13 12 -19 -21 c-10 -12 -18 -27
-18 -35 0 -21 28 -15 35 7 l6 20 13 -13 c8 -7 13 -24 12 -38 l-2 -24 -12 3
c-7 1 -12 -2 -12 -8 0 -14 22 -12 43 4 19 15 23 42 6 48 l-10 3 10 28 c14 36
31 34 31 -3 0 -16 5 -30 10 -30 11 0 13 33 4 78 l-6 30 -23 -4 -23 -4 5 20 5
20 25 0 25 0 -6 16 c-3 9 -6 20 -6 25 0 5 -4 9 -10 9 -5 0 -10 -7 -10 -15z
m-10 -105 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4
20 -10z"></path><path d="M2155 3250 c-4 -11 -11 -18 -16 -15 -5 4 -9 -3 -9 -14 0 -23 18 -28
26 -8 l5 13 29 -4 30 -4 0 16 c0 19 -26 22 -33 4 -3 -7 -6 -3 -6 10 -1 27 -17
29 -26 2z"></path><path d="M5120 3241 c0 -15 -3 -48 -6 -73 l-6 -45 15 0 c9 0 14 4 11 9 -4 5
-3 29 1 54 l6 44 10 -5 9 -6 0 21 0 21 -10 -6 c-5 -3 -10 -1 -10 4 0 6 -4 11
-10 11 -5 0 -10 -13 -10 -29z"></path><path d="M6316 3228 c-3 -17 -4 -33 -2 -36 8 -7 36 25 36 41 0 8 -6 17 -14 20
l-14 6 -6 -31z"></path><path d="M696 3194 c-10 -27 -7 -31 24 -26 l30 4 0 15 c0 8 -4 11 -10 8 -5 -3
-10 -1 -10 4 0 17 -27 13 -34 -5z"></path><path d="M2170 3194 c15 -17 30 -18 30 -1 0 7 -9 13 -21 14 l-21 2 12 -15z"></path><path d="M5160 3114 l0 -15 -10 6 -10 6 0 -36 0 -36 9 5 c5 4 12 0 14 -6 3 -7
6 11 6 40 1 28 -1 52 -4 52 -3 0 -5 -7 -5 -16z"></path><path d="M20 3075 c0 -19 4 -35 9 -35 5 0 9 16 9 35 0 19 -4 35 -9 35 -5 0 -9
-16 -9 -35z"></path><path d="M6295 3063 c4 -27 8 -62 8 -79 l1 -30 18 -1 18 -2 0 14 c0 7 7 15 15
19 8 3 15 13 15 22 0 10 -3 14 -7 11 -3 -4 -15 0 -25 10 l-18 16 0 34 0 33
-16 0 -17 0 8 -47z"></path><path d="M1322 3073 l3 -18 23 -3 22 -3 0 16 0 17 -16 -6 -15 -6 6 10 6 10
-16 0 -16 0 3 -17z"></path><path d="M2120 3075 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M70 3040 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14
0 -25 -4 -25 -10z"></path><path d="M5076 2977 c-10 -40 -24 -94 -30 -122 -5 -27 -13 -52 -16 -55 -4 -3
-26 -53 -49 -112 -24 -60 -47 -108 -51 -108 -4 0 -10 -13 -13 -29 -3 -16 -12
-35 -20 -43 -22 -22 -39 -59 -33 -73 l5 -14 -14 5 -14 6 -21 -43 c-12 -23 -30
-51 -41 -62 -10 -12 -19 -25 -19 -29 0 -5 -9 -18 -20 -30 -11 -12 -20 -28 -20
-35 0 -7 -7 -13 -15 -13 -8 0 -15 -5 -15 -11 0 -6 -21 -30 -47 -54 l-46 -43 6
-16 c7 -19 38 -22 34 -3 -1 6 4 11 11 9 l13 -2 -6 10 -7 11 21 18 c11 10 18
21 14 25 -3 3 -1 6 6 6 7 0 9 5 6 10 l-6 10 16 0 c18 0 45 23 45 37 0 5 7 18
16 29 8 10 12 13 8 5 l-7 -14 12 7 12 8 -12 14 -12 14 17 -6 17 -6 -3 13 c-2
8 2 13 9 12 l12 -3 -6 17 -6 16 10 -6 11 -7 6 16 6 15 -10 -6 -10 -6 6 15 c3
9 10 13 15 10 5 -3 9 6 9 19 l0 25 18 8 c9 4 20 6 25 6 7 -2 11 13 8 37 l-2
13 12 -8 12 -7 -6 11 c-9 13 2 44 13 37 4 -3 8 1 7 8 -1 35 4 58 12 53 5 -3
15 14 22 38 7 25 17 43 21 40 4 -3 5 2 2 10 l-6 16 11 0 c6 0 11 12 11 28 0
15 5 34 10 42 l10 15 0 -12 c0 -7 7 -13 15 -13 18 0 19 12 3 28 l-12 12 11 0
12 0 -4 20 c-2 11 0 20 6 20 5 0 9 18 9 41 l0 40 13 -7 c6 -4 9 -4 6 0 -4 4 0
28 7 53 8 25 14 59 14 74 0 52 -15 37 -34 -34z"></path><path d="M1297 3034 c-4 -4 -7 -16 -7 -27 0 -11 -7 -25 -16 -32 l-17 -12 12
-19 12 -19 -5 23 -4 23 12 -10 12 -10 13 29 12 29 -8 16 c-5 9 -12 12 -16 9z"></path><path d="M2048 3012 c-31 -4 -38 -21 -10 -23 9 -1 20 -1 25 0 4 0 7 -4 7 -10
0 -6 -6 -9 -12 -6 -7 2 -21 -2 -30 -10 l-17 -15 -10 33 c-5 19 -10 28 -10 21
-1 -7 -5 -10 -11 -7 l-10 6 0 -35 0 -36 15 0 c19 0 19 -9 -1 -31 -19 -21 -11
-41 13 -32 l16 6 -7 -11 c-3 -6 -2 -13 4 -17 l10 -6 9 23 9 23 -10 18 c-5 10
-7 21 -4 25 3 3 6 1 6 -5 l0 -10 26 5 26 5 -5 18 -5 19 14 0 14 0 0 19 c0 25
-19 37 -52 33z"></path><path d="M38 2952 c3 -15 8 -29 12 -32 4 -3 10 -17 14 -32 l6 -28 -9 0 c-5 0
-7 -9 -4 -20 5 -20 23 -28 23 -10 0 5 7 7 15 4 l15 -6 0 37 0 37 -16 -6 -16
-6 5 20 c3 11 2 18 -3 15 -4 -3 -11 6 -14 19 -3 13 -12 26 -19 29 l-14 5 5
-26z"></path><path d="M140 2947 c0 -1 -4 -17 -10 -35 l-9 -34 14 6 14 5 7 -49 c4 -28 10
-50 14 -50 11 0 20 27 20 60 l0 28 -15 6 -15 5 0 31 c0 16 -4 30 -10 30 -5 0
-10 -1 -10 -3z"></path><path d="M1281 2909 c-7 -16 -10 -33 -6 -37 4 -4 10 -1 12 6 3 6 11 12 19 12
l14 0 0 25 0 25 -13 0 c-6 0 -18 -14 -26 -31z m20 -2 l-12 -12 7 18 c3 10 9
15 12 12 3 -3 0 -11 -7 -18z"></path><path d="M1235 2917 l-8 -18 12 -12 c16 -16 26 0 14 25 l-11 23 -7 -18z"></path><path d="M6301 2886 l-12 -14 16 -6 15 -5 0 19 c0 24 -4 25 -19 6z"></path><path d="M1199 2833 c-5 -13 -9 -33 -9 -44 l0 -20 13 6 c6 3 2 -2 -10 -11 -28
-20 -29 -34 -3 -34 l20 0 1 23 c1 12 2 30 3 39 1 10 4 18 9 18 30 1 37 -1 37
-10 0 -5 -7 -10 -16 -10 l-16 0 5 -12 c2 -7 12 -12 21 -12 l16 2 -2 -17 -3
-16 23 -3 22 -3 0 20 c0 11 -7 27 -16 35 -8 9 -12 20 -9 26 3 5 1 10 -4 10 -6
0 -11 6 -11 14 l0 14 -31 4 -31 5 -9 -24z"></path><path d="M689 2836 c0 -6 -2 -21 -3 -33 l-3 -23 14 0 c7 0 13 6 13 13 0 6 3
21 6 33 l5 21 -15 0 c-9 0 -16 -5 -17 -11z"></path><path d="M6316 2825 l6 -15 -11 0 c-6 0 -11 -7 -11 -15 0 -8 7 -15 15 -15 8 0
15 6 15 14 0 8 3 21 6 30 l6 16 -16 0 -15 0 5 -15z"></path><path d="M1984 2800 l2 -31 -10 4 -11 4 -1 -23 c-1 -13 2 -24 7 -24 5 0 9 -7
9 -15 0 -8 5 -15 11 -15 l12 0 -6 30 -6 30 14 0 c16 0 18 12 9 48 -4 12 -12
22 -19 22 l-13 0 2 -30z"></path><path d="M100 2801 c-11 -21 -14 -111 -4 -111 4 0 14 9 23 19 l17 20 -4 35 -4
36 -11 -7 -10 -6 6 16 c9 23 0 21 -13 -2z"></path><path d="M6297 2739 l-5 -20 11 4 c15 5 22 37 9 37 -5 0 -12 -9 -15 -21z"></path><path d="M170 2720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M6240 2695 l0 -25 15 0 c17 0 20 -16 4 -26 l-11 -6 7 -25 6 -25 -11
7 c-16 10 -41 64 -35 75 l6 10 -15 -6 -16 -6 0 21 c0 12 -4 21 -10 21 l-10 0
0 -40 0 -40 21 0 21 0 -7 -42 c-4 -24 -12 -46 -17 -49 l-10 -6 11 -39 c6 -21
8 -40 6 -43 -3 -2 -5 1 -5 7 0 7 -4 12 -9 12 -5 0 -11 -15 -13 -33 l-3 -33 30
1 30 0 3 25 c2 14 8 29 13 35 15 15 10 32 -11 38 -30 8 -24 26 7 24 l27 -2 4
18 c2 9 -1 17 -7 17 -6 0 -11 -8 -12 -17 l-1 -18 -9 29 c-4 17 -6 32 -4 35 3
2 5 -1 5 -7 0 -24 28 -11 40 17 l12 30 -7 32 c-7 30 -26 59 -39 59 -3 0 -6
-11 -6 -25z m-23 -242 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M153 2648 l6 -50 -16 -16 -16 -16 12 -4 c6 -2 11 -10 11 -17 0 -7 7
-16 15 -19 l16 -6 9 26 c12 32 13 64 1 64 l-9 0 5 39 5 38 -22 6 -23 6 6 -51z"></path><path d="M553 2693 c-15 -5 -18 -43 -4 -43 5 0 11 11 13 25 3 14 5 25 4 24 0
0 -7 -3 -13 -6z"></path><path d="M626 2671 l-18 -20 7 -18 8 -18 5 20 c2 11 9 23 14 26 6 4 9 12 6 19
l-4 12 -18 -21z"></path><path d="M1966 2671 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M106 2653 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"></path><path d="M1120 2623 l1 -28 14 19 c19 25 19 36 0 36 l-15 0 0 -27z"></path><path d="M1955 2630 c-4 -6 -9 -8 -13 -6 -9 5 -38 -16 -41 -29 -1 -5 -5 -21
-8 -34 l-6 -23 14 -9 c8 -5 20 -9 28 -9 l13 0 -4 25 -5 26 13 7 c7 4 16 19 19
35 7 27 2 36 -10 17z"></path><path d="M520 2590 c0 -11 5 -20 10 -20 6 0 10 -9 10 -19 0 -10 7 -21 15 -25
l15 -5 0 20 c0 11 7 22 15 25 8 4 15 10 15 15 0 14 -28 11 -33 -3 l-6 -13 -6
23 c-4 12 -14 22 -21 22 l-14 0 0 -20z"></path><path d="M6140 2591 l0 -19 20 -5 20 -5 0 24 0 24 -20 0 -20 0 0 -19z"></path><path d="M1105 2560 l7 -30 -16 0 c-9 0 -16 -7 -16 -15 0 -8 6 -15 13 -15 19
1 57 37 57 55 l0 17 -14 -5 -15 -6 6 15 5 14 -17 0 -17 0 7 -30z"></path><path d="M1800 2560 c0 -5 9 -10 21 -10 l21 0 -6 -16 c-3 -9 -6 -22 -6 -30 0
-9 -4 -12 -10 -9 l-10 6 0 -24 0 -25 21 -5 21 -6 -4 -13 c-3 -7 -14 -14 -26
-16 l-22 -3 0 -20 0 -19 14 0 c8 0 18 4 21 10 l6 9 29 -6 30 -5 0 21 0 22 -10
-6 -10 -6 0 20 0 21 -20 0 c-11 0 -20 5 -20 10 0 6 6 10 14 10 7 0 18 6 24 13
l10 12 -14 19 c-8 11 -14 28 -14 38 l0 18 -30 0 c-16 0 -30 -4 -30 -10z"></path><path d="M603 2553 c-15 -5 -18 -59 -4 -67 9 -6 31 36 31 59 0 15 -6 17 -27 8z"></path><path d="M1132 2481 l-11 -31 -30 6 -31 7 0 -17 c0 -9 7 -19 15 -22 22 -9 18
-23 -9 -30 l-24 -6 -6 -30 c-7 -35 1 -50 14 -28 l10 15 0 -18 0 -19 -20 -5
c-11 -3 -20 -12 -20 -20 l0 -15 15 12 c8 7 15 9 15 4 0 -5 7 -1 14 9 l14 18 7
-10 c3 -6 15 -11 26 -11 l19 0 0 29 0 30 -37 3 -38 4 20 8 c11 4 28 12 38 17
10 5 22 7 26 4 5 -3 12 2 15 11 9 23 7 87 -3 102 l-8 14 -11 -31z"></path><path d="M4990 2500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M190 2481 c0 -7 -4 -9 -10 -6 l-10 6 7 -17 6 -17 -11 7 -12 7 0 -15
c0 -9 4 -16 8 -16 11 0 35 51 27 58 -3 3 -5 0 -5 -7z"></path><path d="M527 2393 c4 -21 7 -48 6 -60 0 -13 3 -23 8 -23 5 0 9 5 9 11 0 5 5
7 10 4 l10 -6 0 20 0 21 15 0 15 0 0 20 0 20 -25 0 -26 0 -5 15 c-4 8 -10 15
-16 15 l-9 0 8 -37z"></path><path d="M4940 2420 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M6130 2390 l0 -29 15 5 c17 7 20 20 5 29 -6 4 -8 11 -5 16 4 5 1 9
-4 9 -6 0 -11 -13 -11 -30z"></path><path d="M210 2395 l0 -14 17 6 16 6 -6 -16 -6 -16 15 6 c7 3 14 14 14 24 l0
19 -25 0 -25 0 0 -15z"></path><path d="M340 2400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6180 2375 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M1829 2353 l-16 -4 -7 -46 c-6 -44 1 -73 11 -45 3 6 10 9 15 6 l11
-7 -7 12 c-4 6 -12 9 -17 6 -5 -4 -9 -1 -9 6 l0 11 20 -5 20 -5 0 39 c0 21 -1
38 -2 38 -2 -1 -10 -3 -19 -6z"></path><path d="M1867 2340 c3 -11 9 -17 14 -14 14 8 10 34 -5 34 l-14 0 5 -20z"></path><path d="M252 2291 c1 -33 0 -57 -4 -54 -15 8 -8 -12 27 -85 l35 -72 18 0 18
0 0 19 c0 11 -4 17 -8 15 -4 -3 -8 1 -9 8 0 7 -4 4 -9 -7 l-9 -20 0 15 c-1 21
28 62 40 55 l9 -6 0 25 0 26 -14 0 c-7 0 -16 -9 -19 -20 -3 -11 -10 -20 -17
-20 l-11 0 6 21 7 21 -21 -7 -21 -6 0 19 c0 11 5 22 11 24 l10 3 -7 53 -7 52
-14 0 -15 0 4 -59z"></path><path d="M6140 2340 l-15 -10 18 0 c9 0 17 5 17 10 0 12 -1 12 -20 0z"></path><path d="M314 2315 c2 -11 1 -29 -4 -40 -10 -26 -2 -45 19 -45 l16 0 2 53 2
53 -20 0 -20 0 5 -21z"></path><path d="M212 2303 c2 -9 9 -18 16 -21 l12 -4 0 21 0 21 -16 0 -15 0 3 -17z"></path><path d="M6152 2286 l-11 -24 14 -5 13 -6 6 22 c8 30 7 37 -2 37 -5 0 -14 -11
-20 -24z"></path><path d="M1059 2241 c-7 -28 -16 -48 -21 -45 -4 3 -8 -17 -8 -44 l0 -49 31 -7
c45 -9 69 -7 69 4 0 6 -7 10 -15 10 l-15 0 0 18 c0 9 -9 23 -20 30 l-20 12 0
28 c0 15 4 33 9 41 l9 14 16 -6 c9 -4 16 -15 16 -26 l0 -20 15 5 c8 4 15 15
15 25 l0 19 -16 0 c-9 0 -25 9 -34 20 l-18 21 -13 -50z m28 -128 c-4 -3 -7 0
-7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M4830 2270 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M557 2240 c8 -31 23 -34 23 -6 l0 26 -14 0 -14 0 5 -20z"></path><path d="M5970 2230 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6073 2225 l0 -25 13 0 13 0 -5 15 c-4 8 -2 15 4 15 5 0 15 -7 22
-15 7 -8 16 -15 21 -15 5 0 9 7 9 15 l0 15 -21 0 c-11 0 -18 4 -15 8 2 4 -6 9
-18 10 l-23 2 0 -25z"></path><path d="M1843 2213 l-13 -5 0 -34 c0 -19 4 -34 9 -34 4 0 11 17 14 37 4 20 5
38 4 39 -1 1 -8 -1 -14 -3z"></path><path d="M6028 2145 l4 -53 -20 -11 -19 -10 5 45 5 44 -18 -6 -17 -7 6 -46 c3
-25 7 -54 8 -64 l2 -17 14 5 14 5 -6 -16 c-3 -8 -6 -27 -6 -40 l0 -25 -9 6
c-5 3 -13 0 -17 -6 l-7 -12 13 8 13 8 -7 -11 c-4 -7 -14 -12 -22 -12 -8 0 -14
-5 -14 -11 l0 -10 -12 8 c-14 8 -128 14 -128 6 0 -2 7 -11 15 -19 l15 -15 0
12 c0 7 2 10 5 7 9 -8 -6 -38 -16 -32 -10 6 -12 -26 -3 -50 3 -9 13 -16 21
-16 l14 0 -6 20 -6 20 13 -5 c7 -3 19 -17 26 -32 l12 -27 -10 -10 -10 -10 0
12 0 12 -35 0 -35 0 1 -32 c0 -18 3 -40 7 -48 8 -18 54 -23 64 -6 14 20 8 28
-15 24 l-23 -4 -1 18 c-1 9 2 20 8 23 5 4 9 -1 9 -9 0 -9 5 -16 11 -16 l10 0
-7 13 -8 12 17 -14 17 -13 0 21 0 21 14 0 c13 0 26 20 26 40 0 5 -6 10 -14 12
l-15 3 15 19 14 20 0 -18 0 -18 13 5 c6 2 11 16 10 29 l-1 26 19 -5 19 -5 0
26 c0 14 5 26 10 26 6 0 10 9 10 20 0 11 4 20 10 20 12 0 45 69 36 77 -3 3
-14 -1 -25 -11 l-18 -17 0 26 0 26 28 -6 29 -7 0 12 0 12 -15 -6 c-18 -7 -20
5 -3 22 l12 12 -12 0 c-7 0 -12 7 -12 16 l0 15 10 -6 10 -6 0 16 0 16 -10 -6
c-5 -3 -10 0 -10 8 0 8 -6 17 -12 20 l-13 4 3 -52z m-101 -252 c-4 -3 -7 0 -7
7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m-8 -36 c1 -4 -8 -3 -19 3 -11 6 -20 15 -20
21 0 6 5 7 10 4 6 -3 10 0 11 7 0 12 17 -18 18 -35z"></path><path d="M6103 2170 c-26 -21 -30 -40 -8 -40 8 0 15 6 15 14 0 8 5 16 12 18 6
2 9 9 7 15 l-4 11 -22 -18z"></path><path d="M523 2173 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M567 2173 c-4 -3 -7 -37 -7 -74 l0 -68 10 -6 10 -6 0 80 c0 45 -1 81
-3 81 -2 0 -7 -3 -10 -7z"></path><path d="M521 2108 l-1 -17 -27 -3 -28 -3 -3 -16 -3 -16 26 -5 25 -5 0 18 c0
9 6 19 14 21 l13 3 -8 20 -8 20 0 -17z"></path><path d="M1000 2110 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4670 2085 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M373 2047 l-2 -28 9 6 c6 4 10 1 10 -5 0 -6 -7 -13 -15 -16 -15 -6
-21 -43 -7 -45 4 0 15 -2 25 -4 l17 -3 0 12 c0 7 -5 17 -12 24 -12 12 -10 13
14 16 14 1 28 49 19 64 -3 6 -17 9 -31 9 l-25 -2 -2 -28z"></path><path d="M4570 2070 c0 -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 l0 -15 29 0 28
0 -5 25 c-4 24 -22 36 -22 15z"></path><path d="M322 2058 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z"></path><path d="M4485 2000 c-32 -31 -70 -62 -124 -100 l-51 -35 5 -12 4 -12 -15 6
c-7 3 -14 0 -14 -5 0 -6 -10 -12 -22 -14 -30 -4 -34 -30 -8 -46 l21 -13 17 11
17 12 -16 -5 c-9 -3 -22 -1 -30 3 l-14 9 40 17 39 16 4 -11 c2 -6 10 -11 18
-11 l14 0 0 23 0 22 -10 -15 c-11 -18 -23 -8 -14 14 l6 16 32 0 31 0 -15 10
-15 10 23 0 c13 0 21 4 18 8 -8 13 38 49 54 44 8 -3 12 -1 8 3 -11 12 11 35
34 35 l19 0 -3 17 c-5 25 -29 26 -53 3z"></path><path d="M500 1980 c0 -32 14 -40 24 -14 10 27 7 44 -9 44 l-15 0 0 -30z"></path><path d="M443 1960 c-10 -11 -23 -20 -30 -20 -7 0 -13 -7 -13 -15 0 -16 14
-18 45 -6 l20 8 0 27 c1 14 0 26 -2 26 -2 0 -11 -9 -20 -20z"></path><path d="M538 1975 c-3 -6 0 -37 6 -69 l12 -59 22 -15 22 -14 0 22 0 22 -15
-6 -15 -5 0 39 c0 22 5 40 10 40 6 0 10 7 10 15 0 18 -30 20 -31 3 -1 -7 -4
-2 -8 12 -4 14 -10 20 -13 15z"></path><path d="M1807 1953 c-11 -10 -8 -73 3 -73 l10 0 0 40 c0 41 -2 45 -13 33z"></path><path d="M4520 1945 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 l-15
5 0 -14z"></path><path d="M481 1916 l-11 -13 7 -18 6 -18 -11 7 -12 7 -15 -15 c-8 -8 -15 -20
-15 -26 l0 -12 21 5 21 6 -6 -23 -5 -22 19 -17 c11 -9 20 -24 20 -32 0 -8 7
-15 15 -15 8 0 15 -7 15 -15 l0 -15 19 0 c11 0 22 -6 25 -12 l5 -13 13 16 c7
9 19 14 27 11 l14 -5 -7 34 c-6 30 -20 48 -38 49 -5 0 -8 -5 -8 -11 0 -5 5 -7
10 -4 6 3 10 1 10 -4 0 -15 -23 -14 -38 1 -7 7 -12 8 -12 4 0 -5 10 -17 22
-28 l22 -18 -24 3 c-26 4 -50 32 -50 60 l0 18 -20 -6 -20 -6 0 10 c0 6 7 11
15 11 8 0 15 5 15 10 0 6 -4 10 -10 10 -13 0 -13 37 0 45 5 3 6 13 3 22 l-6
16 16 -6 17 -6 0 14 0 15 -19 0 c-10 0 -24 -6 -30 -14z"></path><path d="M997 1759 l7 -23 4 13 c2 7 -1 18 -6 23 l-11 11 6 -24z"></path><path d="M1070 1755 l0 -15 -19 0 c-10 0 -22 -7 -25 -16 -3 -8 -10 -12 -16 -9
l-10 6 0 -15 c0 -9 7 -16 16 -16 l17 0 -6 -54 -5 -55 18 2 18 1 -5 16 -5 15
15 -17 c8 -10 17 -15 20 -11 10 9 8 -10 -2 -26 -5 -7 -14 -11 -20 -7 l-10 7
-7 -25 c-3 -13 -1 -33 4 -43 l11 -18 6 17 c4 10 11 15 16 12 l9 -5 0 30 c0 16
5 33 10 36 l10 6 6 -30 c4 -17 10 -31 14 -31 4 0 10 14 14 31 8 44 8 61 -3 55
-5 -3 -12 1 -15 10 l-6 15 -20 -6 -20 -6 0 22 0 21 -15 -12 -15 -12 0 15 c0 8
-2 21 -3 29 -2 7 -2 10 0 6 7 -12 23 -9 23 4 0 7 11 22 25 34 l25 22 -6 16
c-3 9 -15 16 -25 16 l-19 0 0 -15z"></path><path d="M4146 1755 c-8 -8 -22 -15 -30 -15 -9 0 -16 -4 -16 -10 0 -5 -8 -10
-18 -10 -10 0 -26 -6 -36 -13 l-18 -13 16 -13 15 -13 17 16 c8 9 20 13 26 10
l11 -7 -8 13 -8 12 22 -7 21 -6 -6 15 -6 14 24 4 23 3 3 18 c4 21 -11 22 -32
2z"></path><path d="M1797 1693 c-11 -10 -8 -81 3 -88 l10 -6 0 50 c0 28 -1 51 -3 51 -2
0 -7 -3 -10 -7z"></path><path d="M4188 1673 c2 -12 8 -23 13 -23 5 0 9 11 9 23 l0 24 -13 0 -14 0 5
-24z"></path><path d="M615 1680 c-5 -8 -10 -25 -10 -38 l-1 -23 11 4 10 4 2 -25 c0 -15 6
-28 12 -30 6 -2 9 -8 5 -13 -3 -5 -3 -18 1 -28 l6 -20 19 2 c11 1 20 7 20 15
0 7 -4 11 -9 7 -5 -3 -12 -1 -16 5 -3 5 -1 10 4 10 6 0 11 5 11 11 0 5 -4 7
-10 4 l-10 -6 0 30 c0 16 -6 36 -14 44 -8 7 -16 25 -18 38 l-3 24 -10 -15z"></path><path d="M2320 1645 l1 -40 13 18 14 18 7 -10 c3 -6 4 -14 2 -18 -3 -5 5 -8
18 -8 l23 0 -11 15 c-7 8 -18 19 -25 23 -7 5 -10 12 -7 18 4 5 2 9 -4 9 -5 0
-12 -6 -14 -12 l-5 -13 -1 12 c-2 22 20 25 42 5 21 -18 47 -20 47 -2 0 11 -51
30 -77 28 l-23 -2 0 -41z"></path><path d="M3993 1683 c-12 -4 -19 -33 -9 -33 2 0 15 6 30 13 35 19 17 36 -21
20z"></path><path d="M5823 1683 c-16 -6 -17 -43 -2 -43 11 0 38 29 39 41 0 7 -19 8 -37 2z"></path><path d="M3930 1650 c0 -5 -11 -10 -25 -10 l-25 0 0 -15 0 -15 20 0 c11 0 20
5 20 10 l0 10 28 -6 27 -7 -12 15 c-7 8 -10 18 -6 21 3 4 -1 7 -10 7 -9 0 -17
-4 -17 -10z"></path><path d="M2440 1640 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4036 1632 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 l-11 11 -6 -10z"></path><path d="M2420 1622 c-11 -4 -14 -51 -4 -76 11 -29 24 -16 24 24 l0 40 29 0
c17 0 32 4 35 8 4 8 -62 11 -84 4z"></path><path d="M2550 1594 c0 -17 17 -37 40 -46 l16 -6 -1 26 0 25 -20 8 c-30 11
-35 11 -35 -7z"></path><path d="M3788 1603 c-10 -2 -18 -11 -18 -19 0 -8 2 -14 4 -14 2 0 12 -3 22
-7 l17 -6 -7 11 c-4 7 -3 12 2 12 6 0 12 7 16 15 l5 15 -12 -1 c-7 -1 -20 -3
-29 -6z"></path><path d="M5663 1595 c-18 -20 -16 -25 7 -25 11 0 20 -4 20 -10 0 -5 -7 -10
-15 -10 -8 0 -15 -7 -15 -15 l0 -14 15 5 15 6 0 -11 c0 -6 -4 -11 -10 -11 -5
0 -9 -3 -9 -7 1 -5 1 -14 0 -21 l-1 -13 -12 7 -11 7 6 -9 c7 -13 -14 -40 -39
-48 l-20 -6 4 -37 5 -36 -16 6 -16 6 6 -15 c3 -7 0 -14 -6 -14 l-10 0 1 35 c1
19 -1 37 -4 40 -3 3 -19 2 -36 -1 l-32 -6 0 -34 0 -33 16 -15 c8 -9 13 -20 10
-24 -3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 5 8 12 8 18 0 78 70 78 92 l0 20 20 5
c10 3 22 14 25 24 4 10 11 19 17 19 l10 0 -6 15 -6 16 16 6 15 6 -6 25 -6 25
15 -6 c8 -3 16 -1 19 6 2 7 12 12 23 12 l19 0 3 23 4 24 -19 -4 c-10 -2 -18
-10 -18 -18 l0 -15 -24 -3 -24 -4 6 15 6 15 -15 6 c-22 8 -27 7 -41 -9z m-123
-245 c0 -5 -9 -8 -19 -7 l-19 1 5 12 c5 13 33 8 33 -6z"></path><path d="M3666 1567 c-27 -7 -51 -18 -54 -24 l-4 -13 31 0 c17 0 31 4 31 10 0
5 15 8 33 7 l33 -3 0 18 c0 20 -2 20 -70 5z"></path><path d="M2651 1556 l-11 -13 25 5 c14 2 25 8 25 13 0 14 -26 11 -39 -5z"></path><path d="M3860 1560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2706 1545 c4 -8 14 -15 23 -15 9 0 26 -3 39 -6 l22 -6 0 15 c0 15
-28 27 -67 27 l-22 0 5 -15z"></path><path d="M2340 1534 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M2683 1533 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3503 1533 c-39 -7 -25 -21 25 -25 l48 -3 0 18 0 17 -26 -1 c-14 -1
-35 -4 -47 -6z"></path><path d="M3868 1533 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M2855 1520 c-3 -5 0 -10 8 -10 8 0 29 -3 46 -6 l31 -6 0 16 0 16 -39
0 c-22 0 -43 -4 -46 -10z"></path><path d="M3383 1513 c3 -5 -74 -7 -171 -5 l-177 3 13 -13 13 -13 144 0 c79 0
138 -3 132 -7 -15 -9 -9 -14 27 -22 l29 -7 -12 14 -12 15 43 8 c24 4 37 9 30
11 -6 3 -10 9 -6 14 l5 9 -31 0 c-17 0 -29 -3 -27 -7z"></path><path d="M2368 1483 l-3 -28 -12 3 -13 2 0 -21 c0 -11 5 -40 11 -65 l12 -45
31 7 c17 3 33 8 34 9 2 2 1 13 -3 24 -6 21 -25 29 -25 11 0 -5 -7 -10 -15 -10
l-15 0 1 23 c0 12 3 16 6 10 8 -21 20 -15 30 15 l10 27 -9 33 -9 32 -14 0 -14
0 -3 -27z"></path><path d="M2600 1500 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3730 1500 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"></path><path d="M2950 1480 l0 -20 40 0 c22 0 40 2 40 4 0 14 -35 36 -56 36 l-24 0 0
-20z"></path><path d="M2846 1465 l6 -15 39 0 39 0 0 15 0 15 -45 0 -44 0 5 -15z"></path><path d="M1063 1455 c-8 -22 4 -30 21 -16 8 7 20 10 25 7 5 -4 13 -1 17 5 l7
12 -11 -7 -12 -7 0 10 c0 17 -40 13 -47 -4z"></path><path d="M3570 1460 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5530 1440 c0 -5 13 -10 29 -10 17 0 33 5 36 10 l6 10 -35 0 c-20 0
-36 -4 -36 -10z"></path><path d="M1800 1401 c0 -17 5 -33 10 -36 l10 -6 0 35 c0 20 -4 36 -10 36 -5 0
-10 -13 -10 -29z"></path><path d="M1133 1409 c-6 -6 -9 -18 -6 -25 3 -8 0 -14 -6 -14 -6 0 -11 7 -11
15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15 0 -9 7 -18 15 -21 8 -4 15 -14 15
-24 l0 -19 20 2 c19 1 29 17 11 17 l-8 0 7 40 c4 22 7 40 6 40 -1 0 -7 -5 -13
-11z"></path><path d="M1818 1275 c7 -37 22 -41 22 -6 l0 31 -14 0 -13 0 5 -25z"></path><path d="M2394 1274 l7 -26 -11 7 -10 6 0 -20 0 -21 15 0 15 0 -6 -30 -6 -30
11 0 c6 0 11 -8 11 -17 l0 -18 25 -12 c14 -6 27 -8 30 -3 3 4 -4 10 -15 13
l-20 5 -1 26 c-3 48 -4 51 -14 58 -5 4 -11 19 -12 33 l-2 26 15 6 c23 9 16 23
-12 23 l-26 0 6 -26z"></path><path d="M1194 1274 c7 -19 -12 -47 -25 -38 -5 3 -9 -1 -9 -10 0 -25 12 -46
26 -46 l15 0 -12 22 -12 21 19 14 c10 7 21 13 26 13 4 0 8 9 8 20 l0 20 -21 0
-21 0 6 -16z"></path><path d="M5337 1283 c-14 -13 -6 -33 12 -33 16 0 31 18 31 36 0 7 -36 5 -43
-3z"></path><path d="M5398 1273 c-9 -2 -19 -12 -23 -21 l-6 -17 10 -3 c6 -2 11 -11 11
-20 l-1 -17 -13 18 c-14 19 -36 23 -36 7 0 -5 4 -10 9 -10 13 0 20 -52 10 -72
-5 -10 -17 -18 -25 -18 l-15 0 6 -10 c4 -6 12 -7 18 -3 l12 7 -14 -17 -13 -17
10 0 c6 0 19 11 30 25 11 14 25 25 31 25 15 0 14 17 -1 24 -7 3 -3 3 8 1 l22
-6 5 21 c3 11 12 20 21 20 9 0 16 5 16 10 0 6 -13 10 -29 10 l-29 0 -6 15 c-7
20 4 46 17 38 l10 -6 -7 11 c-4 7 -8 11 -9 11 -1 -1 -9 -3 -19 -6z m16 -88
c-4 -8 -16 -15 -28 -15 l-21 1 19 14 c25 19 37 19 30 0z"></path><path d="M5424 1250 l-18 -7 11 -6 c7 -4 15 -3 18 3 l6 10 19 -22 c20 -25 35
-20 26 9 l-6 19 -19 0 c-11 0 -28 -3 -37 -6z"></path><path d="M1253 1233 c-7 -2 -13 -11 -13 -18 0 -7 -6 -16 -12 -18 l-13 -5 15
-11 c14 -10 7 -15 -13 -12 -10 2 2 -71 14 -83 18 -20 23 6 7 35 -9 16 -13 29
-8 29 5 0 12 -9 15 -20 12 -37 23 -11 22 50 -1 33 -2 60 -2 59 0 0 -6 -3 -12
-6z"></path><path d="M1819 1214 l-14 -18 14 9 14 8 -6 -16 -6 -17 21 -11 21 -11 -7 28
c-10 40 -20 48 -37 28z"></path><path d="M1294 1148 c4 -18 8 -41 9 -50 1 -10 5 -18 9 -18 4 0 8 20 8 44 l0
44 -16 6 -16 6 6 -32z"></path><path d="M2574 1099 c3 -17 6 -35 6 -40 0 -5 5 -9 10 -9 l10 0 0 40 0 40 -16
0 -16 0 6 -31z"></path><path d="M5284 1095 l1 -35 13 0 12 0 0 35 0 35 -13 0 -14 0 1 -35z"></path><path d="M2510 1100 c0 -11 -5 -20 -12 -20 -6 0 -8 -3 -5 -6 4 -4 13 -1 22 6
l15 12 0 -25 c0 -32 9 -57 21 -57 5 0 9 14 9 30 0 17 -4 30 -10 30 -5 0 -10
11 -10 25 l0 25 -15 0 -15 0 0 -20z"></path><path d="M5200 1110 c0 -5 -7 -10 -16 -10 l-15 0 3 -32 3 -33 12 0 c6 0 15 -4
20 -8 l8 -9 -18 -9 c-21 -12 -22 -29 -2 -29 8 0 15 4 15 10 0 5 7 7 15 4 16
-6 45 12 45 27 0 11 -23 7 -33 -6 l-7 -10 4 29 c3 16 11 33 18 38 l13 8 -24
20 c-25 22 -41 26 -41 10z m16 -46 c-11 -7 -22 -11 -25 -8 -3 3 3 12 13 20 11
7 22 11 25 8 3 -3 -3 -12 -13 -20z"></path><path d="M1256 1074 l-7 -27 10 -10 c11 -11 31 -1 31 15 0 5 -7 8 -15 4 -8 -3
-15 -1 -15 4 0 6 5 10 10 10 6 0 10 7 10 15 0 25 -17 17 -24 -11z"></path><path d="M1850 1076 c0 -13 7 -29 15 -36 24 -20 18 -30 -20 -30 l-34 0 -7 -36
-7 -37 17 -9 16 -9 0 -30 0 -30 -15 -5 c-17 -7 -21 -44 -5 -44 6 0 10 8 10 19
0 10 7 24 16 32 l16 13 -2 -15 c-5 -31 1 -49 14 -49 l15 0 3 28 c4 33 22 36
26 5 l3 -23 18 0 c24 0 45 -26 37 -45 l-6 -16 9 6 c5 3 12 -8 16 -24 3 -16 13
-32 21 -35 8 -3 14 -2 14 2 0 19 -44 99 -64 115 -11 10 -15 16 -8 12 l12 -6 0
15 0 16 -25 0 -25 0 0 15 0 16 -19 -11 c-27 -14 -31 -13 -31 8 0 9 -9 23 -20
30 -11 7 -20 22 -20 33 l0 21 15 -6 c8 -3 15 -1 15 4 0 6 9 10 19 10 19 0 31
18 31 46 0 8 -7 14 -15 14 l-14 0 6 30 6 30 -17 0 -16 0 0 -24z"></path><path d="M1800 1058 l0 -28 15 0 15 0 0 28 0 29 -15 0 -15 0 0 -29z m17 -5
c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M2440 1076 c0 -7 9 -16 21 -19 l21 -6 -8 -13 -7 -13 8 7 c12 11 35
-11 35 -33 l0 -19 15 0 c20 0 19 27 -2 38 l-16 10 6 16 c4 9 2 16 -3 16 -6 0
-10 -5 -10 -11 l0 -11 -15 12 c-8 7 -15 19 -15 26 0 8 -7 14 -15 14 -8 0 -15
-6 -15 -14z"></path><path d="M1292 1026 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 l-14 4 4 -16z"></path><path d="M1895 958 c4 -13 7 -31 8 -40 1 -10 8 -18 15 -18 l15 0 -7 31 c-3 17
-6 35 -6 40 0 5 -7 9 -16 9 l-16 0 7 -22z"></path><path d="M1330 955 l0 -15 20 0 20 0 0 15 0 15 -20 0 -20 0 0 -15z"></path><path d="M1398 958 c-6 -16 13 -37 28 -31 12 4 28 -26 19 -35 -3 -3 -5 0 -5 8
l0 12 -15 -12 c-19 -16 -19 -31 0 -24 l15 6 -10 -26 -10 -26 15 0 15 0 0 -21
0 -21 20 -5 c11 -3 20 -12 20 -19 0 -8 7 -17 15 -20 8 -4 15 -13 15 -21 l0
-15 28 5 28 6 -4 -30 -4 -30 14 3 c8 2 29 -12 46 -29 16 -18 37 -33 46 -33 9
0 16 -4 16 -10 0 -5 9 -10 20 -10 l20 0 0 -20 0 -20 20 0 20 0 -2 13 c-2 6
-11 15 -20 19 -10 4 -18 12 -18 19 l0 11 16 -6 c9 -3 20 -6 25 -6 5 0 9 -7 9
-15 0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 16 l0
16 -12 -5 c-7 -2 -18 3 -25 12 l-11 16 -21 -19 c-12 -11 -21 -15 -21 -9 0 5
-12 13 -26 19 l-27 9 -4 34 c-2 19 -5 37 -8 39 -2 3 -15 6 -27 7 -37 4 -38 4
-38 21 l0 15 -12 -7 -13 -8 13 16 12 15 -12 10 c-7 6 -25 13 -40 17 l-28 6 -2
18 c0 9 0 27 1 40 1 12 -3 22 -9 22 -5 0 -9 10 -7 22 l2 23 -22 10 c-13 5 -23
13 -23 17 0 13 -18 9 -22 -4z m197 -258 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z"></path><path d="M2500 952 c0 -34 18 -72 35 -72 8 0 15 -7 15 -16 l0 -15 11 6 10 7
-15 17 c-20 22 -20 31 -1 31 8 0 15 5 15 10 0 6 -5 10 -12 10 -6 0 -22 10 -35
22 l-23 21 0 -21z"></path><path d="M2580 950 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5010 962 c0 -5 12 -15 28 -23 15 -8 31 -20 35 -26 11 -17 35 -16 53
2 9 8 24 15 35 15 l19 0 0 16 0 16 -25 -4 c-13 -3 -26 -1 -27 3 l-3 8 -37 -8
-38 -9 0 9 c0 5 -9 9 -20 9 -11 0 -20 -4 -20 -8z m107 -29 c-4 -3 -7 0 -7 7 0
7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M1880 900 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5046 895 l-5 -15 19 0 19 0 -5 15 c-4 8 -10 15 -14 15 -4 0 -10 -7
-14 -15z"></path><path d="M5007 848 c-7 -25 -3 -33 12 -23 13 8 15 45 2 45 -5 0 -11 -10 -14
-22z"></path><path d="M2560 816 c0 -9 5 -16 10 -16 6 0 10 -7 10 -17 0 -9 3 -13 7 -10 3 4
12 2 20 -4 l13 -12 20 18 c23 21 18 35 -15 35 -13 0 -26 6 -28 13 l-5 12 -1
-14 -1 -13 -15 12 -15 12 0 -16z"></path><path d="M1590 802 c0 -5 7 -15 15 -22 8 -7 15 -19 15 -26 0 -8 5 -14 11 -14
l10 0 -3 33 -3 32 -22 3 c-13 2 -23 -1 -23 -6z"></path><path d="M2694 794 l6 -16 -29 -29 c-16 -16 -34 -29 -40 -29 -16 0 -13 -19 5
-37 l16 -17 19 12 c10 7 21 12 24 12 3 0 2 -12 -1 -27 l-7 -26 18 6 17 7 -5
-12 c-2 -7 2 -19 9 -26 l13 -12 11 20 11 20 -10 0 c-9 0 -51 70 -51 85 0 3 9
5 20 5 l20 0 0 20 c0 11 -5 20 -11 20 l-10 0 6 20 6 20 -21 0 -22 0 6 -16z
m16 -44 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"></path><path d="M4720 795 l0 -17 28 4 28 4 12 -13 c15 -15 15 -23 1 -23 -7 0 -17 -7
-23 -16 -6 -8 -19 -16 -29 -18 -10 -1 -17 -6 -16 -12 0 -5 4 -8 8 -7 4 1 8 -4
8 -11 l0 -13 -24 3 c-26 3 -30 -8 -9 -25 l15 -12 25 16 c14 10 26 24 26 32 l0
14 -11 -7 -12 -7 5 13 c2 7 14 17 26 21 l22 8 0 -14 c0 -8 7 -15 15 -15 l15 0
1 23 c0 12 3 16 6 10 6 -16 33 -17 33 -3 0 6 -5 16 -10 23 -6 7 -16 20 -23 29
l-12 17 -52 7 -53 7 0 -18z m97 -62 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10
0 -14z"></path><path d="M4840 804 c0 -3 5 -14 10 -25 12 -21 42 -26 38 -7 l-3 13 28 -3 27
-3 0 17 0 16 -15 -12 c-9 -7 -18 -10 -22 -6 -3 3 0 6 7 6 7 0 10 3 7 6 -4 4
-13 1 -22 -6 l-15 -12 0 11 c0 6 -9 11 -20 11 -11 0 -20 -3 -20 -6z"></path><path d="M4690 786 c0 -8 6 -17 13 -20 l12 -6 -12 -6 c-15 -7 -17 -34 -3 -34
5 0 13 7 18 16 l9 16 -11 24 c-12 26 -26 32 -26 10z"></path><path d="M2646 764 c-20 -19 -20 -25 -2 -18 8 3 17 12 20 20 7 18 1 18 -18 -2z"></path><path d="M1966 677 l-5 -22 9 -3 10 -4 -6 -25 -7 -26 27 7 26 6 0 -19 c0 -10
7 -22 16 -25 l15 -6 -7 11 -6 11 17 -4 c10 -1 20 -9 23 -17 l5 -14 -11 7 -10
6 6 -22 6 -23 4 18 c2 9 8 15 13 12 5 -4 9 3 9 14 0 12 -3 21 -8 21 -4 0 -12
10 -17 23 -6 12 -16 23 -22 23 -25 3 -27 4 -15 16 20 20 14 28 -18 22 l-30 -6
0 15 c0 26 -17 29 -24 4z m31 -44 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0
-14z m63 -27 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z"></path><path d="M2900 640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4526 635 l-5 -15 -25 0 -25 0 -10 -17 -9 -18 22 -17 23 -16 12 8 13
8 -6 20 -7 21 21 11 c11 6 20 15 20 20 0 16 -17 12 -24 -5z m-29 -62 c-4 -3
-7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M4384 629 c-4 -7 -8 -38 -10 -70 l-4 -59 15 0 14 0 -6 17 -6 16 21
-5 22 -6 -6 9 c-3 5 -2 15 2 21 5 7 9 21 10 32 0 11 6 22 12 24 17 5 15 32 -2
32 -8 0 -17 -10 -19 -22 l-4 -23 -2 23 -1 22 -14 0 c-8 0 -18 -5 -22 -11z m17
-66 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z"></path><path d="M4571 631 l-13 -8 31 -5 31 -5 0 14 c0 15 -28 17 -49 4z"></path><path d="M4634 620 c0 -24 20 -21 24 3 l3 17 -13 0 -14 0 0 -20z"></path><path d="M2780 620 c0 -5 -7 -10 -15 -10 l-15 0 -6 -27 -7 -26 10 -16 c6 -9
17 -17 24 -19 l15 -3 0 21 0 20 -18 6 c-34 10 -18 24 27 24 25 0 45 3 45 6 0
3 -6 12 -14 20 -17 16 -46 19 -46 4z"></path><path d="M1960 561 l0 -21 16 0 15 0 -3 17 c-2 9 -9 18 -15 21 l-13 4 0 -21z"></path><path d="M2860 550 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2896 551 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4455 550 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M1977 514 c-12 -12 -8 -24 8 -24 8 0 15 -4 15 -10 0 -5 11 -10 25
-10 l25 0 0 20 c0 11 -6 20 -12 20 -7 0 -22 3 -33 6 -11 3 -24 2 -28 -2z"></path><path d="M2110 500 l0 -20 -20 0 -19 0 2 -30 2 -30 13 0 c22 0 38 23 46 63 l7
37 -16 0 -15 0 0 -20z"></path><path d="M2800 494 c0 -8 5 -14 11 -14 7 0 20 -9 30 -21 l18 -21 12 7 11 8 -7
-22 -6 -21 13 -13 c7 -6 19 -13 26 -15 16 -3 16 25 0 31 l-13 4 12 31 c6 18 8
34 5 36 -4 2 -16 0 -27 -4 l-20 -7 -10 14 c-5 7 -20 15 -32 18 l-23 4 0 -15z"></path><path d="M1875 482 c4 -7 1 -19 -5 -27 l-12 -15 11 0 c6 0 11 -7 11 -15 l0
-14 35 -7 c35 -6 59 -24 33 -24 l-13 0 15 -10 15 -9 8 15 c4 8 5 19 1 25 l-7
11 22 -7 21 -6 -6 15 c-3 9 -10 16 -14 16 -4 0 -11 11 -14 25 -8 30 -32 33
-39 5 l-5 -20 -19 0 -18 1 18 13 c21 16 22 36 2 36 -8 0 -15 -8 -15 -17 l0
-17 -16 20 c-8 10 -13 13 -9 6z"></path><path d="M4205 480 l-6 -9 -31 6 -31 6 -13 -18 -13 -18 -14 6 -15 6 5 -20 5
-19 22 0 c12 0 27 -5 32 -10 13 -13 44 -3 44 14 0 7 -6 18 -12 25 l-13 14 28
-5 c15 -3 27 -2 27 2 0 5 9 6 20 3 l20 -5 -6 16 c-7 17 -39 22 -49 6z m-55
-40 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"></path><path d="M1820 465 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M3890 465 l0 -15 60 0 60 0 0 -10 c0 -15 37 -12 43 3 l5 14 -24 -5
-24 -4 0 16 0 16 -60 0 -60 0 0 -15z"></path><path d="M4280 455 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M2142 428 l-10 -10 20 -45 20 -45 -10 -10 -10 -10 -18 31 c-10 17
-22 31 -27 31 -5 0 -6 7 -3 16 l6 16 -30 -7 c-17 -4 -43 -18 -58 -31 l-26 -25
14 -9 15 -9 5 21 c3 12 12 24 19 26 l14 5 -6 -17 -7 -17 35 7 35 6 0 -10 c0
-6 -7 -18 -16 -26 -20 -21 -10 -36 24 -36 l27 0 10 23 10 22 29 0 29 1 3 18 2
17 -29 -6 -29 -7 0 11 c0 6 7 11 15 11 8 0 15 6 15 13 l0 14 -30 -5 -30 -4 0
10 c0 5 10 12 22 15 12 4 19 10 15 14 -12 11 -34 12 -45 1z m-6 -125 c-10 -11
-37 -12 -30 -2 3 5 12 9 21 9 9 0 13 -3 9 -7z"></path><path d="M4034 395 l-19 -14 32 -1 32 0 -5 15 c-8 19 -15 19 -40 0z"></path><path d="M3900 390 c-8 -5 -11 -12 -7 -16 4 -4 0 -16 -10 -26 l-16 -18 16 0
17 0 0 16 0 16 30 -7 30 -7 0 11 c0 6 -5 11 -11 11 l-11 0 6 15 5 15 -17 0
c-9 0 -24 -4 -32 -10z"></path><path d="M2900 355 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2260 320 c0 -5 -9 -10 -21 -10 l-20 0 6 -22 6 -23 -11 18 c-15 23
-37 22 -51 -4 l-11 -22 21 6 22 5 6 -14 c3 -8 12 -14 20 -14 8 0 12 -3 10 -7
-6 -11 10 -53 21 -53 4 0 16 14 26 32 l19 31 -22 -6 -23 -6 6 15 c3 8 1 14 -5
14 l-10 0 15 22 c18 26 21 48 6 48 -5 0 -10 -4 -10 -10z m17 -107 c-4 -3 -10
-3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M2940 315 c0 -8 6 -15 13 -15 8 0 14 7 14 15 0 8 -6 15 -14 15 -7 0
-13 -7 -13 -15z"></path><path d="M3046 317 c-13 -4 -27 -15 -30 -24 l-6 -16 32 7 c18 3 35 11 39 17
l6 11 86 -4 c47 -2 83 0 81 4 -2 3 0 8 6 10 6 1 -35 3 -90 3 -55 0 -111 -4
-124 -8z"></path><path d="M3410 315 l0 -15 63 0 63 0 13 13 14 12 -5 -15 c-3 -8 -17 -16 -31
-18 -39 -5 -33 -22 7 -22 l34 0 6 15 c6 16 -2 45 -13 45 -3 0 -21 -4 -39 -9
l-32 -10 5 10 6 9 -45 0 -46 0 0 -15z"></path><path d="M3760 318 c0 -4 7 -8 15 -8 8 0 15 4 15 8 0 5 -7 9 -15 9 -8 0 -15
-4 -15 -9z"></path><path d="M3630 311 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2280 284 c0 -9 7 -22 15 -28 l15 -11 -9 -33 -10 -34 16 6 15 6 -6
-16 -6 -17 17 -13 c24 -18 43 -18 43 0 0 8 -6 17 -12 19 l-13 5 10 9 c6 4 11
16 12 26 l1 18 -14 -5 -14 -5 0 17 -1 17 -14 -19 c-17 -23 -20 -14 -4 15 l11
21 -18 19 c-21 23 -34 24 -34 3z"></path><path d="M2977 293 c-11 -11 -8 -20 8 -20 8 0 15 6 15 14 0 14 -12 17 -23 6z"></path><path d="M3064 275 l-19 -14 23 -1 22 0 0 15 c0 8 -1 15 -3 15 -2 0 -12 -7
-23 -15z"></path><path d="M3473 275 c0 -8 4 -15 9 -15 4 0 8 4 8 9 0 6 -4 12 -8 15 -5 3 -9 -1
-9 -9z"></path><path d="M2386 174 c-9 -24 32 -74 63 -77 l19 -1 -5 -13 c-3 -7 0 -13 6 -13 6
0 11 5 11 12 0 6 3 8 8 4 4 -4 7 -13 7 -19 0 -7 8 -13 18 -15 l17 -3 0 13 c0
8 -21 32 -46 54 -26 21 -54 47 -63 57 -19 21 -27 22 -35 1z m11 -21 c-4 -3 -7
0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z m38 -13 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#84c22b" stroke="none"><path d="M5792 7185 l5 -25 -22 -6 -23 -6 -6 -30 c-3 -16 -3 -34 0 -39 3 -6 0
-17 -7 -25 l-12 -15 11 -11 12 -12 0 17 c0 9 5 17 10 17 6 0 10 14 10 31 l0
30 10 -6 10 -6 0 16 0 16 -10 -6 -10 -6 6 16 7 16 13 -5 14 -5 0 34 0 35 -11
0 -11 0 4 -25z"></path><path d="M5587 7178 c-8 -28 9 -48 39 -48 25 0 33 15 12 23 l-13 5 13 1 c17 1
15 28 -4 35 -28 11 -42 6 -47 -16z m33 -12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"></path><path d="M5525 7167 c-4 -9 -14 -16 -23 -15 -8 2 -17 -3 -20 -11 l-4 -13 23 7
c32 10 42 20 36 37 l-5 13 -7 -18z"></path><path d="M5324 7095 c-9 -14 -14 -29 -10 -33 4 -3 10 -2 14 4 10 15 40 13 46
-3 l5 -13 31 0 c16 0 30 5 30 10 0 6 -5 10 -11 10 l-11 0 6 16 6 16 -36 -7
-37 -6 6 15 6 16 -14 0 c-8 0 -22 -11 -31 -25z"></path><path d="M5670 7075 c0 -19 2 -35 4 -35 16 0 36 15 36 27 0 14 -23 43 -34 43
-3 0 -6 -16 -6 -35z"></path><path d="M5270 7085 c0 -7 8 -16 17 -19 10 -4 14 -4 10 0 -4 4 -3 12 3 18 l9
12 -20 1 -19 2 0 -14z"></path><path d="M5510 7080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5097 7024 c-3 -4 -34 -7 -69 -8 l-63 -1 3 -9 c2 -5 -2 -12 -9 -17
-23 -14 0 -23 26 -11 l25 12 0 -10 c0 -6 -6 -10 -14 -10 -8 0 -16 -7 -20 -15
l-5 -15 17 0 c9 0 34 -3 55 -6 l37 -7 0 12 c0 6 -7 11 -15 11 -8 0 -15 6 -15
14 0 8 -6 17 -12 20 l-13 5 20 0 c11 -1 35 -1 53 0 l32 1 0 20 0 20 -13 0 c-8
0 -16 -3 -20 -6z"></path><path d="M5690 7000 c0 -5 5 -10 10 -10 6 0 10 -7 10 -15 0 -8 5 -15 10 -15
15 0 12 19 -4 36 -17 16 -26 18 -26 4z"></path><path d="M4896 6985 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M5150 6980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4846 6965 c4 -8 10 -15 14 -15 4 0 10 7 14 15 l5 15 -19 0 -19 0 5
-15z"></path><path d="M5677 6957 c-4 -10 -7 -26 -7 -35 l1 -17 10 18 c6 10 9 25 7 35 l-4
17 -7 -18z"></path><path d="M4796 6953 c-14 -15 -116 -40 -141 -36 l-25 5 6 -16 c3 -9 11 -16 16
-16 6 0 8 -7 4 -15 l-5 -15 14 0 c8 0 15 -6 15 -14 0 -8 7 -17 16 -20 24 -9
36 -7 29 4 -4 6 -11 8 -16 5 -5 -4 -9 3 -9 14 l0 21 -15 0 c-8 0 -15 3 -15 8
0 12 59 34 76 28 l16 -6 29 21 c16 11 29 25 29 30 0 11 -14 12 -24 2z"></path><path d="M4900 6940 c0 -5 -9 -10 -20 -10 -23 0 -60 -33 -60 -55 0 -8 7 -15
15 -15 l15 0 0 25 0 25 30 0 c17 0 30 5 30 10 0 6 7 10 15 10 8 0 15 5 15 10
0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10z"></path><path d="M4960 6900 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M5070 6900 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M4713 6883 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4790 6875 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4730 6860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5650 6835 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4740 6821 c0 -5 7 -12 16 -15 22 -9 24 -8 18 9 -7 16 -34 21 -34 6z"></path><path d="M4830 6800 c0 -5 -9 -10 -20 -10 l-20 0 0 -15 c0 -19 27 -19 46 -1
16 17 19 36 4 36 -5 0 -10 -4 -10 -10z"></path><path d="M5580 6779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5490 6720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5570 6722 c0 -4 5 -13 12 -20 l12 -12 -13 -12 -12 -13 6 16 6 16
-15 0 -15 0 16 -27 c8 -15 22 -27 30 -27 l14 0 -3 26 -3 26 15 12 15 11 -8 8
-8 8 -10 -14 -11 -15 -5 13 c-5 13 -23 17 -23 4z"></path><path d="M4999 6707 l11 -14 -15 -19 -15 -19 6 18 6 17 -15 0 c-8 0 -17 6 -20
13 -2 6 -8 9 -12 5 -9 -9 35 -58 53 -58 6 0 12 7 12 15 0 8 6 15 14 15 l14 0
-5 20 -5 20 -20 0 -20 0 11 -13z"></path><path d="M5070 6685 c0 -8 4 -15 9 -15 5 0 11 7 15 15 l5 15 -14 0 c-8 0 -15
-7 -15 -15z"></path><path d="M5140 6663 c0 -5 4 -15 10 -23 11 -18 25 -7 17 15 -6 15 -27 21 -27
8z"></path><path d="M5067 6643 c-12 -11 -8 -30 6 -30 7 0 14 9 14 19 2 18 -8 24 -20 11z"></path><path d="M2997 6610 c6 -7 29 -16 51 -20 l40 -7 -5 14 c-3 7 -11 13 -18 13 -7
0 -28 3 -46 6 l-34 7 12 -13z"></path><path d="M3375 6610 c3 -5 12 -10 20 -10 8 0 17 5 20 10 l6 10 -26 0 -26 0 6
-10z"></path><path d="M5530 6609 c0 -6 -7 -17 -15 -25 -16 -16 -9 -47 9 -41 6 2 10 14 8
26 l-4 23 22 -7 22 -7 -11 21 c-12 22 -31 28 -31 10z"></path><path d="M2880 6600 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3247 6597 l-5 -13 34 4 c19 2 37 8 40 13 l5 9 -34 0 -35 0 -5 -13z"></path><path d="M2694 6585 l20 -15 55 0 56 0 -25 13 c-14 7 -47 14 -75 15 l-50 2 19
-15z"></path><path d="M3338 6593 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"></path><path d="M2920 6580 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3464 6578 c-3 -4 20 -8 50 -8 l55 0 6 11 7 10 -57 -2 c-31 -1 -58
-6 -61 -11z"></path><path d="M5108 6578 c-7 -19 2 -38 18 -38 l13 0 -5 15 c-4 8 -2 15 4 15 6 0
12 -6 15 -12 2 -7 8 -10 12 -6 10 9 -19 38 -38 38 -8 0 -17 -6 -19 -12z"></path><path d="M5010 6571 c0 -5 6 -14 14 -20 l15 -12 10 18 9 19 -24 2 c-13 1 -24
-2 -24 -7z"></path><path d="M2576 6553 c-4 -4 -13 -8 -19 -9 -7 0 -20 -2 -30 -2 l-18 -2 6 -10
c9 -14 -9 -12 -23 2 l-12 12 -21 -12 -21 -13 7 10 6 11 -25 0 c-25 0 -43 -16
-32 -27 10 -10 113 -5 133 6 10 5 30 12 46 16 15 3 27 10 27 15 0 12 -14 13
-24 3z"></path><path d="M5000 6534 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M3810 6520 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2337 6513 c-15 -14 -6 -23 24 -23 l31 0 -12 15 c-13 16 -32 19 -43
8z"></path><path d="M3893 6506 l4 -11 56 -3 c31 -2 57 -7 57 -12 0 -6 9 -10 20 -10 l20
0 0 -19 c0 -11 -6 -22 -12 -25 l-13 -6 13 -6 c6 -3 12 -14 12 -25 0 -23 6 -24
26 -5 19 20 18 26 -6 26 -11 0 -20 5 -20 10 0 6 7 10 15 10 l15 0 0 18 0 17
-40 18 c-22 9 -40 21 -40 27 l0 9 -55 -1 -55 -2 3 -10z"></path><path d="M2535 6500 l6 -10 -60 0 -61 0 0 -10 0 -10 70 0 70 0 0 10 c0 6 9 10
21 10 l20 0 -6 10 c-3 6 -20 10 -36 10 l-30 0 6 -10z"></path><path d="M3843 6503 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5510 6495 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2256 6485 c4 -8 12 -15 20 -15 8 0 14 7 14 15 l0 15 -20 0 -19 0 5
-15z"></path><path d="M4913 6486 c-13 -6 -23 -19 -23 -29 l0 -18 -20 6 -20 6 0 -10 c0 -6
6 -11 14 -11 8 0 16 -4 18 -9 2 -5 18 -12 37 -16 l34 -7 -6 21 -5 21 19 0 18
0 -5 15 c-4 8 -13 15 -21 15 l-15 0 6 15 c6 17 1 17 -31 1z m14 -36 c-1 -11
-5 -20 -9 -20 -4 0 -8 9 -8 20 0 11 4 20 9 20 6 0 9 -9 8 -20z"></path><path d="M4977 6494 c-8 -8 3 -34 15 -34 l10 0 -11 13 c-6 8 -8 17 -4 20 3 4
4 7 1 7 -2 0 -7 -3 -11 -6z"></path><path d="M5223 6483 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2230 6471 c0 -5 -13 -12 -30 -16 -29 -6 -37 -15 -24 -28 7 -7 74 24
74 34 0 4 -4 11 -10 14 -5 3 -10 2 -10 -4z"></path><path d="M2320 6460 c0 -5 -9 -10 -19 -10 -10 0 -21 -6 -24 -13 l-5 -13 79 3
79 4 0 -11 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -11 10 -25 10 -14 0 -25
4 -25 8 0 5 -23 14 -51 20 -62 15 -69 15 -69 2z"></path><path d="M3890 6460 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5240 6460 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2540 6440 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"></path><path d="M3950 6440 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5492 6425 l-12 -25 14 0 c13 0 26 20 26 41 0 18 -17 8 -28 -16z"></path><path d="M2110 6436 c0 -2 6 -9 14 -15 l14 -12 6 16 5 15 -19 0 c-11 0 -20 -2
-20 -4z"></path><path d="M4265 6433 c-11 -2 -30 -6 -41 -8 l-21 -5 5 -25 c2 -14 7 -25 11 -25
3 0 12 9 19 21 l13 21 25 -5 24 -4 0 13 c0 17 -10 21 -35 17z"></path><path d="M4825 6425 c3 -8 7 -22 10 -31 l5 -16 15 12 c9 7 19 9 23 5 4 -4 1
-10 -5 -12 -16 -6 -17 -23 -2 -23 6 0 17 7 25 15 l14 14 -20 11 c-11 6 -24 9
-29 6 -5 -3 -11 3 -14 14 -3 11 -10 20 -16 20 -6 0 -8 -7 -6 -15z"></path><path d="M2240 6420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2500 6420 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4144 6408 c11 -19 46 -25 46 -8 0 6 -6 10 -12 10 l-13 0 15 10 15
10 -31 -2 -32 -2 12 -18z"></path><path d="M2036 6405 l6 -15 40 0 40 0 -4 11 c-2 6 -23 13 -46 15 l-41 4 5 -15z"></path><path d="M4780 6405 c0 -8 4 -15 9 -15 5 0 11 7 15 15 l5 15 -14 0 c-8 0 -15
-7 -15 -15z"></path><path d="M5310 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5410 6400 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5430 6361 c0 -28 5 -53 10 -56 l10 -6 0 20 c0 12 5 21 10 21 6 0 10
7 10 15 0 8 -5 15 -11 15 l-10 0 6 20 6 20 -15 0 -16 0 0 -49z"></path><path d="M2013 6380 l5 -20 -17 0 c-9 0 -22 5 -28 11 -6 6 -17 9 -25 7 l-13
-5 35 -21 c19 -12 44 -22 55 -21 l20 0 -20 9 -20 9 18 0 17 1 0 19 c0 10 -7
22 -16 25 l-16 6 5 -20z"></path><path d="M5200 6387 c0 -8 7 -20 15 -27 8 -7 15 -18 15 -25 0 -7 9 -15 20 -18
23 -6 27 -27 5 -27 -8 0 -15 -7 -15 -15 0 -16 9 -19 34 -9 l16 6 0 29 0 29
-16 0 c-8 0 -22 6 -30 14 l-14 15 16 9 17 9 -7 12 c-4 6 -12 11 -17 11 -6 0
-8 -4 -5 -9 3 -5 -1 -12 -10 -15 l-15 -6 6 9 c3 5 1 12 -5 16 l-10 6 0 -14z"></path><path d="M4258 6371 c-22 -22 -23 -31 -3 -31 8 0 15 7 15 15 0 8 5 15 10 15 6
0 10 5 10 10 0 15 -13 11 -32 -9z"></path><path d="M4730 6360 l11 -21 12 8 11 7 -12 4 c-7 2 -10 8 -6 13 3 5 -2 9 -11
9 l-16 0 11 -20z"></path><path d="M5357 6373 c-11 -10 -8 -63 3 -63 6 0 10 16 10 35 0 36 -2 40 -13 28z"></path><path d="M4113 6355 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"></path><path d="M4780 6364 c0 -9 31 -37 36 -32 9 9 -8 38 -22 38 -8 0 -14 -3 -14 -6z"></path><path d="M1926 6345 c3 -7 -2 -20 -12 -29 -9 -9 -13 -20 -10 -24 4 -3 10 -2
14 4 4 6 15 13 25 17 23 9 22 33 -2 40 l-20 5 5 -13z"></path><path d="M4690 6351 c0 -5 -7 -11 -17 -13 l-16 -3 18 -15 19 -15 -8 13 -7 12
15 0 c9 0 21 -9 28 -20 13 -21 48 -28 48 -10 0 6 -7 10 -15 10 -8 0 -15 5 -15
10 0 6 -7 10 -16 10 l-16 0 6 15 5 15 -14 0 c-8 0 -15 -4 -15 -9z"></path><path d="M2086 6334 c-8 -21 -3 -26 18 -18 9 3 16 12 16 20 0 19 -26 18 -34
-2z"></path><path d="M4846 6335 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M4778 6321 c13 -11 21 -25 17 -31 l-6 -10 15 0 c26 0 18 17 -16 40
l-33 22 23 -21z"></path><path d="M4063 6323 c-15 -6 -17 -43 -3 -43 6 0 10 7 10 15 0 8 7 15 16 15
l15 0 -6 10 c-7 11 -13 11 -32 3z"></path><path d="M1813 6300 l5 -20 -22 0 -23 0 0 -15 c0 -19 14 -19 33 0 8 8 21 12
29 9 l15 -6 0 11 c0 6 -5 11 -11 11 l-11 0 6 15 5 15 -15 0 -16 0 5 -20z"></path><path d="M4631 6306 c-7 -8 -11 -23 -8 -33 l5 -18 7 23 8 23 14 -5 15 -6 -4
12 c-6 18 -24 20 -37 4z"></path><path d="M1980 6291 c0 -17 18 -31 41 -31 5 0 9 7 9 15 0 8 -7 15 -15 15 -8 0
-15 5 -15 10 0 6 -4 10 -10 10 -5 0 -10 -9 -10 -19z"></path><path d="M4124 6301 c4 -5 -7 -15 -24 -23 -34 -14 -46 -35 -22 -35 16 0 62 43
62 58 0 5 -5 9 -11 9 -5 0 -8 -4 -5 -9z"></path><path d="M5410 6265 c0 -14 4 -25 9 -25 4 0 6 11 3 25 -2 14 -6 25 -8 25 -2 0
-4 -11 -4 -25z"></path><path d="M2080 6260 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4650 6260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4680 6261 c0 -5 7 -12 16 -15 8 -3 13 -10 10 -15 -4 -5 2 -8 11 -7
9 2 22 10 27 19 l10 16 -8 8 -9 8 -4 -12 c-5 -15 -33 -17 -33 -3 0 6 -4 10
-10 10 -5 0 -10 -4 -10 -9z"></path><path d="M5370 6240 c0 -16 -4 -30 -10 -30 -18 0 -11 -35 10 -48 l20 -12 0 10
c0 5 -4 10 -10 10 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -7 10 -15 0 -8 5
-15 11 -15 l11 0 -7 23 c-3 12 -9 32 -12 45 -8 32 -23 27 -23 -8z"></path><path d="M1711 6236 c7 -14 18 -26 26 -26 l13 0 0 20 c0 11 -4 20 -9 20 -5 0
-17 3 -26 6 l-16 6 12 -26z"></path><path d="M4559 6246 c7 -9 23 -16 34 -16 l21 0 -9 9 c-6 5 -21 12 -35 16 l-25
8 14 -17z"></path><path d="M5303 6253 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M3890 6241 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M3960 6240 c0 -5 7 -10 16 -10 l16 0 -4 -25 -5 -25 13 0 c24 0 44 12
44 27 0 8 -4 11 -10 8 -5 -3 -15 -3 -21 0 l-10 7 18 14 18 13 -37 1 c-21 0
-38 -4 -38 -10z"></path><path d="M4620 6220 c0 -5 12 -10 26 -10 l26 0 -12 -15 -13 -15 17 6 17 7 -5
14 c-3 8 -6 17 -6 19 0 2 -11 4 -25 4 -14 0 -25 -4 -25 -10z"></path><path d="M5260 6215 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4590 6200 c0 -5 -7 -10 -16 -10 l-15 0 6 -10 6 -10 -20 0 c-12 0
-21 4 -21 8 0 5 -18 9 -40 10 l-41 2 -6 -15 c-7 -19 5 -34 15 -18 4 6 16 13
27 15 l20 3 3 -22 c2 -13 -1 -23 -6 -23 -13 0 -23 -27 -16 -44 3 -8 12 -17 20
-20 18 -7 18 -1 -2 18 l-15 16 16 16 16 16 22 -26 c23 -27 47 -34 47 -15 0 6
-9 13 -20 16 -11 3 -20 12 -20 21 0 19 29 41 55 42 l20 0 -13 -9 -14 -8 16 -6
c18 -7 20 1 6 37 -9 25 -30 36 -30 16z"></path><path d="M5320 6193 c0 -13 -4 -23 -10 -23 -5 0 -10 -5 -10 -11 l0 -11 15 12
15 12 0 -25 c0 -13 7 -30 15 -37 l14 -12 -4 19 c-2 10 -11 36 -19 58 l-15 40
-1 -22z"></path><path d="M1570 6190 c0 -14 26 -23 41 -15 l11 8 -13 8 c-19 12 -39 11 -39 -1z"></path><path d="M1541 6158 l-14 -22 12 -4 c6 -2 11 -8 11 -14 l0 -10 -37 4 -36 3 -4
-12 -4 -11 28 2 c41 3 83 17 83 27 0 5 -7 9 -15 9 -8 0 -15 5 -15 10 0 6 5 10
10 10 6 0 10 7 10 15 0 22 -13 18 -29 -7z"></path><path d="M1636 6171 c-7 -11 3 -41 14 -41 11 0 21 30 14 41 -3 5 -9 9 -14 9
-5 0 -11 -4 -14 -9z"></path><path d="M3890 6166 c0 -8 5 -18 10 -21 l10 -6 0 20 c0 12 -4 21 -10 21 -5 0
-10 -6 -10 -14z"></path><path d="M3946 6154 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24 -2z"></path><path d="M4416 6125 c4 -8 11 -15 16 -15 6 0 7 -7 4 -16 l-6 -15 16 -16 c8 -8
18 -12 22 -9 3 4 2 10 -4 14 -5 4 -11 18 -11 31 -1 13 -8 28 -15 32 -20 13
-28 11 -22 -6z"></path><path d="M4365 6093 l5 -23 -10 0 c-15 0 -12 -37 3 -43 l14 -5 -5 24 c-5 29 8
31 28 4 15 -21 40 -27 40 -10 0 6 -5 10 -11 10 -18 0 -38 27 -35 47 l4 19 -20
0 -19 0 6 -23z"></path><path d="M1600 6100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1440 6080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3610 6080 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3710 6080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3815 6080 c3 -5 0 -10 -7 -10 -8 0 -18 -12 -24 -27 l-9 -27 -18 18
c-10 10 -21 15 -25 12 -3 -4 0 -11 7 -15 7 -5 10 -14 7 -20 l-7 -11 14 0 c8 0
28 14 45 30 18 17 37 30 43 30 l11 0 -12 15 c-14 17 -35 21 -25 5z"></path><path d="M5340 6075 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M1400 6060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1459 6042 c-8 -10 -18 -23 -22 -29 l-7 -12 20 2 21 1 6 24 c7 30 1
36 -18 14z"></path><path d="M5024 6045 c18 -13 15 -20 -6 -16 -5 0 -8 -3 -8 -9 0 -5 9 -10 20
-10 22 0 27 -16 8 -23 l-13 -4 9 -9 9 -9 4 13 c3 6 11 12 18 12 7 0 15 7 19
15 l5 15 -19 0 -19 0 -6 20 c-3 11 -14 20 -23 20 l-17 -1 19 -14z"></path><path d="M4485 6030 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M1330 6014 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M3670 6020 c0 -5 -6 -10 -14 -10 -12 0 -26 -20 -26 -36 0 -10 40 -2
54 11 8 8 19 15 25 15 6 0 8 7 5 15 -7 17 -44 21 -44 5z"></path><path d="M4260 6022 c0 -5 9 -19 20 -32 11 -13 20 -26 20 -30 0 -4 23 -8 50
-9 l50 -1 0 10 c0 5 -5 10 -11 10 l-11 0 13 18 14 17 -21 -17 c-23 -20 -44
-23 -44 -8 0 6 -8 10 -18 10 -10 0 -24 9 -32 20 -15 21 -30 26 -30 12z"></path><path d="M4960 6012 c0 -7 -6 -12 -14 -12 -8 0 -17 -9 -21 -19 -3 -11 -12 -22
-20 -25 -28 -10 -15 -22 25 -23 l40 -2 0 14 c0 7 -5 15 -11 17 l-12 4 18 18
18 17 -12 12 -11 11 0 -12z"></path><path d="M1396 5994 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24 -2z"></path><path d="M4447 5997 l-5 -14 14 0 c8 0 14 6 14 14 0 7 -4 13 -9 13 -5 0 -11
-6 -14 -13z"></path><path d="M4190 5985 c0 -8 4 -15 9 -15 5 0 11 7 15 15 l5 15 -14 0 c-8 0 -15
-7 -15 -15z"></path><path d="M5090 5986 c0 -8 6 -16 13 -19 l12 -4 -9 -9 -9 -9 -4 13 c-6 16 -20
15 -27 -3 -3 -8 -2 -14 2 -13 4 1 18 -2 31 -6 l23 -8 8 13 8 12 17 -17 c20
-19 30 -15 22 9 l-6 15 -25 0 -24 0 -5 20 c-6 23 -27 27 -27 6z"></path><path d="M1260 5980 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4986 5974 c-9 -22 -8 -24 10 -24 l15 0 -6 -10 -6 -10 15 0 c19 0 19
2 4 35 -13 29 -23 32 -32 9z"></path><path d="M1230 5960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1453 5955 c-18 -20 -16 -25 7 -25 l20 0 0 20 c0 23 -10 25 -27 5z"></path><path d="M1260 5948 l0 -18 20 0 20 0 0 -20 c0 -11 5 -20 10 -20 6 0 10 7 10
16 0 19 -25 46 -34 36 -4 -3 -11 0 -16 8 l-10 15 0 -17z"></path><path d="M3655 5950 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4124 5949 c-3 -6 -1 -15 6 -19 l12 -7 -21 -6 c-12 -3 -21 -12 -21
-21 l0 -16 -23 0 -24 0 0 -15 c0 -8 4 -12 8 -10 4 3 14 2 23 -1 l17 -6 -6 -19
-6 -19 24 0 25 0 6 16 c9 23 8 24 -13 24 l-19 0 2 18 c2 28 22 42 59 42 l35 0
4 -12 4 -12 17 7 c24 9 21 34 -5 48 l-22 12 -4 -12 c-2 -6 -15 -11 -28 -11
l-24 0 0 15 c0 18 -16 20 -26 4z m-27 -86 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7
0 10 -3 7 -7z"></path><path d="M1340 5940 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3573 5935 c4 -8 2 -17 -3 -20 -16 -10 -11 -31 8 -32 l18 -1 -2 26
c-2 40 -3 42 -15 42 l-11 0 5 -15z"></path><path d="M1212 5915 l-12 -15 6 -25 c3 -14 12 -25 18 -25 l12 0 2 40 c3 42 -4
49 -26 25z"></path><path d="M3421 5914 c-7 -8 -10 -18 -6 -22 4 -4 10 -1 12 6 3 6 11 12 19 12 8
0 14 5 14 10 0 15 -23 11 -39 -6z"></path><path d="M3543 5923 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4280 5916 c0 -7 -18 -31 -40 -52 -22 -22 -40 -42 -40 -45 0 -3 7 -6
14 -6 8 0 14 5 13 10 -1 5 7 14 18 20 11 7 23 17 28 22 l7 10 15 -19 c8 -11
17 -18 19 -15 2 2 7 14 10 27 l7 22 -10 0 c-6 0 -11 -7 -11 -16 l0 -15 -10 6
c-15 10 -12 22 12 45 l22 20 -27 0 -27 0 0 -14z"></path><path d="M4350 5921 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M3490 5909 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5205 5890 l-7 -11 18 -14 c11 -8 22 -15 26 -15 4 0 10 -9 13 -20 3
-11 13 -20 21 -20 l15 0 -3 16 c-2 9 -12 22 -23 28 -11 6 -27 19 -36 29 l-17
18 -7 -11z"></path><path d="M3460 5880 c0 -5 -6 -10 -14 -10 -15 0 -30 -29 -21 -39 3 -3 10 1 15
8 l8 14 31 -6 c33 -7 45 2 35 27 l-6 16 -24 0 c-13 0 -24 -4 -24 -10z"></path><path d="M1162 5863 c6 -7 14 -13 19 -13 5 0 9 6 9 13 0 7 -9 13 -19 13 l-20
0 11 -13z"></path><path d="M1300 5855 c0 -20 -45 -65 -65 -65 -8 0 -15 -4 -15 -10 0 -5 15 -10
34 -10 l35 0 6 20 c3 11 11 20 16 20 5 0 9 14 9 30 0 17 -4 30 -10 30 -5 0
-10 -7 -10 -15z"></path><path d="M4180 5860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5170 5860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1100 5838 l1 -23 14 19 14 18 12 -8 12 -7 -7 11 c-4 7 -16 12 -27
12 l-19 0 0 -22z"></path><path d="M4828 5824 l4 -34 -22 0 -22 0 5 13 5 14 -26 -1 -25 -1 -5 -15 -4
-15 12 12 13 12 19 -21 18 -21 25 13 25 13 0 28 c0 15 -6 29 -13 32 l-13 5 4
-34z"></path><path d="M4010 5839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4265 5830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1150 5820 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4030 5810 l0 -20 -20 0 c-11 0 -23 7 -27 16 l-6 16 -8 -14 -9 -13 0
13 c0 6 -5 12 -11 12 l-11 0 12 -15 c7 -8 19 -15 26 -15 8 0 14 -4 14 -10 0
-5 9 -10 20 -10 11 0 20 5 20 10 0 6 9 10 20 10 24 0 25 6 6 26 -20 19 -26 18
-26 -6z"></path><path d="M1060 5801 c0 -5 6 -14 14 -20 l14 -12 -5 20 c-5 21 -23 30 -23 12z"></path><path d="M1120 5800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5174 5794 c3 -9 6 -18 6 -20 0 -2 9 -4 20 -4 11 0 20 5 20 10 0 6
-7 10 -15 10 -8 0 -15 5 -15 10 0 6 -5 10 -11 10 l-11 0 6 -16z"></path><path d="M5230 5800 c0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 14 -10 23 -9
l17 0 -21 9 c-12 5 -25 13 -29 19 -8 14 -35 14 -35 1z"></path><path d="M1040 5780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1150 5780 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3406 5774 l-6 -15 22 3 c12 2 23 9 26 16 l4 12 -20 0 c-11 0 -23 -7
-26 -16z"></path><path d="M4166 5766 l6 -25 -21 6 c-12 3 -21 9 -21 14 0 11 -40 12 -57 1 l-12
-8 10 -14 11 -15 5 13 c7 17 29 15 58 -4 l25 -16 0 11 c0 6 7 11 15 11 8 0 15
5 15 10 0 6 7 10 15 10 l14 0 -5 15 -6 15 -29 0 -29 0 6 -24z"></path><path d="M3260 5760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3362 5758 c6 -18 25 -21 25 -3 0 8 -7 15 -14 15 -8 0 -13 -5 -11
-12z"></path><path d="M5340 5740 l-10 -20 15 6 c8 4 15 12 15 20 0 20 -7 17 -20 -6z"></path><path d="M4650 5726 c0 -8 6 -16 13 -19 l12 -4 -8 -9 -9 -9 -9 18 c-12 21 -29
22 -29 1 l0 -15 -10 6 c-5 3 -10 1 -10 -4 0 -6 -7 -11 -15 -11 -8 0 -15 -7
-15 -15 l0 -15 20 0 c11 0 20 -5 20 -11 l0 -10 -22 7 -23 7 13 -15 13 -16 24
12 c14 6 28 19 31 29 l6 18 13 -5 14 -5 -5 13 c-3 8 -1 17 4 20 14 9 2 46 -14
46 -8 0 -14 -6 -14 -14z"></path><path d="M3301 5713 c-1 -13 -6 -23 -12 -23 -14 0 -39 -25 -39 -39 0 -20 26
-11 49 15 l23 26 -10 22 -11 21 0 -22z"></path><path d="M970 5716 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M3820 5700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4061 5686 c-8 -13 -10 -29 -7 -35 13 -20 23 -12 18 14 l-4 25 11 0
c6 0 11 5 11 10 0 18 -16 10 -29 -14z"></path><path d="M5350 5700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M900 5665 c0 -8 4 -15 9 -15 5 0 11 7 15 15 l5 15 -14 0 c-8 0 -15
-7 -15 -15z"></path><path d="M3163 5673 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5351 5644 c-12 -14 -20 -28 -17 -31 3 -4 14 4 25 15 10 12 23 22 30
22 6 0 11 5 11 10 0 18 -24 10 -49 -16z"></path><path d="M3793 5636 l12 -26 18 0 c9 0 17 5 17 10 0 6 -5 10 -12 10 -6 0 -19
7 -29 16 l-18 16 12 -26z"></path><path d="M5410 5640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M890 5627 c0 -8 7 -14 15 -14 l15 0 -11 14 c-6 7 -13 13 -15 13 -2 0
-4 -6 -4 -13z"></path><path d="M957 5624 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M3745 5630 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M5450 5620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5490 5595 l0 -27 13 5 c6 2 12 12 12 22 0 10 -6 20 -12 23 l-13 4 0
-27z"></path><path d="M2950 5600 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"></path><path d="M3060 5600 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M3690 5594 l0 -15 12 7 11 7 -7 -19 -7 -19 11 11 c12 12 4 44 -11 44
-5 0 -9 -7 -9 -16z"></path><path d="M3890 5600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3975 5600 c-3 -5 -14 -10 -24 -10 l-17 0 -16 -34 -17 -34 22 6 22 5
-25 -27 -25 -27 18 7 c9 3 17 10 17 16 0 5 5 6 10 3 6 -3 10 0 10 8 l0 15 19
-5 18 -5 7 17 6 16 -12 -7 -11 -7 8 13 8 12 -14 9 c-7 4 -10 11 -6 15 4 4 14
-1 22 -12 15 -19 20 -8 9 20 -7 18 -20 21 -29 6z"></path><path d="M3138 5592 c-10 -2 -18 -8 -18 -13 l0 -10 -21 6 -20 7 18 -21 c9 -12
22 -21 29 -21 11 0 41 47 33 53 -2 2 -12 2 -21 -1z"></path><path d="M4505 5570 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M4446 5544 c-9 -24 -7 -46 4 -39 6 3 10 1 10 -4 0 -6 6 -11 14 -11 8
0 16 -6 19 -12 l4 -13 9 8 8 8 -14 9 -14 9 23 21 c14 11 21 23 16 28 -4 4 -10
1 -12 -5 -3 -7 -14 -13 -25 -13 l-20 0 6 15 5 15 -13 0 c-8 0 -17 -7 -20 -16z"></path><path d="M5563 5535 c7 -14 16 -25 21 -25 5 0 3 -7 -4 -15 l-13 -15 19 3 c25
3 44 16 44 28 0 5 -6 9 -13 9 -7 0 -19 9 -27 20 -8 11 -20 20 -26 20 l-12 0
11 -25z"></path><path d="M3020 5540 c0 -5 -6 -10 -14 -10 -12 0 -31 -28 -24 -36 2 -1 15 -5
31 -9 l27 -7 -6 16 -6 16 15 -6 16 -6 -12 26 c-12 26 -27 35 -27 16z"></path><path d="M3080 5535 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M3645 5541 c-6 -10 8 -27 27 -34 l18 -6 10 19 c5 10 10 20 10 22 0 6
-61 5 -65 -1z m22 -8 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M5501 5534 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M811 5527 c9 -7 18 -19 22 -27 l5 -15 1 16 c1 17 -18 39 -33 39 -6 0
-4 -6 5 -13z"></path><path d="M3616 5508 c-3 -13 -6 -25 -7 -28 -1 -3 14 -8 35 -11 l36 -6 0 14 c0
7 -5 13 -11 13 -7 0 -20 9 -29 20 l-18 20 -6 -22z"></path><path d="M5523 5495 c-7 -8 -13 -16 -13 -18 0 -3 10 -7 23 -9 l24 -5 -5 24
c-5 26 -12 28 -29 8z"></path><path d="M740 5485 l0 -15 19 0 c10 0 21 7 25 15 l5 15 -24 0 -25 0 0 -15z"></path><path d="M3520 5454 c0 -30 14 -31 27 -1 l11 23 -19 1 -19 2 0 -25z"></path><path d="M3690 5465 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M4361 5467 c-15 -18 -6 -25 24 -19 14 2 25 11 25 18 0 18 -34 18 -49
1z"></path><path d="M795 5460 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13 -4
-16 -10z"></path><path d="M3886 5454 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M5630 5460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M740 5444 c0 -8 -4 -12 -10 -9 l-10 6 0 -20 c0 -12 4 -21 10 -21 5 0
7 -7 4 -15 l-6 -15 17 0 16 0 -6 10 c-3 6 -1 10 4 10 6 0 11 13 11 29 l0 29
-15 6 -15 5 0 -15z"></path><path d="M2790 5440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2937 5437 l-5 -14 14 0 c8 0 14 6 14 14 0 7 -4 13 -9 13 -5 0 -11
-6 -14 -13z"></path><path d="M3550 5426 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26
l-10 6 0 -25z"></path><path d="M3770 5442 c0 -4 9 -14 20 -22 11 -8 20 -20 20 -27 0 -7 5 -13 10
-13 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 10 0 6 7 10 16 10 l15 0
-6 10 c-7 12 -65 14 -65 2z"></path><path d="M2870 5428 c0 -5 5 -18 10 -29 l11 -19 14 14 c22 21 18 33 -10 38
-14 3 -25 1 -25 -4z"></path><path d="M4345 5430 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5651 5393 l-1 -43 10 0 c6 0 10 5 10 10 0 15 30 12 47 -5 l15 -16
-23 -19 c-12 -11 -19 -23 -16 -27 4 -3 16 4 27 17 21 24 26 56 11 79 l-9 13
-33 -7 -34 -8 15 19 c8 10 10 15 3 11 l-11 -7 -5 12 c-3 7 -6 -6 -6 -29z"></path><path d="M2800 5400 c0 -5 6 -10 13 -10 8 0 20 -11 27 -25 14 -26 30 -29 38
-5 l5 13 -12 -7 -11 -7 6 15 6 16 -21 0 c-12 0 -21 5 -21 10 0 6 -7 10 -15 10
-8 0 -15 -4 -15 -10z"></path><path d="M3468 5403 c-10 -2 -18 -11 -18 -19 l0 -14 20 0 c11 0 20 -4 20 -10
0 -12 27 -13 34 -1 5 8 -26 52 -35 50 -2 -1 -12 -3 -21 -6z"></path><path d="M4286 5394 c-16 -15 -22 -44 -10 -44 9 0 44 41 44 52 0 13 -17 9 -34
-8z"></path><path d="M680 5380 l-15 -10 23 0 c12 0 22 5 22 10 0 13 -11 13 -30 0z"></path><path d="M2600 5361 c0 -13 42 -21 56 -10 6 5 20 7 30 3 l19 -6 -15 11 c-18
13 -90 15 -90 2z"></path><path d="M3420 5360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4302 5334 c-18 -19 -37 -32 -43 -29 -5 4 -9 1 -9 -4 0 -21 31 -11
60 19 17 17 30 35 30 40 0 6 -1 10 -2 9 -2 0 -18 -16 -36 -35z"></path><path d="M621 5338 c-7 -11 -10 -22 -7 -25 3 -3 8 1 12 10 l6 16 14 -6 c8 -3
14 -1 14 5 0 5 -6 12 -13 15 l-13 5 -13 -20z"></path><path d="M3740 5340 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3341 5310 c-31 -30 -41 -60 -20 -60 5 0 7 5 4 10 -3 6 0 10 8 10 8
0 18 7 21 16 l6 15 12 -7 c7 -4 10 -4 6 1 -4 4 -3 16 3 26 l10 19 -9 0 c-5 0
-23 -14 -41 -30z"></path><path d="M3446 5314 c-4 -9 -5 -18 -3 -20 1 -2 12 -1 24 2 l22 6 -6 14 c-7 19
-29 18 -37 -2z"></path><path d="M631 5304 c-7 -8 -19 -13 -27 -10 l-13 5 6 -15 c6 -17 20 -18 26 -1
l5 12 12 -15 12 -15 -5 16 c-3 9 0 22 6 28 l11 11 -9 0 c-6 -1 -16 -8 -24 -16z"></path><path d="M3547 5313 c-11 -11 -8 -23 6 -23 17 0 57 -46 57 -65 0 -8 -9 -20
-20 -27 -11 -7 -20 -22 -20 -34 l0 -21 35 11 35 11 6 20 7 19 -12 4 c-6 2 -11
11 -11 20 0 10 -14 28 -30 41 -17 13 -30 29 -30 37 0 15 -12 19 -23 7z m86
-117 l6 -15 -15 6 -14 5 0 -12 c0 -6 -5 -8 -10 -5 l-11 7 12 14 c15 18 25 18
32 0z"></path><path d="M5736 5304 l-6 -17 15 13 c17 14 19 20 6 20 -5 0 -12 -7 -15 -16z"></path><path d="M760 5300 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2574 5295 l-19 -14 28 1 27 2 0 13 c0 17 -12 16 -36 -2z"></path><path d="M4350 5300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5780 5284 c0 -8 4 -13 9 -10 5 3 12 -1 15 -10 4 -9 9 -14 12 -11 7
6 -18 47 -28 47 -5 0 -8 -7 -8 -16z"></path><path d="M2120 5280 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2375 5280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2440 5280 c0 -5 9 -10 21 -10 l20 0 -6 10 c-3 6 -13 10 -21 10 -8 0
-14 -4 -14 -10z"></path><path d="M3630 5280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M575 5250 l7 -30 -26 0 -25 0 2 -20 1 -20 21 6 c11 4 20 7 20 8 0 0
6 11 13 24 14 24 8 62 -9 62 l-11 0 7 -30z"></path><path d="M2360 5260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M4183 5250 l5 -20 -30 5 -30 6 7 -11 c4 -6 14 -9 24 -5 l16 6 -12
-12 -13 -13 -20 19 -20 18 0 -12 c0 -7 7 -19 16 -28 l15 -16 25 13 c13 8 24
17 24 22 l0 9 13 -7 12 -8 -14 17 -13 17 11 0 c6 0 11 5 11 10 0 6 -7 10 -16
10 l-16 0 5 -20z"></path><path d="M3267 5239 l-18 -20 15 -14 c8 -8 22 -15 30 -15 9 0 16 -4 16 -10 0
-5 11 -10 25 -10 14 0 25 3 25 6 0 8 -24 30 -40 37 l-15 5 12 1 13 1 -17 20
c-10 11 -20 20 -23 19 -3 0 -13 -9 -23 -20z"></path><path d="M2280 5230 l0 -20 14 0 c8 0 17 7 20 16 9 23 8 24 -14 24 l-20 0 0
-20z"></path><path d="M3386 5225 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M5830 5221 c0 -18 42 -81 50 -76 12 7 0 46 -16 52 -8 3 -14 11 -14
19 0 8 -4 14 -10 14 -5 0 -10 -4 -10 -9z"></path><path d="M4090 5208 c0 -9 5 -20 11 -23 17 -11 20 0 4 21 l-14 19 -1 -17z"></path><path d="M600 5190 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3200 5200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2285 5190 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2160 5180 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M3190 5163 c0 -16 20 -16 42 1 l17 13 -29 0 -30 0 0 -14z"></path><path d="M4183 5173 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M4110 5165 c0 -12 36 -45 50 -45 l15 0 -27 25 c-26 24 -38 30 -38 20z"></path><path d="M5810 5140 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M500 5145 c0 -8 6 -15 14 -15 8 0 16 7 20 15 l5 15 -19 0 -20 0 0
-15z"></path><path d="M575 5149 c-10 -15 3 -25 16 -12 l11 11 -10 6 c-6 3 -14 1 -17 -5z"></path><path d="M640 5140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3390 5140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5880 5130 l-15 -10 17 -10 17 -11 -5 -14 -6 -15 12 0 c6 0 9 -4 6
-8 -2 -4 0 -14 5 -22 l9 -15 9 15 c6 8 6 17 2 20 -4 3 -11 18 -15 33 l-7 28
-11 -7 -11 -7 6 16 c7 20 7 20 -13 7z"></path><path d="M1960 5120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2140 5120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3470 5120 c0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 9 -10 20
-10 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"></path><path d="M4206 5114 l-6 -16 16 6 c8 3 20 6 25 6 6 0 7 5 4 10 -10 15 -32 12
-39 -6z"></path><path d="M4290 5099 c0 -5 -6 -7 -12 -4 -7 4 -4 -1 6 -9 11 -9 26 -16 34 -16
l14 0 -6 16 c-3 9 -6 18 -6 20 0 2 -7 4 -15 4 -8 0 -15 -5 -15 -11z"></path><path d="M2077 5093 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M480 5070 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2020 5070 l0 -20 -30 0 -30 0 0 -20 0 -20 16 0 16 0 -6 16 -6 15 20
-6 20 -6 0 10 c0 6 6 11 14 11 8 0 17 7 20 16 9 23 8 24 -14 24 l-20 0 0 -20z"></path><path d="M3503 5075 c-15 -17 -17 -35 -3 -35 9 0 30 31 30 44 0 11 -15 6 -27
-9z"></path><path d="M3040 5064 c0 -8 2 -14 4 -14 2 0 11 -2 19 -5 l14 -6 0 15 c0 8 -9
17 -19 19 l-18 5 0 -14z"></path><path d="M5810 5040 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M4340 5040 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2984 5029 c-3 -6 -1 -15 6 -19 7 -4 10 -12 6 -18 l-6 -10 20 5 c11
3 20 7 20 10 0 2 -5 13 -10 24 -12 21 -25 24 -36 8z"></path><path d="M5947 5020 l-5 -20 14 0 14 0 0 20 c0 11 -4 20 -9 20 -5 0 -11 -9
-14 -20z"></path><path d="M460 5020 c0 -5 5 -10 10 -10 6 0 10 -9 10 -20 l0 -20 14 0 c14 0 26
21 26 46 l0 14 -30 0 c-16 0 -30 -4 -30 -10z"></path><path d="M5870 5015 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M410 5005 c0 -8 4 -15 9 -15 5 0 17 -3 26 -7 l17 -6 -11 21 c-13 25
-41 29 -41 7z"></path><path d="M5901 5001 c-6 -10 -7 -21 -4 -25 13 -13 21 -5 19 19 0 14 -2 25 -3
25 -1 0 -7 -8 -12 -19z"></path><path d="M3410 4991 c0 -11 -6 -22 -12 -24 l-13 -5 15 -11 15 -10 3 14 c2 9 8
13 13 10 l9 -6 0 25 0 26 -15 0 -15 0 0 -19z"></path><path d="M3040 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3230 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4370 4981 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30 6z"></path><path d="M5820 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5940 4980 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M402 4940 c-7 -16 -22 -32 -33 -35 l-19 -6 6 -11 c4 -6 0 -16 -9 -23
l-17 -12 19 -22 c23 -27 34 -27 26 0 -4 11 -1 33 5 49 l12 29 21 3 22 3 0 24
c0 35 -18 36 -33 1z"></path><path d="M1878 4956 c-22 -16 -23 -26 -3 -26 8 0 15 7 15 16 l0 16 16 -6 15
-6 -6 10 c-8 13 -16 12 -37 -4z"></path><path d="M3263 4955 c-16 -17 -17 -35 -3 -35 6 0 10 7 10 15 l0 15 20 0 c11 0
20 5 20 10 0 15 -33 11 -47 -5z"></path><path d="M5976 4943 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3386 4932 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"></path><path d="M4490 4932 c1 -10 26 -42 34 -42 3 0 6 8 6 19 l0 19 -20 5 c-11 3
-20 2 -20 -1z"></path><path d="M449 4912 c-20 -21 -14 -42 11 -42 20 0 32 23 24 45 -8 19 -15 18
-35 -3z"></path><path d="M2850 4911 c0 -6 8 -14 17 -20 l18 -9 -24 -21 -23 -20 16 -17 c9 -9
20 -13 24 -10 3 4 0 11 -7 16 l-14 8 17 17 17 17 -7 21 -7 22 17 -14 16 -13 0
14 0 14 -30 2 c-16 1 -30 -2 -30 -7z"></path><path d="M3276 4894 c-9 -24 -8 -26 14 -19 l20 6 0 -10 c0 -6 9 -11 20 -11
l20 0 0 15 0 15 -20 0 c-11 0 -20 5 -20 10 0 16 -27 11 -34 -6z"></path><path d="M3350 4901 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M5996 4901 c-3 -5 1 -11 9 -15 15 -6 29 7 18 18 -9 9 -21 8 -27 -3z"></path><path d="M1813 4883 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4583 4883 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5991 4864 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M500 4840 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"></path><path d="M3251 4852 l-1 -24 12 4 c6 2 14 10 16 18 l5 13 -11 -7 c-6 -3 -13 0
-15 6 -3 7 -6 3 -6 -10z"></path><path d="M2813 4835 c13 -27 33 -35 23 -9 -3 9 -6 20 -6 25 0 5 -6 9 -14 9
l-14 0 11 -25z"></path><path d="M6027 4838 c-4 -12 -4 -24 -1 -27 6 -7 24 20 24 37 0 21 -17 13 -23
-10z"></path><path d="M416 4835 l-5 -15 19 0 20 0 0 15 c0 8 -6 15 -14 15 -8 0 -16 -7 -20
-15z"></path><path d="M2760 4825 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M2900 4820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3263 4816 c-6 -8 -9 -17 -6 -20 4 -3 -1 -6 -10 -6 -9 0 -17 -7 -17
-15 l0 -15 -30 -6 c-16 -4 -30 -10 -31 -15 -3 -27 -3 -49 1 -49 3 0 15 3 28 7
l23 6 -5 23 -6 24 15 0 c15 0 75 57 75 71 0 14 -24 10 -37 -5z"></path><path d="M3150 4800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4635 4800 l-6 -10 17 7 17 6 -5 -14 c-4 -13 13 -39 26 -39 3 0 6 14
6 30 l0 30 -24 0 c-14 0 -28 -4 -31 -10z"></path><path d="M5970 4785 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M6050 4781 c0 -11 5 -23 12 -27 l11 -7 -7 11 -7 12 15 -6 c9 -3 14
-10 11 -14 -3 -5 -1 -11 5 -15 17 -11 11 21 -9 43 -10 12 -21 22 -25 22 -3 0
-6 -9 -6 -19z"></path><path d="M330 4762 c-16 -28 -15 -72 1 -72 5 0 9 14 9 30 0 17 5 30 10 30 6 0
10 9 10 20 0 28 -12 25 -30 -8z"></path><path d="M3080 4770 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2671 4744 c-7 -9 -15 -33 -18 -53 l-5 -38 41 1 c22 0 41 4 41 8 0 5
-5 8 -11 8 l-11 0 6 15 5 15 -14 0 c-8 0 -18 -8 -24 -17 l-9 -18 -9 9 -8 9 13
4 c6 3 12 14 12 25 l0 19 20 -6 20 -6 0 20 0 21 -17 0 c-10 -1 -24 -8 -32 -16z"></path><path d="M1653 4735 c-7 -8 -13 -17 -13 -20 0 -8 24 -1 32 10 19 25 1 34 -19
10z"></path><path d="M5990 4711 l0 -39 -12 -5 -13 -4 8 -9 8 -8 8 13 c5 7 14 10 20 7 l11
-7 0 14 c0 30 -13 77 -21 77 -5 0 -9 -18 -9 -39z"></path><path d="M6030 4740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2736 4731 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M3097 4716 l-6 -15 15 6 14 5 0 -19 c0 -11 6 -27 13 -35 l12 -17 12
14 12 13 -19 23 c-11 12 -20 26 -20 31 0 14 -28 9 -33 -6z"></path><path d="M4750 4720 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6071 4697 c-14 -17 -4 -47 14 -47 l14 0 -3 25 c-4 33 -10 39 -25 22z"></path><path d="M271 4672 c3 -19 -2 -23 -16 -12 l-15 12 0 -20 c0 -11 -6 -32 -14
-46 -7 -14 -11 -37 -8 -51 l4 -25 19 0 19 0 0 -20 0 -20 15 0 c8 0 15 7 15 16
0 8 -4 13 -9 10 -10 -7 -21 16 -21 44 0 10 5 31 11 47 l11 28 11 -22 c10 -23
27 -31 27 -14 0 21 -23 70 -36 75 -7 3 -13 2 -13 -2z"></path><path d="M340 4660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2570 4645 c0 -8 6 -15 14 -15 8 0 16 7 20 15 l5 15 -19 0 -20 0 0
-15z"></path><path d="M3080 4646 c0 -8 4 -16 10 -18 5 -1 -3 -18 -19 -37 -17 -19 -28 -38
-25 -43 6 -10 44 9 44 22 0 6 7 10 15 10 18 0 19 17 3 53 -14 29 -28 36 -28
13z"></path><path d="M6126 4646 c3 -8 12 -17 20 -20 l15 -6 -3 17 c-2 10 -11 19 -21 21
l-17 3 6 -15z"></path><path d="M1480 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1560 4630 c0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -19 0 -11 -6 -21
-12 -24 l-13 -4 12 -13 11 -12 13 23 c16 31 37 51 46 46 5 -3 9 6 9 19 l2 24
-24 0 -24 0 0 -20z"></path><path d="M2920 4632 c0 -10 9 -24 20 -32 11 -8 20 -22 20 -31 0 -9 9 -26 20
-39 11 -13 20 -27 20 -32 0 -4 5 -8 10 -8 6 0 10 6 10 14 0 8 6 16 13 19 l12
4 -32 30 c-18 17 -33 36 -33 42 0 6 -6 11 -14 11 -8 0 -18 9 -21 20 -8 25 -25
26 -25 2z"></path><path d="M3000 4640 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M340 4560 l0 -50 10 0 10 0 0 50 0 50 -10 0 -10 0 0 -50z"></path><path d="M2535 4592 c-16 -10 -36 -30 -44 -45 l-14 -26 16 -15 c17 -18 47 -21
47 -6 0 6 -7 10 -15 10 -24 0 -18 17 20 54 19 20 35 38 35 41 0 9 -15 5 -45
-13z"></path><path d="M4804 4589 c-3 -6 -1 -16 5 -22 l11 -11 0 22 c0 24 -5 28 -16 11z"></path><path d="M2577 4584 c-8 -8 3 -34 14 -34 5 0 9 9 9 20 0 19 -11 26 -23 14z"></path><path d="M6150 4565 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4780 4556 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M2923 4496 l1 -33 18 5 c10 2 18 6 18 8 0 11 -24 54 -31 54 -4 0 -7
-15 -6 -34z"></path><path d="M6130 4520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2430 4500 l0 -19 14 5 c8 3 17 12 20 20 l5 14 -19 0 -20 0 0 -20z"></path><path d="M6170 4505 c0 -8 5 -15 10 -15 l10 0 0 -47 c1 -27 7 -59 14 -72 l14
-24 7 23 8 24 -12 4 -11 4 0 43 c0 24 -3 50 -6 59 -8 20 -34 21 -34 1z"></path><path d="M4820 4495 l0 -15 20 0 c11 0 20 2 20 5 0 2 -9 9 -20 15 l-20 11 0
-16z"></path><path d="M170 4488 c1 -7 8 -24 16 -38 l15 -25 -4 21 c-2 12 0 29 5 38 l9 16
-21 0 c-11 0 -20 -6 -20 -12z"></path><path d="M1320 4480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1480 4460 c0 -16 5 -30 10 -30 6 0 10 6 10 14 0 8 3 21 6 30 l6 16
-16 0 -16 0 0 -30z"></path><path d="M4950 4480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2975 4451 c-3 -5 -17 -8 -30 -5 l-25 4 0 -15 0 -16 10 6 10 6 0 -15
c0 -9 7 -16 15 -16 l14 0 -5 15 -6 15 16 -6 16 -6 0 21 c0 22 -5 27 -15 12z"></path><path d="M4892 4425 l5 -24 -8 5 c-5 3 -9 -2 -9 -10 0 -23 16 -19 34 9 22 33
20 45 -5 45 l-21 0 4 -25z"></path><path d="M2366 4425 l6 -15 -17 6 -17 7 4 -20 c1 -11 -3 -27 -10 -35 l-12 -15
15 -13 c21 -17 31 -5 14 17 l-14 18 18 -14 c23 -18 34 -7 13 14 -18 19 -21 29
-5 19 6 -3 15 0 20 7 l9 14 9 -14 9 -14 16 16 c20 19 20 23 2 30 l-15 6 6 -15
5 -14 -11 0 c-7 0 -14 7 -17 15 -4 8 -12 15 -20 15 l-13 0 5 -15z"></path><path d="M4990 4410 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6130 4371 l0 -40 23 -3 22 -3 -3 -32 -3 -33 11 0 c6 0 13 7 16 15 4
8 12 15 20 15 l14 0 0 -40 0 -40 21 0 21 0 -6 23 c-3 12 -9 39 -12 60 l-7 37
-28 0 -29 0 0 20 0 20 -20 0 -20 0 0 20 c0 11 -4 20 -10 20 -5 0 -10 -18 -10
-39z"></path><path d="M150 4380 c0 -11 2 -20 5 -20 2 0 9 9 15 20 l11 20 -16 0 -15 0 0
-20z"></path><path d="M180 4360 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2420 4350 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6236 4361 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4936 4334 l-6 -16 15 6 15 5 0 -12 0 -12 10 15 c14 22 12 30 -9 30
-10 0 -22 -7 -25 -16z"></path><path d="M2255 4330 c-8 -13 4 -70 15 -70 10 0 8 19 -3 38 l-8 12 9 0 c6 0 12
7 16 15 l5 15 -14 0 c-7 0 -17 -5 -20 -10z"></path><path d="M2800 4324 c0 -12 30 -35 40 -29 l10 6 -6 -15 c-3 -9 -10 -16 -15
-16 -5 0 -9 -7 -9 -15 0 -25 27 -17 34 10 8 30 1 45 -20 45 -8 0 -14 5 -14 10
0 6 -4 10 -10 10 -5 0 -10 -3 -10 -6z"></path><path d="M160 4309 c0 -16 -26 -39 -35 -31 -5 4 -4 -4 0 -18 5 -14 10 -67 12
-117 l4 -93 10 0 c5 0 9 15 9 33 0 17 5 48 10 67 l10 35 -10 3 c-5 2 -10 12
-10 23 l0 19 20 0 20 0 0 20 c0 11 -4 20 -10 20 -5 0 -10 -4 -10 -10 0 -5 -4
-10 -10 -10 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 -4 15 -10
15 -5 0 -10 -5 -10 -11z"></path><path d="M1410 4300 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4988 4285 c-3 -3 -3 -17 0 -31 l5 -26 8 14 c17 27 6 62 -13 43z"></path><path d="M6130 4250 l0 -40 10 0 10 0 0 40 0 40 -10 0 -10 0 0 -40z"></path><path d="M2215 4270 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M1380 4261 c0 -5 9 -11 19 -14 l20 -5 -6 14 c-6 15 -33 19 -33 5z"></path><path d="M2762 4233 c2 -20 9 -38 16 -41 6 -2 12 1 12 8 0 6 -4 8 -10 5 -5 -3
-10 1 -10 9 l0 15 20 -4 c11 -2 20 -1 20 3 0 8 -37 42 -46 42 -3 0 -4 -17 -2
-37z"></path><path d="M1365 4229 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M2175 4230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2188 4202 c-21 -9 -38 -21 -38 -26 l0 -8 23 7 c12 4 29 7 37 8 9 1
16 9 16 19 0 10 0 18 -1 17 0 0 -17 -8 -37 -17z"></path><path d="M6210 4180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2765 4170 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M1330 4160 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2700 4130 c0 -22 2 -40 4 -40 2 0 11 2 19 5 l14 6 -11 34 c-6 19
-14 35 -18 35 -5 0 -8 -18 -8 -40z"></path><path d="M2730 4165 c0 -2 10 -10 23 -16 l22 -12 -14 16 c-13 16 -31 23 -31
12z"></path><path d="M5040 4160 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5068 4158 c-4 -9 -4 -27 -1 -40 8 -27 31 -53 41 -44 3 4 1 10 -6 14
-6 5 -15 26 -20 47 l-8 40 -6 -17z"></path><path d="M2680 4150 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2613 4135 c-13 -14 -18 -45 -7 -45 11 0 34 29 34 44 0 20 -11 20
-27 1z"></path><path d="M80 4108 c0 -18 5 -40 11 -48 l10 -15 -6 24 -7 24 8 -6 c5 -3 9 8 9
24 l0 29 -12 0 -13 0 0 -32z"></path><path d="M2052 4108 c-7 -7 -12 -18 -12 -25 l0 -13 29 0 29 0 5 14 6 15 -15
-6 -14 -5 0 16 c0 19 -11 21 -28 4z"></path><path d="M2640 4092 c0 -10 -3 -22 -7 -26 -9 -9 -11 -46 -2 -46 4 0 10 11 13
25 l6 25 21 0 21 0 -6 16 c-3 9 -6 18 -6 20 0 2 -9 4 -20 4 l-20 0 0 -18z"></path><path d="M1330 4081 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M2010 4044 l0 -16 17 13 16 14 -12 -22 -12 -23 11 0 c5 0 10 4 10 10
0 5 7 7 15 4 19 -8 19 0 -1 20 -20 21 -44 20 -44 0z"></path><path d="M101 4024 c-32 -69 -34 -114 -6 -114 l14 0 6 54 c4 29 5 62 3 72 l-3
18 -14 -30z"></path><path d="M180 4000 l0 -50 10 0 10 0 0 50 0 50 -10 0 -10 0 0 -50z"></path><path d="M2100 4030 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5087 4030 l-5 -20 14 0 14 0 0 20 c0 11 -4 20 -9 20 -5 0 -11 -9
-14 -20z"></path><path d="M6308 4033 c-5 -28 3 -83 13 -83 5 0 9 23 9 50 0 28 -4 50 -9 50 -5
0 -11 -8 -13 -17z"></path><path d="M70 4020 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"></path><path d="M2593 4013 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M1200 4000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1300 4000 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2490 4000 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2620 3991 c0 -11 -6 -22 -12 -24 l-13 -5 15 -11 c22 -16 30 -13 30
8 0 11 3 26 6 35 l6 16 -16 0 -16 0 0 -19z"></path><path d="M1316 3961 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M2440 3940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1920 3921 c0 -10 7 -22 16 -25 20 -8 9 -26 -17 -26 -10 0 -19 -4
-19 -10 0 -5 9 -10 20 -10 11 0 20 5 20 11 0 5 5 7 11 3 l11 -6 -3 33 c-3 32
-14 48 -31 49 -5 0 -8 -9 -8 -19z"></path><path d="M1160 3891 l0 -39 13 -6 12 -6 -12 -6 c-17 -8 -17 -34 0 -34 l13 0
-2 28 c-1 15 -2 44 -3 65 -1 49 -21 48 -21 -2z"></path><path d="M1247 3917 c-4 -10 -7 -35 -7 -55 l0 -36 16 16 16 16 -9 39 -9 38 -7
-18z"></path><path d="M2527 3923 c-10 -9 -9 -51 1 -57 5 -3 15 6 23 20 l13 25 17 -4 18 -5
-6 14 -5 14 -27 0 c-15 0 -31 -3 -34 -7z"></path><path d="M5131 3878 c-1 -14 -6 -28 -13 -30 l-12 -4 19 -50 20 -49 3 40 c2 22
-1 58 -7 80 l-9 40 -1 -27z"></path><path d="M6292 3875 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"></path><path d="M1857 3874 c-4 -4 -4 -18 -1 -30 l6 -24 -13 0 -14 0 28 -25 c15 -14
27 -30 27 -35 0 -6 9 -10 20 -10 11 0 20 4 20 10 0 5 -11 20 -24 33 -26 27
-35 55 -23 75 l8 12 -14 0 c-7 0 -17 -3 -20 -6z"></path><path d="M2440 3840 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M1252 3818 c-18 -18 -14 -38 8 -38 l20 0 0 16 0 15 -12 -7 -11 -7 6
16 c8 20 5 21 -11 5z"></path><path d="M6310 3785 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"></path><path d="M1790 3785 c0 -9 -6 -18 -14 -21 -15 -6 -19 -54 -4 -54 22 0 59 56
51 78 -7 17 -33 15 -33 -3z"></path><path d="M16 3767 c-3 -12 -6 -36 -6 -54 l0 -32 15 5 15 6 0 43 c0 48 -14 68
-24 32z"></path><path d="M6355 3767 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 l-4 18 -8 -18z"></path><path d="M1180 3760 c0 -3 12 -13 25 -23 l25 -18 0 24 0 25 -25 -2 c-14 0 -25
-3 -25 -6z"></path><path d="M2480 3755 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1940 3710 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1830 3700 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5156 3688 l-8 -28 8 -27 8 -28 4 28 c2 15 2 39 0 55 l-4 27 -8 -27z"></path><path d="M1171 3691 c-7 -5 -10 -14 -7 -20 11 -17 22 -13 22 9 0 11 0 20 -1
20 0 0 -6 -4 -14 -9z"></path><path d="M1720 3685 l0 -15 20 0 20 0 0 -20 c0 -11 5 -20 10 -20 6 0 10 9 10
19 0 11 6 21 13 24 l12 4 -8 9 -8 8 -9 -13 -8 -14 -17 16 c-20 21 -35 22 -35
2z"></path><path d="M6343 3693 c-7 -2 -13 -16 -13 -29 l0 -25 11 7 11 6 -4 -65 -3 -65
13 6 13 5 -3 83 c-2 46 -5 84 -8 83 -3 0 -11 -3 -17 -6z"></path><path d="M1040 3684 c0 -7 27 -34 34 -34 3 0 6 9 6 20 l0 20 -20 0 c-11 0 -20
-3 -20 -6z"></path><path d="M2300 3680 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2360 3666 c0 -8 -9 -18 -20 -21 -11 -3 -20 -13 -20 -21 l0 -14 20 0
20 0 0 20 c0 11 5 20 10 20 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 -6 -10
-14z"></path><path d="M2420 3660 c6 -11 13 -20 15 -20 3 0 5 9 5 20 l0 20 -15 0 -16 0 11
-20z"></path><path d="M1134 3650 c0 -11 4 -20 8 -20 4 0 8 9 8 20 0 11 -4 20 -8 20 -4 0
-8 -9 -8 -20z"></path><path d="M1000 3640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1096 3625 c-9 -9 -16 -25 -16 -36 0 -10 -4 -19 -8 -19 -10 0 -32
-45 -38 -77 l-4 -23 16 0 16 0 -4 25 c-3 13 -2 22 3 20 4 -3 10 8 14 25 l7 30
13 0 13 0 7 31 c4 17 5 33 2 35 -2 3 -12 -2 -21 -11z"></path><path d="M1693 3625 c-16 -17 -17 -35 -3 -35 6 0 10 5 10 10 0 17 22 11 42
-12 10 -12 16 -27 14 -34 l-5 -13 20 -6 c23 -7 39 2 24 13 -6 4 -19 24 -30 45
-11 20 -23 37 -26 37 -4 0 -13 2 -21 5 -7 2 -19 -2 -25 -10z"></path><path d="M23 3483 c1 -84 5 -153 10 -153 9 0 7 236 -3 275 l-8 30 1 -152z"></path><path d="M2377 3616 c-3 -8 -3 -17 0 -20 7 -7 33 13 33 25 0 15 -27 10 -33 -5z"></path><path d="M1643 3580 c1 -11 5 -20 9 -20 4 0 8 9 8 20 0 11 -4 20 -9 20 -6 0
-9 -9 -8 -20z"></path><path d="M1040 3580 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2360 3580 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6290 3481 l0 -109 13 -5 12 -4 -3 113 -3 114 -10 0 -9 0 0 -109z"></path><path d="M1703 3550 c1 -11 7 -20 14 -20 15 0 15 2 7 24 -9 23 -23 20 -21 -4z"></path><path d="M2320 3541 c0 -10 40 -22 52 -15 18 11 6 24 -22 24 -16 0 -30 -4 -30
-9z"></path><path d="M1000 3525 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1663 3533 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M6378 3534 c-5 -4 -8 -43 -8 -87 l0 -79 -13 5 -13 5 4 -34 4 -34 13
0 13 0 7 63 c8 73 3 172 -7 161z"></path><path d="M840 3510 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M1600 3514 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M1622 3503 l-13 -23 -24 0 -25 0 0 -15 c0 -8 7 -15 16 -15 l15 0 -7
12 -7 11 14 -5 c8 -3 23 0 33 7 l18 13 -4 19 -4 18 -12 -22z"></path><path d="M2301 3494 c-7 -8 -11 -18 -7 -21 9 -10 36 6 36 22 0 19 -11 19 -29
-1z"></path><path d="M2292 3451 c-16 -10 -12 -43 5 -48 l12 -4 -2 30 c-1 17 -2 31 -2 31
0 0 -6 -4 -13 -9z"></path><path d="M1000 3441 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1600 3440 c0 -5 5 -10 11 -10 l12 0 -7 -31 -6 -30 15 15 c19 18 19
33 1 52 -17 16 -26 18 -26 4z"></path><path d="M1534 3398 c17 -26 36 -37 36 -20 0 10 -20 39 -20 28 0 -5 -8 -2 -17
5 l-16 14 17 -27z"></path><path d="M920 3390 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M840 3330 l0 -40 10 0 c6 0 10 -13 10 -30 0 -16 5 -30 10 -30 6 0 10
9 10 21 l0 21 14 -5 14 -5 -2 16 -1 17 23 3 22 3 0 15 c0 8 5 14 10 14 6 0 10
7 10 15 0 8 -7 15 -15 15 l-14 0 5 -15 6 -15 -12 0 c-7 0 -21 -11 -31 -25
l-20 -24 -14 6 -15 5 0 39 c0 21 -4 39 -10 39 l-10 0 0 -40z"></path><path d="M905 3362 c-5 -4 -11 -15 -11 -24 l-2 -16 13 4 c7 3 15 14 18 25 5
20 -1 24 -18 11z"></path><path d="M2120 3360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2200 3349 c0 -5 4 -8 9 -5 5 4 11 1 13 -6 l4 -12 12 8 c19 12 14 21
-13 24 -14 1 -25 -3 -25 -9z"></path><path d="M2160 3310 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5150 3295 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"></path><path d="M31 3304 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1441 3288 c-9 -24 -6 -38 8 -38 12 0 20 49 10 56 -5 3 -13 -5 -18
-18z"></path><path d="M1540 3299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2211 3291 c-7 -5 -10 -14 -7 -20 11 -17 22 -13 22 9 0 11 0 20 -1
20 0 0 -6 -4 -14 -9z"></path><path d="M1410 3241 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1455 3230 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5150 3213 l0 -28 10 15 c13 20 13 40 0 40 -5 0 -10 -12 -10 -27z"></path><path d="M10 3178 l0 -42 9 10 c4 5 11 21 15 35 l6 27 -15 6 -15 5 0 -41z"></path><path d="M857 3204 l6 -16 -21 7 -22 6 0 -11 c0 -15 29 -44 37 -36 11 10 12
55 2 61 l-9 6 7 -17z"></path><path d="M753 3203 c-18 -7 -16 -33 2 -33 8 0 15 2 15 4 0 2 3 11 6 20 6 16 0
19 -23 9z"></path><path d="M2130 3200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1460 3185 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M680 3160 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"></path><path d="M1420 3150 c0 -11 -4 -20 -10 -20 -5 0 -10 5 -10 10 0 20 -18 9 -24
-15 l-6 -25 -20 0 c-26 0 -26 -17 0 -25 11 -3 20 -11 20 -16 0 -5 5 -9 10 -9
l10 0 -6 24 -6 24 11 6 c6 4 11 2 11 -3 0 -6 5 -11 10 -11 6 0 10 9 10 20 0
11 5 20 10 20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 -9 -10 -20z"></path><path d="M2160 3160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5136 3135 c-4 -11 -12 -34 -16 -52 l-8 -32 14 -12 14 -11 5 15 c4 8
5 36 3 63 l-3 49 -9 -20z"></path><path d="M760 3116 c0 -22 11 -46 21 -46 5 0 9 7 9 15 0 8 4 15 9 15 5 0 11 7
15 15 l5 15 -29 0 -30 0 0 -14z"></path><path d="M2120 3110 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6310 3080 c0 -16 -4 -30 -10 -30 l-10 0 0 -50 0 -50 11 0 10 0 -6
20 c-8 25 3 62 16 55 5 -4 9 -21 9 -40 0 -19 5 -37 10 -40 l10 -6 0 55 0 56
-10 0 c-5 0 -10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10 -13 -10 -30z"></path><path d="M700 3071 c0 -11 -6 -22 -12 -24 l-13 -5 14 -10 c7 -5 10 -15 7 -21
l-7 -11 15 0 c9 0 16 7 16 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 l0 10 20 -6
20 -6 0 25 0 26 -20 0 -20 0 0 -19z"></path><path d="M760 3021 c0 -17 5 -33 10 -36 6 -3 10 -3 11 2 0 4 2 20 4 36 l4 27
-15 0 -14 0 0 -29z"></path><path d="M1960 2990 l0 -60 10 0 10 0 0 60 0 60 -10 0 -10 0 0 -60z"></path><path d="M2070 3020 c6 -11 12 -20 13 -20 2 0 3 9 3 20 0 11 -6 20 -13 20
l-14 0 11 -20z"></path><path d="M1287 2987 l-6 -16 14 -15 c8 -7 11 -16 8 -20 -4 -3 -1 -6 5 -6 16 0
15 32 -1 54 l-14 19 -6 -16z"></path><path d="M2045 2990 c3 -6 -1 -13 -9 -16 -20 -8 -21 -34 -3 -59 l14 -18 8 13
9 14 -12 4 c-15 5 -16 38 -2 47 13 8 13 25 -1 25 -5 0 -7 -5 -4 -10z"></path><path d="M740 2968 l-1 -23 -10 18 c-5 9 -14 17 -20 17 l-10 0 17 -32 c22 -44
24 -45 34 -28 13 20 13 70 0 70 -5 0 -10 -10 -10 -22z"></path><path d="M84 2938 c3 -18 11 -53 17 -78 7 -25 12 -66 13 -91 l1 -46 12 4 13 5
-2 79 c0 43 -5 79 -10 79 -4 0 -8 18 -8 40 l0 40 -21 0 -22 0 7 -32z"></path><path d="M2060 2955 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M178 2915 c-5 -46 2 -105 13 -105 5 0 9 32 9 70 0 73 -15 96 -22 35z"></path><path d="M680 2930 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1264 2906 c-4 -9 -5 -19 -2 -21 7 -8 48 7 48 18 0 6 -3 7 -7 4 -3
-4 -12 -2 -19 4 l-14 11 -6 -16z"></path><path d="M6310 2895 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M1973 2893 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M2014 2855 l-11 -35 13 0 13 0 -6 17 -6 16 11 -7 12 -7 0 25 c0 37
-13 32 -26 -9z"></path><path d="M5080 2881 c0 -5 7 -16 15 -25 19 -19 19 -26 -2 -26 l-17 0 12 -12
13 -13 9 15 c5 8 10 27 10 43 l0 27 -20 0 c-11 0 -20 -4 -20 -9z"></path><path d="M680 2830 c0 -19 11 -26 23 -14 8 8 -3 34 -14 34 -5 0 -9 -9 -9 -20z"></path><path d="M1260 2830 l0 -20 20 0 20 0 0 20 0 20 -20 0 -20 0 0 -20z"></path><path d="M1217 2833 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M1960 2830 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6304 2828 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M73 2799 c4 -13 7 -27 7 -31 0 -15 20 -8 20 7 0 8 -5 15 -11 15 -5 0
-8 4 -4 9 3 5 0 13 -6 17 l-12 7 6 -24z"></path><path d="M6300 2795 c0 -8 4 -15 8 -15 5 0 9 7 9 15 0 8 -4 15 -9 15 -4 0 -8
-7 -8 -15z"></path><path d="M1240 2776 c0 -24 -20 -29 -35 -10 l-14 19 -1 -15 c0 -36 52 -55 64
-24 9 24 7 44 -4 44 -5 0 -10 -6 -10 -14z"></path><path d="M5070 2773 c0 -10 5 -25 10 -33 l10 -15 0 17 c0 9 3 23 6 32 l6 16
-16 0 -16 0 0 -17z"></path><path d="M1300 2750 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1980 2755 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1260 2740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5050 2740 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M142 2705 c-7 -14 -15 -44 -18 -66 l-7 -39 16 6 c9 3 19 14 22 25 7
20 25 27 25 9 0 -5 5 -10 10 -10 6 0 10 14 10 30 0 17 -4 30 -10 30 -5 0 -10
-4 -10 -10 0 -5 -4 -10 -10 -10 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 5
10 10 0 20 -27 9 -38 -15z"></path><path d="M540 2721 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M6200 2713 c0 -22 17 -49 25 -41 8 8 -5 58 -16 58 -5 0 -9 -8 -9 -17z"></path><path d="M600 2707 c0 -7 7 -22 15 -33 l15 -19 -6 18 -6 17 21 0 21 0 0 15 0
15 -30 0 -30 0 0 -13z"></path><path d="M6270 2666 c0 -8 5 -18 10 -21 l10 -6 0 20 c0 12 -4 21 -10 21 -5 0
-10 -6 -10 -14z"></path><path d="M1130 2652 c0 -5 7 -16 16 -23 l16 -13 -3 18 c-4 20 -29 35 -29 18z"></path><path d="M1885 2640 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4993 2611 c0 -22 5 -37 9 -35 11 7 10 74 -1 74 -6 0 -9 -18 -8 -39z"></path><path d="M6240 2624 l-10 -25 10 -20 c6 -10 12 -19 14 -19 2 0 3 12 3 28 0 15
3 35 7 45 l8 17 -11 0 c-6 0 -16 -12 -21 -26z"></path><path d="M620 2605 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M163 2590 l5 -20 -14 0 -14 0 0 -27 1 -28 14 19 c18 24 30 15 16 -12
-14 -26 -14 -32 -1 -32 6 0 10 10 10 23 l0 22 10 -15 10 -15 0 33 c0 17 -4 32
-10 32 -5 0 -10 9 -10 20 0 11 -5 20 -11 20 l-11 0 5 -20z"></path><path d="M6171 2588 c1 -13 4 -34 8 -47 l6 -24 18 18 c22 22 36 11 19 -15 -17
-28 -15 -39 8 -37 l20 1 0 19 c0 25 -51 106 -67 107 l-13 0 1 -22z"></path><path d="M559 2534 l3 -44 -16 0 c-9 0 -16 -7 -16 -16 0 -8 4 -12 10 -9 5 3
22 -2 37 -12 l28 -18 -15 19 -15 19 3 47 4 47 -14 5 -13 5 4 -43z"></path><path d="M4948 2545 l4 -35 -11 0 c-6 0 -11 -7 -11 -16 0 -19 -27 -47 -38 -40
l-9 5 5 -24 4 -25 19 0 c11 0 18 3 17 8 -3 13 13 52 22 52 5 0 14 16 19 36
l10 36 -17 19 -18 18 4 -34z"></path><path d="M610 2561 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1140 2550 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1820 2560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1880 2550 c0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 0 -16 5 -30
10 -30 6 0 10 9 10 19 0 11 6 26 13 33 6 7 13 21 15 31 l3 17 -16 0 -15 0 0
-20z"></path><path d="M1800 2515 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1098 2498 c-20 -24 -24 -48 -8 -48 6 0 10 5 10 11 l0 10 20 -6 20
-6 0 13 c0 8 -6 22 -12 31 l-13 17 -17 -22z"></path><path d="M6130 2475 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"></path><path d="M1800 2465 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1840 2430 c0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 5 -20
10 -20 6 0 10 -11 10 -25 0 -14 5 -25 10 -25 6 0 10 7 10 15 0 8 4 15 9 15 5
0 11 9 14 20 l5 20 -14 14 c-8 8 -14 22 -14 30 0 9 -4 16 -10 16 -5 0 -10 -9
-10 -20z m20 -40 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6
0 10 -9 10 -20z"></path><path d="M6190 2435 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1138 2394 c2 -13 8 -24 13 -24 14 0 10 37 -4 43 l-14 5 5 -24z"></path><path d="M520 2373 c0 -21 4 -44 8 -50 l8 -13 1 36 c1 37 13 58 20 37 7 -20
23 -15 23 7 l0 20 -30 0 -30 0 0 -37z"></path><path d="M6159 2404 c-7 -56 -8 -74 -1 -78 4 -2 12 11 18 30 l11 34 -13 11
c-8 6 -15 8 -15 3z"></path><path d="M200 2385 c0 -8 5 -15 10 -15 6 0 10 -9 10 -19 0 -45 30 -70 44 -36
l5 15 -24 24 c-14 14 -25 30 -25 36 0 5 -4 10 -10 10 -5 0 -10 -7 -10 -15z"></path><path d="M1060 2385 c0 -18 16 -20 24 -2 l6 12 6 -12 c8 -18 24 -16 24 2 l0
15 -30 0 -30 0 0 -15z"></path><path d="M340 2320 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"></path><path d="M1080 2321 c0 -37 -16 -49 -23 -19 -4 12 -9 19 -13 15 -8 -8 15 -67
27 -67 5 0 9 9 9 20 0 11 5 20 10 20 6 0 10 14 10 30 0 17 -4 30 -10 30 -5 0
-10 -13 -10 -29z"></path><path d="M1120 2320 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M1800 2333 c0 -10 5 -25 10 -33 l10 -15 0 33 c0 17 -4 32 -10 32 -5
0 -10 -8 -10 -17z"></path><path d="M4819 2321 l-11 -31 -16 0 c-8 0 -12 -3 -9 -7 4 -3 2 -12 -4 -20
l-11 -13 25 0 26 0 -5 14 -6 14 21 21 c23 23 26 37 11 46 l-11 7 -10 -31z"></path><path d="M560 2325 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M280 2269 l0 -20 -20 6 -20 6 0 -16 c0 -9 4 -14 9 -11 5 4 11 1 13
-6 l4 -12 27 20 c14 10 27 27 27 37 l0 17 -20 0 -20 0 0 -21z"></path><path d="M541 2257 l-1 -28 15 15 c16 16 20 30 6 22 -5 -4 -12 0 -14 6 -3 7
-6 0 -6 -15z"></path><path d="M1831 2257 c-14 -35 -14 -54 -1 -62 l10 -6 -3 23 -2 23 13 -2 c14 -3
16 16 2 37 l-9 14 -10 -27z"></path><path d="M6130 2270 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M6111 2243 c4 -22 -3 -33 -21 -33 l-19 0 -5 -32 c-7 -48 -4 -66 11
-72 l13 -5 0 25 0 25 11 -7 12 -7 -7 28 -7 28 15 -6 15 -6 -6 16 -6 16 10 -6
10 -6 -1 21 c0 12 -7 23 -14 26 -7 2 -12 0 -11 -5z"></path><path d="M1100 2205 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"></path><path d="M318 2176 l-4 -34 13 5 c14 6 18 63 4 63 -5 0 -11 -15 -13 -34z"></path><path d="M1804 2113 l1 -86 14 -5 14 -5 -8 54 c-4 30 -7 68 -6 86 0 18 -3 34
-7 37 -4 3 -8 -34 -8 -81z"></path><path d="M535 2180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1066 2174 c-9 -24 -7 -54 4 -54 6 0 10 16 10 35 0 38 -4 44 -14 19z"></path><path d="M1020 2135 l0 -45 16 0 16 0 -6 16 c-3 9 -6 29 -6 45 0 16 -4 29 -10
29 l-10 0 0 -45z"></path><path d="M6003 2173 l-13 -5 0 -33 c0 -37 18 -85 32 -85 4 0 8 -9 8 -19 0 -11
5 -23 12 -27 l11 -7 -6 36 -6 36 15 -6 c8 -3 14 0 14 6 0 6 -11 13 -24 17
l-24 6 -7 34 c-3 19 -3 39 0 44 7 11 6 11 -12 3z"></path><path d="M340 2130 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1127 2116 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 l-4 18 -7 -19z"></path><path d="M4670 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4600 2075 l0 -25 20 0 20 0 0 19 c0 10 -7 22 -16 25 -23 9 -24 8
-24 -19z"></path><path d="M320 2081 c0 -6 5 -13 10 -16 6 -4 7 -11 4 -17 l-7 -11 16 6 16 6 -6
-36 -6 -36 16 6 c10 4 23 4 31 1 l14 -5 7 -50 7 -49 11 7 10 6 -6 -16 -6 -17
9 0 c6 0 10 -6 10 -14 0 -17 18 -31 27 -22 3 3 0 12 -6 20 l-12 15 16 31 17
32 -12 -7 -11 -7 -20 23 c-22 25 -24 45 -7 55 l12 8 4 -12 c5 -17 19 -15 26 3
8 23 -4 45 -25 45 l-19 0 0 -20 c0 -22 -15 -27 -23 -7 l-5 12 -1 -12 c-1 -7
-6 -13 -12 -13 -5 0 -8 4 -5 9 4 5 1 11 -5 13 -6 2 -11 20 -12 41 l-1 37 -28
0 c-15 0 -28 -4 -28 -9z"></path><path d="M500 2060 l0 -30 11 0 c14 0 38 30 39 48 l0 12 -25 0 -25 0 0 -30z"></path><path d="M416 2065 c4 -8 12 -15 20 -15 8 0 14 7 14 15 l0 15 -20 0 -19 0 5
-15z"></path><path d="M5970 1996 c0 -19 5 -38 11 -41 6 -4 8 -13 5 -21 l-5 -14 14 0 c20 0
19 26 -1 34 l-16 6 5 18 c2 9 8 25 12 35 l7 17 -16 0 -16 0 0 -34z"></path><path d="M4527 2002 l6 -15 -14 8 -14 8 18 -19 18 -19 9 15 c14 22 12 27 -9
33 l-20 5 6 -16z"></path><path d="M540 1970 l0 -40 10 0 10 0 0 -44 c0 -46 13 -68 24 -40 l6 15 -10 -6
c-14 -8 -13 8 1 22 l12 12 -8 28 c-4 15 -7 41 -6 57 l3 28 -11 -6 c-6 -4 -11
-2 -11 3 0 6 -4 11 -10 11 l-10 0 0 -40z"></path><path d="M6002 1998 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"></path><path d="M4500 1960 c0 -5 -7 -10 -16 -10 l-15 0 6 -10 c3 -5 17 -10 31 -10
l24 0 0 20 0 20 -15 0 c-8 0 -15 -4 -15 -10z"></path><path d="M520 1912 l0 -22 -19 0 -20 0 14 -26 c7 -15 11 -33 8 -42 l-6 -15 10
6 c12 8 28 105 19 114 -3 4 -6 -3 -6 -15z"></path><path d="M5830 1910 c0 -11 -4 -20 -10 -20 -5 0 -10 -13 -10 -30 l0 -30 21 0
21 0 -12 15 -12 15 6 25 c4 14 11 25 16 25 6 0 10 5 10 10 0 6 -7 10 -15 10
l-15 0 0 -20z"></path><path d="M5903 1915 c-7 -8 -13 -22 -13 -31 l0 -16 -13 5 -13 5 0 -17 c-1 -9
-5 -25 -9 -35 l-8 -19 25 5 25 5 -5 -19 -4 -18 15 0 15 0 -5 20 c-3 11 -9 23
-14 26 -14 8 -11 24 4 19 l12 -5 2 32 c2 18 7 34 13 36 14 5 12 22 -2 22 -7
-1 -19 -7 -25 -15z"></path><path d="M5952 1898 c3 -7 13 -15 22 -16 l18 -4 -4 14 c-3 7 -13 15 -22 16
l-18 4 4 -14z"></path><path d="M4280 1825 c0 -17 22 -29 41 -23 l12 4 -17 17 c-22 21 -36 22 -36 2z"></path><path d="M587 1813 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13 18z"></path><path d="M540 1800 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M505 1790 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4200 1784 l0 -16 15 12 c8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0 -15
-7 -15 -16z"></path><path d="M5830 1755 c0 -14 -4 -25 -10 -25 -5 0 -10 -13 -10 -29 l0 -29 16 -6
c9 -3 17 -6 19 -6 2 0 -2 13 -8 30 l-11 29 8 8 9 8 4 -12 c3 -7 9 -13 15 -13
5 0 8 6 5 13 -2 6 0 21 6 32 l10 20 -21 -18 -21 -19 6 21 5 21 -11 0 c-6 0
-11 -11 -11 -25z"></path><path d="M1020 1744 l0 -26 13 4 c6 3 13 14 15 26 l3 22 -15 0 -16 0 0 -26z"></path><path d="M1130 1760 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M563 1731 c-5 -21 -23 -30 -23 -11 0 6 -4 10 -10 10 -5 0 -10 -7 -10
-15 l0 -15 25 0 25 0 0 -15 c0 -8 4 -15 8 -15 12 0 12 69 0 76 -5 3 -12 -3
-15 -15z"></path><path d="M1060 1695 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"></path><path d="M4113 1704 c-7 -3 -9 -12 -6 -20 6 -16 53 -20 53 -4 0 6 8 10 18 10
l17 0 -15 10 c-16 10 -47 12 -67 4z"></path><path d="M2335 1690 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M1100 1665 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"></path><path d="M620 1655 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25 -5
0 -10 -11 -10 -25z"></path><path d="M2340 1656 c0 -8 5 -18 10 -21 l10 -6 0 20 c0 12 -4 21 -10 21 -5 0
-10 -6 -10 -14z"></path><path d="M4047 1632 c1 -8 3 -16 3 -18 0 -2 7 -4 15 -4 8 0 15 5 15 10 0 6 -6
10 -14 10 -8 0 -16 3 -19 8 -2 4 -2 1 0 -6z"></path><path d="M3937 1623 c6 -15 51 -17 72 -4 l14 9 -45 3 -45 4 4 -12z"></path><path d="M596 1615 l-5 -15 26 0 25 0 -12 15 c-7 8 -16 15 -20 15 -4 0 -10 -7
-14 -15z"></path><path d="M1140 1615 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2396 1614 c-4 -9 -3 -32 0 -51 l7 -35 8 14 c5 7 9 25 9 41 0 15 5
27 10 27 6 0 10 5 10 10 0 17 -37 11 -44 -6z"></path><path d="M4190 1620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3825 1610 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M1056 1595 l-6 -16 15 -5 c8 -4 15 -12 15 -20 0 -8 5 -14 10 -14 6 0
10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 24 -15 27 -24 5z"></path><path d="M2340 1599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5730 1602 c0 -4 11 -9 26 -10 l25 -2 -6 10 c-7 12 -45 14 -45 2z"></path><path d="M5670 1580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1140 1565 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1040 1544 l0 -25 10 6 c6 3 10 15 10 26 0 10 -4 19 -10 19 -5 0 -10
-12 -10 -26z"></path><path d="M3735 1549 l-30 -19 58 0 57 0 0 10 c0 6 -8 10 -18 10 -10 0 -22 4
-27 9 l-10 9 -30 -19z"></path><path d="M3793 1563 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5730 1554 l0 -14 20 0 c11 0 20 6 20 13 0 7 -9 13 -20 14 l-20 2 0
-15z"></path><path d="M2330 1546 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M2613 1553 c-22 -8 -14 -23 12 -23 14 0 25 4 25 9 0 11 -21 19 -37
14z"></path><path d="M654 1528 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M2780 1525 c0 -8 6 -15 14 -15 8 0 16 7 20 15 l5 15 -19 0 -20 0 0
-15z"></path><path d="M5655 1530 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M1080 1505 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2743 1513 c-28 -5 -31 -23 -4 -23 10 0 21 7 25 15 3 8 4 14 3 14 -1
-1 -12 -4 -24 -6z"></path><path d="M2896 1508 c2 -3 -14 -9 -36 -13 -22 -4 -40 -11 -40 -17 l0 -10 45 6
45 7 6 -15 6 -16 42 0 c22 0 48 5 56 10 l15 10 -37 0 c-21 0 -38 4 -38 9 0 9
48 21 86 21 13 0 24 5 24 10 l0 10 -60 0 -60 0 0 -20 c0 -11 -4 -20 -9 -20 -5
0 -7 8 -5 18 l5 17 -25 0 c-13 0 -22 -3 -20 -7z"></path><path d="M2360 1454 l0 -55 10 6 c6 3 10 28 10 56 l0 49 -10 0 -10 0 0 -56z"></path><path d="M3570 1500 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"></path><path d="M5686 1494 l-6 -15 13 7 12 8 -14 -17 -13 -17 16 0 16 0 0 25 c0 28
-15 34 -24 9z"></path><path d="M1120 1475 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3133 1483 c31 -2 83 -2 115 0 31 2 5 3 -58 3 -63 0 -89 -1 -57 -3z"></path><path d="M3340 1480 l-15 -9 46 2 c26 1 45 5 42 10 -6 10 -55 9 -73 -3z"></path><path d="M2600 1460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3730 1460 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M1075 1443 c2 -10 4 -27 4 -38 l1 -21 20 -1 c25 -2 36 15 16 23 -9 3
-14 10 -11 14 2 4 -4 15 -14 25 l-19 17 3 -19z"></path><path d="M2400 1428 l0 -23 10 15 c13 19 13 30 0 30 -5 0 -10 -10 -10 -22z"></path><path d="M5508 1443 c-22 -5 -23 -31 -3 -57 l15 -19 -6 -24 -6 -23 23 3 c29 5
34 20 14 41 -9 9 -14 20 -11 25 4 5 0 11 -6 14 l-13 5 13 13 c6 7 12 17 12 21
0 8 -4 9 -32 1z"></path><path d="M5587 1425 c2 -8 11 -16 19 -19 l14 -5 0 13 c0 8 -9 16 -19 19 l-20
5 6 -13z"></path><path d="M1140 1420 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5632 1418 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"></path><path d="M5550 1390 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M1150 1360 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2380 1345 l0 -25 15 0 c8 0 15 -6 15 -14 0 -8 7 -16 15 -20 l15 -5
0 22 c0 12 -3 33 -7 45 l-6 24 -9 -14 -10 -13 -5 13 c-8 21 -23 13 -23 -13z"></path><path d="M1137 1321 l-6 -24 11 11 c5 5 8 16 6 23 l-4 13 -7 -23z"></path><path d="M1801 1313 l-1 -23 15 0 c8 0 15 7 15 16 0 8 -4 13 -9 10 -5 -4 -12
0 -14 6 -3 7 -6 3 -6 -9z"></path><path d="M5475 1290 l-30 -6 23 -8 c12 -4 22 -14 22 -22 0 -18 26 -18 33 0 3
8 0 20 -6 28 l-12 15 -30 -7z"></path><path d="M5330 1284 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M1154 1260 c0 -11 5 -19 11 -17 5 2 10 10 10 17 0 7 -5 15 -10 17 -6
2 -11 -6 -11 -17z"></path><path d="M5426 1271 c-3 -5 1 -11 9 -14 l14 -6 -6 15 c-6 16 -10 17 -17 5z"></path><path d="M2406 1244 c-20 -19 -20 -24 -2 -24 7 0 16 9 19 20 3 11 4 20 2 20
-2 0 -11 -7 -19 -16z"></path><path d="M1184 1226 c0 -13 5 -22 10 -19 l8 6 -6 -20 c-8 -24 9 -73 24 -73 5
0 10 5 10 11 0 5 -4 8 -9 4 l-10 -5 9 30 9 31 -11 29 c-5 17 -16 30 -23 30
l-13 0 2 -24z"></path><path d="M5400 1232 c-8 -9 -27 -24 -42 -31 l-28 -14 0 -23 c0 -12 -3 -29 -6
-38 l-6 -16 20 0 c11 0 23 7 26 16 l6 15 -10 -6 c-6 -3 -10 -4 -10 -1 0 2 -3
11 -6 20 l-6 16 16 0 c9 0 16 5 16 10 0 6 6 10 14 10 8 0 18 9 21 20 3 11 11
20 16 20 5 0 9 5 9 10 0 15 -13 12 -30 -8z"></path><path d="M5330 1220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1274 1212 c3 -5 -2 -27 -9 -50 -14 -40 -12 -72 5 -72 5 0 12 9 15
20 4 11 9 18 13 16 4 -2 5 15 4 39 l-4 44 -14 5 c-8 3 -13 2 -10 -2z"></path><path d="M5426 1195 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M1820 1160 l0 -30 15 0 15 0 0 -20 c0 -11 5 -20 11 -20 l11 0 -6 23
c-3 12 -6 27 -6 34 l0 12 -16 -6 -16 -6 7 21 6 22 -10 0 c-6 0 -11 -13 -11
-30z"></path><path d="M1230 1160 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2440 1120 c0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 -4 -10 -10
-10 -5 0 -10 -7 -10 -15 0 -30 33 -11 38 21 l3 21 -23 7 c-32 8 -38 8 -38 -4z"></path><path d="M2557 1123 c-13 -12 -7 -33 8 -33 l15 0 0 20 c0 20 -11 26 -23 13z"></path><path d="M5240 1110 l-14 -9 23 -20 c28 -25 43 -27 35 -6 -4 8 -13 15 -21 15
l-15 0 6 15 c6 17 6 18 -14 5z"></path><path d="M5290 1101 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M5198 1088 c-8 -21 2 -38 23 -38 10 0 19 2 19 5 0 3 -8 15 -19 26
l-18 20 -5 -13z"></path><path d="M1286 1064 l-19 -26 14 -8 c16 -10 32 -3 24 11 -4 5 -1 9 4 9 6 0 11
9 11 20 0 11 -3 20 -7 20 -5 0 -16 -12 -27 -26z"></path><path d="M1820 1060 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M1867 1073 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13 18z"></path><path d="M2518 1063 c-10 -11 -18 -27 -18 -36 0 -9 -4 -17 -10 -17 -12 0 -13
-37 -1 -44 5 -4 17 -3 26 0 l16 7 -5 14 c-3 8 -1 23 5 34 5 11 9 29 7 41 l-3
20 -17 -19z"></path><path d="M2460 1036 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M5235 1040 c-3 -5 -5 -10 -3 -11 2 0 11 -2 21 -4 l17 -3 0 14 c0 16
-26 19 -35 4z"></path><path d="M1840 970 l0 -40 -10 0 c-5 0 -10 -18 -10 -39 l0 -39 13 -5 12 -4 -4
43 -3 44 10 0 c11 0 22 48 15 67 -10 27 -23 12 -23 -27z"></path><path d="M5176 985 c-3 -8 -12 -15 -20 -15 -7 0 -16 -7 -20 -15 -6 -17 9 -21
19 -5 3 6 15 10 26 10 l19 0 0 14 c0 21 -17 29 -24 11z"></path><path d="M1880 965 c0 -8 4 -15 9 -15 5 0 11 7 15 15 l5 15 -14 0 c-8 0 -15
-7 -15 -15z"></path><path d="M1320 956 c0 -17 35 -27 48 -14 6 6 15 7 21 4 14 -9 14 -36 1 -36 -5
0 -10 -9 -10 -20 0 -22 20 -26 37 -9 l12 12 15 -15 15 -15 -6 -22 -5 -21 16 6
c24 9 19 33 -14 64 -16 15 -30 35 -30 43 0 17 -40 30 -64 21 l-16 -6 0 11 c0
6 -4 11 -10 11 -5 0 -10 -6 -10 -14z m100 -56 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"></path><path d="M2535 956 c-24 -14 -30 -36 -10 -36 8 0 15 7 15 15 0 8 7 15 15 15 8
0 15 5 15 10 0 12 -9 11 -35 -4z"></path><path d="M5060 961 c0 -6 9 -11 21 -11 11 0 18 -4 15 -9 -3 -5 1 -12 10 -15
l17 -6 -15 22 c-16 24 -48 37 -48 19z"></path><path d="M1440 944 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M5022 930 c-6 -11 -12 -31 -12 -45 l0 -25 30 0 c17 0 30 5 30 10 0 6
7 10 15 10 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 -4 -10 -10 0 -5 -10 -10
-21 -10 l-21 0 6 30 c6 34 -3 39 -22 10z"></path><path d="M1900 891 c0 -10 -3 -34 -6 -52 l-6 -34 11 15 11 15 0 -12 c0 -7 5
-13 11 -13 l11 0 -6 17 -7 16 13 9 13 10 -12 5 c-7 2 -13 13 -13 24 0 10 -4
19 -10 19 -5 0 -10 -8 -10 -19z"></path><path d="M2580 893 c0 -10 5 -25 10 -33 l10 -15 0 33 c0 17 -4 32 -10 32 -5 0
-10 -8 -10 -17z"></path><path d="M2550 879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5000 830 c0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -6 l0 -11 -21 5 -20 5
-6 -14 c-3 -8 -12 -14 -20 -14 -8 0 -11 -4 -8 -10 11 -17 34 -11 75 19 l41 29
-11 7 c-5 3 -10 1 -10 -5z"></path><path d="M1499 793 l-14 -16 28 7 27 6 0 -10 c0 -15 17 -12 23 4 l6 15 -15 -6
-14 -5 0 11 c0 17 -26 13 -41 -6z"></path><path d="M1601 784 c-12 -14 -21 -28 -18 -31 6 -5 57 41 57 52 0 11 -16 3 -39
-21z"></path><path d="M2647 790 c12 -10 24 -28 28 -40 l6 -20 20 0 19 0 0 15 c0 8 -6 15
-13 15 -8 0 -19 11 -25 25 l-12 25 -22 0 -22 0 21 -20z"></path><path d="M2720 800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4761 794 c-7 -8 -10 -18 -6 -22 3 -3 10 0 14 7 5 7 14 10 20 7 l11
-7 0 15 c0 20 -21 20 -39 0z"></path><path d="M4810 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4721 782 l-9 -17 14 -19 c7 -11 17 -16 21 -11 5 4 2 10 -4 12 l-13
5 6 24 c7 28 -1 32 -15 6z"></path><path d="M4864 788 c-13 -21 -12 -48 2 -48 l13 0 -2 30 c-1 16 -3 30 -4 30 -1
0 -5 -6 -9 -12z"></path><path d="M2600 785 c0 -12 24 -55 32 -55 11 0 10 4 -4 35 -6 14 -15 25 -20 25
-4 0 -8 -2 -8 -5z"></path><path d="M1966 740 c2 -19 6 -49 10 -66 l6 -32 19 5 19 5 0 -21 c0 -11 5 -21
11 -21 l11 0 -5 34 c-6 46 -13 55 -37 49 l-20 -5 0 11 c0 6 5 11 10 11 6 0 10
12 10 26 l0 26 -14 -11 -14 -12 -5 18 c-3 10 -3 2 -1 -17z"></path><path d="M1613 717 c4 -10 7 -25 7 -33 0 -8 5 -14 10 -14 16 0 11 36 -7 51
l-17 14 7 -18z"></path><path d="M4747 724 c-9 -9 21 -36 33 -29 5 3 10 13 10 21 l0 14 -18 0 c-10 0
-21 -3 -25 -6z"></path><path d="M2652 688 l1 -28 19 0 18 0 0 -16 c0 -18 23 -44 39 -44 6 0 11 7 11
15 0 8 7 15 15 15 8 0 15 5 15 10 0 14 -37 12 -43 -2 l-5 -13 -16 22 c-9 12
-23 28 -31 36 l-14 14 10 10 10 10 -15 0 -15 0 1 -29z"></path><path d="M2740 700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4707 704 c-10 -10 12 -44 28 -44 19 0 19 17 1 36 -16 16 -20 17 -29
8z"></path><path d="M4690 660 l0 -10 -42 0 c-24 0 -50 -5 -58 -10 l-15 -10 19 0 18 0 -6
-15 -5 -15 14 0 c8 0 15 7 15 15 l0 15 23 -5 23 -6 26 14 27 14 -11 11 c-15
15 -28 15 -28 2z"></path><path d="M1712 634 l-13 -14 7 -12 c4 -7 8 -22 9 -34 l1 -22 43 -41 c24 -23
50 -41 57 -41 21 0 17 17 -4 22 l-19 5 5 32 4 31 -31 -6 -31 -6 0 21 c0 11 4
21 9 21 5 0 11 6 14 13 l5 14 -24 -5 -24 -4 0 11 c0 6 5 11 10 11 6 0 10 5 10
10 0 14 -13 12 -28 -6z"></path><path d="M2840 640 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4390 641 c0 -5 7 -11 15 -15 8 -3 15 -12 15 -21 0 -8 5 -15 10 -15
6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 5 10 10 0 6 -16 10 -35 10 -19 0 -35
-4 -35 -9z"></path><path d="M4470 620 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4510 620 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M1680 600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2897 596 c-7 -18 3 -56 14 -56 5 0 9 16 9 35 0 35 -13 47 -23 21z"></path><path d="M4526 594 c-10 -27 -7 -34 17 -34 32 0 38 8 24 31 -14 23 -33 25 -41
3z m34 -8 c0 -11 -19 -15 -25 -6 l-6 10 15 0 c9 0 16 -2 16 -4z"></path><path d="M1960 586 c0 -8 6 -16 13 -19 l13 -5 0 19 c0 11 -6 19 -13 19 -7 0
-13 -6 -13 -14z"></path><path d="M4380 588 c0 -11 18 -29 48 -46 l23 -13 -3 18 -3 18 23 3 22 3 0 -10
c0 -6 -7 -11 -15 -11 -8 0 -15 -7 -15 -15 0 -8 3 -15 8 -15 10 1 42 26 42 34
0 4 -7 15 -16 23 l-16 17 -19 -12 c-10 -7 -28 -12 -40 -12 l-21 0 5 13 5 14
-14 0 c-8 0 -14 -4 -14 -9z"></path><path d="M2041 583 c3 -21 -2 -33 -16 -33 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 8 0 15 5 15 10 0 6 6 10 13 10 7 0 13 9 14 20 l2 20 -15 0 c-8 0 -13 -3
-13 -7z"></path><path d="M2778 550 c12 -12 22 -28 22 -36 l0 -15 11 7 11 7 -7 -22 -6 -21 15
-16 c9 -8 24 -14 33 -12 l17 3 -16 15 -15 15 5 37 5 38 -12 0 c-7 0 -9 5 -6
10 l6 10 -42 0 -43 0 22 -20z"></path><path d="M2090 539 c0 -11 -4 -18 -9 -15 -12 8 -22 -20 -15 -39 l6 -15 24 0
c26 0 30 -10 12 -28 -15 -15 -48 -16 -48 -2 0 6 -12 10 -26 10 l-26 0 6 -16
c3 -9 6 -18 6 -20 0 -2 13 -4 29 -4 16 0 31 -5 33 -11 l4 -12 37 14 c35 12 50
29 27 29 l-10 0 6 26 7 27 -13 -8 -13 -8 6 17 7 17 -10 -6 -10 -6 0 18 -1 18
-14 -19 c-8 -11 -18 -17 -22 -13 -3 4 1 15 10 25 19 21 22 42 7 42 -5 0 -10
-9 -10 -21z"></path><path d="M1976 526 l5 -14 -22 -15 -22 -16 16 -15 c21 -21 30 -20 22 3 l-6 19
16 15 c19 20 19 37 0 37 l-14 0 5 -14z"></path><path d="M4410 515 l-12 -15 16 0 c9 0 16 7 16 15 0 8 -2 15 -4 15 -2 0 -9 -7
-16 -15z"></path><path d="M2900 491 c0 -11 -6 -21 -12 -24 -7 -2 -10 -8 -5 -12 8 -9 36 23 37
43 0 6 -4 12 -10 12 -5 0 -10 -9 -10 -19z"></path><path d="M3890 480 l0 -10 60 0 60 0 0 10 0 10 -60 0 -60 0 0 -10z"></path><path d="M4130 480 c0 -5 12 -10 26 -10 l25 0 -6 10 c-3 6 -15 10 -26 10 -10
0 -19 -4 -19 -10z"></path><path d="M4265 479 c-4 -6 -13 -8 -21 -5 -7 3 -20 -1 -28 -9 -8 -8 -26 -15
-40 -15 -14 0 -26 -4 -26 -10 0 -5 -13 -10 -29 -10 l-29 0 -4 -25 -5 -25 13 0
c8 0 14 5 14 10 0 6 9 10 20 10 l20 0 0 16 0 16 24 -6 24 -6 4 9 c3 10 62 26
131 36 l38 6 -6 10 c-8 13 -92 12 -100 -2z"></path><path d="M1900 460 c0 -5 5 -10 12 -10 6 0 9 -2 7 -5 -3 -2 -20 -1 -37 4 l-32
10 0 -15 0 -14 25 0 25 0 -2 -11 c-2 -6 3 -14 10 -16 l12 -5 0 15 c0 9 5 17
11 19 l10 3 -6 18 c-8 18 -35 24 -35 7z"></path><path d="M4050 460 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M3890 418 l0 -13 40 0 40 0 0 13 0 12 -40 0 -40 0 0 -12z"></path><path d="M1937 414 c-10 -11 -8 -26 2 -19 5 3 12 1 16 -5 3 -5 15 -10 25 -10
l18 0 14 -30 13 -30 27 0 28 0 0 15 0 15 -20 0 -20 0 -6 24 c-7 30 -29 40 -55
26 l-19 -11 2 12 c3 13 -15 23 -25 13z m70 -21 c-4 -3 -10 -3 -14 0 -3 4 0 7
7 7 7 0 10 -3 7 -7z"></path><path d="M3918 393 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"></path><path d="M3963 375 l0 -15 19 0 18 0 0 15 0 15 -18 0 -19 0 0 -15z"></path><path d="M2215 360 l-6 -10 17 0 18 0 -12 -12 c-7 -7 -12 -20 -12 -31 l0 -18
13 8 12 7 -17 -27 c-20 -29 -24 -52 -8 -42 6 4 8 11 4 16 l-6 10 21 6 c12 3
21 7 21 10 0 2 -5 13 -10 23 l-10 19 10 6 10 6 -13 19 c-13 22 -23 25 -32 10z"></path><path d="M2120 334 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2160 321 c0 -5 7 -14 15 -21 16 -14 18 -10 9 14 -6 17 -24 22 -24 7z"></path><path d="M2958 323 c6 -3 9 -9 6 -15 l-7 -10 24 7 c13 4 31 11 39 16 l15 8
-45 -1 c-25 -1 -39 -3 -32 -5z"></path><path d="M3642 323 c-13 -5 -7 -23 9 -23 6 0 8 4 5 9 -4 5 0 12 6 14 7 3 9 5
3 5 -5 0 -16 -2 -23 -5z"></path><path d="M3710 323 c-14 -3 -29 -9 -34 -14 l-10 -9 37 0 37 0 0 15 c0 8 -1 14
-2 14 -2 -1 -14 -4 -28 -6z"></path><path d="M3564 312 l7 -12 -11 0 c-6 0 -8 -4 -5 -10 3 -5 10 -10 16 -10 13 0
11 27 -3 36 l-11 7 7 -11z"></path><path d="M2080 295 c0 -8 7 -15 15 -15 l14 0 -5 15 c-4 8 -10 15 -15 15 -5 0
-9 -7 -9 -15z"></path><path d="M3263 303 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"></path><path d="M3410 296 l0 -14 -21 5 -21 6 -9 -14 -9 -14 0 13 0 12 -134 0 -134 0
-6 -15 -5 -15 195 0 195 0 -6 9 c-3 5 3 12 12 14 l18 5 -25 2 -25 3 55 7 55 7
-67 1 -68 2 0 -14z"></path><path d="M2974 289 l-7 -12 13 8 13 8 -6 -16 -6 -17 30 0 30 0 -5 9 c-4 5 0
12 6 14 7 3 3 6 -9 6 -12 1 -28 4 -36 7 -8 3 -18 0 -23 -7z"></path><path d="M2130 280 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M2261 263 l-16 -12 13 -1 c17 0 15 -20 -3 -20 -8 0 -15 -7 -15 -17
l0 -16 18 16 c20 18 38 22 45 10 2 -5 2 -2 0 5 -1 7 2 22 7 32 l10 19 -21 -2
c-12 -1 -29 -7 -38 -14z"></path><path d="M2170 255 c0 -8 7 -15 15 -15 l14 0 -5 15 c-4 8 -10 15 -15 15 -5 0
-9 -7 -9 -15z"></path><path d="M2256 195 l-5 -15 26 0 25 0 -12 15 c-7 8 -16 15 -20 15 -4 0 -10 -7
-14 -15z"></path><path d="M2320 201 c0 -5 6 -12 13 -14 l12 -5 -12 -9 -12 -9 8 -13 9 -14 12 8
13 8 -6 -10 c-4 -6 -1 -13 7 -16 l14 -6 6 22 c6 23 -9 47 -30 47 -8 0 -14 5
-14 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -9z"></path><path d="M2395 121 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"></path><path d="M2490 90 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#608731" stroke="none"><path d="M2750 5330 c0 -5 -11 -10 -25 -10 l-24 0 6 -15 5 -16 -41 7 c-22 4
-41 4 -41 1 0 -7 49 -57 55 -57 9 0 5 9 -11 25 -9 9 -14 19 -10 22 8 9 46 -27
46 -44 0 -16 26 -17 37 0 4 6 17 17 28 23 11 6 21 22 23 35 l3 24 -16 -19 c-8
-11 -18 -17 -21 -13 -4 3 1 13 10 22 9 9 16 18 16 21 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -10z"></path><path d="M2596 5255 c-4 -14 -11 -25 -17 -25 -6 0 -7 5 -4 10 3 6 -10 0 -30
-11 l-36 -22 -54 7 -54 6 5 -15 6 -15 -14 5 -14 5 -2 -25 -2 -25 12 0 12 0
-12 -12 -13 -13 -7 12 c-4 6 -28 14 -54 18 l-48 7 0 -16 0 -16 33 0 33 0 14
-20 c15 -21 50 -27 50 -9 l0 11 24 -4 24 -5 -2 11 c-3 20 -18 37 -39 45 l-22
8 45 0 44 0 4 12 c2 6 12 11 22 11 10 0 20 4 22 9 3 10 21 14 56 12 l22 -1 0
-40 c0 -22 3 -40 8 -40 4 0 20 14 35 30 16 17 33 30 38 30 5 0 9 6 9 14 0 15
-21 26 -51 26 l-19 0 0 25 c0 14 -4 25 -9 25 -5 0 -12 -11 -15 -25z m38 -60
l5 -15 -14 0 -14 0 5 15 c4 8 7 15 9 15 2 0 5 -7 9 -15z"></path><path d="M2345 5170 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4050 5173 c-8 -3 -41 -33 -72 -66 l-56 -61 3 -28 c2 -15 -1 -28 -6
-28 -5 0 -9 -4 -9 -10 0 -5 11 -10 25 -10 14 0 25 5 25 11 0 5 -4 8 -9 5 l-9
-6 2 22 c2 13 6 33 10 44 l7 22 19 -5 20 -5 5 13 5 14 45 2 45 1 0 -18 0 -18
59 -4 59 -3 -1 16 -1 16 -24 -3 -24 -2 6 14 5 14 -26 -6 -26 -7 6 16 6 16 -16
-6 -16 -6 5 15 6 15 -24 -5 -24 -4 0 16 c0 9 5 16 10 16 6 0 10 5 10 10 0 11
-16 12 -40 3z m-11 -62 l13 -8 -10 -7 c-5 -3 -15 1 -22 9 -14 16 -3 20 19 6z
m-52 -38 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M2516 5135 l-5 -15 19 0 20 0 0 -15 c0 -18 12 -19 28 -3 16 16 15 28
-3 28 -8 0 -15 5 -15 10 0 16 -37 12 -44 -5z"></path><path d="M2465 5120 c-4 -6 -2 -17 4 -24 l11 -12 -10 -10 -10 -10 14 -19 14
-19 16 17 c9 9 24 21 33 25 l16 9 -8 12 c-4 7 -14 10 -22 7 l-15 -6 -18 20
-18 21 -7 -11z m42 -47 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M2233 5106 c1 -6 -6 -10 -15 -10 -10 1 -15 -3 -12 -8 3 -5 -2 -18
-12 -29 -9 -10 -14 -22 -9 -27 4 -4 10 -1 12 6 l5 12 39 0 39 0 0 -30 0 -30
-20 0 c-11 0 -20 -4 -20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 -16
-10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 -8 -10 -18 -10 l-19 0 -16 -37 c-9 -21
-15 -51 -13 -68 2 -16 1 -34 -1 -39 -8 -12 -23 15 -23 42 0 26 -6 28 -26 8
-20 -20 -17 -44 5 -51 11 -3 24 -12 30 -19 l11 -13 -25 5 -25 4 0 -11 c0 -14
-48 -61 -62 -61 -5 0 -6 -4 -3 -10 l6 -10 -17 7 -17 6 5 -9 c4 -5 0 -18 -8
-29 l-14 -19 0 27 0 28 17 6 c25 10 50 38 45 51 l-4 12 21 0 21 0 0 19 c0 10
-7 22 -16 25 -23 9 -36 7 -29 -4 3 -5 -5 -21 -19 -35 -15 -14 -26 -32 -26 -40
0 -8 -5 -17 -12 -21 l-11 -7 8 13 8 13 -11 -7 -12 -7 0 -45 0 -44 -17 0 -18
-1 19 -11 19 -11 29 32 c15 17 32 31 38 31 5 0 15 9 22 20 7 11 18 20 24 20 6
0 21 11 34 25 13 14 27 25 32 25 17 0 198 148 198 162 0 3 -7 9 -16 12 l-15 6
6 -11 7 -10 -21 -6 c-12 -3 -21 -9 -21 -14 0 -5 -7 -6 -15 -3 -8 3 -21 -1 -30
-9 l-15 -16 10 -6 11 -7 -12 -14 c-6 -8 -18 -14 -25 -14 -8 0 -14 -4 -14 -10
0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 11 10 25 0 13 5
36 12 50 l12 27 43 -4 c24 -2 43 -3 44 -3 0 0 3 23 6 50 3 28 9 52 14 53 14 5
11 32 -5 48 l-15 14 -18 -6 -18 -6 15 11 15 11 -14 9 -13 9 13 4 14 5 -27 5
-27 5 2 -11z m-126 -373 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"></path><path d="M2435 5050 c-6 -20 -20 -26 -28 -12 -2 4 -2 1 0 -6 l3 -14 -20 -5
-20 -5 0 -19 c0 -11 5 -19 12 -19 6 0 2 -9 -10 -21 l-22 -20 -11 7 -12 7 5
-14 c3 -8 10 -14 16 -14 7 0 32 17 56 38 l45 38 -2 39 c-1 22 -2 40 -3 40 -2
0 -6 -9 -9 -20z"></path><path d="M4025 5031 l-7 -26 -14 20 -14 19 -23 -23 c-12 -13 -17 -21 -10 -17
l12 6 6 -20 7 -21 31 3 32 3 5 41 6 41 -12 0 c-7 0 -16 -12 -19 -26z"></path><path d="M2105 5030 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2457 5024 c6 -15 23 -19 23 -5 0 5 -7 11 -14 14 l-15 6 6 -15z"></path><path d="M4256 5024 c-9 -8 -14 -20 -11 -25 10 -15 41 -10 56 8 l14 17 -14 -9
-14 -8 6 16 6 17 -14 0 c-7 0 -21 -7 -29 -16z"></path><path d="M3870 4993 c-17 -21 -47 -54 -67 -74 l-35 -37 6 -11 c4 -6 14 -11 22
-11 l15 0 -6 10 c-7 12 20 42 29 32 4 -3 4 2 0 13 l-7 18 14 -5 c19 -7 46 13
51 37 1 11 9 26 16 33 6 7 12 18 12 23 0 19 -19 8 -50 -28z"></path><path d="M2073 5007 l2 -12 -18 1 -18 2 12 -14 11 -14 -10 0 c-6 0 -14 -9 -17
-21 l-7 -20 -12 4 -12 5 0 -22 1 -21 -37 -1 -37 -1 5 9 c3 4 9 6 14 3 5 -3 11
-1 15 5 l6 10 -14 0 c-8 0 -19 -6 -25 -13 l-11 -13 15 -22 15 -22 40 0 40 0
24 25 25 24 0 46 0 45 15 0 16 0 6 -30 c6 -35 19 -39 28 -10 3 11 13 20 21 20
8 0 14 5 14 10 0 6 -9 10 -19 10 -11 0 -21 4 -23 8 -1 5 -18 12 -35 15 l-33 6
3 -12z"></path><path d="M4345 4990 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15
0 6 -10z"></path><path d="M4130 4980 l0 -10 60 1 60 2 -6 8 c-3 5 -30 9 -60 9 l-54 0 0 -10z"></path><path d="M4370 4925 l0 -35 11 0 c16 0 39 -26 39 -44 l0 -16 -25 0 c-14 0 -25
-5 -25 -10 l0 -11 55 2 55 2 -11 14 -11 13 15 0 c9 0 19 4 22 10 l6 10 -17 -7
-17 -6 6 19 7 19 -16 -20 -16 -20 4 26 5 26 -26 -5 -26 -4 20 17 20 17 -17 -6
-17 -5 6 16 6 16 -16 -6 -16 -6 -7 11 -7 11 13 -8 13 -8 -7 11 c-14 23 -26 12
-26 -23z"></path><path d="M2304 4938 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3870 4910 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3820 4850 c0 -11 6 -20 13 -20 7 0 19 -9 27 -20 8 -11 18 -20 22
-20 4 0 8 9 8 20 0 11 -5 20 -11 20 l-11 0 6 16 6 15 -10 -6 c-6 -3 -10 -1
-10 4 0 6 -9 11 -20 11 l-20 0 0 -20z"></path><path d="M1876 4845 l6 -15 -18 7 -19 7 14 -17 c7 -9 24 -17 37 -17 25 0 33
15 12 23 l-13 5 13 1 c22 2 12 17 -13 19 l-24 2 5 -15z"></path><path d="M3716 4820 c-20 -22 -36 -47 -36 -56 0 -17 57 -73 103 -101 15 -10
27 -21 27 -25 0 -4 6 -8 13 -8 7 0 18 -9 25 -21 l13 -21 15 6 15 6 -6 -10 c-4
-6 8 -27 27 -48 18 -20 43 -50 55 -67 l22 -30 12 13 c7 6 22 12 32 12 l19 0
-4 -23 -4 -22 39 4 40 3 -6 -16 -7 -17 11 7 c15 9 29 -14 29 -48 l0 -28 -19 0
c-20 0 -35 25 -25 42 l7 10 -23 -7 -23 -7 6 16 7 17 -9 -5 c-10 -6 -40 24 -38
39 0 6 -7 11 -16 13 -9 2 -17 -1 -17 -6 0 -10 35 -56 68 -90 7 -8 19 -27 27
-43 9 -16 22 -29 30 -29 l14 0 -5 -15 -6 -15 11 0 c6 0 11 7 11 16 l0 15 11
-6 c6 -4 8 -13 5 -20 l-5 -13 21 6 20 5 -6 29 -6 29 10 -6 c6 -3 10 1 10 9 0
9 -7 16 -15 16 l-15 0 6 24 6 24 -13 24 c-7 12 -21 31 -31 40 -11 9 -18 21
-15 25 3 4 -15 6 -39 4 l-44 -3 0 21 c0 12 -4 21 -10 21 -5 0 -10 -7 -10 -15
0 -8 -7 -15 -15 -15 -8 0 -15 2 -15 4 0 1 5 17 11 34 6 18 15 29 20 26 l9 -5
-6 15 -6 16 11 0 c6 0 11 9 11 20 0 26 -17 26 -25 0 l-7 -21 -19 6 -19 6 5
-13 5 -13 -20 -13 -21 -13 2 13 c1 7 1 25 0 41 l-1 27 -14 0 c-8 0 -17 -6 -20
-12 -4 -7 -4 2 -1 20 l5 32 -15 0 -15 0 0 40 0 40 -20 0 -20 0 6 -25 c3 -14 7
-33 8 -43 0 -9 10 -25 21 -34 l20 -17 -14 -1 c-7 0 -23 10 -35 23 -12 13 -26
22 -31 20 -6 -1 -18 11 -27 27 l-17 30 -19 0 c-10 0 -29 12 -43 28 -13 15 -20
24 -15 20 5 -4 15 -3 23 3 l13 11 0 -11 0 -11 45 0 c25 0 45 3 45 6 0 14 -31
34 -54 34 l-26 0 0 16 0 15 23 -5 c12 -3 16 -3 10 0 -7 3 -13 14 -13 25 0 10
-4 19 -9 19 -5 0 -25 -18 -45 -40z m224 -255 l12 -15 -10 0 c-5 0 -12 7 -16
15 -3 8 -4 15 -2 15 2 0 9 -7 16 -15z m47 -72 c-4 -3 -7 0 -7 7 0 7 3 10 7 7
3 -4 3 -10 0 -14z m167 -188 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20 1 1 0 -6
-3 -14z"></path><path d="M1800 4805 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"></path><path d="M1958 4794 c2 -13 8 -24 13 -24 5 0 9 2 9 4 0 2 3 11 6 19 l5 14 -19
5 -19 5 5 -23z"></path><path d="M4532 4794 c-2 -29 13 -25 21 6 l5 20 -12 0 c-6 0 -13 -11 -14 -26z"></path><path d="M1777 4743 l6 -16 -13 8 -13 8 7 -12 8 -12 -12 -14 -11 -14 -16 6
-15 6 -8 -14 -9 -13 10 -17 c9 -18 3 -49 -11 -49 -4 0 -11 -7 -14 -16 l-6 -17
15 13 15 13 -6 -18 -7 -17 -38 4 -38 3 9 -9 c13 -13 13 -29 0 -21 -5 3 -10 1
-10 -4 l0 -11 45 0 46 0 24 25 25 24 0 39 c0 21 -5 43 -12 50 l-12 12 32 6 32
7 0 33 0 34 -15 0 -14 0 6 -17z"></path><path d="M4538 4753 c6 -2 12 -16 12 -30 0 -13 6 -31 14 -39 15 -15 45 -19 47
-6 0 4 2 19 3 33 l2 26 -21 -5 -20 -5 15 19 15 19 -18 -14 -18 -14 -11 11 c-7
7 -17 12 -22 11 l-11 -1 13 -5z"></path><path d="M4679 4659 l-14 -9 13 0 c6 0 12 -9 12 -20 l0 -20 15 0 15 0 0 24 c0
27 -20 39 -41 25z"></path><path d="M1797 4643 c3 -7 11 -13 18 -13 16 0 26 -25 19 -45 l-6 -15 -12 7
-11 7 14 -17 13 -17 -15 0 c-9 0 -19 -9 -22 -20 -3 -11 -12 -20 -20 -20 -8 0
-17 -9 -20 -20 -3 -11 -11 -20 -16 -20 -5 0 -9 -4 -9 -10 0 -14 48 -12 52 2
l4 12 18 -7 17 -7 -15 -16 c-9 -8 -16 -20 -16 -25 0 -5 -7 -9 -16 -9 -9 0 -18
-9 -21 -20 l-5 -20 -12 7 c-6 4 -5 -1 3 -10 7 -9 16 -17 20 -17 4 0 27 28 51
62 25 34 58 74 73 90 l29 28 -7 17 c-3 10 -3 14 1 11 8 -9 44 9 44 22 0 6 -7
10 -15 10 -8 0 -15 -4 -15 -10 0 -12 -22 -9 -32 5 -11 15 -10 37 2 30 6 -3 10
-1 10 4 0 6 -13 11 -30 11 -16 0 -30 4 -30 9 0 5 -11 11 -24 13 l-24 5 5 -14z
m79 -87 c10 -8 15 -18 11 -22 -4 -4 -17 2 -29 14 -25 25 -13 30 18 8z m-22
-47 l-18 -19 -8 12 c-12 20 -9 31 7 24 8 -3 15 -1 15 5 0 6 5 7 11 4 l10 -7
-17 -19z"></path><path d="M3970 4640 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M1820 4600 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4720 4590 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M4723 4573 c-7 -3 -13 -13 -13 -24 l0 -19 20 0 c11 0 20 -2 20 -4 0
-2 3 -11 6 -20 l6 -16 -16 0 -16 0 0 -26 c0 -27 15 -60 36 -81 7 -7 28 -13 47
-13 l35 0 5 -12 c6 -19 18 -4 23 29 l5 29 -42 -4 -42 -4 6 9 c3 5 -3 16 -14
23 -10 8 -19 23 -19 33 l0 19 19 -5 20 -5 -2 19 c-1 11 -7 19 -15 19 l-13 0 7
12 7 11 -16 -6 -17 -6 5 22 6 23 -18 1 c-10 0 -24 -2 -30 -4z"></path><path d="M1607 4493 c4 -10 12 -23 17 -29 6 -6 18 -21 26 -33 8 -11 20 -21 28
-21 13 0 42 23 42 34 0 3 -13 6 -29 6 l-30 0 -6 20 c-3 11 -13 20 -21 20 -8 0
-14 5 -14 10 0 6 -4 10 -10 10 l-9 0 6 -17z"></path><path d="M4180 4470 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path><path d="M1548 4441 l5 -14 -11 7 -12 7 7 -17 6 -17 -13 8 -13 8 7 -19 c10
-24 10 -34 1 -56 l-7 -18 10 0 c6 0 14 9 17 20 3 11 11 20 16 20 19 0 39 28
39 53 l0 25 -29 4 -29 4 6 -15z"></path><path d="M1628 4373 c-18 -5 -23 -30 -10 -49 8 -12 38 -25 50 -21 4 1 6 6 4
12 -2 5 6 1 17 -9 23 -21 31 -18 34 12 l1 20 13 -5 c7 -3 13 0 13 6 0 15 -37
14 -43 -1 -3 -7 -6 -3 -6 10 l-1 22 -15 0 c-8 0 -15 -4 -15 -10 0 -5 -7 -10
-16 -10 l-16 0 6 15 c3 8 4 14 3 14 -1 -1 -10 -3 -19 -6z"></path><path d="M4690 4350 l0 -20 20 0 c24 0 25 6 6 26 -20 19 -26 18 -26 -6z"></path><path d="M1487 4343 l6 -16 -10 6 c-5 3 -10 0 -10 -8 0 -8 8 -15 18 -15 l18 0
-2 20 c-1 11 -7 22 -14 24 l-12 5 6 -16z"></path><path d="M4883 4332 l-13 -8 10 -33 c5 -17 6 -39 3 -47 l-5 -14 15 0 c8 0 20
-14 27 -30 7 -17 14 -29 17 -27 2 3 8 20 13 38 l9 34 -13 -18 -14 -18 -4 25
c-3 14 0 30 6 36 l11 11 -18 -7 -18 -7 7 27 6 26 -18 -7 -17 -6 6 16 c7 19 7
20 -10 9z"></path><path d="M1543 4296 c-6 -7 -21 -32 -33 -56 l-22 -43 14 -8 13 -9 -14 -9 c-8
-5 -16 -25 -18 -44 l-3 -34 14 -12 15 -13 30 34 c17 18 31 37 31 41 0 17 -35
1 -46 -21 -17 -34 -21 -35 -20 -3 1 16 3 29 6 29 3 0 11 0 18 1 l12 1 0 34 c0
19 3 41 6 49 l5 15 20 -5 19 -5 -6 16 -6 16 41 0 41 0 0 -10 c0 -5 -9 -10 -21
-10 l-20 0 7 -12 7 -11 -16 6 -16 6 6 -15 5 -14 -16 6 -16 6 -1 -13 c-1 -8 -1
-20 0 -26 1 -7 -4 -13 -11 -13 l-12 0 12 -12 12 -12 23 28 c12 16 39 46 60 67
20 21 37 41 37 44 0 4 -9 4 -19 0 -11 -3 -22 -1 -25 4 -4 6 -13 8 -21 4 l-15
-5 0 11 0 11 -32 0 c-18 -1 -38 -7 -45 -14z"></path><path d="M1457 4262 l6 -15 -10 6 -10 6 5 -26 5 -26 -17 6 c-18 7 -20 3 -7
-22 l10 -17 -9 -14 c-5 -8 -12 -11 -17 -7 -4 5 -2 12 6 16 l13 8 -17 7 -18 6
7 -30 6 -31 -10 6 -10 6 7 -17 6 -17 -11 7 -12 7 7 -17 6 -17 -14 8 -14 8 8
-9 c5 -5 22 -9 38 -10 l29 -1 0 13 c0 7 5 14 11 16 6 2 11 24 12 48 1 25 5 47
9 50 3 3 8 18 10 34 l3 29 -17 2 -17 3 6 -16z m0 -89 c-4 -3 -7 0 -7 7 0 7 3
10 7 7 3 -4 3 -10 0 -14z"></path><path d="M4184 4215 c2 -7 0 -17 -6 -23 l-11 -11 42 -85 c22 -47 41 -90 41
-96 0 -5 7 -10 15 -10 l15 0 0 26 0 25 -11 -7 -11 -6 2 23 c4 40 1 49 -14 43
l-13 -5 -14 33 c-7 18 -12 39 -11 47 l3 13 -10 -6 -10 -6 8 28 9 29 -15 0 c-7
0 -12 -6 -9 -12z"></path><path d="M4850 4160 l0 -50 16 0 c22 0 44 25 44 50 l0 21 -20 -6 -20 -6 0 20
c0 12 -4 21 -10 21 l-10 0 0 -50z"></path><path d="M4965 4180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4246 4158 c3 -4 13 -8 23 -8 l19 0 12 -29 12 -30 -7 -40 -7 -41 26
0 26 0 0 -30 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 l-10 0 0
50 0 50 -10 0 c-5 0 -10 13 -10 30 l0 30 -44 -2 c-25 -1 -42 -6 -40 -10z"></path><path d="M4990 4162 c0 -5 7 -17 16 -28 l16 -19 -6 18 c-3 10 -6 23 -6 28 0 5
-4 9 -10 9 -5 0 -10 -4 -10 -8z"></path><path d="M4264 4128 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M1465 4070 c5 -9 -7 -14 -27 -11 -3 0 -5 -10 -4 -24 2 -13 0 -29 -4
-36 -12 -18 -30 -1 -31 29 0 15 -3 21 -6 14 l-5 -13 -13 18 -13 18 5 -29 6
-29 -12 7 -11 7 0 -11 c0 -7 9 -14 20 -17 21 -6 26 -18 10 -28 -5 -3 -7 -12
-3 -20 l5 -15 -15 0 c-20 0 -31 17 -15 23 l13 5 -14 13 -14 14 8 -15 9 -15
-16 13 -16 12 -5 -22 -6 -22 12 -15 12 -14 -12 -4 c-16 -6 -17 -29 -2 -38 10
-7 18 1 74 70 43 53 47 58 80 94 l30 32 -15 9 c-20 13 -33 13 -25 0z"></path><path d="M4996 4072 c-25 -5 -46 -26 -46 -46 l0 -16 29 0 29 0 6 32 c3 17 5
32 4 33 -2 1 -12 -1 -22 -3z"></path><path d="M5022 4018 c-14 -14 -16 -57 -2 -78 l9 -14 -1 29 c-1 17 2 40 6 53 8
25 5 27 -12 10z"></path><path d="M1413 4013 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4270 3957 c0 -7 7 -37 16 -66 l15 -53 25 5 25 4 -3 -16 -4 -16 -18
2 -18 1 6 -21 c3 -12 9 -39 12 -59 7 -38 20 -49 30 -25 3 8 0 26 -6 42 l-11
28 15 -12 14 -12 -5 19 -5 20 -21 -4 -22 -5 25 13 c14 6 26 13 28 14 2 1 0 23
-3 48 l-7 46 -14 0 c-8 0 -19 -10 -24 -22 l-9 -23 -2 20 c-1 11 -2 26 -2 33 1
6 -3 12 -8 12 -5 0 -9 9 -9 20 0 11 -4 20 -10 20 -5 0 -10 -6 -10 -13z"></path><path d="M5038 3882 c-2 -18 -2 -32 2 -32 11 0 30 -97 30 -151 l0 -49 -11 0
-11 0 7 -57 c4 -32 11 -61 17 -64 l10 -6 -4 -117 c-1 -64 0 -116 5 -116 4 0 7
10 8 23 l1 22 8 -25 9 -25 -1 105 c-1 58 2 121 6 140 l7 35 -16 -19 -15 -20 0
20 c0 10 4 36 9 56 l9 38 -9 -5 -8 -6 3 116 4 116 -12 -4 c-6 -2 -18 9 -27 26
l-16 30 -5 -31z"></path><path d="M5010 3740 l0 -50 10 0 10 0 0 50 0 50 -10 0 -10 0 0 -50z"></path><path d="M4344 3651 c3 -18 6 -42 6 -52 0 -11 5 -19 10 -19 16 0 12 64 -6 86
l-15 19 5 -34z"></path><path d="M4364 3485 c11 -94 17 -125 27 -125 5 0 9 -31 9 -70 l0 -70 -9 0
c-11 0 -17 -29 -27 -119 l-6 -64 10 7 c5 3 13 22 17 42 3 20 13 43 21 51 8 8
14 28 14 44 l0 29 25 0 25 0 0 80 0 80 10 0 10 0 0 -76 0 -75 -16 -16 -16 -16
6 -28 5 -28 -10 -3 -11 -4 7 -37 7 -37 14 0 14 0 0 80 0 80 10 0 10 0 0 80 0
80 -11 0 -12 0 6 80 5 80 -24 0 -24 0 2 -50 c1 -27 6 -50 10 -50 4 0 8 -13 8
-29 l0 -29 -13 -5 c-25 -9 -37 9 -44 61 l-6 51 -11 -2 c-6 -1 -12 9 -14 23 -2
14 -8 30 -14 35 l-10 10 6 -60z"></path><path d="M5010 3320 l0 -210 10 0 10 0 0 210 0 210 -10 0 -10 0 0 -210z"></path><path d="M5080 3260 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5100 3260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5070 3181 c0 -47 -22 -131 -35 -131 l-12 0 -1 -32 c-1 -18 -4 -59
-8 -91 -3 -32 -3 -65 0 -74 l7 -16 10 10 10 10 -9 53 -10 54 14 13 c7 7 19 12
26 11 l13 -3 -3 32 -3 33 16 -6 16 -7 -7 78 -7 78 16 -6 17 -6 0 14 0 15 -25
0 -25 0 0 -29z"></path><path d="M4337 2913 c-10 -49 -20 -100 -23 -115 -4 -16 -2 -28 3 -28 4 0 8
-18 8 -39 l0 -39 -10 6 c-5 3 -4 -2 4 -11 l13 -17 -21 0 -22 0 6 20 6 20 -9 0
c-5 0 -12 -10 -16 -22 -8 -30 -8 -53 1 -45 7 8 33 -11 33 -24 0 -5 -12 -6 -27
-3 l-28 5 -22 -59 c-13 -33 -23 -62 -23 -66 0 -3 11 -5 25 -4 14 1 24 7 24 13
-1 5 -1 28 0 50 l2 40 13 -18 13 -17 17 6 c9 3 16 5 16 3 0 -2 -7 -29 -15 -59
-8 -30 -15 -56 -15 -57 0 -2 7 -3 16 -3 8 0 22 6 30 14 l14 15 0 45 0 46 10 0
c6 0 10 5 10 10 0 6 -9 10 -20 10 -24 0 -26 13 -4 35 l16 16 -6 24 -6 25 11 0
10 0 -3 23 -3 22 -17 -3 c-10 -2 -18 -1 -18 2 0 3 5 17 11 33 6 15 13 55 15
88 2 33 7 79 10 103 l6 42 -9 0 c-4 0 -16 -39 -26 -87z"></path><path d="M4360 2790 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4995 2740 l-18 -20 15 0 c9 0 19 7 22 16 10 25 1 27 -19 4z"></path><path d="M4850 2710 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M4930 2710 c0 -11 -4 -20 -8 -20 -5 0 -17 -18 -27 -40 -10 -22 -24
-40 -32 -40 -7 0 -13 -9 -13 -19 l0 -19 33 -4 33 -4 -4 28 -4 27 16 16 c9 9
20 14 26 10 l10 -6 -7 17 -6 17 11 -7 12 -7 -7 17 -6 17 11 -7 12 -7 0 10 c0
23 -14 41 -31 41 l-19 0 0 -20z"></path><path d="M4873 2545 c0 -8 6 -16 14 -19 15 -6 15 -4 7 18 -8 20 -21 21 -21 1z"></path><path d="M4855 2521 c5 -34 12 -48 19 -40 2 2 -3 20 -10 39 l-14 35 5 -34z"></path><path d="M4190 2465 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4250 2440 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 14 -10 30
-10 17 0 30 2 30 4 0 2 -3 11 -6 20 -7 18 -24 22 -24 6z"></path><path d="M4340 2443 c0 -15 19 -37 26 -31 3 4 -1 14 -10 23 -9 9 -16 13 -16 8z"></path><path d="M4131 2354 c-24 -47 -53 -96 -65 -108 -11 -12 -30 -34 -41 -50 -11
-15 -35 -44 -52 -65 -32 -36 -42 -61 -23 -61 5 0 14 8 19 18 12 20 12 20 17
-37 l4 -43 -15 5 -14 6 6 -15 5 -14 -36 0 -36 0 0 -12 -1 -13 -19 24 -20 23
-35 -35 c-19 -19 -35 -38 -35 -41 0 -3 9 -6 20 -6 l20 0 0 -24 c0 -33 -10 -57
-21 -50 -5 3 -9 -2 -9 -10 l0 -16 -25 0 -25 0 0 -16 c0 -8 7 -22 15 -30 l15
-16 40 42 c22 23 43 53 47 68 l6 26 -11 4 c-20 6 -13 22 8 22 11 0 20 5 20 10
0 6 16 10 35 10 l36 0 35 36 36 35 -4 37 -3 37 62 -3 c35 -2 63 -1 63 3 0 3
-6 13 -14 21 -17 16 -46 19 -46 4 0 -5 -9 -10 -20 -10 l-20 0 0 20 c0 11 0 23
1 26 0 4 -5 10 -12 15 -8 4 -10 11 -6 15 3 4 13 2 20 -4 l13 -11 15 20 c8 12
22 30 32 41 9 12 17 34 17 50 0 15 5 28 10 28 6 0 10 8 10 17 l0 17 10 -10 c6
-6 12 -24 13 -41 1 -17 5 -29 10 -26 11 7 9 -46 -3 -68 l-11 -19 15 -15 c8 -8
17 -15 20 -15 3 0 6 34 6 76 l0 77 -21 18 -20 17 7 37 6 36 13 -17 12 -17 0
19 c0 19 -14 44 -26 44 -3 0 -26 -39 -50 -86z m-16 -94 c3 -5 4 -10 1 -10 -3
0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z m-96 -149 c-15 -5 -22 6 -14
19 l8 12 8 -13 8 -14 -10 -4z m-149 -161 l-15 -9 -3 9 c-2 6 -9 8 -15 4 -7 -3
-3 2 7 10 l20 16 10 -10 11 -11 -15 -9z"></path><path d="M4770 2430 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4830 2422 c0 -5 -15 -9 -32 -10 -18 0 -34 -6 -36 -12 -2 -6 -15 -22
-28 -35 -28 -28 -31 -57 -9 -75 l16 -13 -10 27 -11 26 11 0 c5 0 19 9 31 20
12 10 25 17 29 14 5 -3 6 3 2 12 l-6 17 11 -7 12 -7 -6 14 -5 14 22 -1 c12 -1
24 5 26 12 3 6 0 12 -6 12 -6 0 -11 -4 -11 -8z"></path><path d="M4690 2282 l0 -32 -12 -1 c-7 -1 -19 -1 -25 0 -7 1 -13 -3 -13 -9 0
-5 6 -11 13 -11 6 -1 19 -2 27 -3 8 0 20 3 26 9 l11 10 -8 29 c-12 41 -19 44
-19 8z"></path><path d="M4572 2194 c-18 -20 -9 -40 21 -46 l25 -5 -5 13 c-2 7 0 22 7 33 l11
21 -23 0 c-13 -1 -29 -8 -36 -16z"></path><path d="M4543 2133 c-7 -2 -13 -13 -13 -24 l0 -19 -25 0 -24 0 8 -22 8 -23
22 -3 21 -3 1 13 c1 7 1 19 0 25 l-2 13 13 -5 13 -5 0 30 c0 31 -1 32 -22 23z"></path><path d="M4370 2080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3900 2045 c-25 -26 -21 -47 4 -26 8 7 20 10 25 6 l11 -6 -6 16 -6
15 18 -7 19 -7 -14 17 c-7 9 -17 17 -20 17 -4 0 -18 -11 -31 -25z"></path><path d="M4396 2023 c4 -16 11 -32 17 -38 l10 -10 -6 11 -7 12 15 6 c8 3 21 3
28 1 l12 -5 1 25 2 25 -39 0 -39 0 6 -27z"></path><path d="M4435 1990 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M4371 1963 c3 -21 -3 -31 -21 -36 -11 -3 -20 -10 -20 -16 0 -12 50
-15 51 -3 0 4 3 19 5 35 l5 27 -11 0 c-5 0 -9 -3 -9 -7z"></path><path d="M4020 1940 c0 -5 7 -10 15 -10 l15 0 0 -20 c0 -24 6 -25 26 -6 19 20
18 26 -6 26 -11 0 -20 5 -20 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10z"></path><path d="M3739 1893 c-19 -21 -33 -42 -31 -46 1 -5 -6 -6 -16 -3 l-18 6 -20
-15 -19 -15 18 -11 c9 -5 17 -17 17 -24 0 -21 24 -19 43 3 19 21 22 42 6 42
l-11 0 5 -15 c8 -20 -5 -26 -26 -10 l-17 12 30 13 c16 7 30 18 30 25 l0 12 22
-2 c12 -1 28 3 34 9 l12 11 -12 14 -11 15 19 -7 19 -7 -13 15 c-7 8 -16 15
-20 15 -4 0 -22 -17 -41 -37z"></path><path d="M4245 1890 c-6 -20 -35 -28 -35 -10 0 15 -69 12 -84 -3 -21 -21 -36
-54 -36 -81 l0 -26 -24 0 c-14 0 -28 -4 -31 -10 l-6 -10 18 0 c10 0 28 -3 41
-6 l23 -7 -3 14 -3 14 16 -1 15 -1 -4 30 -5 30 10 -6 c5 -3 16 3 23 14 17 23
36 24 56 4 19 -19 35 -19 28 -1 l-6 15 21 -6 21 -5 -6 17 -7 17 22 -7 23 -7
-12 22 -12 22 24 -13 23 -12 -14 16 c-7 10 -26 17 -42 17 l-28 0 -6 -20z"></path><path d="M3695 1810 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M3561 1780 c-25 -16 -66 -39 -93 -51 -51 -24 -61 -43 -28 -52 35 -9
22 -24 -19 -23 -22 0 -43 -3 -48 -8 l-8 -9 13 -4 c6 -3 12 -9 12 -14 l0 -9
-54 4 -55 5 6 -15 5 -14 55 0 56 0 18 21 18 21 30 -7 31 -7 12 27 12 26 23 -6
23 -5 0 30 c0 32 -14 40 -24 15 -3 -8 -13 -14 -23 -14 l-18 1 18 5 c9 2 15 9
12 14 -4 5 -1 9 5 9 l12 0 -5 14 -6 15 15 -6 14 -5 0 16 0 16 18 0 c9 0 23 9
30 20 l12 20 -12 -1 c-7 0 -33 -13 -57 -29z m-53 -67 c-9 -10 -13 -24 -11 -30
l5 -13 -21 0 c-27 0 -27 12 -1 28 12 7 18 17 15 22 -3 6 2 10 11 10 l17 0 -15
-17z"></path><path d="M3860 1780 c0 -5 7 -10 15 -10 8 0 15 -3 15 -6 0 -13 -30 -34 -50
-34 -11 0 -32 -11 -45 -25 l-25 -24 0 -25 c0 -13 -5 -26 -12 -28 l-12 -4 8
-12 c4 -7 20 -12 36 -12 l28 0 -5 20 -5 19 16 16 16 16 12 -7 11 -7 -6 16 -6
17 19 0 20 0 0 30 0 30 20 0 c11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 5
-20 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10z"></path><path d="M3950 1740 c0 -5 -11 -21 -26 -35 -27 -27 -25 -45 7 -45 l19 0 -2 13
-3 12 17 -1 c10 -1 16 3 15 10 -1 6 6 10 15 10 10 -1 15 2 13 7 -3 4 3 12 12
18 10 6 13 11 8 11 -6 0 -16 -5 -23 -12 l-12 -12 0 17 0 17 -20 0 c-11 0 -20
-4 -20 -10z"></path><path d="M2325 1730 l6 -10 -15 6 -16 6 0 -21 c0 -23 7 -25 44 -11 l25 9 -6
-14 -5 -15 16 0 c9 0 16 -5 16 -11 l0 -12 43 6 c23 3 34 3 25 0 -10 -2 -18 -9
-18 -14 l0 -11 38 3 37 3 -2 -12 -3 -12 25 0 25 0 0 14 0 14 -47 17 c-27 10
-77 31 -112 46 -67 31 -86 35 -76 19z"></path><path d="M3323 1672 l-73 -27 0 -12 c0 -7 -6 -13 -12 -13 -7 0 -24 -3 -37 -7
l-24 -6 8 13 8 13 -34 -6 c-19 -4 -58 -9 -86 -13 l-53 -7 0 -12 0 -12 111 5
112 5 18 16 c38 35 49 41 69 41 11 0 20 4 20 10 0 5 6 7 13 4 7 -2 22 4 32 16
10 11 14 20 9 19 -5 0 -42 -12 -81 -27z"></path><path d="M2572 1635 c4 -5 2 -15 -4 -23 l-12 -14 59 7 58 8 -6 -17 -7 -17 53
7 52 7 -14 -16 -13 -17 15 0 c14 0 27 20 27 40 0 4 -33 12 -72 18 -40 6 -89
14 -108 18 -19 4 -32 3 -28 -1z"></path><path d="M3538 1633 c-27 -7 -22 -23 7 -23 l25 0 0 15 c0 15 -2 16 -32 8z"></path><path d="M2310 1596 l0 -26 16 0 15 0 -3 22 c-2 12 -9 23 -15 26 l-13 4 0 -26z"></path><path d="M3570 1600 l0 -10 -80 0 -80 0 0 -19 0 -20 44 -6 c24 -4 52 -5 62 -2
l19 5 -22 7 c-36 12 -27 25 17 25 l40 0 0 -10 c0 -5 9 -10 20 -10 11 0 19 5
18 11 -2 6 7 15 20 20 l22 8 0 -9 c0 -6 18 -10 41 -10 l40 0 -5 9 c-4 5 0 12
6 14 7 3 -26 6 -74 6 l-88 1 0 -10z"></path><path d="M2826 1585 c4 -8 2 -15 -4 -15 -5 0 -12 -7 -16 -15 l-5 -15 15 0 15
0 -7 11 -7 11 44 -6 c24 -3 38 -3 31 0 -6 3 -10 9 -7 13 2 5 -7 6 -22 3 l-26
-5 6 17 6 16 -14 0 -14 0 5 -15z"></path><path d="M2911 1568 c1 -7 1 -19 0 -26 l-1 -12 -25 6 -25 6 0 -11 0 -11 68 0
67 1 -19 14 -20 15 41 0 41 0 6 -15 5 -15 -17 -1 -17 -1 25 -8 c36 -11 52 -11
45 0 l-6 10 93 2 c51 1 119 1 150 -1 l58 -3 -6 16 -6 16 -29 0 c-16 0 -29 5
-29 10 0 6 -13 10 -30 10 -16 0 -30 -4 -30 -10 l0 -10 -85 0 c-47 0 -85 4 -85
8 0 5 -6 9 -12 9 -7 0 -46 3 -86 7 l-73 7 2 -13z"></path><path d="M2320 1520 l0 -20 15 0 15 0 0 20 0 20 -15 0 -15 0 0 -20z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#8ac12c" stroke="none"><path d="M5750 7237 l-65 -22 3 -32 c2 -18 6 -33 8 -33 3 0 12 7 21 15 l15 16
-5 18 -4 18 19 -5 19 -5 14 19 c8 11 15 14 15 7 l0 -11 20 5 c11 3 20 12 20
19 0 8 -3 14 -7 13 -5 0 -37 -10 -73 -22z"></path><path d="M5800 7175 l0 -36 11 7 c6 3 9 15 6 25 l-5 19 10 -6 11 -7 -5 13 c-2
8 -10 16 -16 18 l-12 4 0 -37z"></path><path d="M5589 7198 c0 -2 -2 -10 -3 -18 l-3 -15 28 -1 c16 -1 26 3 23 7 -3 5
0 9 5 9 6 0 11 5 11 10 0 6 -13 10 -30 10 -16 0 -30 -1 -31 -2z m28 -15 c-4
-3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M5526 7165 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M5425 7146 l-30 -11 -1 -17 -2 -18 19 0 19 0 0 18 0 19 31 -5 32 -5
-5 14 c-6 18 -26 20 -63 5z"></path><path d="M5755 7084 c0 -40 19 -51 23 -13 l3 26 -13 11 -14 11 1 -35z"></path><path d="M5285 7105 c-5 -2 -23 -6 -38 -9 l-28 -7 6 -9 c7 -12 66 -14 64 -2
-3 19 2 23 16 12 l15 -12 0 16 c0 15 -12 19 -35 11z"></path><path d="M5630 7080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5393 7063 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5044 7016 l1 -24 19 10 18 10 -6 -11 c-4 -6 -3 -11 2 -11 10 0 22
23 22 41 0 5 -13 9 -29 9 l-29 0 2 -24z"></path><path d="M5740 7028 c0 -9 -8 -30 -17 -47 -10 -16 -15 -33 -11 -36 12 -12 28
5 28 29 0 12 5 27 12 34 l12 12 -12 12 -12 12 0 -16z"></path><path d="M4904 6992 c3 -6 0 -13 -6 -15 -7 -3 2 -6 20 -6 l34 -1 -4 13 c-3 6
-15 14 -27 15 l-23 4 6 -10z"></path><path d="M4723 6943 l-43 -15 0 -14 c0 -8 1 -14 3 -14 2 0 24 7 50 16 l47 16
0 14 c0 16 -1 16 -57 -3z"></path><path d="M5070 6940 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4764 6908 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4644 6894 l-19 -15 23 11 c12 6 22 13 22 15 0 8 -5 6 -26 -11z"></path><path d="M4806 6895 c-3 -8 -1 -15 4 -15 6 0 10 -4 10 -10 0 -5 -7 -10 -15
-10 l-14 0 6 -17 7 -17 4 12 c7 21 22 13 22 -13 0 -14 5 -25 10 -25 6 0 10 15
10 34 l0 34 -12 6 -13 6 13 6 c18 9 14 24 -7 24 -10 0 -21 -7 -25 -15z"></path><path d="M5675 6870 c3 -6 -1 -17 -10 -26 -9 -9 -14 -21 -13 -26 5 -13 23 -6
23 9 0 7 6 18 13 25 16 16 15 28 -4 28 l-15 0 6 -10z"></path><path d="M4660 6860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4722 6818 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 l-16 4 4 -14z"></path><path d="M4756 6801 c-3 -5 6 -16 20 -26 l26 -17 16 16 16 16 -12 0 c-7 0 -12
-6 -13 -12 0 -7 -5 -3 -11 10 -11 22 -32 29 -42 13z"></path><path d="M5621 6726 c-8 -9 -11 -19 -7 -23 9 -9 29 13 24 27 l-4 12 -13 -16z"></path><path d="M4980 6720 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5524 6705 c-4 -14 -4 -29 0 -32 8 -8 36 17 35 31 l-1 11 -5 -12 c-2
-7 -9 -13 -15 -13 l-9 0 6 20 c3 11 4 20 1 20 -3 0 -8 -11 -12 -25z"></path><path d="M4916 6701 c-3 -5 6 -16 19 -25 26 -17 45 -14 45 6 l0 10 -20 -5 -20
-5 0 14 c0 16 -16 19 -24 5z"></path><path d="M5595 6680 l6 -10 -15 0 c-9 0 -16 -7 -16 -15 0 -8 5 -15 10 -15 6 0
10 5 10 11 0 5 4 8 9 5 5 -3 12 1 15 10 9 22 8 24 -10 24 l-15 0 6 -10z"></path><path d="M4991 6664 l-13 -15 18 -14 c25 -19 34 -18 34 0 0 8 -5 15 -11 15
l-11 0 6 15 c7 19 -5 19 -23 -1z"></path><path d="M5055 6615 c2 -14 -1 -25 -6 -25 -6 0 -7 -4 -4 -10 3 -5 12 -10 19
-10 l13 0 -1 35 -1 35 -11 0 -11 0 2 -25z"></path><path d="M2847 6604 l6 -16 24 5 c13 2 30 -1 36 -8 l12 -12 20 8 20 8 -24 0
-23 1 6 15 5 15 -44 0 -44 0 6 -16z"></path><path d="M3560 6594 l0 -15 23 6 c36 11 39 21 7 23 l-30 2 0 -16z"></path><path d="M3666 6586 l6 -16 39 0 c21 0 39 5 39 10 0 6 -3 10 -7 10 -5 1 -25 3
-45 6 l-38 6 6 -16z"></path><path d="M3080 6580 l0 -10 85 0 85 0 0 10 0 10 -85 0 -85 0 0 -10z"></path><path d="M2576 6565 c4 -8 10 -15 14 -15 4 0 10 7 14 15 l5 15 -19 0 -19 0 5
-15z"></path><path d="M5087 6557 l-5 -13 22 -2 23 -2 0 15 c0 18 -33 20 -40 2z"></path><path d="M2470 6551 l-25 -8 17 -8 c10 -4 15 -11 12 -16 -7 -12 9 -12 22 0 5
5 20 11 34 13 14 1 25 8 25 13 0 13 -52 16 -85 6z"></path><path d="M3884 6543 c2 -10 9 -18 17 -18 7 0 15 8 17 18 l3 17 -19 0 -20 0 2
-17z"></path><path d="M4980 6547 c-9 -7 -14 -20 -12 -29 l3 -18 20 10 c10 6 19 20 19 30 0
23 -8 25 -30 7z"></path><path d="M2396 6525 c-7 -18 5 -20 22 -3 l12 12 0 -17 c0 -9 5 -17 10 -17 l11
0 -11 20 c-13 24 -35 26 -44 5z"></path><path d="M3940 6525 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3980 6520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2326 6507 c3 -8 13 -14 22 -13 25 4 21 20 -5 24 l-23 3 6 -14z"></path><path d="M4044 6502 l0 -18 22 4 23 4 -6 14 c-3 8 -13 14 -22 14 l-17 0 0 -18z"></path><path d="M5005 6485 c-16 -19 -34 -34 -40 -32 -5 2 -16 -3 -24 -10 l-14 -14
11 -11 10 -10 23 13 c36 19 91 77 84 89 -3 5 -9 10 -13 10 -4 0 -20 -16 -37
-35z"></path><path d="M5144 6498 c3 -13 6 -26 6 -30 0 -5 5 -8 10 -8 6 0 10 14 10 30 l0
30 -16 0 -16 0 6 -22z"></path><path d="M4882 6481 l-43 -28 15 -12 15 -13 21 7 21 7 -5 14 -5 14 15 0 16 0
-5 14 -6 15 15 -6 14 -5 0 11 c0 18 -23 12 -68 -18z"></path><path d="M5516 6494 c-7 -19 2 -37 15 -29 12 8 12 45 0 45 -5 0 -12 -7 -15
-16z"></path><path d="M4120 6485 l0 -15 20 0 c24 0 27 15 4 24 -22 9 -24 8 -24 -9z"></path><path d="M2176 6471 c-3 -4 -19 -11 -36 -14 -16 -4 -30 -11 -30 -16 0 -9 70
-19 89 -13 6 2 11 15 11 28 l0 24 -14 0 c-8 0 -17 -4 -20 -9z"></path><path d="M4196 6466 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 l-12 4 6 -16z"></path><path d="M2360 6460 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4280 6431 c0 -17 18 -21 24 -6 l5 15 -14 0 c-8 0 -15 -4 -15 -9z"></path><path d="M2245 6420 l-6 -10 25 0 c14 0 26 5 26 10 0 6 -9 10 -19 10 -11 0
-23 -4 -26 -10z"></path><path d="M4773 6413 c-17 -6 -16 -23 1 -23 8 0 16 7 20 15 6 15 1 17 -21 8z"></path><path d="M5197 6412 l-8 -12 16 -50 c9 -28 20 -50 23 -50 4 0 13 4 21 9 l14 9
-14 16 c-32 35 -37 44 -41 67 l-3 24 -8 -13z"></path><path d="M4210 6400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5270 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5360 6379 l0 -30 16 16 c9 8 13 22 10 30 -10 26 -26 16 -26 -16z"></path><path d="M2007 6384 l6 -15 -27 7 -26 6 0 -11 c0 -6 7 -14 16 -18 18 -7 64 12
64 27 0 6 -4 8 -10 5 -5 -3 -10 -1 -10 4 0 6 -4 11 -10 11 -5 0 -7 -7 -3 -16z"></path><path d="M4267 6376 l-18 -14 10 -6 c11 -7 51 14 51 26 0 12 -23 9 -43 -6z"></path><path d="M4880 6379 l0 -10 -12 7 -11 7 6 -16 6 -17 -11 0 -12 0 12 -12 11
-11 16 13 c17 14 21 50 5 50 -5 0 -10 -5 -10 -11z"></path><path d="M4082 6345 c-7 -14 -12 -28 -12 -31 0 -2 14 5 30 18 34 25 38 38 12
38 l-19 0 -11 -25z"></path><path d="M5453 6355 c-7 -8 -13 -23 -13 -35 l0 -20 14 0 c7 0 16 10 20 23 12
47 3 60 -21 32z"></path><path d="M4680 6340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4185 6328 l-7 -18 -23 0 -23 0 -6 -16 c-16 -41 5 -46 47 -10 l29 25
-5 18 -4 18 -8 -17z"></path><path d="M4820 6319 l0 -20 -12 7 -11 7 7 -11 7 -12 20 10 c23 13 25 26 4 34
l-15 5 0 -20z"></path><path d="M5310 6310 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1747 6294 c-12 -12 4 -24 32 -24 l28 0 0 15 0 15 -27 0 c-15 0 -30
-3 -33 -6z"></path><path d="M4603 6293 c-18 -7 -16 -23 2 -23 8 0 15 7 15 15 0 8 -1 15 -2 14 -2
0 -9 -3 -15 -6z"></path><path d="M2013 6275 c-7 -8 -13 -17 -13 -20 0 -3 14 -5 30 -5 17 0 30 3 30 6
0 6 -27 34 -32 34 -2 -1 -9 -7 -15 -15z"></path><path d="M4776 6274 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M4070 6263 l11 -23 19 0 c22 0 27 15 8 26 l-12 8 -4 -13 -4 -12 -15
18 -14 18 11 -22z"></path><path d="M4655 6260 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M4730 6239 l-12 -21 19 4 c10 2 19 11 21 21 5 24 -15 21 -28 -4z"></path><path d="M5327 6244 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M5423 6253 c-16 -6 -17 -33 -3 -33 11 0 26 28 19 34 -2 2 -10 1 -16
-1z"></path><path d="M3950 6237 l0 -9 25 4 24 5 -5 -9 c-3 -5 0 -15 6 -23 l12 -15 -15 0
c-17 0 -43 -28 -34 -37 7 -7 107 55 107 67 0 4 -6 10 -14 13 l-14 6 -6 -21 -5
-21 -15 12 c-8 7 -12 19 -10 26 l6 14 -31 -1 c-17 -1 -31 -5 -31 -11z"></path><path d="M4574 6238 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4685 6209 c-8 -23 -1 -34 14 -24 13 8 15 45 2 45 -5 0 -13 -9 -16
-21z"></path><path d="M3903 6213 c-15 -5 -18 -33 -4 -33 5 0 11 9 14 20 3 11 4 20 4 19 -1
0 -8 -3 -14 -6z"></path><path d="M4480 6205 c-7 -8 -19 -15 -26 -15 -8 0 -19 -9 -26 -20 -7 -11 -18
-20 -25 -20 -7 0 -13 -7 -13 -15 0 -8 5 -15 10 -15 6 0 13 7 16 15 4 8 12 15
19 15 7 0 15 6 18 14 l6 15 30 -4 30 -4 7 11 7 11 -15 -6 -16 -6 5 20 c6 23
-9 25 -27 4z"></path><path d="M5260 6217 c0 -4 4 -15 9 -25 5 -9 12 -25 15 -34 3 -10 10 -18 16
-18 18 0 11 26 -15 56 -14 16 -25 26 -25 21z"></path><path d="M5392 6185 c-7 -14 -12 -34 -12 -43 0 -18 -33 -52 -50 -52 -14 0 -13
-17 2 -26 l12 -8 4 12 c2 7 8 10 13 6 5 -3 12 3 15 13 3 10 15 37 25 60 10 24
19 47 19 53 0 19 -17 10 -28 -15z"></path><path d="M1537 6174 c-8 -8 1 -24 13 -24 4 0 10 7 14 15 l5 15 -13 0 c-7 0
-16 -3 -19 -6z"></path><path d="M4618 6154 l4 -24 -11 0 c-7 0 -9 -5 -6 -10 l7 -11 14 12 c18 15 18
45 1 52 l-14 5 5 -24z"></path><path d="M1615 6149 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1520 6141 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M4441 6113 l-1 -23 25 0 c14 0 25 5 25 10 0 6 -9 10 -19 10 -11 0
-22 6 -24 13 -3 6 -6 2 -6 -10z"></path><path d="M4347 6113 c-13 -12 -7 -23 13 -23 l19 0 -5 15 c-7 16 -17 19 -27 8z"></path><path d="M4548 6096 c-22 -16 -23 -36 -3 -36 l14 0 -5 15 -6 15 16 -6 16 -6
-6 16 c-3 9 -7 16 -8 16 0 0 -9 -7 -18 -14z"></path><path d="M1430 6084 c0 -17 14 -18 28 -1 l11 13 -20 1 -19 2 0 -15z"></path><path d="M1362 6060 c-12 -11 -26 -20 -32 -20 -5 0 -10 -7 -10 -15 0 -18 4
-18 33 -3 12 6 29 14 37 18 8 4 16 15 18 24 l3 16 -13 0 c-8 0 -24 -9 -36 -20z"></path><path d="M3782 6034 l-34 -34 16 0 c9 0 16 5 16 10 0 6 6 10 13 10 14 0 47 31
47 43 0 16 -28 3 -58 -29z"></path><path d="M4269 6053 c-1 -5 -1 -11 0 -15 0 -5 -6 -8 -15 -8 -9 0 -29 -8 -44
-17 l-28 -16 1 -18 1 -17 13 5 c7 3 10 9 7 14 -3 5 2 9 10 9 9 0 16 5 16 10 0
6 7 10 15 10 13 0 39 12 65 28 5 4 -21 22 -32 22 -5 0 -9 -3 -9 -7z"></path><path d="M5040 6026 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"></path><path d="M4366 6014 l-6 -15 11 7 c6 4 15 0 20 -7 4 -7 11 -10 15 -7 6 6 -18
38 -29 38 -3 0 -8 -7 -11 -16z"></path><path d="M4468 6016 c-10 -7 -15 -16 -11 -19 10 -11 25 -8 18 3 l-6 10 20 0
c12 0 21 5 21 10 0 14 -22 12 -42 -4z"></path><path d="M1418 5999 c-24 -13 -23 -29 2 -29 11 0 20 2 20 4 0 2 3 11 6 20 7
18 -3 20 -28 5z"></path><path d="M3676 5994 c-7 -17 0 -34 14 -34 5 0 7 6 4 14 l-6 15 21 -6 21 -5 -6
16 c-8 21 -40 21 -48 0z"></path><path d="M1253 5983 c-13 -8 -23 -20 -23 -25 0 -5 10 -2 21 6 12 8 28 12 37 9
l15 -6 -5 13 c-6 20 -20 21 -45 3z"></path><path d="M4906 5977 l-29 -24 7 -11 7 -12 20 19 20 19 22 -5 22 -4 -17 14
c-10 8 -15 17 -11 20 3 4 2 7 -3 7 -5 0 -22 -11 -38 -23z"></path><path d="M4430 5976 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"></path><path d="M5030 5980 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5110 5965 c0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 4 -10 8
-10 15 0 62 25 62 33 0 5 -4 5 -10 2 -5 -3 -10 -1 -10 4 0 6 -4 11 -10 11 -5
0 -10 -7 -10 -15z"></path><path d="M4985 5960 l-6 -10 20 0 c12 0 21 5 21 10 0 6 -6 10 -14 10 -8 0 -18
-4 -21 -10z"></path><path d="M1294 5948 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4112 5939 c-12 -11 -20 -24 -17 -29 3 -5 -6 -10 -20 -12 -38 -5 -35
-23 4 -26 l34 -3 -5 23 -5 24 21 0 21 -1 3 23 c2 12 -1 22 -5 22 -5 0 -19 -9
-31 -21z"></path><path d="M1215 5930 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M3450 5920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3590 5921 c0 -15 16 -22 34 -15 25 9 19 24 -9 24 -14 0 -25 -4 -25
-9z"></path><path d="M4280 5914 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M4366 5915 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M1196 5905 l5 -12 -30 5 -31 4 -6 -16 c-9 -24 1 -30 19 -11 21 20 27
19 27 -5 l0 -20 16 0 c19 0 44 24 44 43 l0 15 -24 4 -24 5 4 -12z m31 -12 c-4
-3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z"></path><path d="M3550 5885 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4788 5873 c-14 -16 -28 -36 -31 -46 -4 -9 -12 -16 -18 -15 -6 2 -13
-5 -14 -14 -2 -10 -2 -18 -1 -18 2 0 15 11 30 25 l26 24 17 -6 16 -6 -7 12 -8
12 15 12 c8 7 17 20 20 30 l6 17 -12 0 c-7 0 -25 -12 -39 -27z"></path><path d="M5215 5890 c-7 -11 18 -40 34 -40 5 0 16 -9 24 -20 l15 -21 6 17 7
17 -33 28 c-18 16 -36 28 -40 29 -4 0 -10 -5 -13 -10z"></path><path d="M4312 5870 c-12 -31 -6 -39 13 -19 l14 14 -9 15 -9 15 -9 -25z"></path><path d="M3502 5858 c-7 -7 -12 -18 -12 -26 l0 -13 23 7 c29 9 29 8 21 28 -7
19 -16 20 -32 4z"></path><path d="M3979 5842 l-22 -19 11 -7 12 -7 0 10 c0 6 9 11 20 11 11 0 20 4 20
9 0 19 -19 20 -41 3z"></path><path d="M4207 5844 c6 -15 23 -19 23 -5 0 5 -7 11 -14 14 l-15 6 6 -15z"></path><path d="M3410 5840 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M1071 5822 c-19 -9 -35 -25 -37 -34 -2 -20 11 -24 21 -8 4 6 11 7 17
4 l11 -7 -7 12 -8 12 21 6 c12 3 21 12 21 19 0 8 -1 14 -2 14 -2 -1 -18 -9
-37 -18z"></path><path d="M4247 5824 c-14 -14 -7 -25 13 -22 12 2 21 8 21 13 0 12 -24 18 -34
9z"></path><path d="M4026 5793 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M5310 5800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1140 5780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4685 5780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4047 5763 c6 -14 43 -18 43 -4 0 5 -11 11 -24 13 l-24 5 5 -14z"></path><path d="M3330 5760 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5350 5756 l0 -15 20 2 c11 1 20 7 20 14 0 7 -9 13 -20 13 l-20 0 0
-14z"></path><path d="M958 5732 c-16 -15 -28 -35 -28 -45 0 -23 17 -22 25 3 3 11 11 20 16
20 5 0 9 7 9 15 0 8 5 15 10 15 6 0 10 5 10 10 0 16 -13 11 -42 -18z"></path><path d="M3870 5725 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4157 5720 l-5 -20 13 0 14 0 -2 20 c-1 11 -4 20 -8 20 -4 0 -9 -9
-12 -20z"></path><path d="M4646 5725 l6 -15 -16 6 -15 6 -16 -16 c-9 -9 -13 -16 -8 -16 4 0 -4
-8 -18 -18 -26 -17 -29 -42 -4 -42 7 0 15 -6 18 -12 l4 -13 9 8 8 8 -14 10
-15 9 10 8 c6 4 10 13 11 20 2 29 5 33 14 27 6 -4 17 -1 25 5 l14 12 13 -8 13
-8 -12 16 c-7 8 -10 18 -6 21 3 4 -1 7 -10 7 l-16 0 5 -15z"></path><path d="M5393 5733 c-18 -7 -16 -23 1 -23 8 0 21 -8 28 -18 7 -10 21 -21 31
-25 l17 -6 0 12 c0 13 -51 68 -61 66 -2 0 -10 -3 -16 -6z"></path><path d="M3801 5704 l-23 -16 10 -10 10 -10 21 18 c37 32 23 46 -18 18z"></path><path d="M5341 5693 c-18 -20 -6 -29 14 -10 8 9 12 18 8 20 -5 3 -14 -2 -22
-10z"></path><path d="M3300 5691 c-8 -3 -16 -12 -17 -18 -4 -19 27 -16 34 2 7 20 2 24 -17
16z"></path><path d="M4110 5670 c-8 -11 -23 -20 -33 -20 l-18 0 6 -10 c9 -15 27 -12 54
11 l24 20 -9 9 -10 10 -14 -20z"></path><path d="M882 5657 c-27 -29 -28 -47 -2 -47 l20 0 0 20 c0 11 4 20 8 20 5 0 9
7 9 15 0 21 -10 19 -35 -8z"></path><path d="M3743 5663 c-27 -19 -30 -33 -5 -33 24 1 55 26 47 39 -8 14 -15 13
-42 -6z"></path><path d="M3250 5646 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M5408 5636 c-19 -14 -23 -26 -10 -26 4 0 13 9 20 20 14 23 13 24 -10
6z"></path><path d="M4530 5625 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M5490 5620 c6 -11 15 -20 19 -20 5 0 11 -9 14 -20 3 -11 12 -20 19
-20 21 0 18 32 -4 38 l-18 4 0 19 0 19 -20 0 -21 0 11 -20z"></path><path d="M970 5620 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4650 5620 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4036 5604 l-19 -18 13 -4 c15 -5 33 13 28 28 l-4 13 -18 -19z"></path><path d="M3080 5600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3671 5597 c-11 -14 -6 -27 10 -27 5 0 13 9 19 20 l11 20 -14 0 c-8
0 -20 -6 -26 -13z"></path><path d="M3140 5577 c-16 -13 -25 -37 -12 -37 4 0 16 7 26 15 l20 15 -10 9 -9
10 -15 -12z"></path><path d="M4000 5575 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M4446 5554 l-40 -35 13 -9 13 -8 18 17 c9 9 15 19 13 24 -2 4 3 7 12
7 l17 0 -5 14 -6 14 20 -5 19 -5 0 11 c0 22 -36 10 -74 -25z"></path><path d="M3694 5554 c3 -9 6 -18 6 -20 0 -2 7 -4 15 -4 19 0 19 7 1 26 -19 19
-30 18 -22 -2z"></path><path d="M3975 5540 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M777 5533 c-11 -10 -8 -43 3 -43 6 0 10 5 10 11 0 5 4 8 8 5 4 -2 8
4 8 15 0 19 -16 26 -29 12z"></path><path d="M5589 5533 c0 -5 -2 -16 -4 -26 -2 -9 1 -15 6 -11 5 3 9 0 9 -7 l0
-11 15 12 15 12 -11 19 c-10 19 -28 26 -30 12z"></path><path d="M2920 5520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3890 5520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3940 5515 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M3550 5496 l0 -14 24 2 23 1 -4 13 c-3 6 -14 12 -24 12 l-19 0 0 -14z"></path><path d="M3706 5495 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M3920 5501 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M737 5493 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"></path><path d="M3625 5490 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M4363 5488 c-9 -16 -13 -38 -6 -38 9 0 33 29 33 39 0 15 -17 14 -27
-1z"></path><path d="M3495 5460 c-10 -11 -16 -23 -12 -26 11 -11 45 7 50 26 l5 20 -12 0
c-7 0 -21 -9 -31 -20z"></path><path d="M805 5460 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M3895 5460 l6 -10 -15 0 c-9 0 -16 -5 -16 -11 0 -5 -4 -7 -10 -4
l-10 6 0 -15 0 -16 -21 0 c-11 0 -18 -4 -15 -8 8 -13 -23 -52 -40 -52 -18 0
-14 -22 5 -28 l12 -4 6 21 c3 12 10 21 16 21 13 0 97 87 97 101 0 5 -5 9 -11
9 -5 0 -7 -4 -4 -10z"></path><path d="M4450 5460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5560 5460 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4313 5442 c-2 -10 -14 -22 -26 -27 -12 -6 -27 -20 -33 -32 -6 -13
-14 -20 -17 -16 -4 3 -7 1 -7 -5 0 -7 7 -12 14 -12 18 0 72 49 68 61 -1 5 5
12 13 15 17 7 21 34 4 34 -6 0 -13 -8 -16 -18z"></path><path d="M2810 5440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2950 5434 l0 -15 17 3 c9 2 18 9 21 16 l4 12 -21 0 -21 0 0 -16z"></path><path d="M704 5427 c-6 -7 -8 -19 -6 -26 l5 -14 -14 9 -14 8 14 -17 c20 -25
44 -21 58 9 l12 26 -15 -6 -14 -5 0 14 c0 18 -11 19 -26 2z"></path><path d="M2903 5416 c-26 -14 -20 -26 12 -26 27 0 35 8 28 26 -6 16 -10 16
-40 0z"></path><path d="M3730 5405 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"></path><path d="M3442 5408 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"></path><path d="M5687 5399 c4 -12 14 -24 25 -26 10 -3 18 -12 18 -19 0 -8 7 -14 15
-14 8 0 15 3 15 7 0 14 -52 73 -65 73 l-13 0 5 -21z"></path><path d="M820 5380 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"></path><path d="M2760 5375 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2836 5355 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M3369 5353 c-1 -5 -1 -11 0 -15 0 -5 -7 -8 -17 -8 -10 0 -23 -5 -30
-12 -12 -12 -17 -48 -6 -48 3 0 17 14 31 30 13 17 31 30 39 30 8 0 14 7 14 15
0 15 -29 22 -31 8z"></path><path d="M5750 5323 c0 -11 20 -40 20 -29 0 5 7 3 15 -4 l15 -12 0 14 c0 8 -5
19 -12 26 -13 13 -38 16 -38 5z"></path><path d="M4187 5314 c-8 -8 1 -24 13 -24 4 0 10 7 14 15 l5 15 -13 0 c-7 0
-16 -3 -19 -6z"></path><path d="M3450 5300 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4270 5300 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5650 5300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4153 5280 c-3 -11 -9 -20 -14 -20 -5 0 -9 -7 -9 -15 0 -22 17 -18
23 6 3 12 10 18 15 15 11 -7 24 19 15 28 -12 12 -25 6 -30 -14z"></path><path d="M660 5280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M550 5240 c-6 -11 -8 -25 -4 -31 4 -6 1 -15 -6 -19 -7 -4 -10 -14 -8
-21 l5 -14 15 30 c9 17 20 29 25 27 4 -1 9 9 11 23 l3 25 -15 0 c-8 0 -20 -9
-26 -20z"></path><path d="M3267 5240 c3 -11 9 -20 13 -20 4 0 10 9 13 20 l5 20 -18 0 -18 0 5
-20z"></path><path d="M3678 5249 c-10 -5 -18 -19 -18 -29 l0 -19 -17 6 -16 6 7 -11 c4 -7
11 -12 17 -12 13 0 42 36 45 55 1 8 2 15 1 15 -1 0 -10 -5 -19 -11z"></path><path d="M3380 5240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4170 5225 c7 -8 17 -15 22 -15 l10 0 -12 15 c-7 8 -17 15 -22 15
l-10 0 12 -15z"></path><path d="M5833 5220 c1 -11 7 -20 14 -20 l14 0 -11 20 c-6 11 -13 20 -15 20
-2 0 -3 -9 -2 -20z"></path><path d="M3173 5203 c-13 -9 -23 -21 -23 -28 l0 -13 20 5 c10 3 25 12 31 20
l12 14 -9 8 -9 9 -22 -15z"></path><path d="M4083 5195 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"></path><path d="M3604 5179 c0 -10 6 -19 13 -19 21 0 24 19 5 29 l-18 9 0 -19z"></path><path d="M4190 5180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4102 5158 c3 -7 13 -15 24 -17 l18 -3 -13 16 c-16 19 -34 21 -29 4z"></path><path d="M5817 5156 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 l-4 18 -7 -19z"></path><path d="M3227 5144 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M483 5143 c-16 -6 -17 -33 -2 -33 15 0 39 26 32 34 -7 7 -12 7 -30
-1z"></path><path d="M2120 5120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5903 5093 c4 -27 30 -53 42 -41 8 8 -26 68 -37 68 -5 0 -7 -12 -5
-27z"></path><path d="M450 5085 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3504 5061 c-10 -17 -26 -31 -35 -31 -9 0 -20 -7 -23 -16 -17 -44 4
-45 50 -2 19 18 32 38 30 45 l-5 12 16 -6 16 -6 -5 13 c-8 25 -26 21 -44 -9z"></path><path d="M4280 5080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3027 5073 c-13 -12 -7 -23 13 -23 l19 0 -5 15 c-7 16 -17 19 -27 8z"></path><path d="M430 5060 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M3060 5025 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M410 5015 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M5957 5016 l-7 -17 14 -15 c8 -8 17 -14 20 -14 11 0 6 26 -7 44 l-14
19 -6 -17z"></path><path d="M5870 5001 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M2970 4980 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3050 4980 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5850 4980 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M390 4965 c0 -8 5 -15 11 -15 5 0 7 -5 4 -10 -4 -6 -11 -8 -16 -5
l-9 6 6 -15 6 -14 19 16 c10 9 19 20 19 24 0 4 -9 12 -20 18 l-20 11 0 -16z"></path><path d="M3300 4960 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3330 4960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3410 4955 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M2898 4915 c2 -14 8 -25 13 -25 5 0 9 11 9 25 l0 25 -14 0 -13 0 5
-25z"></path><path d="M3357 4917 c-7 -19 8 -41 22 -33 6 4 11 16 11 27 l0 19 -14 0 c-8 0
-16 -6 -19 -13z"></path><path d="M356 4913 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M2855 4911 c-10 -17 12 -21 25 -6 l12 15 -15 0 c-9 0 -18 -4 -22 -9z"></path><path d="M6016 4911 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4505 4890 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M3336 4875 c-9 -23 -8 -25 9 -25 17 0 19 9 6 28 l-8 14 -7 -17z"></path><path d="M340 4866 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M2790 4862 l-23 -19 7 -11 c10 -17 26 -15 26 3 0 8 7 15 15 15 8 0
15 7 15 15 0 20 -13 19 -40 -3z"></path><path d="M6039 4878 c-6 -12 -5 -48 1 -48 8 0 20 25 20 41 0 8 -18 13 -21 7z"></path><path d="M305 4819 c-4 -11 -4 -23 0 -27 7 -7 25 17 25 36 0 20 -18 14 -25 -9z"></path><path d="M400 4820 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2820 4819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3270 4815 l-12 -15 16 -6 c16 -6 46 11 46 27 0 15 -37 10 -50 -6z
m25 -5 c3 -5 4 -10 1 -10 -3 0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z"></path><path d="M6058 4796 l-5 -24 18 -5 18 -5 -1 -31 c-1 -35 5 -46 21 -36 17 10
13 53 -5 63 -9 5 -17 20 -18 32 0 13 -6 25 -12 27 l-12 4 -4 -25z"></path><path d="M3165 4800 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M343 4772 c0 -12 5 -22 9 -22 11 0 10 12 -2 30 l-9 15 2 -23z"></path><path d="M3245 4769 c-8 -23 -1 -34 14 -24 13 8 15 45 2 45 -5 0 -13 -9 -16
-21z"></path><path d="M2665 4740 c-21 -22 -39 -49 -40 -60 l-2 -20 18 0 17 0 7 36 6 36 18
-5 19 -5 17 21 17 20 -13 8 c-8 5 -16 9 -19 9 -3 0 -23 -18 -45 -40z m45 0 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"></path><path d="M4670 4760 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M276 4753 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M3184 4732 c-4 -9 -9 -25 -11 -35 l-3 -18 17 3 18 4 -1 17 c-1 9 2
14 7 11 14 -8 11 6 -5 21 l-14 14 -8 -17z"></path><path d="M6008 4684 c2 -19 8 -34 13 -34 14 0 10 57 -4 63 l-13 5 4 -34z"></path><path d="M6117 4679 l-5 -20 11 4 c15 5 22 37 9 37 -5 0 -12 -9 -15 -21z"></path><path d="M226 4647 c-20 -45 -18 -70 5 -75 l16 -3 4 18 c3 10 2 36 -1 58 l-6
40 -18 -38z m24 -41 c0 -3 -5 -8 -12 -12 l-11 -7 7 11 c7 12 16 16 16 8z"></path><path d="M3080 4661 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M3105 4610 l6 -20 -13 0 c-7 0 -21 -12 -31 -25 l-18 -25 13 0 c7 0
25 13 41 29 l27 29 -6 16 c-3 9 -10 16 -16 16 l-9 0 6 -20z"></path><path d="M2502 4584 l-34 -35 6 -16 7 -16 33 36 c19 21 42 37 51 37 l17 0 -12
15 c-7 8 -18 15 -24 14 -6 0 -26 -16 -44 -35z"></path><path d="M6155 4598 c1 -38 15 -68 32 -68 l15 0 -5 13 c-2 6 -10 11 -17 9
l-12 -3 6 19 c7 20 -1 52 -13 52 -4 0 -7 -10 -6 -22z"></path><path d="M4787 4576 l-6 -15 15 6 c7 3 14 9 14 14 0 14 -17 10 -23 -5z"></path><path d="M3014 4538 c-13 -20 -12 -47 2 -52 l13 -5 -6 16 -6 16 11 -7 12 -7 0
20 c0 24 -16 36 -26 19z"></path><path d="M196 4525 l6 -16 -13 3 c-7 2 -14 -5 -16 -14 l-4 -16 20 5 21 5 0 19
c0 10 -4 21 -10 24 l-10 6 6 -16z"></path><path d="M282 4510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path><path d="M6177 4513 c-12 -11 -8 -23 8 -23 l15 0 0 -46 0 -45 18 3 c9 2 17 10
17 18 0 8 -6 14 -13 12 l-14 -3 7 20 c3 11 3 31 -1 45 -6 25 -23 34 -37 19z"></path><path d="M4840 4475 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M2385 4460 l-19 -20 11 -11 c5 -5 17 -7 25 -4 l16 6 -2 25 c0 13 -4
24 -7 24 -3 0 -14 -9 -24 -20z"></path><path d="M4903 4440 c2 -8 -3 -21 -12 -28 l-16 -13 14 7 14 7 -6 -15 -6 -16
20 5 c10 3 19 8 19 12 -1 3 -8 17 -16 31 -8 14 -13 18 -11 10z"></path><path d="M6130 4440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1495 4430 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2945 4430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M140 4405 c-5 -14 -13 -37 -16 -51 l-7 -26 19 4 19 3 3 28 c2 15 8
27 13 27 5 0 9 9 9 20 l0 20 -15 0 c-9 0 -20 -11 -25 -25z"></path><path d="M2330 4398 c-5 -13 -9 -20 -9 -16 -1 10 -91 -73 -91 -84 0 -4 9 -8
20 -8 l19 0 -6 17 -6 16 13 -8 13 -8 -7 11 -7 12 15 0 c9 0 16 5 16 10 0 6 9
10 19 10 19 0 32 19 36 53 2 25 -14 21 -25 -5z"></path><path d="M2940 4394 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M6236 4354 c-4 -13 -4 -30 0 -36 l7 -11 -11 7 -12 7 0 -13 c1 -16 35
-58 47 -58 5 0 10 11 11 26 l2 25 -11 -7 -11 -7 7 23 7 23 -9 -6 -10 -6 4 23
c3 13 0 26 -5 30 l-10 6 -6 -26z"></path><path d="M2760 4320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2849 4288 c-14 -36 -34 -60 -42 -52 -2 3 -7 -5 -10 -16 l-5 -21 15
3 c20 4 86 95 78 108 -11 18 -22 10 -36 -22z"></path><path d="M114 4276 l7 -28 -10 7 -11 6 7 -16 6 -17 8 14 c12 19 11 46 -2 54
l-12 7 7 -27z"></path><path d="M2210 4280 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M2260 4260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6170 4260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6284 4209 c-3 -6 -1 -16 5 -22 l11 -11 0 22 c0 24 -5 28 -16 11z"></path><path d="M2150 4182 c0 -5 7 -9 15 -9 8 0 15 4 15 9 0 4 -7 8 -15 8 -8 0 -15
-4 -15 -8z"></path><path d="M74 4155 c-3 -14 -4 -39 0 -56 l6 -30 -9 5 c-5 3 -11 -3 -14 -14 l-5
-20 13 0 c14 0 24 30 32 98 l5 42 -11 0 c-6 0 -13 -11 -17 -25z"></path><path d="M2098 4114 c2 -13 8 -24 13 -24 14 0 10 37 -4 43 l-14 5 5 -24z"></path><path d="M2704 4115 l5 -15 -19 -10 -20 -11 10 -19 c6 -11 14 -20 18 -20 4 0
15 15 25 32 l19 32 -11 4 c-6 2 -9 8 -5 13 3 5 -2 9 -11 9 l-17 0 6 -15z"></path><path d="M2030 4082 c-37 -44 -43 -53 -38 -57 7 -7 43 26 57 53 14 26 2 29
-19 4z"></path><path d="M6318 4079 c-3 -13 -3 -49 1 -79 l7 -55 7 55 8 56 -9 24 -9 24 -5
-25z"></path><path d="M2050 4020 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2660 4019 l0 -10 -20 6 -20 6 0 -10 c0 -6 5 -11 11 -11 5 0 8 -4 5
-8 -10 -16 29 -9 41 7 17 22 16 31 -2 31 -8 0 -15 -5 -15 -11z"></path><path d="M102 3985 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"></path><path d="M2540 4000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M81 3944 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1910 3939 c-6 -11 -13 -30 -15 -42 l-4 -22 12 16 c7 9 17 14 22 12
6 -1 11 11 12 27 3 35 -11 39 -27 9z"></path><path d="M2440 3900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2537 3893 c-4 -3 -7 -15 -7 -25 l0 -18 -15 0 c-8 0 -15 -5 -15 -11
l0 -11 16 6 c9 3 19 6 22 6 4 0 7 14 7 30 0 17 0 30 -1 30 0 0 -4 -3 -7 -7z"></path><path d="M1856 3871 c-4 -5 -3 -17 1 -27 l6 -17 -11 7 -12 7 0 -14 c0 -15 29
-33 31 -19 0 4 2 15 4 25 2 9 -1 17 -7 17 l-10 0 12 15 12 15 -10 0 c-6 0 -13
-4 -16 -9z"></path><path d="M5100 3824 l0 -25 12 7 11 7 -6 -19 -7 -19 14 19 15 18 -12 19 c-16
26 -27 24 -27 -7z"></path><path d="M5130 3840 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M18 3800 c-4 -14 -8 -34 -8 -44 l0 -20 11 11 c6 6 9 26 7 44 l-3 34
-7 -25z"></path><path d="M1240 3785 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 l-15
5 0 -14z"></path><path d="M1786 3781 c-9 -10 -19 -30 -22 -45 l-4 -26 10 0 c5 0 9 6 8 13 -2 6
3 11 11 9 l13 -3 -5 15 c-3 8 1 20 9 26 15 14 19 30 6 30 -5 0 -17 -8 -26 -19z"></path><path d="M6365 3785 c-3 -8 -7 -22 -10 -30 l-4 -15 14 0 15 0 0 30 c0 32 -8
40 -15 15z"></path><path d="M2440 3770 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1220 3740 l0 -22 13 5 c6 2 12 10 12 17 0 7 -6 15 -12 18 l-13 4 0
-22z"></path><path d="M2483 3745 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"></path><path d="M6290 3710 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1713 3683 c-4 -9 -15 -25 -25 -35 -21 -21 -16 -38 11 -38 l18 0 -1
23 c-1 12 4 32 12 45 l14 22 -11 0 c-6 0 -15 -8 -18 -17z"></path><path d="M2428 3684 c-8 -7 2 -44 12 -44 8 0 20 24 20 41 0 10 -23 12 -32 3z"></path><path d="M6333 3650 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"></path><path d="M5150 3640 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1107 3620 c-3 -14 -6 -27 -6 -29 0 -2 -5 -7 -12 -12 l-13 -8 17 -7
17 -6 15 12 c17 14 20 40 6 40 -5 0 -11 8 -13 18 l-4 17 -7 -25z"></path><path d="M2400 3615 l13 -15 -17 6 -16 6 0 -11 c0 -12 23 -15 33 -5 8 8 -3 34
-15 34 l-10 0 12 -15z"></path><path d="M1000 3540 c0 -16 4 -30 8 -30 4 0 9 14 10 30 l2 30 -10 0 c-5 0 -10
-13 -10 -30z"></path><path d="M1715 3540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1057 3520 l-5 -20 13 0 14 0 -2 20 c-1 11 -4 20 -8 20 -4 0 -9 -9
-12 -20z"></path><path d="M1595 3529 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"></path><path d="M6380 3456 l0 -85 10 -6 10 -6 0 85 0 85 -10 6 -10 6 0 -85z"></path><path d="M2280 3520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2346 3514 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M6330 3450 l0 -80 -10 0 -10 0 0 -80 0 -80 10 0 10 0 0 80 0 80 10 0
10 0 0 80 0 80 -10 0 -10 0 0 -80z"></path><path d="M1565 3479 c-10 -10 -15 -22 -10 -27 4 -4 10 -1 12 6 l5 12 34 0 34
0 0 13 0 12 -29 2 -29 2 -17 -20z"></path><path d="M1021 3463 c-23 -49 -5 -60 22 -14 9 16 17 31 17 33 0 3 -6 5 -14 5
-8 0 -19 -11 -25 -24z"></path><path d="M2310 3465 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M967 3372 l-18 -4 5 -13 c2 -7 -4 -22 -15 -33 -10 -12 -19 -28 -19
-37 0 -8 -4 -15 -9 -15 -5 0 -11 -7 -15 -15 l-5 -15 15 0 c8 0 19 10 24 23 6
12 22 42 36 65 14 24 24 45 22 46 -2 1 -11 1 -21 -2z"></path><path d="M880 3360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2210 3360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2226 3334 l-16 -15 10 -20 c14 -25 27 -24 36 3 l7 22 -12 4 c-6 2
-9 8 -5 13 8 14 -3 11 -20 -7z"></path><path d="M5150 3287 l0 -47 10 0 10 0 -6 43 c-4 23 -8 44 -10 46 -2 2 -4 -17
-4 -42z"></path><path d="M1426 3273 c-3 -10 -9 -25 -12 -33 l-5 -15 15 19 c8 10 19 15 24 12
l10 -6 -5 20 c-7 25 -19 26 -27 3z"></path><path d="M2133 3274 c-15 -15 -17 -34 -4 -34 8 0 24 41 18 46 -1 1 -8 -4 -14
-12z"></path><path d="M2195 3247 c-12 -12 -24 -27 -28 -33 l-7 -11 26 0 26 0 -5 18 -5 19
14 0 c8 0 14 7 14 15 0 20 -11 18 -35 -8z"></path><path d="M1460 3215 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2120 3205 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"></path><path d="M845 3161 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"></path><path d="M2162 3158 c-14 -14 -16 -38 -3 -38 10 0 26 39 19 46 -2 2 -10 -2
-16 -8z"></path><path d="M6352 3093 c-7 -43 -12 -100 -12 -126 0 -27 -5 -57 -10 -68 l-11 -19
14 0 14 0 7 41 c4 22 4 57 0 76 l-7 36 10 -6 10 -7 7 47 c8 63 8 103 -2 103
-5 0 -14 -35 -20 -77z"></path><path d="M1364 3144 l6 -15 -12 7 -11 7 6 -17 7 -17 -9 6 c-5 3 -12 1 -16 -5
-3 -5 4 -20 16 -32 l22 -23 -6 18 c-4 9 -3 17 2 17 4 0 13 11 19 24 l10 24
-11 11 c-16 16 -30 14 -23 -5z"></path><path d="M5140 3120 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5117 3106 l6 -19 -11 7 -12 7 0 -25 0 -26 25 0 c14 0 25 5 25 10 0
6 -5 10 -11 10 -5 0 -8 4 -5 9 3 4 -1 17 -9 27 l-15 19 7 -19z"></path><path d="M787 3103 c-12 -11 -8 -31 7 -36 l15 -6 -6 16 -6 16 11 -7 12 -7 -6
15 c-7 17 -16 20 -27 9z"></path><path d="M1460 3070 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1320 3060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2114 3047 c-3 -9 -11 -14 -18 -11 l-12 5 1 -26 c0 -30 18 -37 24 -9
2 10 8 25 14 32 5 7 7 15 4 19 -4 3 -9 -1 -13 -10z"></path><path d="M712 3025 l-12 -25 10 0 c6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 5 10
10 0 19 -17 10 -28 -15z"></path><path d="M2000 3040 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M770 3030 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M766 2995 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14 0
5 -15z"></path><path d="M1277 3003 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M2040 3000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M749 2985 c-1 -5 -3 -20 -4 -31 -1 -12 -5 -26 -9 -32 -3 -6 -2 -13 3
-16 5 -3 14 7 21 23 l12 30 -11 18 c-6 10 -11 14 -12 8z"></path><path d="M80 2960 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2063 2955 c0 -8 6 -15 14 -15 7 0 13 7 13 15 0 8 -6 15 -13 15 -8 0
-14 -7 -14 -15z"></path><path d="M100 2910 c0 -16 4 -30 8 -30 5 0 7 14 4 30 -2 17 -6 30 -8 30 -2 0
-4 -13 -4 -30z"></path><path d="M2040 2904 c-6 -14 -10 -34 -10 -45 0 -32 18 -21 29 19 13 46 14 52
1 52 -6 0 -15 -12 -20 -26z"></path><path d="M1246 2895 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M710 2876 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M1960 2880 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M50 2865 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M1217 2844 c6 -15 23 -19 23 -5 0 5 -7 11 -14 14 l-15 6 6 -15z"></path><path d="M6310 2846 c0 -8 7 -16 15 -20 l15 -5 0 19 0 20 -15 0 c-8 0 -15 -6
-15 -14z"></path><path d="M1200 2825 c0 -8 7 -15 16 -15 l16 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"></path><path d="M690 2821 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1997 2806 l-6 -14 14 -6 c17 -6 29 9 20 24 -9 14 -22 12 -28 -4z"></path><path d="M70 2785 c0 -30 11 -65 21 -65 14 0 10 44 -6 66 l-14 19 -1 -20z"></path><path d="M1175 2779 c-7 -22 -2 -32 18 -35 l13 -2 -4 29 c-5 34 -18 38 -27 8z"></path><path d="M6304 2760 l6 -30 -14 0 -14 0 -4 -29 c-2 -16 -1 -37 3 -47 l7 -19
11 27 c5 15 13 50 17 78 l7 50 -13 0 -12 0 6 -30z"></path><path d="M1986 2755 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M560 2713 c0 -13 5 -23 10 -23 13 0 13 11 0 30 l-10 15 0 -22z"></path><path d="M634 2700 l6 -30 -11 0 -10 0 3 -41 c1 -22 5 -42 9 -45 11 -7 41 105
34 127 -4 10 -13 19 -22 19 l-15 0 6 -30z"></path><path d="M1130 2666 c0 -8 6 -16 13 -19 l13 -5 0 19 c0 11 -6 19 -13 19 -7 0
-13 -6 -13 -14z"></path><path d="M107 2655 l-7 -16 10 6 9 5 -6 -36 -6 -37 11 7 c14 8 16 55 3 74 l-8
14 -6 -17z"></path><path d="M6246 2624 c-9 -35 -2 -40 12 -9 l11 24 -8 6 c-5 2 -12 -7 -15 -21z"></path><path d="M1101 2576 c-7 -25 -10 -50 -5 -57 l7 -12 -11 7 -10 6 -7 -31 c-7
-30 3 -56 12 -31 3 7 10 18 17 25 7 7 16 40 20 75 l7 62 -8 0 c-5 0 -15 -20
-22 -44z"></path><path d="M6130 2590 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1922 2564 c4 -21 22 -23 26 -1 l3 17 -16 0 -16 0 3 -16z"></path><path d="M6250 2566 c0 -24 17 -28 23 -6 l5 20 -14 0 c-8 0 -14 -6 -14 -14z"></path><path d="M120 2562 c0 -14 32 -106 40 -115 4 -5 11 4 14 19 l7 28 -15 12 c-9
6 -16 23 -16 38 l0 26 -15 0 c-8 0 -15 -4 -15 -8z"></path><path d="M6170 2540 c0 -16 5 -30 10 -30 6 0 10 6 10 14 0 8 3 21 6 30 l6 16
-16 0 -16 0 0 -30z"></path><path d="M1897 2532 l11 -7 -10 -53 c-5 -30 -7 -59 -5 -65 l5 -12 5 15 c33
100 33 130 0 130 l-18 0 12 -8z"></path><path d="M6230 2510 c-8 -12 -15 -25 -14 -30 1 -6 2 -17 3 -25 0 -8 6 -15 12
-15 l11 0 -7 30 -7 31 13 -3 c7 -2 15 5 17 15 5 23 -10 22 -28 -3z"></path><path d="M520 2470 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M597 2453 c-4 -3 -7 -14 -7 -24 0 -9 -5 -19 -12 -21 l-12 -4 -3 -76
-4 -76 10 -6 10 -7 7 26 c4 14 4 48 2 75 -3 27 -1 47 3 44 4 -2 11 12 15 31 6
36 3 51 -9 38z"></path><path d="M6130 2430 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1057 2433 c-4 -7 -3 -50 2 -61 2 -7 31 27 31 36 0 14 -27 34 -33 25z"></path><path d="M177 2391 c3 -14 8 -28 10 -31 5 -5 33 29 33 41 0 4 -11 9 -25 11
l-25 5 7 -26z"></path><path d="M285 2393 c5 -10 10 -33 13 -50 7 -51 22 -39 22 17 l0 50 -21 0 -21
0 7 -17z"></path><path d="M6193 2395 c0 -8 6 -15 14 -15 7 0 13 7 13 15 0 8 -6 15 -13 15 -8 0
-14 -7 -14 -15z"></path><path d="M1870 2371 c0 -11 5 -23 10 -26 l10 -6 0 25 c0 14 -4 26 -10 26 -5 0
-10 -9 -10 -19z"></path><path d="M4830 2341 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M6160 2325 c0 -14 4 -25 10 -25 5 0 7 7 3 15 l-5 16 12 -3 c7 -2 15
3 17 10 l5 12 -21 0 -21 0 0 -25z"></path><path d="M217 2333 c-4 -3 -7 -13 -7 -20 l0 -14 10 6 11 7 -7 -26 -6 -25 15
-21 14 -21 7 23 c3 13 6 31 6 41 0 21 -34 60 -43 50z"></path><path d="M1030 2316 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26
l-10 6 0 -25z"></path><path d="M1020 2261 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21
l-10 6 0 -20z"></path><path d="M1844 2254 c3 -9 6 -18 6 -20 0 -3 5 -2 10 1 16 10 12 35 -6 35 l-16
0 6 -16z"></path><path d="M6011 2208 l0 -43 11 23 c6 12 15 22 20 22 4 0 8 9 8 20 l0 20 -20 0
-20 0 1 -42z"></path><path d="M6123 2238 c2 -7 10 -13 17 -13 7 0 15 6 18 13 l4 12 -22 0 -22 0 5
-12z"></path><path d="M1017 2213 c-12 -11 -8 -53 8 -83 19 -36 28 -39 15 -6 -5 14 -6 41
-3 60 l6 36 -10 0 c-5 0 -13 -3 -16 -7z"></path><path d="M1830 2201 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M6080 2166 c0 -7 7 -13 15 -13 8 0 15 4 15 8 0 4 -7 9 -15 13 l-15 5
0 -13z"></path><path d="M272 2159 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"></path><path d="M345 2131 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M6080 2119 c0 -11 -9 -32 -20 -46 -11 -14 -20 -37 -20 -50 l0 -24 10
6 11 7 -7 20 -6 21 10 -7 10 -6 16 31 c18 36 21 69 6 69 -5 0 -10 -9 -10 -21z"></path><path d="M4598 2075 c2 -14 6 -25 7 -25 1 0 5 11 7 25 l5 25 -12 0 -12 0 5
-25z"></path><path d="M312 2078 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M400 2080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M520 2058 l0 -33 10 15 c13 20 13 50 0 50 -5 0 -10 -15 -10 -32z"></path><path d="M336 2028 c8 -28 12 -33 20 -25 9 8 -6 47 -17 47 l-10 0 7 -22z"></path><path d="M1818 2034 c-5 -4 -8 -19 -8 -33 l0 -25 11 11 c6 6 9 21 7 33 -1 12
-6 18 -10 14z"></path><path d="M366 1994 l-6 -17 15 13 c17 14 19 20 6 20 -5 0 -12 -7 -15 -16z"></path><path d="M6011 2003 c1 -5 1 -18 0 -31 l-1 -23 -32 -29 c-32 -29 -33 -48 -3
-36 9 3 13 10 10 16 -3 6 1 13 10 16 18 7 44 63 37 82 -4 13 -23 17 -21 5z"></path><path d="M386 1974 l-6 -15 11 6 10 7 -7 -28 -7 -27 15 6 16 6 -5 -20 -5 -19
11 0 12 0 -5 48 c-2 26 -5 48 -5 50 -1 1 -7 2 -15 2 -8 0 -17 -7 -20 -16z"></path><path d="M4500 1950 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M577 1943 c-7 -12 -3 -71 6 -79 4 -4 7 14 7 39 0 45 -4 56 -13 40z"></path><path d="M5850 1920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5940 1841 l-11 -21 11 0 c12 0 25 28 17 36 -3 3 -10 -4 -17 -15z"></path><path d="M445 1840 c-7 -12 12 -40 26 -40 14 0 11 20 -5 36 l-15 14 -6 -10z"></path><path d="M4270 1831 c0 -6 5 -13 10 -16 6 -3 10 -2 10 4 l0 10 18 -5 17 -6
-15 11 c-19 13 -40 14 -40 2z"></path><path d="M590 1796 c0 -14 5 -28 10 -31 l10 -6 0 30 c0 17 -4 31 -10 31 -5 0
-10 -11 -10 -24z"></path><path d="M5900 1810 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4186 1765 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M5890 1760 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M513 1738 c-2 -22 27 -58 46 -58 5 0 11 -10 14 -22 l3 -23 9 19 9 20
-12 4 c-7 2 -12 10 -12 18 l0 14 -20 0 -20 0 10 25 10 25 -18 0 -18 0 -1 -22z"></path><path d="M1020 1721 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21
l-10 6 0 -20z"></path><path d="M5860 1720 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M614 1709 c-3 -6 -1 -16 5 -22 l11 -11 0 22 c0 24 -5 28 -16 11z"></path><path d="M4153 1703 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5820 1690 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M630 1651 c0 -11 5 -23 10 -26 l10 -6 0 25 c0 14 -4 26 -10 26 -5 0
-10 -9 -10 -19z"></path><path d="M1017 1648 c-5 -18 3 -48 14 -48 5 0 9 14 9 30 0 30 -15 41 -23 18z"></path><path d="M592 1598 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z"></path><path d="M2330 1584 l0 -25 10 6 c6 3 10 15 10 26 0 10 -4 19 -10 19 -5 0 -10
-12 -10 -26z"></path><path d="M5686 1594 c-4 -9 -4 -19 -1 -22 3 -3 5 0 5 6 0 7 7 12 15 12 8 0 15
5 15 10 0 16 -27 11 -34 -6z"></path><path d="M630 1566 c0 -24 18 -28 30 -6 l11 20 -21 0 -20 0 0 -14z"></path><path d="M1030 1555 c0 -33 17 -41 22 -10 l5 24 -13 5 -14 5 0 -24z"></path><path d="M3746 1565 c4 -8 10 -15 14 -15 4 0 10 7 14 15 l5 15 -19 0 -19 0 5
-15z"></path><path d="M2600 1540 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3730 1539 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3
-20 1 -20 -4z"></path><path d="M5732 1539 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"></path><path d="M5696 1503 c-11 -14 -15 -23 -9 -20 6 4 14 1 16 -5 l5 -13 11 15 c12
17 15 50 4 50 -5 0 -17 -12 -27 -27z"></path><path d="M675 1510 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"></path><path d="M1051 1472 c0 -4 9 -25 19 -47 l19 -40 0 22 c1 12 -3 24 -9 28 -5 3
-7 12 -4 19 l5 13 -15 6 c-9 4 -16 3 -15 -1z"></path><path d="M5670 1454 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M5570 1440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2357 1414 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M5612 1402 c2 -9 8 -17 15 -19 l12 -4 -2 19 c0 10 -7 19 -14 19 -8 0
-13 -7 -11 -15z"></path><path d="M1100 1363 c0 -25 23 -83 32 -83 l10 0 -7 23 c-4 12 -8 32 -9 45 0
12 -7 22 -13 22 -7 0 -13 -3 -13 -7z"></path><path d="M2380 1355 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M5527 1363 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M5527 1299 c6 -22 23 -26 23 -5 0 8 -6 16 -14 19 l-14 6 5 -20z"></path><path d="M2380 1280 c0 -11 2 -20 5 -20 2 0 9 9 15 20 l11 20 -16 0 -15 0 0
-20z"></path><path d="M1245 1273 c4 -10 10 -28 12 -40 7 -31 20 -29 26 4 7 36 -1 53 -25
53 l-20 0 7 -17z"></path><path d="M5477 1273 c-13 -13 -7 -33 11 -33 l18 0 0 20 c0 20 -16 27 -29 13z"></path><path d="M1150 1235 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2405 1240 c3 -5 1 -10 -5 -10 l-11 0 3 -20 c5 -33 23 -28 26 8 l3
32 -11 0 c-6 0 -8 -4 -5 -10z"></path><path d="M5410 1240 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M5435 1200 l6 -10 -16 0 -17 0 4 -12 c6 -18 28 -21 28 -4 0 9 5 16
11 16 5 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0 -7 -4 -4 -10z"></path><path d="M1837 1193 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M5337 1179 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z"></path><path d="M1190 1156 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26
l-10 6 0 -25z"></path><path d="M1852 1121 c1 -17 6 -31 10 -31 12 0 10 48 -2 55 l-10 6 2 -30z"></path><path d="M5360 1141 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2430 1114 l0 -16 15 12 c8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0 -15
-7 -15 -16z"></path><path d="M5196 1114 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M5250 1120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2531 1088 l1 -33 5 18 c2 9 8 17 13 17 4 0 10 7 14 15 l5 15 -19 0
-20 0 1 -32z"></path><path d="M1232 1078 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M5320 1080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1870 1055 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"></path><path d="M2456 1065 c-8 -23 -8 -35 3 -35 13 0 23 32 13 42 l-9 9 -7 -16z"></path><path d="M5290 1073 c-8 -4 -20 -12 -26 -18 l-12 -12 8 -12 7 -13 22 13 c11 8
21 22 21 31 0 10 -1 18 -2 17 -2 0 -10 -3 -18 -6z"></path><path d="M1265 1040 c-9 -15 4 -30 25 -30 l19 0 -5 14 c-7 18 -32 28 -39 16z"></path><path d="M5240 1018 l0 -16 -20 5 -20 5 0 -16 c0 -19 19 -20 42 -3 l17 13 -10
15 -9 14 0 -17z"></path><path d="M1881 1001 c-15 -27 -14 -31 9 -31 l20 0 0 19 c0 28 -17 35 -29 12z"></path><path d="M2470 1005 c0 -25 23 -65 38 -65 l14 0 -16 25 c-9 14 -14 29 -11 34
2 5 -2 11 -10 15 l-15 5 0 -14z"></path><path d="M1800 990 l0 -20 15 0 c17 0 18 2 9 24 -9 23 -24 20 -24 -4z"></path><path d="M1290 993 c0 -4 17 -27 38 -52 21 -25 47 -58 56 -73 22 -34 39 -36
34 -5 l-3 22 -13 -3 c-8 -1 -11 1 -8 6 3 5 -4 16 -15 26 -12 9 -18 23 -16 30
l6 14 -19 -5 -18 -5 -6 20 c-6 19 -36 39 -36 25z"></path><path d="M5106 915 c-14 -14 -30 -25 -36 -25 -5 0 -10 -5 -10 -11 l0 -11 -15
12 -16 13 -10 -10 c-11 -11 -2 -43 12 -43 16 0 89 50 89 60 0 6 7 10 15 10 8
0 15 7 15 15 0 22 -16 18 -44 -10z"></path><path d="M2510 915 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M1917 913 c-4 -3 -7 -16 -7 -29 l0 -22 16 -6 c9 -4 19 -4 21 -2 7 7
-8 66 -16 66 -4 0 -11 -3 -14 -7z"></path><path d="M2534 875 c-3 -8 -3 -19 1 -25 11 -18 25 -11 25 15 0 27 -17 34 -26
10z"></path><path d="M1840 835 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1520 795 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M1560 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2660 803 c0 -5 7 -19 16 -31 15 -22 44 -30 44 -12 0 6 -7 10 -15 10
l-16 0 3 17 3 17 -17 4 c-10 2 -18 0 -18 -5z"></path><path d="M4960 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2596 775 l-5 -15 14 0 c16 0 20 16 6 24 -5 3 -11 -1 -15 -9z"></path><path d="M4850 785 c0 -3 9 -15 19 -26 11 -12 24 -18 30 -14 18 10 13 25 -9
25 -11 0 -20 5 -20 10 0 6 -4 10 -10 10 -5 0 -10 -2 -10 -5z"></path><path d="M1543 773 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4723 773 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1 0
-8 -3 -14 -6z"></path><path d="M1990 735 l1 -30 10 18 11 19 -11 11 -11 11 0 -29z"></path><path d="M1510 734 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z"></path><path d="M1611 734 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M2612 723 c2 -10 10 -17 16 -15 7 1 11 -1 10 -5 -1 -5 4 -19 9 -33
12 -27 57 -70 73 -70 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 15 0 12 26 -5 40
-8 7 -12 16 -9 21 3 5 -2 9 -10 9 -9 0 -16 6 -16 14 0 8 -8 17 -18 20 l-18 6
-2 27 c-2 27 -16 43 -40 43 l-13 0 3 -17z"></path><path d="M1537 724 c-9 -9 16 -44 30 -44 17 0 16 18 -1 36 -16 16 -20 17 -29
8z"></path><path d="M1625 699 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M4767 703 c-13 -12 -7 -23 13 -23 l19 0 -5 15 c-7 16 -17 19 -27 8z"></path><path d="M4709 673 l-14 -18 17 13 c9 8 22 11 28 7 5 -3 10 -1 10 4 0 17 -26
13 -41 -6z"></path><path d="M1983 653 c-3 -10 -8 -22 -11 -27 -3 -5 0 -6 5 -3 11 7 25 47 17 47
-3 0 -7 -8 -11 -17z"></path><path d="M1777 636 l-6 -15 15 6 c7 3 14 9 14 14 0 14 -17 10 -23 -5z"></path><path d="M1630 622 c0 -5 18 -23 41 -41 l40 -33 16 6 15 6 -38 35 c-36 32 -74
46 -74 27z"></path><path d="M4407 613 c-12 -11 -8 -23 7 -23 8 0 16 7 20 15 l5 15 -13 0 c-7 0
-16 -3 -19 -7z"></path><path d="M4612 613 c-14 -5 -68 -53 -60 -53 2 0 23 9 46 20 24 12 40 25 37 30
-4 6 -7 10 -8 9 -1 0 -8 -3 -15 -6z"></path><path d="M2067 593 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M2774 558 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M4495 560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M1987 547 c-5 -14 13 -57 23 -57 4 0 7 13 7 30 0 29 -22 49 -30 27z"></path><path d="M1730 541 c0 -12 14 -21 40 -26 24 -5 25 1 6 21 -16 16 -46 19 -46 5z"></path><path d="M2793 538 c-4 -7 -6 -24 -4 -36 4 -37 51 -83 51 -51 0 6 -7 17 -15
25 -8 9 -15 22 -15 29 l0 14 15 -5 c18 -7 20 7 3 24 -15 15 -25 15 -35 0z"></path><path d="M2900 540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4440 534 l0 -15 22 3 c12 2 23 9 26 16 l4 12 -26 0 -26 0 0 -16z"></path><path d="M4420 521 c0 -6 -9 -11 -20 -11 l-20 0 0 -14 0 -14 30 11 c31 12 37
21 20 32 -5 3 -10 1 -10 -4z"></path><path d="M2110 505 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"></path><path d="M2900 480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4135 480 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"></path><path d="M4295 480 l6 -10 -25 -1 c-29 -2 -34 -3 -58 -13 -10 -5 -18 -15 -18
-23 l0 -15 23 7 c12 3 32 9 45 12 12 3 22 9 22 14 0 5 10 9 23 9 12 0 28 4 35
9 l14 8 -17 7 c-24 9 -27 8 -19 -6 l7 -13 -14 13 c-16 14 -33 16 -24 2z"></path><path d="M1915 428 l-15 -23 17 -13 18 -13 4 13 c2 7 9 16 14 20 l10 6 -16 16
-16 16 -16 -22z m25 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z"></path><path d="M2850 440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4140 423 l0 -13 -23 0 -24 0 0 -15 0 -15 21 6 c12 4 26 9 33 13 l12
6 -9 15 -10 15 0 -12z"></path><path d="M2125 410 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M2860 406 c0 -16 26 -46 40 -46 5 0 7 8 3 18 -14 38 -43 57 -43 28z"></path><path d="M1960 375 l0 -15 26 0 25 0 -7 -12 -7 -11 11 7 12 7 0 -11 c0 -6 4
-9 9 -6 5 3 11 -4 14 -15 l6 -21 12 4 c24 8 20 25 -10 38 -17 7 -33 21 -36 31
l-6 19 -25 0 -24 0 0 -15z"></path><path d="M3978 383 c6 -2 9 -9 6 -15 l-6 -10 17 4 c10 1 20 9 23 16 l4 12 -29
-1 c-15 0 -22 -3 -15 -6z"></path><path d="M4040 380 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2232 350 c13 -20 28 -27 28 -12 0 10 -22 32 -32 32 -5 0 -3 -9 4
-20z"></path><path d="M3923 357 c5 -15 29 -16 34 -2 2 6 -6 11 -18 12 l-20 2 4 -12z"></path><path d="M2160 335 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3040 320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3740 315 l0 -14 15 5 c8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 -7
-15 -15z"></path><path d="M2076 301 c-7 -11 42 -44 54 -36 6 3 10 1 10 -4 0 -6 6 -11 14 -11 8
0 16 -7 20 -15 7 -18 26 -20 26 -2 0 16 -64 57 -90 57 l-21 0 6 10 c3 6 2 10
-4 10 -5 0 -12 -4 -15 -9z"></path><path d="M3580 295 l0 -15 25 0 24 0 -5 15 c-4 8 -15 15 -25 15 l-19 0 0 -15z"></path><path d="M2252 274 l10 -21 25 0 25 0 -12 7 -13 8 6 16 6 16 -28 -2 -29 -2 10
-22z"></path><path d="M2993 275 c0 -8 7 -15 15 -15 l14 0 -12 15 c-7 8 -13 15 -15 15 -1 0
-2 -7 -2 -15z"></path><path d="M2330 234 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2220 220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2350 220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2263 203 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2270 181 c0 -11 25 -21 51 -21 l20 0 -6 -10 c-6 -9 8 -14 28 -11 4
0 7 -3 7 -8 0 -13 28 -22 35 -10 4 5 1 8 -7 7 l-13 -3 1 18 c2 20 -7 22 -24 5
l-12 -12 0 17 0 17 -20 0 c-11 0 -20 5 -20 10 0 6 -9 10 -20 10 -11 0 -20 -4
-20 -9z"></path><path d="M2420 105 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 l-15 5
0 -14z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#85c223" stroke="none"><path d="M5813 7245 c0 -8 5 -16 12 -18 l11 -5 1 19 c1 10 -4 19 -11 19 -7 0
-13 -7 -13 -15z"></path><path d="M5736 7225 l-6 -15 20 10 c23 13 26 20 6 20 -8 0 -16 -7 -20 -15z"></path><path d="M5536 7166 l6 -16 25 0 25 0 -4 13 c-3 6 -17 13 -31 15 l-27 3 6 -15z"></path><path d="M5463 7145 c0 -8 4 -15 7 -15 4 0 13 7 20 15 l12 15 -19 0 -20 0 0
-15z"></path><path d="M5617 7145 c6 -17 25 -15 31 3 l4 12 -21 0 -20 0 6 -15z"></path><path d="M5754 7118 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M5308 7113 l-18 -4 16 -16 16 -16 21 22 21 21 -19 -1 c-11 -1 -28 -3
-37 -6z"></path><path d="M5460 7100 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8
0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"></path><path d="M5243 7085 c0 -8 6 -15 13 -15 6 0 14 7 18 15 l5 15 -18 0 -18 0 0
-15z"></path><path d="M5670 7040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5083 7034 l8 -8 -23 -13 c-13 -7 -17 -12 -10 -13 7 0 10 -4 7 -10
l-6 -10 16 7 17 6 -14 8 -13 9 23 0 24 0 -4 12 c-2 6 -11 14 -19 16 l-14 5 8
-9z"></path><path d="M4983 7005 c0 -8 4 -15 7 -15 4 0 13 7 20 15 l12 15 -19 0 -20 0 0
-15z"></path><path d="M4935 6991 c3 -5 -3 -12 -12 -14 l-18 -5 28 -1 c29 -1 37 13 11 23
l-15 6 6 -9z"></path><path d="M5715 6960 l-18 -20 17 0 c9 0 19 7 23 15 9 25 -2 27 -22 5z"></path><path d="M4757 6954 c-10 -11 3 -24 22 -24 10 0 24 7 31 15 l12 15 -29 0 c-16
0 -33 -3 -36 -6z"></path><path d="M5000 6940 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5674 6938 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M4700 6929 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4662 6904 c9 -9 21 -13 27 -9 16 10 3 25 -22 25 l-21 0 16 -16z"></path><path d="M4796 6875 l-5 -15 19 0 20 0 0 15 c0 8 -6 15 -14 15 -8 0 -16 -7
-20 -15z"></path><path d="M5670 6881 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M4663 6856 c5 -16 27 -19 27 -3 0 7 -7 14 -15 14 -9 0 -14 -5 -12
-11z"></path><path d="M4750 6860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4773 6795 c0 -8 4 -15 9 -15 4 0 8 4 8 9 0 6 -4 12 -8 15 -5 3 -9
-1 -9 -9z"></path><path d="M4836 6795 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"></path><path d="M4810 6775 l-12 -15 14 0 c8 0 15 7 15 15 0 8 -1 15 -2 15 -2 0 -8
-7 -15 -15z"></path><path d="M5005 6720 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M5150 6720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4927 6696 l-5 -13 24 -13 c13 -8 27 -11 30 -7 8 8 -4 27 -17 27
l-10 0 7 -12 7 -11 -11 7 c-6 4 -9 13 -5 21 3 8 1 15 -5 15 -5 0 -12 -6 -15
-14z"></path><path d="M5070 6700 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5596 6678 c3 -4 9 -8 15 -8 5 0 9 4 9 8 0 5 -7 9 -15 9 -8 0 -12 -4
-9 -9z"></path><path d="M5150 6660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5573 6654 l-6 -16 14 -8 c18 -12 25 -4 11 13 -7 8 -9 17 -5 20 3 4
3 7 -1 7 -3 0 -9 -7 -13 -16z"></path><path d="M4995 6650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5016 6635 l-5 -15 12 0 c24 0 57 13 57 21 0 5 -13 9 -29 9 l-29 0
-6 -15z"></path><path d="M5530 6630 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3552 6600 l-12 -20 19 0 19 0 -2 15 c-3 24 -11 25 -24 5z"></path><path d="M2715 6590 c4 -6 10 -8 15 -5 4 3 10 0 12 -5 l3 -11 20 15 20 15 -38
1 -38 0 6 -10z"></path><path d="M2903 6593 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3685 6590 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M5040 6591 c0 -5 7 -12 16 -15 22 -9 24 -8 18 9 -7 16 -34 21 -34 6z"></path><path d="M5563 6593 c-16 -6 -17 -33 -3 -33 11 0 26 28 19 34 -2 2 -10 1 -16
-1z"></path><path d="M5096 6555 c4 -8 11 -15 16 -15 l10 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"></path><path d="M5154 6558 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4995 6550 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2505 6540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5066 6535 l-5 -15 19 0 19 0 -5 15 c-4 8 -10 15 -14 15 -4 0 -10 -7
-14 -15z"></path><path d="M2425 6530 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M3943 6524 l0 -15 25 3 c14 2 27 9 30 16 l4 12 -29 0 -30 0 0 -16z"></path><path d="M4987 6524 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M5030 6504 l0 -26 12 4 c6 2 14 10 16 18 l5 13 -12 -7 -11 -7 6 15 6
16 -11 0 c-6 0 -11 -12 -11 -26z"></path><path d="M2355 6510 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"></path><path d="M4918 6506 l-18 -13 19 -17 19 -17 -6 -21 -5 -21 13 8 13 8 -7 -11
-7 -11 -16 6 -16 6 7 -11 c10 -16 34 -15 51 2 l14 13 -31 33 -30 34 21 8 c11
4 21 10 21 13 0 9 -25 4 -42 -9z"></path><path d="M4086 6485 c4 -8 16 -15 28 -15 l23 0 0 15 0 15 -28 0 -28 0 5 -15z"></path><path d="M4030 6480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2194 6463 c2 -10 6 -17 9 -15 4 1 14 2 22 2 8 0 15 7 15 15 l0 15
-24 0 -24 0 2 -17z"></path><path d="M4997 6474 c-4 -4 -7 -14 -7 -23 l0 -16 9 13 c4 8 11 13 15 13 3 -1
6 2 7 6 1 10 -16 15 -24 7z"></path><path d="M2350 6460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2130 6449 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2163 6445 c0 -8 6 -16 13 -19 l12 -5 -5 20 c-6 22 -20 26 -20 4z"></path><path d="M4865 6444 c20 -15 37 -14 42 2 2 5 -11 10 -29 10 l-32 1 19 -13z"></path><path d="M5230 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5370 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5460 6401 c0 -5 9 -11 20 -14 l20 -5 0 14 0 14 -20 0 c-11 0 -20 -4
-20 -9z"></path><path d="M4890 6374 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M1984 6366 c22 -18 46 -21 46 -6 0 6 -6 10 -14 10 -8 0 -23 3 -33 6
l-18 6 19 -16z"></path><path d="M4270 6370 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M5441 6360 l-1 -26 20 0 c11 0 20 6 20 13 l0 12 -14 -5 -14 -5 -5 18
-5 18 -1 -25z"></path><path d="M5216 6353 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M4685 6349 c-11 -17 1 -21 15 -4 l12 15 -10 0 c-6 0 -14 -5 -17 -11z"></path><path d="M1947 6343 c-10 -10 -8 -33 2 -33 6 0 9 9 8 20 -1 23 -1 22 -10 13z"></path><path d="M4090 6340 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4188 6325 l-5 -25 12 0 c18 0 23 9 18 31 -7 27 -20 24 -25 -6z"></path><path d="M4810 6341 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M4050 6326 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"></path><path d="M2090 6320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4710 6320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4840 6320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1797 6313 c-12 -11 -8 -23 7 -23 8 0 16 7 20 15 l5 15 -13 0 c-7 0
-16 -3 -19 -7z"></path><path d="M4113 6282 c-18 -20 -30 -38 -28 -41 3 -2 16 1 29 9 l24 13 -5 13 -5
14 23 8 22 9 -14 5 -14 6 -32 -36z m7 -16 c0 -3 -5 -8 -12 -12 l-11 -7 7 11
c7 12 16 16 16 8z"></path><path d="M5267 6304 c6 -15 23 -19 23 -5 0 5 -7 11 -14 14 l-15 6 6 -15z"></path><path d="M4614 6295 c-4 -8 -10 -12 -15 -9 -5 3 -9 1 -9 -4 0 -5 9 -12 20 -15
11 -3 20 -1 20 3 0 5 3 15 6 24 l6 16 -11 0 c-7 0 -14 -7 -17 -15z"></path><path d="M1756 6285 c4 -8 14 -15 23 -14 l16 1 -13 5 c-6 2 -10 9 -6 14 3 5
-2 9 -10 9 l-15 0 5 -15z"></path><path d="M1840 6280 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5242 6264 c3 -17 21 -21 26 -6 1 5 -4 12 -13 16 l-16 6 3 -16z"></path><path d="M4743 6258 c2 -7 10 -13 17 -13 7 0 15 6 18 13 l4 12 -22 0 -22 0 5
-12z"></path><path d="M5370 6240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3994 6228 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4035 6227 c-8 -19 -2 -31 13 -26 l9 4 -7 20 -8 20 -7 -18z"></path><path d="M3910 6220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4696 6215 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"></path><path d="M4482 6208 c-7 -7 -12 -19 -12 -27 l0 -16 9 14 9 13 6 -11 c11 -17
25 -3 19 19 -6 23 -14 25 -31 8z"></path><path d="M4600 6200 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4672 6198 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M4005 6190 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M3890 6174 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M3973 6176 l6 -14 -16 -6 c-9 -4 -13 -9 -10 -12 3 -3 14 0 25 7 l20
13 -5 13 c-3 7 -10 13 -15 13 -6 0 -8 -6 -5 -14z"></path><path d="M1529 6167 l-17 -13 13 -17 c6 -9 15 -14 18 -10 4 3 1 13 -6 21 l-12
16 16 -6 16 -6 0 14 c0 17 -7 17 -28 1z"></path><path d="M4419 6164 c-12 -14 -22 -44 -15 -44 9 0 46 39 46 49 0 16 -15 14
-31 -5z"></path><path d="M1620 6160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4626 6156 c7 -19 38 -22 32 -3 -3 6 -12 13 -21 15 l-17 3 6 -15z"></path><path d="M4530 6140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5330 6140 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1463 6126 l-11 -13 9 -14 9 -14 9 14 c5 7 13 11 18 8 5 -3 9 3 9 14
l0 19 -16 0 c-8 -1 -20 -7 -27 -14z"></path><path d="M4450 6100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1412 6088 c6 -18 25 -20 31 -3 l6 15 -20 0 -21 0 4 -12z"></path><path d="M4531 6079 l-24 -20 11 -11 c14 -14 22 -7 27 19 1 10 8 17 15 15 l11
-2 -6 10 c-3 5 -7 10 -8 10 -1 -1 -13 -10 -26 -21z"></path><path d="M5322 6078 c3 -7 12 -14 21 -16 l17 -3 -6 15 c-7 19 -38 22 -32 4z"></path><path d="M1396 6065 l6 -15 -14 5 -15 6 0 -16 c0 -8 5 -15 11 -15 14 0 37 36
29 44 -12 12 -22 6 -17 -9z"></path><path d="M4453 6053 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M1316 6031 c-8 -12 3 -41 15 -41 5 0 8 11 7 25 -2 25 -12 32 -22 16z"></path><path d="M4240 6032 c0 -5 7 -13 16 -16 l16 -6 -4 12 c-5 15 -28 23 -28 10z"></path><path d="M5040 6029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1460 6015 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4472 6019 c2 -7 10 -15 17 -17 l14 -4 -5 13 c-2 7 -10 15 -17 17
l-13 5 4 -14z"></path><path d="M4947 6013 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M1410 6000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3657 6003 c-12 -11 -8 -22 14 -33 l21 -12 -12 22 -12 22 24 -13 23
-12 -14 16 c-14 18 -33 22 -44 10z"></path><path d="M4350 6000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4394 5998 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4457 5994 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M1287 5975 c19 -25 33 -32 33 -15 0 6 -5 10 -11 10 l-11 0 6 15 5 15
-20 0 -20 0 18 -25z"></path><path d="M5103 5982 c10 -10 14 -23 10 -27 -5 -5 0 -3 10 4 l17 14 -17 13
c-26 20 -39 17 -20 -4z"></path><path d="M4310 5970 l0 -20 15 0 c17 0 18 2 9 24 -9 23 -24 20 -24 -4z"></path><path d="M4155 5970 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"></path><path d="M4906 5965 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M4389 5970 c0 -3 -2 -15 -3 -27 l-3 -23 13 0 c21 0 24 16 9 36 -8 10
-15 17 -16 14z"></path><path d="M4995 5960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4210 5940 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5090 5940 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4123 5924 c0 -8 6 -14 14 -14 7 0 13 4 13 9 0 5 -6 11 -13 14 l-14
5 0 -14z"></path><path d="M5170 5926 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"></path><path d="M3592 5917 c3 -6 14 -13 26 -15 l22 -3 0 13 0 14 -26 2 -26 2 4 -13z"></path><path d="M4350 5919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4267 5903 l6 -16 -17 6 -17 7 7 -11 c4 -6 3 -14 -2 -18 -5 -3 -18
-16 -28 -29 l-20 -23 17 7 c9 3 17 10 17 15 0 5 6 9 14 9 8 0 18 9 21 20 3 11
11 20 16 20 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14 0 6 -17z"></path><path d="M1213 5903 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3553 5896 c3 -7 0 -19 -6 -25 l-11 -11 20 0 19 0 0 25 0 25 -14 0
-13 0 5 -14z"></path><path d="M1194 5881 l19 -21 14 11 c15 13 18 23 3 14 -5 -3 -20 -1 -32 6 l-23
12 19 -22z"></path><path d="M4086 5885 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"></path><path d="M5210 5884 l0 -16 15 12 c8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0 -15
-7 -15 -16z"></path><path d="M3470 5880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4800 5885 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"></path><path d="M1107 5862 c-10 -10 -14 -22 -10 -27 l8 -8 -13 7 -12 7 -15 -15 -15
-15 10 -6 c5 -3 21 5 35 19 14 15 30 26 35 26 6 0 10 7 10 15 0 20 -13 19 -33
-3z"></path><path d="M5237 5873 c-10 -10 -8 -23 4 -23 l10 0 -7 13 -8 12 17 -14 17 -13 0
16 c0 16 -21 22 -33 9z"></path><path d="M3504 5858 c-14 -22 -13 -38 1 -38 8 0 17 5 20 10 l6 10 -18 0 -18 0
15 10 c8 5 12 12 8 15 -3 4 -10 0 -14 -7z"></path><path d="M4190 5860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1183 5853 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3414 5838 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M4745 5830 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"></path><path d="M5310 5807 c0 -8 7 -14 15 -14 8 0 15 6 15 14 0 7 -7 13 -15 13 -8 0
-15 -6 -15 -13z"></path><path d="M1130 5795 c7 -8 17 -15 22 -15 5 0 3 7 -6 15 -8 8 -18 15 -22 15 -3
0 -1 -7 6 -15z"></path><path d="M4189 5783 c0 -5 -2 -16 -4 -25 l-3 -18 19 0 c20 0 23 8 13 34 -6 16
-23 22 -25 9z"></path><path d="M4810 5780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5347 5760 c3 -11 10 -20 15 -20 5 0 6 5 3 10 -3 6 -1 10 4 10 6 0
11 5 11 10 0 6 -9 10 -19 10 l-19 0 5 -20z"></path><path d="M3250 5760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3336 5761 c-3 -5 5 -11 19 -13 l25 -5 -11 13 c-13 16 -25 18 -33 5z"></path><path d="M4130 5740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3873 5725 c0 -8 4 -15 9 -15 4 0 8 7 8 15 0 8 -4 15 -8 15 -5 0 -9
-7 -9 -15z"></path><path d="M5395 5729 c-11 -17 1 -21 15 -4 l12 15 -10 0 c-6 0 -14 -5 -17 -11z"></path><path d="M927 5702 c-20 -22 -21 -32 -6 -32 12 0 38 29 39 43 0 13 -17 7 -33
-11z"></path><path d="M3788 5696 l-27 -24 16 -14 17 -13 -7 19 -7 18 15 -6 15 -5 -3 12
c-1 7 3 11 10 10 6 -1 14 4 17 12 l5 15 -12 0 c-7 0 -24 -11 -39 -24z"></path><path d="M4635 5710 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5417 5713 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"></path><path d="M3310 5695 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"></path><path d="M4670 5700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5355 5700 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5436 5684 l-6 -17 15 13 c17 14 19 20 6 20 -5 0 -12 -7 -15 -16z"></path><path d="M3275 5681 c-3 -5 0 -13 6 -17 l12 -7 -7 11 c-3 5 0 13 6 15 l13 5
-12 1 c-6 1 -14 -3 -18 -8z"></path><path d="M4050 5680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M951 5654 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M3861 5654 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M874 5649 c-3 -6 -1 -15 6 -19 l12 -8 -13 -5 -14 -5 18 -1 17 -1 0
25 c0 26 -14 33 -26 14z"></path><path d="M3255 5650 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5475 5652 c-5 -4 -11 -12 -12 -18 l-2 -12 19 5 c11 3 20 12 20 19 0
15 -10 18 -25 6z"></path><path d="M3720 5640 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M4066 5638 c3 -4 0 -8 -5 -8 -6 0 -11 -7 -11 -16 0 -8 -4 -13 -8 -10
-5 3 -9 -1 -9 -9 0 -8 3 -15 6 -15 7 0 61 52 61 60 0 3 -9 6 -19 6 -11 0 -17
-4 -15 -8z"></path><path d="M4506 5613 l-27 -27 18 -7 c22 -8 33 -3 33 17 0 8 4 14 8 14 5 0 9 7
9 15 0 8 -3 15 -7 15 -4 0 -19 -12 -34 -27z"></path><path d="M3810 5620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4595 5620 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4670 5620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3671 5604 c-7 -8 -10 -18 -6 -22 4 -4 10 -1 12 6 3 6 11 12 19 12 8
0 14 5 14 10 0 15 -23 11 -39 -6z"></path><path d="M5507 5613 c-4 -3 0 -17 9 -31 l16 -25 -12 -7 -12 -7 11 -7 c6 -4 11
-2 11 3 0 16 29 13 46 -3 l14 -14 -13 -5 -12 -5 11 -1 c6 -1 16 5 22 12 l10
12 -39 43 c-40 42 -50 48 -62 35z m48 -41 c-3 -3 -11 0 -18 7 l-12 12 18 -7
c10 -3 15 -9 12 -12z"></path><path d="M3083 5603 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3970 5600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3146 5584 c-8 -20 0 -32 14 -18 l9 9 -8 13 -8 14 -7 -18z"></path><path d="M790 5545 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M3715 5540 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4431 5541 l-12 -8 7 -16 c8 -21 24 -22 24 -3 0 8 5 16 12 18 l12 4
-15 6 c-9 3 -21 3 -28 -1z"></path><path d="M4514 5538 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M762 5518 c-16 -16 -15 -28 2 -28 19 0 31 19 19 31 l-9 9 -12 -12z"></path><path d="M3008 5524 c-8 -8 3 -44 14 -44 l9 0 -11 20 c-12 22 -1 26 20 8 l14
-13 -13 18 c-14 18 -24 21 -33 11z"></path><path d="M3670 5521 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M3894 5518 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3560 5511 c0 -18 18 -21 30 -6 l12 15 -21 0 c-11 0 -21 -4 -21 -9z"></path><path d="M3620 5500 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"></path><path d="M4470 5500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3542 5489 c2 -7 10 -15 17 -17 l14 -4 -5 13 c-2 7 -10 15 -17 17
l-13 5 4 -14z"></path><path d="M4365 5489 c-8 -12 -5 -16 15 -21 15 -3 20 2 20 22 0 13 -27 13 -35
-1z"></path><path d="M3688 5483 c-22 -5 -24 -33 -3 -33 8 0 15 4 15 10 0 5 7 7 15 4 l15
-6 0 16 c0 16 -9 18 -42 9z"></path><path d="M5640 5465 l-12 -15 16 0 c9 0 16 7 16 15 0 8 -2 15 -4 15 -2 0 -9
-7 -16 -15z"></path><path d="M3900 5460 c0 -5 -8 -10 -18 -10 -26 0 -34 -11 -20 -27 l11 -14 -14
3 -14 3 3 -13 2 -13 -19 10 -19 11 5 -20 5 -20 -16 0 c-9 0 -16 -7 -16 -15 0
-8 4 -15 8 -15 13 0 93 86 86 92 -3 3 4 9 15 13 21 6 29 25 11 25 -5 0 -10 -4
-10 -10z"></path><path d="M5550 5459 c0 -11 27 -12 34 -1 2 4 -4 9 -15 9 -10 1 -19 -2 -19 -8z"></path><path d="M3496 5445 c4 -8 12 -15 20 -15 19 0 18 16 -2 24 -22 9 -24 8 -18 -9z"></path><path d="M4326 5445 c4 -8 10 -15 14 -15 4 0 10 7 14 15 l5 15 -19 0 -19 0 5
-15z"></path><path d="M2800 5440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2905 5421 c3 -5 1 -12 -5 -16 -17 -10 -11 -25 9 -25 20 0 31 17 24
38 -3 6 -11 12 -19 12 -9 0 -13 -4 -9 -9z"></path><path d="M721 5406 c15 -16 11 -26 -8 -20 l-18 6 18 -12 c20 -13 33 -7 39 17
l5 18 -23 0 -23 0 10 -9z"></path><path d="M3456 5405 c4 -8 12 -15 20 -15 19 0 18 16 -2 24 -22 9 -24 8 -18 -9z"></path><path d="M4286 5405 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 l-14
0 5 -15z"></path><path d="M3550 5400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4271 5384 l14 -17 -23 7 -22 7 6 -15 c7 -19 31 -21 37 -3 6 16 -3
37 -15 37 l-10 0 13 -16z"></path><path d="M3730 5380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5655 5380 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M3407 5364 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M5730 5371 c0 -6 6 -11 13 -11 l12 0 -13 -8 -13 -9 6 -16 c8 -19 32
-30 40 -17 8 13 -4 32 -16 25 l-9 -6 6 14 c3 8 -2 20 -11 27 l-15 12 0 -11z"></path><path d="M2756 5355 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M2843 5355 c0 -8 7 -15 15 -15 l14 0 -12 15 c-7 8 -13 15 -15 15 -1
0 -2 -7 -2 -15z"></path><path d="M3510 5360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3360 5350 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M3342 5329 c2 -7 10 -15 17 -17 l14 -4 -5 13 c-2 7 -10 15 -17 17
l-13 5 4 -14z"></path><path d="M4184 5316 l-21 -22 7 -19 c3 -10 14 -19 23 -21 l16 -2 -17 26 -17
26 18 -7 18 -7 -6 10 -6 10 16 0 17 0 -4 12 c-7 20 -22 18 -44 -6z"></path><path d="M3550 5320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3326 5306 l6 -16 -18 6 -19 7 11 -12 11 -12 17 7 c18 7 17 25 -2 32
l-12 4 6 -16z"></path><path d="M3730 5301 c0 -5 -9 -11 -21 -14 l-21 -6 4 -18 c2 -10 -3 -23 -12
-30 -8 -7 -10 -13 -4 -13 14 0 84 65 84 79 0 6 -7 11 -15 11 -8 0 -15 -4 -15
-9z"></path><path d="M3570 5280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3650 5280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M552 5248 c-14 -14 -16 -42 -3 -33 5 3 13 0 17 -6 l7 -11 8 13 c14
22 11 29 -8 22 l-18 -7 14 17 c15 19 1 23 -17 5z"></path><path d="M4120 5256 c0 -2 6 -9 13 -15 l14 -11 0 15 c0 8 -6 15 -14 15 -7 0
-13 -2 -13 -4z"></path><path d="M3249 5228 c15 -20 41 -24 41 -7 0 6 -6 9 -14 6 -7 -3 -20 0 -27 6
l-13 12 13 -17z"></path><path d="M4102 5228 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M4170 5236 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M4080 5210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M660 5190 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3590 5200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3637 5194 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M4150 5200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5830 5200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M515 5190 c-3 -5 -3 -14 0 -20 l6 -10 -15 0 c-9 0 -16 -6 -16 -13 0
-8 7 -14 15 -14 8 0 12 4 9 9 -3 4 2 8 10 8 l16 0 0 19 c0 23 -16 36 -25 21z"></path><path d="M3151 5181 l-24 -18 7 -12 c11 -18 26 -13 26 8 0 11 6 23 13 27 l12
7 -7 -8 c-4 -4 -3 -14 3 -22 14 -16 49 -17 49 -2 l0 11 -17 -6 c-10 -4 -14 -4
-10 0 7 7 -10 34 -22 34 -3 0 -17 -9 -30 -19z"></path><path d="M3577 5167 l-5 -14 14 0 c16 0 19 13 5 21 -5 3 -11 0 -14 -7z"></path><path d="M4100 5155 c0 -8 7 -15 16 -15 l16 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"></path><path d="M580 5140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3240 5140 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2125 5120 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"></path><path d="M3550 5121 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M4290 5080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3047 5055 c16 -22 33 -32 33 -20 0 7 -39 45 -45 45 -3 0 2 -11 12
-25z"></path><path d="M5923 5073 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M485 5060 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M3410 5062 c0 -4 10 -14 21 -22 12 -8 28 -21 36 -29 l14 -13 19 17
c24 22 26 35 5 35 -8 0 -15 -7 -15 -15 l0 -14 -17 6 c-10 4 -25 15 -34 25 -17
19 -29 23 -29 10z"></path><path d="M5810 5040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M416 5033 c-3 -3 -6 -17 -6 -30 l0 -24 10 6 c5 3 6 13 3 22 l-6 16
11 -7 12 -7 0 10 c0 13 -15 22 -24 14z"></path><path d="M2975 5029 c-12 -19 5 -23 22 -6 l17 17 -16 0 c-9 0 -20 -5 -23 -11z"></path><path d="M5960 5011 c-8 -3 -16 -12 -18 -20 l-3 -14 15 12 14 12 10 -18 c6
-10 14 -14 18 -10 5 5 4 11 -2 15 -6 4 -13 12 -15 18 l-4 11 -15 -6z"></path><path d="M3428 4994 c-11 -10 3 -34 19 -34 l14 0 -3 17 c-3 18 -20 27 -30 17z"></path><path d="M440 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5890 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M390 4960 c0 -4 10 -18 22 -31 l21 -24 -6 18 c-4 10 -7 24 -7 31 0 7
-7 13 -15 13 -8 0 -15 -3 -15 -7z"></path><path d="M3280 4960 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2903 4918 c-1 -11 -8 -25 -15 -29 -7 -4 -9 -11 -6 -15 4 -3 13 0 20
7 15 16 23 59 11 59 -5 0 -9 -10 -10 -22z"></path><path d="M3250 4920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3376 4922 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 l-11 11 -6 -10z"></path><path d="M4510 4920 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M357 4913 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M6020 4906 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M3273 4883 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M330 4865 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M3312 4858 c3 -7 13 -15 22 -16 l18 -4 -4 14 c-3 7 -13 15 -22 16
l-18 4 4 -14z"></path><path d="M2747 4822 c-21 -23 -21 -32 -2 -32 8 0 15 5 15 10 0 6 7 10 16 10 9
0 14 5 12 12 -7 20 -23 20 -41 0z"></path><path d="M6023 4833 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M420 4820 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2824 4818 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3294 4815 l5 -15 -15 0 -15 0 7 13 7 12 -8 -8 c-11 -10 -18 -57 -9
-57 9 0 54 53 54 63 0 4 -7 7 -16 7 l-16 0 6 -15z"></path><path d="M6059 4818 c-1 -2 -3 -13 -4 -25 -1 -12 1 -20 5 -18 12 7 19 45 9 45
-5 0 -10 -1 -10 -2z"></path><path d="M5970 4800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2705 4770 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M3232 4758 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M277 4753 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M2738 4734 c2 -13 8 -24 13 -24 14 0 10 37 -4 43 l-14 5 5 -24z"></path><path d="M2705 4730 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M6098 4733 l-18 -4 0 -20 c0 -10 5 -19 10 -19 6 0 10 5 10 11 0 5 5
7 10 4 l10 -6 0 20 c0 12 -1 20 -2 20 -2 -1 -11 -3 -20 -6z"></path><path d="M3170 4721 c0 -5 9 -11 19 -14 l20 -5 -6 14 c-6 15 -33 19 -33 5z"></path><path d="M5990 4720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M255 4710 c-7 -11 5 -40 16 -40 5 0 9 2 9 4 0 2 3 11 6 19 l5 14 -15
6 c-8 3 -18 2 -21 -3z"></path><path d="M2645 4710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2590 4666 l0 -16 21 0 21 0 -4 13 c-3 6 -12 13 -21 15 l-17 3 0 -15z"></path><path d="M6118 4673 c-24 -6 -23 -23 0 -23 l19 0 0 15 c0 8 -1 14 -1 14 -1 -1
-9 -3 -18 -6z"></path><path d="M2670 4660 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M3140 4655 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M300 4630 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3103 4623 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M250 4605 c-7 -9 -19 -14 -27 -11 l-13 5 0 -14 0 -14 20 2 c11 1 20
7 20 15 0 7 5 10 10 7 l10 -6 0 15 c0 20 -4 20 -20 1z"></path><path d="M2545 4610 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M6156 4578 c3 -4 9 -8 15 -8 5 0 9 4 9 8 0 5 -7 9 -15 9 -8 0 -12 -4
-9 -9z"></path><path d="M6176 4553 c-3 -3 -6 -19 -6 -35 l0 -29 15 15 c8 8 15 21 15 30 0 17
-14 29 -24 19z"></path><path d="M3006 4537 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M2580 4500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2922 4493 c2 -10 9 -19 16 -19 6 -1 19 -2 27 -3 8 0 15 -8 15 -17 0
-17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 5 -16 10 0 6 -9 10 -20 10 -11 0
-20 5 -20 10 0 6 -5 10 -11 10 -6 0 -9 -8 -7 -17z"></path><path d="M2410 4485 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M4860 4480 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1485 4460 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2925 4440 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M150 4425 l0 -14 15 5 c8 4 15 7 15 9 0 2 -7 5 -15 9 l-15 5 0 -14z"></path><path d="M2377 4423 c3 -7 9 -13 14 -13 5 0 9 6 9 13 0 8 -6 14 -14 14 l-14 0
5 -14z"></path><path d="M4906 4421 c-3 -5 1 -14 9 -21 l15 -12 0 21 c0 21 -14 28 -24 12z"></path><path d="M6201 4414 c-1 -12 5 -24 13 -27 17 -7 26 1 26 21 l0 14 -14 -5 c-8
-3 -17 -1 -20 6 -2 7 -5 2 -5 -9z"></path><path d="M165 4400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2936 4394 l-6 -15 17 3 c9 2 18 9 21 16 6 18 -25 15 -32 -4z"></path><path d="M2333 4383 c9 -9 20 -13 24 -9 4 4 1 11 -7 16 -25 16 -35 11 -17 -7z"></path><path d="M130 4364 l0 -15 12 7 11 7 -7 -12 -7 -11 16 -16 c9 -9 19 -13 23 -9
4 4 1 10 -5 12 -7 3 -13 13 -13 23 0 10 -7 20 -15 24 l-15 5 0 -15z"></path><path d="M2288 4361 c-10 -10 -18 -21 -18 -24 0 -14 23 -7 36 11 l14 17 14
-17 c7 -10 17 -18 21 -18 5 0 -2 11 -15 25 -13 14 -26 25 -29 24 -3 0 -14 -8
-23 -18z"></path><path d="M6236 4344 c-9 -8 -16 -21 -16 -27 l1 -12 13 17 c7 10 16 15 19 11 4
-3 7 1 7 10 0 21 -4 21 -24 1z"></path><path d="M6168 4314 c2 -13 8 -24 13 -24 5 0 9 2 9 4 0 2 3 11 6 19 l5 14 -19
5 -19 5 5 -23z"></path><path d="M6226 4285 c4 -8 10 -15 15 -15 5 0 9 -9 9 -20 0 -22 16 -27 23 -7 2
6 -7 22 -20 35 -28 26 -35 28 -27 7z"></path><path d="M2851 4281 l-13 -8 10 -7 c6 -3 14 -1 17 5 9 14 2 20 -14 10z"></path><path d="M2241 4243 c-31 -33 -19 -42 13 -10 14 14 22 28 18 30 -4 2 -18 -7
-31 -20z"></path><path d="M2775 4240 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2170 4180 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2260 4180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M87 4159 l6 -22 -11 7 -12 7 0 -14 0 -15 21 5 20 6 -3 20 c-2 11 -8
22 -15 24 l-11 4 5 -22z"></path><path d="M2700 4120 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2057 4113 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M2025 4070 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2680 4060 c6 -11 12 -20 14 -20 2 0 2 9 -1 20 -3 11 -9 20 -15 20
l-9 0 11 -20z"></path><path d="M2610 4060 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M6310 4050 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M50 4024 l0 -26 13 4 c6 3 13 14 15 26 l3 22 -15 0 -16 0 0 -26z"></path><path d="M6326 4031 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M2600 4021 c0 -11 26 -22 34 -14 3 3 3 10 0 14 -7 12 -34 11 -34 0z"></path><path d="M2655 4000 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2630 3976 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M1914 3949 c-3 -6 -2 -15 4 -21 l10 -10 11 21 12 21 -15 0 c-8 0 -18
-5 -22 -11z"></path><path d="M2560 3920 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1890 3910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1930 3900 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1870 3886 c0 -7 9 -16 20 -19 l20 -5 -11 19 c-11 22 -29 25 -29 5z"></path><path d="M2540 3896 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z"></path><path d="M1926 3863 c-5 -6 18 -33 28 -33 3 0 6 6 6 14 0 15 -24 28 -34 19z"></path><path d="M2533 3853 c-13 -2 -23 -7 -23 -9 0 -3 5 -14 11 -25 l11 -21 19 18
c19 17 26 45 12 43 -5 -1 -18 -4 -30 -6z"></path><path d="M1180 3840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6310 3830 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1865 3819 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1160 3800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1243 3790 c-3 -11 -9 -20 -14 -20 -5 0 -9 -11 -9 -25 0 -14 4 -25
10 -25 l9 0 -6 17 -6 16 11 -7 12 -7 -6 16 -6 16 12 -3 c7 -2 15 3 18 11 l5
14 -13 -8 -13 -8 6 16 c3 10 4 17 1 17 -3 0 -8 -9 -11 -20z"></path><path d="M2496 3774 c-3 -9 -3 -20 0 -26 l6 -9 14 18 c17 23 17 33 0 33 -8 0
-17 -7 -20 -16z"></path><path d="M6363 3753 c-7 -2 -13 -18 -13 -34 l0 -29 15 0 15 0 0 35 c0 19 -1
35 -2 34 -2 0 -9 -3 -15 -6z"></path><path d="M10 3724 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M1750 3736 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M1182 3699 c2 -7 10 -15 17 -17 l14 -4 -5 13 c-2 7 -10 15 -17 17
l-13 5 4 -14z"></path><path d="M2360 3680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2410 3681 c0 -5 9 -11 19 -14 l20 -5 -6 14 c-6 15 -33 19 -33 5z"></path><path d="M1152 3671 c-17 -10 -12 -43 7 -49 l14 -5 -7 28 -7 27 12 -8 12 -7
-7 11 c-8 14 -7 14 -24 3z"></path><path d="M1690 3656 c0 -2 7 -9 15 -16 l15 -12 0 16 c0 9 -7 16 -15 16 -8 0
-15 -2 -15 -4z"></path><path d="M2320 3640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1670 3630 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1090 3601 c0 -5 9 -11 19 -14 l20 -5 -6 14 c-6 15 -33 19 -33 5z"></path><path d="M2280 3590 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2382 3598 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 l-16 6 4 -12z"></path><path d="M1750 3580 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1630 3565 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 l-15
5 0 -14z"></path><path d="M1000 3540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1060 3541 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M1720 3540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1593 3520 c-3 -11 -9 -20 -14 -20 -5 0 -9 -8 -9 -17 l1 -18 24 28
c27 30 32 47 14 47 -6 0 -13 -9 -16 -20z"></path><path d="M2290 3520 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1042 3499 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"></path><path d="M2316 3494 c-3 -8 -10 -12 -16 -9 -16 10 -12 -12 6 -29 19 -20 28
-20 20 -1 l-5 15 12 -3 c6 -1 13 8 15 21 l3 22 -14 0 c-8 0 -18 -7 -21 -16z"></path><path d="M1016 3468 c9 -30 12 -34 24 -22 14 14 2 44 -17 44 l-14 0 7 -22z"></path><path d="M1530 3430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1560 3380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M900 3360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2200 3360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2245 3331 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M2215 3320 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M913 3298 c2 -7 10 -13 17 -13 7 0 15 6 18 13 l4 12 -22 0 -22 0 5
-12z"></path><path d="M1430 3270 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M860 3260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5157 3257 c-4 -10 -7 -26 -7 -35 l1 -17 10 18 c6 10 9 25 7 35 l-4
17 -7 -18z"></path><path d="M2120 3230 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1396 3213 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M740 3200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M820 3200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M862 3198 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z"></path><path d="M1460 3200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M845 3150 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M780 3113 c0 -13 5 -23 10 -23 13 0 13 11 0 30 l-10 15 0 -22z"></path><path d="M1326 3089 c-19 -38 -19 -42 -5 -37 6 2 9 6 7 10 -2 3 5 15 15 26
l18 20 -10 6 c-5 3 -17 -8 -25 -25z"></path><path d="M1360 3065 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M5140 3060 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M787 3051 l-19 -7 16 -13 c18 -15 29 -10 24 12 l-3 14 -18 -6z"></path><path d="M2050 3040 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M1284 3004 l6 -15 -10 6 c-6 3 -10 -1 -10 -9 0 -9 7 -16 15 -16 l15
0 0 25 c0 14 -5 25 -11 25 l-11 0 6 -16z"></path><path d="M2090 3010 l-15 -9 12 -12 12 -12 7 17 c8 22 2 27 -16 16z"></path><path d="M694 2994 c3 -9 6 -18 6 -20 0 -2 5 -4 10 -4 6 0 10 9 10 20 l0 20
-16 0 -16 0 6 -16z"></path><path d="M2050 3000 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M740 2961 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M2042 2903 c4 -20 22 -21 26 -2 l3 16 -16 0 -16 0 3 -14z"></path><path d="M1260 2899 l0 -11 -14 5 -15 6 6 -15 c7 -20 35 -14 39 9 1 9 -2 17
-7 17 -5 0 -9 -5 -9 -11z"></path><path d="M1980 2900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M50 2881 l0 -20 15 6 c17 6 15 25 -3 31 l-12 4 0 -21z"></path><path d="M100 2879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6310 2871 c0 -10 7 -21 15 -25 l15 -5 0 24 0 25 -15 0 -15 0 0 -19z"></path><path d="M1230 2840 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2000 2821 c0 -5 7 -14 15 -21 l15 -12 0 21 0 21 -15 0 c-8 0 -15 -4
-15 -9z"></path><path d="M1250 2780 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1990 2750 l-15 -9 12 -12 12 -12 7 17 c8 22 2 27 -16 16z"></path><path d="M1200 2740 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M600 2720 c0 -5 5 -10 11 -10 5 0 7 -4 4 -10 -3 -5 -1 -10 4 -10 6 0
11 9 11 20 l0 20 -15 0 c-8 0 -15 -4 -15 -10z"></path><path d="M6210 2710 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6260 2711 c0 -18 24 -51 37 -51 2 0 2 14 -2 31 l-7 32 12 -8 13 -8
-7 11 c-4 7 -16 12 -27 12 l-19 0 0 -19z"></path><path d="M620 2641 c0 -16 4 -33 10 -36 l10 -6 -7 17 -6 17 11 -7 12 -7 0 11
c0 6 -7 18 -15 26 l-15 15 0 -30z"></path><path d="M540 2590 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M148 2568 c-19 -24 -24 -53 -8 -43 6 3 10 13 10 21 0 8 5 13 12 12 6
-2 14 5 16 15 5 23 -9 21 -30 -5z"></path><path d="M1917 2573 c-12 -11 -8 -30 6 -30 7 0 13 9 13 19 0 19 -8 23 -19 11z"></path><path d="M6190 2560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1090 2544 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M1111 2514 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M156 2494 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"></path><path d="M1800 2490 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6216 2493 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M1120 2440 c0 -16 -3 -30 -7 -30 -5 0 -17 7 -27 15 -23 18 -36 11
-36 -19 l0 -23 32 5 c18 3 38 11 45 18 15 15 17 64 3 64 -5 0 -10 -13 -10 -30z"></path><path d="M6190 2415 c0 -8 7 -15 15 -15 l14 0 -5 15 c-4 8 -10 15 -15 15 -5 0
-9 -7 -9 -15z"></path><path d="M537 2403 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M6168 2383 c0 -5 -2 -20 -3 -34 l-2 -27 16 3 16 2 1 31 1 32 -13 0
c-8 0 -15 -3 -16 -7z"></path><path d="M570 2361 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M217 2336 c-9 -24 -9 -28 3 -21 5 3 16 -7 24 -22 l15 -28 1 17 c0 9
-5 20 -11 23 -6 4 -14 16 -18 28 l-8 20 -6 -17z"></path><path d="M4833 2332 c0 -12 5 -22 9 -22 11 0 10 12 -2 30 l-9 15 2 -23z"></path><path d="M253 2323 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M1840 2292 l0 -39 15 0 15 0 0 23 c0 12 -3 29 -6 38 -11 29 -24 17
-24 -22z"></path><path d="M1060 2259 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1016 2244 c-10 -25 -7 -92 4 -99 l10 -6 -6 15 -6 16 11 0 c6 0 11 8
11 18 l0 17 -10 -15 -10 -15 0 12 c0 6 5 15 10 18 12 8 13 42 1 49 -5 3 -12
-1 -15 -10z"></path><path d="M4793 2253 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M275 2237 c-20 -23 -26 -37 -20 -47 3 -5 12 -7 21 -3 l15 5 -6 19 -6
19 10 0 c6 0 11 5 11 10 0 13 -13 12 -25 -3z"></path><path d="M340 2240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1100 2240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6075 2158 c4 -13 12 -27 18 -33 l10 -10 -7 18 c-3 10 -6 25 -6 33 0
8 -5 14 -11 14 l-10 0 6 -22z"></path><path d="M1060 2138 c0 -9 -3 -23 -6 -32 l-6 -16 16 0 16 0 0 18 c0 9 -5 24
-10 32 l-10 15 0 -17z"></path><path d="M420 2080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1800 2080 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M400 1979 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6013 1975 c0 -18 19 -15 25 3 2 7 -3 12 -10 12 -8 0 -15 -7 -15 -15z"></path><path d="M404 1954 c-10 -8 -13 -12 -6 -9 l13 7 -2 -13 c-3 -25 1 -42 8 -35 7
6 14 66 9 66 -1 0 -11 -7 -22 -16z"></path><path d="M542 1950 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path><path d="M575 1931 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M480 1920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5970 1921 c0 -5 7 -11 15 -15 l15 -5 0 14 c0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"></path><path d="M5890 1880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5952 1878 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"></path><path d="M430 1857 c0 -8 7 -14 15 -14 8 0 15 6 15 14 0 7 -7 13 -15 13 -8 0
-15 -6 -15 -13z"></path><path d="M470 1783 c0 -5 6 -15 13 -24 l12 -16 33 5 c35 5 45 22 13 22 -11 0
-23 5 -26 10 -7 12 -45 14 -45 3z"></path><path d="M5833 1771 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z"></path><path d="M5892 1778 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"></path><path d="M1050 1758 c0 -7 -9 -24 -20 -38 l-20 -25 0 -32 0 -33 15 0 c8 0 14
3 14 8 -1 4 -1 26 0 50 1 42 21 61 21 20 l0 -23 10 15 c5 8 10 27 10 43 l0 27
-15 0 c-8 0 -15 -6 -15 -12z"></path><path d="M566 1704 l-16 -15 0 10 c0 6 -4 11 -10 11 -19 0 -10 -30 18 -61 l28
-31 -1 51 c0 28 -1 51 -2 51 -1 0 -9 -7 -17 -16z"></path><path d="M5865 1710 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5813 1691 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z"></path><path d="M1100 1630 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3950 1620 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"></path><path d="M618 1613 c-16 -2 -28 -7 -28 -11 1 -8 47 -62 53 -62 2 0 4 18 3 40
-1 22 -1 39 -1 38 0 0 -12 -3 -27 -5z"></path><path d="M1065 1600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1140 1590 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5750 1600 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"></path><path d="M1080 1530 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5650 1540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5716 1523 l-18 -25 11 -11 12 -12 -7 18 -6 17 15 -5 14 -6 6 14 c3
8 2 19 -1 24 l-7 11 -19 -25z"></path><path d="M2773 1533 c-17 -6 -16 -23 1 -23 8 0 16 7 20 15 6 15 1 17 -21 8z"></path><path d="M2926 1505 l-5 -15 -28 0 c-15 0 -35 -5 -43 -10 l-15 -10 40 0 c57 0
82 9 88 31 l5 19 -18 0 c-10 0 -20 -7 -24 -15z"></path><path d="M5667 1459 l-5 -20 15 3 c22 4 27 24 9 31 l-14 6 -5 -20z"></path><path d="M1120 1460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2357 1423 l-5 -28 14 20 c16 22 18 35 5 35 -5 0 -11 -12 -14 -27z"></path><path d="M5505 1430 l6 -20 -10 0 c-6 0 -11 -4 -11 -10 0 -11 30 -14 30 -2 0
4 3 17 6 30 l6 22 -17 0 -16 0 6 -20z"></path><path d="M5620 1426 c0 -2 7 -9 15 -16 l15 -13 -6 17 c-3 9 -10 16 -15 16 -5
0 -9 -2 -9 -4z"></path><path d="M1102 1386 c-12 -28 -9 -46 9 -46 6 0 8 4 5 9 -4 5 -2 11 2 13 5 1 6
14 2 27 l-5 24 -13 -27z"></path><path d="M5530 1360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2377 1343 c-11 -10 -8 -71 3 -78 l10 -6 0 15 c0 9 5 16 10 16 6 0
10 14 10 30 l0 30 -13 0 c-8 0 -17 -3 -20 -7z"></path><path d="M5510 1320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1110 1310 c0 -6 5 -10 11 -10 5 0 8 -4 5 -8 -2 -4 -1 -14 3 -20 l8
-13 16 7 c21 8 22 24 2 24 -8 0 -15 4 -15 9 0 5 -7 11 -15 15 -8 3 -15 1 -15
-4z"></path><path d="M5536 1294 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M5473 1274 c-7 -19 0 -47 15 -52 l12 -4 0 16 c0 9 -5 16 -11 16 -5 0
-7 5 -4 11 4 6 13 8 21 5 l13 -5 -5 13 c-6 16 -34 16 -41 0z"></path><path d="M5410 1240 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5375 1200 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M5435 1190 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M5366 1135 c4 -9 11 -14 16 -13 15 5 11 23 -6 26 l-16 3 6 -16z"></path><path d="M5330 1125 c0 -8 2 -15 4 -15 2 0 12 -3 21 -6 l17 -7 -13 22 c-16 25
-29 27 -29 6z"></path><path d="M1260 1110 c0 -11 5 -20 11 -20 5 0 7 5 4 10 -3 6 -1 10 4 10 6 0 11
5 11 10 0 6 -7 10 -15 10 l-15 0 0 -20z"></path><path d="M2455 1120 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
l-25 0 6 -10z"></path><path d="M2530 1116 l0 -14 20 5 c11 3 20 9 20 14 0 5 -9 9 -20 9 l-20 0 0
-14z"></path><path d="M5170 1104 c0 -10 31 -36 37 -31 10 10 -6 37 -22 37 -8 0 -15 -3 -15
-6z"></path><path d="M5290 1101 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M2456 1074 c-9 -23 -7 -36 3 -30 12 8 21 46 11 46 -4 0 -11 -7 -14
-16z"></path><path d="M5270 1060 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"></path><path d="M2532 1050 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path><path d="M5170 1040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5256 1035 c4 -8 11 -15 16 -15 l10 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"></path><path d="M1273 1017 c5 -15 37 -22 37 -9 0 5 -9 12 -21 15 l-20 5 4 -11z"></path><path d="M5193 983 c-3 -7 -13 -13 -23 -13 -10 0 -20 -6 -23 -13 l-5 -13 19 0
c11 0 19 3 19 6 0 3 14 11 30 18 17 8 30 18 30 22 0 14 -42 7 -47 -7z"></path><path d="M1306 982 c-3 -6 -1 -14 5 -17 14 -9 20 -2 10 14 l-8 13 -7 -10z"></path><path d="M1854 973 c-10 -7 -14 -16 -10 -20 4 -4 11 -2 15 5 4 7 12 12 17 11
18 -3 34 1 34 9 0 12 -37 9 -56 -5z"></path><path d="M2537 964 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M1360 960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5070 960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2530 920 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5010 920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5110 910 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5090 895 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M1397 876 l-6 -15 15 6 c15 6 19 23 5 23 -5 0 -11 -7 -14 -14z"></path><path d="M1445 880 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1910 869 l0 -20 10 6 c5 3 7 12 3 20 -7 21 -13 19 -13 -6z"></path><path d="M2530 874 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M5055 870 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M1432 823 c2 -9 9 -18 16 -21 l12 -4 0 21 0 21 -16 0 -15 0 3 -17z"></path><path d="M1943 833 c-20 -8 -15 -23 7 -23 l20 0 0 15 c0 15 -6 17 -27 8z"></path><path d="M1840 820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2570 796 l0 -15 19 2 19 1 -5 13 c-7 18 -33 17 -33 -1z"></path><path d="M2668 779 c17 -29 32 -38 32 -19 0 6 -5 10 -11 10 l-10 0 5 18 6 18
-19 1 -19 2 16 -30z"></path><path d="M4690 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4790 794 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M4730 786 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"></path><path d="M1473 778 c2 -7 10 -13 17 -13 7 0 15 6 18 13 l4 12 -22 0 -22 0 5
-12z"></path><path d="M4850 779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4906 775 l-6 -16 17 3 c18 3 21 20 5 26 -5 1 -12 -4 -16 -13z"></path><path d="M2590 754 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M4870 761 c0 -6 -11 -11 -25 -11 l-25 0 0 -15 0 -15 21 0 c11 0 18 4
14 9 -3 5 7 11 22 13 l26 3 -16 14 -17 13 0 -11z"></path><path d="M2615 742 c-11 -12 -4 -21 13 -15 l17 7 -13 -16 -13 -16 16 -15 c8
-9 15 -20 15 -26 0 -15 16 -22 34 -15 22 8 20 24 -2 24 -10 0 -25 9 -35 20 -9
11 -15 21 -12 21 3 1 9 2 14 3 20 2 18 12 -4 22 -13 7 -26 9 -30 6z"></path><path d="M1994 728 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M1543 723 c-17 -6 -17 -23 0 -23 8 0 14 7 14 15 0 8 -1 15 -1 14 -1
0 -7 -3 -13 -6z"></path><path d="M1605 720 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4690 700 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M4766 695 l-5 -15 13 0 c7 0 13 7 13 15 0 8 -4 15 -8 15 -4 0 -9 -7
-13 -15z"></path><path d="M2010 691 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1620 679 c0 -5 -4 -8 -9 -5 -5 3 -12 -1 -15 -10 l-6 -16 20 -5 c11
-3 20 -12 20 -20 l0 -14 11 6 10 7 -16 18 -15 17 6 17 c3 9 3 16 0 16 -3 0 -6
-5 -6 -11z"></path><path d="M4716 668 c3 -4 15 -8 26 -8 12 0 18 3 14 7 -10 10 -46 11 -40 1z"></path><path d="M2033 653 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1 0
-8 -3 -14 -6z"></path><path d="M2726 635 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"></path><path d="M2685 630 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M4655 630 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M1760 620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1960 610 l0 -20 15 0 c17 0 18 2 9 24 -9 23 -24 20 -24 -4z"></path><path d="M4490 620 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2710 595 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M4370 585 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4518 583 c-8 -10 -18 -24 -23 -31 l-7 -14 23 4 24 3 0 28 c1 15 0
27 0 27 -1 0 -9 -8 -17 -17z"></path><path d="M1697 578 c-2 -7 3 -18 12 -25 21 -16 28 -16 28 1 l0 14 -15 -6 -15
-5 6 16 c3 9 2 17 -2 17 -5 0 -11 -6 -14 -12z"></path><path d="M4450 580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1780 560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1976 554 c-9 -22 -8 -24 9 -24 l15 0 0 20 c0 24 -15 27 -24 4z"></path><path d="M2801 536 l-12 -14 20 5 21 5 0 -13 c0 -7 2 -10 5 -7 7 7 -5 38 -15
38 -4 0 -13 -6 -19 -14z"></path><path d="M4450 535 l-12 -15 14 0 c8 0 15 7 15 15 0 8 -1 15 -2 15 -2 0 -8 -7
-15 -15z"></path><path d="M1750 520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1960 500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2060 500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4370 495 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1795 480 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"></path><path d="M1834 465 c1 -9 12 -22 24 -31 22 -15 42 -12 42 7 l0 10 -20 -6 -20
-6 0 20 0 21 -14 0 c-8 0 -13 -7 -12 -15z"></path><path d="M1943 473 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2803 468 c2 -7 10 -12 16 -10 6 1 10 -1 9 -5 -2 -5 5 -19 14 -33
l18 -24 12 12 13 13 -34 29 c-35 31 -56 39 -48 18z"></path><path d="M4214 465 l5 -15 -13 0 c-8 0 -16 -7 -20 -15 l-5 -15 19 0 20 0 -2
13 c-2 6 4 11 13 11 l16 -1 -4 18 c-3 11 -12 19 -20 19 l-15 0 6 -15z"></path><path d="M4325 470 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4270 455 l-12 -15 14 0 c8 0 15 7 15 15 0 8 -1 15 -2 15 -2 0 -8 -7
-15 -15z"></path><path d="M2143 413 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2100 400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2877 396 l-6 -15 15 6 c15 6 19 23 5 23 -5 0 -11 -7 -14 -14z"></path><path d="M2200 386 c0 -14 29 -31 38 -21 6 6 -18 35 -29 35 -5 0 -9 -6 -9 -14z"></path><path d="M1996 375 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"></path><path d="M4000 375 l-12 -15 20 0 c29 0 53 11 46 21 -8 14 -40 11 -54 -6z"></path><path d="M3890 360 l0 -20 33 0 32 1 -24 19 c-13 11 -27 20 -32 20 -5 0 -9 -9
-9 -20z"></path><path d="M2025 339 c-4 -6 -5 -11 -4 -12 2 -1 14 -7 25 -14 27 -15 36 -7 21
18 -13 21 -32 25 -42 8z m40 -19 c3 -5 4 -10 1 -10 -3 0 -8 5 -11 10 -3 6 -4
10 -1 10 3 0 8 -4 11 -10z"></path><path d="M3050 320 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 l-23 0 15 -10z"></path><path d="M3726 315 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11 -7
-15 -15z"></path><path d="M2186 313 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M2266 313 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M3566 295 l-5 -15 20 0 c11 0 18 4 15 9 -4 5 0 12 6 14 l13 5 -21 1
c-12 1 -24 -6 -28 -14z"></path><path d="M2100 281 c0 -9 55 -41 70 -41 5 0 11 7 14 16 l6 16 -19 -5 c-11 -3
-21 -1 -23 3 -2 4 -13 11 -25 14 l-23 7 0 -10z"></path><path d="M2260 260 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2285 260 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2190 239 l-12 -21 19 4 c10 2 19 11 21 21 5 24 -15 21 -28 -4z"></path><path d="M2215 221 c-7 -7 6 -21 20 -21 7 0 15 6 18 14 l6 15 -20 -2 c-11 -1
-22 -3 -24 -6z"></path><path d="M2367 159 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z"></path><path d="M2437 104 c-9 -10 2 -24 18 -24 l14 0 -5 15 c-6 16 -17 19 -27 9z"></path><path d="M2514 63 c2 -10 10 -19 19 -21 l17 -3 0 14 c0 8 -7 18 -16 21 -22 8
-23 8 -20 -11z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#5e8b23" stroke="none"><path d="M2670 5321 l-65 -17 -3 -17 c-2 -9 1 -17 7 -17 6 0 11 5 11 10 0 6
13 10 29 10 17 0 33 5 36 10 3 6 21 10 40 10 l33 0 6 15 5 15 -17 -1 c-9 0
-46 -9 -82 -18z"></path><path d="M2787 5320 l6 -21 15 3 c8 2 16 11 18 21 l2 17 -23 0 -23 0 5 -20z"></path><path d="M2503 5268 c-56 -22 -69 -38 -30 -38 26 0 102 34 112 50 l6 10 -18
-1 c-10 -1 -42 -10 -70 -21z"></path><path d="M2736 5275 c4 -8 12 -15 20 -15 l13 0 -5 15 c-4 8 -12 15 -20 15
l-13 0 5 -15z"></path><path d="M2681 5243 l-24 -25 14 -9 c21 -13 32 -11 25 7 l-6 15 11 -6 c14 -9
39 14 32 31 -8 20 -25 16 -52 -13z"></path><path d="M2392 5223 c-6 -2 -10 -9 -7 -14 l6 -9 -15 6 c-18 7 -79 -24 -71 -36
l6 -10 -15 6 c-17 6 -56 -12 -56 -26 0 -6 9 -10 20 -10 11 0 20 5 20 10 0 6 6
10 14 10 17 0 100 42 104 53 2 4 12 7 23 7 10 0 19 5 19 10 0 10 -26 12 -48 3z"></path><path d="M2600 5210 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2625 5170 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15
0 6 -10z"></path><path d="M4042 5168 c6 -18 25 -20 31 -3 l6 15 -20 0 -21 0 4 -12z"></path><path d="M4020 5152 c0 -5 7 -13 16 -16 l16 -6 -4 12 c-5 15 -28 23 -28 10z"></path><path d="M4090 5151 c0 -6 4 -11 8 -11 5 0 22 -11 38 -25 29 -25 64 -33 64
-16 0 10 -56 45 -89 56 l-21 6 0 -10z"></path><path d="M2395 5140 l-6 -10 25 0 c14 0 26 5 26 10 0 6 -9 10 -19 10 -11 0
-23 -4 -26 -10z"></path><path d="M2185 5114 c-16 -8 -35 -21 -42 -27 l-12 -12 9 -15 10 -15 0 13 c0 6
7 12 15 12 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 7 15 15 l0 14 17 -6 16 -6
-7 11 c-9 15 -15 15 -51 -4z"></path><path d="M2508 5123 c6 -2 12 -11 12 -18 0 -7 6 -15 13 -18 l12 -4 0 23 0 24
-25 -1 c-14 0 -19 -3 -12 -6z"></path><path d="M4037 5094 c-4 -4 -7 -17 -7 -28 l0 -21 10 15 10 15 0 -12 c0 -7 5
-13 10 -13 14 0 13 13 -3 34 -7 9 -16 14 -20 10z"></path><path d="M4210 5080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2092 5054 c-12 -8 -20 -20 -17 -25 l6 -9 -15 6 -16 6 0 -11 c0 -6 9
-11 20 -11 11 0 20 5 20 10 0 6 7 10 15 10 8 0 15 2 15 4 0 2 3 11 6 20 8 20
-5 20 -34 0z"></path><path d="M2453 5043 c-15 -32 -16 -43 -4 -43 5 0 8 8 7 17 l-1 18 23 1 c12 1
25 4 28 7 10 9 -6 27 -24 27 l-17 0 -12 -27z"></path><path d="M3970 5059 l0 -10 12 7 11 7 -6 -11 c-8 -12 1 -42 13 -42 14 0 21 32
9 46 -14 16 -39 19 -39 3z"></path><path d="M4235 5060 l-6 -10 20 0 c12 0 21 -4 21 -10 0 -5 7 -10 15 -10 8 0
15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -6 10 -14 10 -8 0 -18 -4
-21 -10z"></path><path d="M3928 5017 c6 -23 25 -47 38 -47 3 0 2 6 -4 13 -10 12 -12 18 -12 36
0 5 -6 11 -14 14 l-14 6 6 -22z"></path><path d="M4170 5020 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"></path><path d="M4307 5023 c-11 -10 13 -33 34 -33 24 0 24 9 2 26 -21 15 -27 17 -36
7z"></path><path d="M1970 4964 c-75 -63 -160 -142 -160 -148 0 -15 37 -4 50 14 8 11 20
20 27 20 6 0 14 9 18 19 3 11 19 26 35 33 17 7 30 16 30 20 0 5 9 8 20 8 l20
0 0 18 c0 10 9 24 19 32 18 12 20 30 3 30 -4 0 -32 -21 -62 -46z"></path><path d="M4030 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3881 4971 c-7 -5 -11 -15 -9 -22 l5 -14 12 23 c13 24 12 26 -8 13z"></path><path d="M4383 4963 l-13 -5 29 -24 c16 -13 32 -24 36 -24 4 0 22 -16 40 -36
l33 -36 8 13 8 13 -60 53 c-32 29 -61 53 -64 52 -3 0 -11 -3 -17 -6z"></path><path d="M2380 4950 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2280 4924 l0 -25 9 6 c11 6 10 45 -1 45 -4 0 -8 -12 -8 -26z"></path><path d="M3843 4916 l-12 -14 12 -5 13 -5 1 19 c1 10 1 19 0 19 -1 -1 -7 -7
-14 -14z"></path><path d="M2310 4890 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2215 4880 c3 -5 1 -10 -4 -10 -6 0 -11 -9 -11 -20 l0 -20 16 0 15 0
-6 10 c-3 6 -1 10 5 10 12 0 40 24 40 34 0 3 -14 6 -31 6 l-30 0 6 -10z"></path><path d="M3786 4857 c11 -12 20 -24 21 -26 2 -2 3 5 3 16 l0 21 -21 5 -21 6
18 -22z"></path><path d="M3870 4860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4370 4860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4530 4830 l0 -20 15 0 c8 0 15 -5 15 -11 0 -6 16 -26 35 -45 19 -18
35 -38 35 -42 0 -5 9 -17 20 -27 l20 -19 11 11 11 11 -44 48 c-24 27 -56 64
-72 82 -16 17 -33 32 -38 32 -4 0 -8 -9 -8 -20z"></path><path d="M1940 4820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2010 4800 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M2146 4798 l7 -11 -16 6 -16 6 -16 -15 c-17 -17 -20 -34 -6 -34 5 0
18 -3 28 -7 l18 -7 -14 17 -13 17 16 0 c9 0 16 2 16 4 0 2 3 11 6 20 l6 16
-12 0 -11 0 7 -12z"></path><path d="M3707 4789 l12 -12 -10 -18 -9 -19 15 6 c21 8 19 40 -2 49 l-18 6 12
-12z"></path><path d="M1734 4741 c-22 -25 -53 -63 -68 -83 l-28 -37 15 -15 15 -15 17 36
c10 20 27 46 39 58 11 12 28 32 36 44 8 11 19 21 25 21 6 0 11 9 12 20 l2 20
-12 -1 c-7 -1 -30 -22 -53 -48z"></path><path d="M1963 4721 c1 -16 5 -27 10 -24 10 6 9 53 -3 53 -5 0 -8 -13 -7 -29z"></path><path d="M2037 4710 c7 -27 21 -34 38 -20 19 16 7 40 -20 40 l-23 0 5 -20z"></path><path d="M1775 4660 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M2006 4663 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M3830 4661 c0 -5 -3 -16 -6 -25 l-6 -16 11 0 c7 0 18 5 25 12 l13 12
-13 13 c-15 15 -24 17 -24 4z"></path><path d="M4690 4660 c0 -5 5 -10 10 -10 6 0 10 -7 10 -16 0 -17 23 -44 38 -44
5 0 12 -13 16 -30 l6 -30 15 0 c18 0 19 5 4 33 -13 23 -23 36 -60 78 -27 30
-39 36 -39 19z"></path><path d="M1960 4641 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M3930 4640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4030 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1606 4585 c-9 -14 -14 -31 -10 -37 l7 -11 -11 7 -11 6 -6 -20 c-3
-11 -11 -20 -17 -20 l-10 0 6 -17 c4 -10 4 -14 0 -10 -16 16 -145 -234 -199
-389 l-29 -80 13 -31 13 -31 23 -4 23 -5 -5 14 c-5 12 -17 17 -35 14 -10 -1
-10 4 2 59 10 45 28 91 83 210 16 36 30 70 31 75 6 30 18 55 25 51 7 -5 35 53
34 71 0 4 8 14 18 21 11 7 19 22 19 33 0 10 5 19 11 19 5 0 7 5 4 10 l-6 10
15 0 c9 0 16 6 16 13 0 7 9 23 19 34 18 19 18 33 0 33 -3 0 -14 -11 -23 -25z"></path><path d="M1800 4590 c0 -24 15 -27 24 -4 9 22 8 24 -9 24 l-15 0 0 -20z"></path><path d="M3887 4596 l-6 -15 15 6 c7 3 14 9 14 14 0 14 -17 10 -23 -5z"></path><path d="M3969 4600 c-1 -5 -2 -16 -3 -22 -1 -7 -8 -12 -15 -10 l-12 2 6 -10
6 -10 22 13 c23 13 37 27 37 40 0 11 -40 8 -41 -3z"></path><path d="M1863 4564 c-13 -13 -23 -27 -23 -32 0 -4 -10 -18 -21 -31 l-21 -23
9 -9 10 -10 13 30 c7 17 17 31 22 31 l10 0 -11 -14 -12 -14 12 -7 11 -8 9 14
8 13 -10 12 -9 11 14 13 c9 6 20 9 25 5 l11 -6 -7 17 -6 17 13 -8 13 -8 -7 12
c-4 6 -11 9 -16 6 -4 -3 -10 -1 -12 3 -1 5 -13 -1 -25 -14z"></path><path d="M4030 4540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4795 4520 c-3 -5 -2 -10 3 -10 6 0 8 -8 5 -18 l-4 -18 18 -20 c10
-11 26 -23 36 -27 l17 -6 0 15 0 16 -16 -6 -15 -6 6 9 c6 11 -25 81 -36 81 -4
0 -11 -4 -14 -10z"></path><path d="M3988 4487 l-5 -24 23 5 23 4 -12 19 c-6 10 -15 19 -18 19 -4 0 -9
-10 -11 -23z"></path><path d="M1680 4480 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M4130 4480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4190 4420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1720 4395 c0 -8 5 -17 10 -20 l10 -6 -2 13 -3 13 15 -3 c8 -2 11 0
7 4 -13 14 -37 13 -37 -1z"></path><path d="M4070 4404 c0 -12 29 -34 45 -34 l17 0 -6 16 c-3 9 -6 18 -6 20 0 2
-11 4 -25 4 -14 0 -25 -3 -25 -6z"></path><path d="M4874 4388 c7 -39 18 -58 31 -58 l13 0 -5 -20 c-3 -11 -1 -20 4 -20
10 0 36 -53 48 -97 3 -11 12 -24 20 -27 l15 -5 0 12 c0 7 -25 63 -56 125 -53
106 -80 140 -70 90z"></path><path d="M1640 4380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1560 4340 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M1620 4320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1661 4304 c-12 -14 -21 -27 -20 -28 2 -2 14 -7 27 -10 l22 -7 0 9
c0 6 -5 12 -12 14 l-12 4 8 13 8 13 24 -6 24 -6 0 15 0 15 -22 -1 c-13 0 -34
-11 -47 -25z"></path><path d="M4107 4323 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"></path><path d="M4170 4320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4196 4287 c-5 -15 -6 -31 -2 -34 11 -11 18 5 14 34 l-4 28 -8 -28z"></path><path d="M4120 4281 c0 -6 5 -13 10 -16 6 -3 10 -15 10 -26 0 -21 15 -26 26
-8 l7 12 -16 9 -16 9 5 14 6 15 -16 0 c-9 0 -16 -4 -16 -9z"></path><path d="M1480 4260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1562 4213 l-3 -37 19 17 c10 9 21 15 24 12 2 -3 4 5 3 18 l-2 22
-19 3 -20 3 -2 -38z"></path><path d="M4169 4220 c-4 -25 -3 -30 5 -32 5 -2 11 7 12 20 2 12 -1 22 -6 22
-6 0 -11 -4 -11 -10z"></path><path d="M1460 4180 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4890 4180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1485 4160 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4350 4160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1503 4134 l-6 -16 14 -9 c18 -11 34 -6 34 12 l0 14 -19 -3 -18 -3 7
10 c3 6 4 11 0 11 -3 0 -8 -7 -12 -16z"></path><path d="M4250 4108 c0 -13 7 -31 14 -41 l14 -18 -9 -32 c-12 -41 -11 -41 4
-29 l12 10 3 59 3 58 -11 -15 -10 -15 0 23 c0 12 -4 22 -10 22 -5 0 -10 -10
-10 -22z"></path><path d="M5010 4086 c0 -13 7 -37 15 -52 8 -16 15 -39 15 -52 0 -13 7 -37 16
-53 8 -17 17 -40 20 -52 2 -11 8 -25 14 -31 l10 -10 0 12 c0 15 -26 113 -51
195 -22 68 -39 87 -39 43z"></path><path d="M1453 4073 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M1305 3931 c-3 -2 -5 -19 -5 -36 l0 -32 14 0 14 0 -5 14 -5 13 13 -5
14 -5 1 28 1 28 -19 0 c-10 0 -21 -2 -23 -5z"></path><path d="M4356 3914 c-9 -25 -7 -64 4 -64 l10 0 0 40 c0 43 -4 50 -14 24z"></path><path d="M4300 3840 l0 -18 20 5 c11 3 20 9 20 13 0 4 -9 10 -20 13 l-20 5 0
-18z"></path><path d="M5097 3824 c-8 -14 2 -72 14 -80 10 -6 10 16 0 61 l-6 30 -8 -11z"></path><path d="M4347 3720 c-3 -11 -1 -18 3 -15 5 3 10 -1 13 -7 2 -7 4 -1 4 15 1
31 -12 36 -20 7z"></path><path d="M5108 3683 c-3 -21 -1 -72 4 -113 5 -41 8 -129 7 -195 0 -66 0 -124
1 -130 1 -5 5 -1 10 10 18 42 9 465 -10 465 -4 0 -10 -17 -12 -37z"></path><path d="M4431 3514 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M4380 3324 l0 -55 10 6 10 6 0 -16 0 -16 -10 6 -10 6 0 -30 0 -30 15
5 15 6 0 78 0 78 -15 6 -15 5 0 -55z m17 -31 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3
-4 3 -10 0 -14z"></path><path d="M4430 3291 l0 -81 10 0 10 0 0 74 0 75 -10 6 -10 6 0 -80z"></path><path d="M5115 3207 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 l-4 18 -8 -18z"></path><path d="M5107 3160 c-3 -8 -11 -44 -17 -80 -7 -36 -19 -78 -27 -95 -15 -28
-38 -112 -50 -182 l-5 -33 9 0 c21 0 110 346 100 387 l-4 18 -6 -15z"></path><path d="M5020 3040 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4304 2738 c3 -13 6 -30 6 -38 l0 -15 10 15 c15 23 12 47 -6 54 l-16
6 6 -22z"></path><path d="M4987 2726 c-9 -10 -17 -24 -17 -31 0 -20 -32 -85 -42 -85 -4 0 -8
-7 -8 -15 0 -17 -41 -99 -52 -103 -10 -4 0 -22 11 -22 12 0 134 247 129 261
l-4 12 -17 -17z"></path><path d="M4890 2720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4288 2654 c2 -13 8 -24 13 -24 14 0 10 37 -4 43 l-14 5 5 -24z"></path><path d="M4350 2650 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M4264 2618 c3 -13 6 -38 6 -55 l0 -33 16 0 15 0 -7 48 c-8 54 -11 62
-26 62 l-10 0 6 -22z"></path><path d="M4330 2580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4250 2500 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4850 2455 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4187 2424 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M4806 2393 c-3 -10 -6 -22 -5 -26 0 -4 -14 -21 -30 -36 -17 -16 -27
-31 -24 -35 3 -3 0 -6 -7 -6 l-14 0 12 -12 13 -13 7 11 c60 87 82 122 82 128
0 14 -29 5 -34 -11z"></path><path d="M4190 2354 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M4080 2265 l0 -15 25 2 c14 1 25 6 25 11 0 5 -7 7 -15 3 -8 -3 -15
-1 -15 4 0 6 -4 10 -10 10 -5 0 -10 -7 -10 -15z"></path><path d="M4150 2260 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4690 2230 l0 -20 -16 0 c-18 0 -44 -23 -44 -40 0 -14 -25 -40 -38
-40 -6 0 -12 -6 -15 -14 l-6 -14 14 -6 14 -5 41 47 c23 26 53 59 66 73 13 14
24 29 24 32 0 4 -9 7 -20 7 l-20 0 0 -20z"></path><path d="M3986 2143 l-7 -16 12 -11 12 -11 -6 18 -7 17 20 -10 20 -11 0 15 c0
7 -7 17 -16 20 -21 8 -21 8 -28 -11z"></path><path d="M4030 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4540 2070 c-8 -11 -18 -20 -23 -20 -14 0 -67 -47 -60 -54 4 -3 -7
-6 -23 -7 l-29 0 19 -8 20 -8 -22 -5 -22 -6 6 -16 c3 -9 9 -16 13 -16 10 0
171 145 171 154 0 15 -37 4 -50 -14z"></path><path d="M3936 2034 c-9 -23 -8 -24 12 -24 l18 0 1 20 2 20 -14 0 c-7 0 -16
-7 -19 -16z"></path><path d="M3861 1984 c13 -16 29 -19 29 -5 0 5 -9 11 -21 14 l-20 5 12 -14z"></path><path d="M3816 1945 c4 -8 16 -15 28 -15 l21 1 -19 14 c-25 19 -37 19 -30 0z"></path><path d="M4370 1920 c0 -5 -7 -10 -15 -10 -8 0 -28 -13 -43 -30 -16 -16 -36
-30 -43 -30 -8 0 -21 -9 -29 -20 l-14 -20 -28 0 c-30 0 -46 -16 -30 -32 l11
-11 57 33 c70 39 164 109 164 121 0 5 -7 9 -15 9 -8 0 -15 -4 -15 -10z"></path><path d="M3770 1875 l0 -16 -19 11 c-25 13 -41 13 -41 -1 l0 -11 15 5 c8 4 17
2 20 -4 4 -5 19 -8 34 -7 l27 2 1 18 2 18 -20 0 -19 0 0 -15z"></path><path d="M3870 1840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3660 1835 c0 -2 10 -10 23 -16 l22 -12 -14 16 c-13 16 -31 23 -31
12z"></path><path d="M3630 1790 c13 -23 20 -26 20 -6 0 8 -7 16 -15 20 l-15 6 10 -20z"></path><path d="M3711 1797 c-6 -8 -8 -17 -4 -20 11 -12 19 -7 20 13 2 24 -1 25 -16
7z"></path><path d="M4130 1760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3547 1749 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z"></path><path d="M4090 1740 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 l-23 0 15 -10z"></path><path d="M3507 1713 c-9 -10 -17 -13 -17 -7 0 6 -6 11 -14 11 -20 0 -8 -24 12
-24 9 0 24 6 33 14 l16 13 17 -6 c9 -3 16 -2 16 3 0 16 -46 13 -63 -4z"></path><path d="M4050 1720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2343 1713 c-26 -5 -31 -23 -8 -23 8 0 24 -6 35 -12 l20 -13 17 17
17 17 -30 -2 -29 -2 3 13 c1 6 1 11 0 11 -2 -1 -13 -4 -25 -6z"></path><path d="M4000 1703 c0 -11 -50 -33 -74 -34 l-21 0 20 -10 21 -10 47 20 c49
21 62 41 27 41 -11 0 -20 -3 -20 -7z"></path><path d="M3405 1675 l0 -25 13 0 c6 0 12 5 12 10 0 6 7 10 15 10 23 0 18 17
-7 24 -33 8 -33 8 -33 -19z"></path><path d="M3355 1670 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"></path><path d="M2420 1660 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M3550 1650 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M3850 1654 c0 -8 -4 -14 -8 -14 -5 0 -9 -7 -9 -15 0 -8 3 -15 5 -15
14 0 72 25 72 31 0 7 -25 20 -47 25 -7 1 -13 -4 -13 -12z"></path><path d="M2465 1640 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M2533 1633 c-13 -2 -23 -7 -23 -11 0 -4 19 -13 41 -20 l42 -13 10 10
11 11 -28 0 -28 0 6 15 c3 8 2 14 -2 14 -4 -1 -17 -4 -29 -6z"></path><path d="M3206 1625 l6 -15 47 0 46 1 -25 14 c-14 8 -37 14 -52 15 l-27 0 5
-15z"></path><path d="M3390 1620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3470 1620 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3760 1600 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2764 1585 l-6 -15 -29 0 c-16 0 -29 5 -29 10 0 6 -13 10 -30 10 -47
0 -35 -17 21 -30 l51 -12 23 12 c13 7 26 19 29 26 l5 14 -15 0 c-8 0 -17 -7
-20 -15z"></path><path d="M2876 1586 c8 -20 32 -24 74 -13 19 5 51 8 70 8 l35 -1 -15 10 c-8 5
-50 10 -92 10 l-77 0 5 -14z m61 -3 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10
-3 7 -7z"></path><path d="M3063 1583 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M3650 1580 l0 -10 -40 0 -40 0 0 -10 0 -10 -60 0 -60 0 0 -10 0 -10
-72 0 -73 0 15 -10 c20 -13 98 -11 190 4 l75 12 -3 13 -3 13 27 -6 c29 -8 134
12 134 26 0 4 -20 8 -45 8 l-45 0 0 -10z"></path><path d="M2810 1540 c0 -5 12 -10 26 -10 l25 0 -6 10 c-3 6 -15 10 -26 10 -10
0 -19 -4 -19 -10z"></path><path d="M3031 1541 l-15 -9 72 -7 72 -8 -6 -8 -5 -9 54 0 54 0 0 15 0 15 -89
0 -88 0 0 10 c0 12 -30 13 -49 1z"></path><path d="M2930 1520 l0 -10 46 0 45 0 -6 10 c-3 6 -24 10 -46 10 -21 0 -39 -4
-39 -10z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#88bb43" stroke="none"><path d="M5820 7256 c0 -2 7 -9 15 -16 l15 -12 0 16 c0 9 -7 16 -15 16 -8 0
-15 -2 -15 -4z"></path><path d="M5760 7240 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5700 7220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5620 7200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5560 7180 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5480 7160 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5615 7160 l-6 -10 20 0 c12 0 21 5 21 10 0 6 -6 10 -14 10 -8 0 -18
-4 -21 -10z"></path><path d="M5410 7140 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5363 7133 c-28 -5 -31 -23 -4 -23 10 0 21 7 25 15 3 8 4 14 3 14 -1
-1 -12 -4 -24 -6z"></path><path d="M5779 7113 c-5 -14 -8 -34 -7 -42 l4 -16 17 34 c20 39 21 51 7 51 -6
0 -15 -12 -21 -27z"></path><path d="M5270 7100 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5390 7100 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5200 7080 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5310 7080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5130 7060 c0 -5 9 -10 21 -10 l20 0 -6 10 c-3 6 -13 10 -21 10 -8 0
-14 -4 -14 -10z"></path><path d="M5088 7053 c-21 -5 -24 -23 -4 -23 8 0 16 7 20 15 3 8 4 14 3 14 -1
-1 -10 -3 -19 -6z"></path><path d="M5000 7020 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5730 6985 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M4850 6980 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5010 6980 c0 -5 10 -10 23 -10 l22 0 -15 10 c-19 13 -30 13 -30 0z"></path><path d="M4790 6960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4970 6940 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5703 6943 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4670 6920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4623 6913 c-19 -7 -16 -33 5 -40 9 -4 20 -11 24 -17 4 -6 10 -7 14
-3 4 4 -2 15 -15 23 l-22 16 5 14 c3 8 4 14 3 13 -1 0 -8 -3 -14 -6z"></path><path d="M4755 6900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4790 6860 c0 -5 9 -10 19 -10 11 0 21 -6 24 -12 2 -7 8 -10 12 -6
10 9 -19 38 -39 38 -9 0 -16 -4 -16 -10z"></path><path d="M4710 6820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4812 6758 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M5636 6751 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4870 6720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5000 6680 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M5530 6680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4990 6640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2930 6620 l0 -10 49 0 c28 0 53 5 56 10 l6 10 -55 0 -56 0 0 -10z"></path><path d="M3365 6620 c3 -5 17 -10 31 -10 13 0 24 5 24 10 0 6 -14 10 -31 10
l-30 0 6 -10z"></path><path d="M5035 6610 l6 -20 -10 0 c-6 0 -11 -4 -11 -10 0 -14 11 -12 34 5 l18
14 -15 15 c-21 22 -30 20 -22 -4z"></path><path d="M3546 6606 c3 -8 11 -17 19 -19 l13 -6 -5 20 c-3 10 -11 19 -19 19
l-13 0 5 -14z"></path><path d="M2740 6600 c0 -5 9 -10 19 -10 11 0 23 5 26 10 l6 10 -25 0 c-14 0
-26 -4 -26 -10z"></path><path d="M3615 6600 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M5570 6594 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2595 6580 l-6 -10 35 0 c20 0 36 5 36 10 0 6 -13 10 -29 10 -17 0
-33 -4 -36 -10z"></path><path d="M3755 6580 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M2530 6560 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M3855 6560 c3 -5 15 -10 26 -10 l20 0 -6 10 c-3 6 -15 10 -26 10
l-20 0 6 -10z"></path><path d="M3935 6540 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M5100 6540 c0 -5 6 -10 14 -10 8 0 16 -7 20 -15 l5 -15 16 6 16 7
-20 18 c-22 20 -51 25 -51 9z"></path><path d="M3884 6525 c8 -8 21 -15 28 -15 l13 1 -19 14 c-26 20 -42 19 -22 0z"></path><path d="M2350 6520 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M4015 6520 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M4940 6520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5060 6520 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5536 6511 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M2270 6500 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M2433 6503 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4085 6500 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M4975 6500 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2238 6493 c-10 -2 -18 -9 -18 -14 0 -14 16 -11 30 6 7 8 11 14 9 14
-2 -1 -12 -3 -21 -6z"></path><path d="M5005 6470 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4850 6460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2090 6440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4275 6440 c3 -5 11 -10 16 -10 6 0 17 -7 25 -15 19 -19 34 -19 34
-2 0 15 -41 37 -66 37 -8 0 -12 -4 -9 -10z"></path><path d="M4820 6440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4933 6436 c22 -17 22 -26 0 -26 l-17 0 11 -11 12 -12 16 17 16 16
-15 15 c-8 8 -21 15 -28 15 l-13 -1 18 -13z"></path><path d="M2040 6420 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2185 6420 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2340 6420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4790 6420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5192 6400 c-1 -17 2 -30 7 -30 5 0 8 11 7 25 -4 36 -11 39 -14 5z"></path><path d="M1983 6402 c-24 -9 -43 -19 -43 -23 0 -15 30 -17 44 -4 8 8 19 15 24
15 6 0 12 7 16 15 3 8 4 14 3 14 -1 -1 -21 -9 -44 -17z"></path><path d="M4750 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5450 6400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4840 6386 c0 -2 7 -9 15 -16 l15 -12 0 10 c0 5 -7 12 -15 16 -8 3
-15 4 -15 2z"></path><path d="M1890 6360 c0 -5 9 -10 21 -10 l20 0 -6 10 c-3 6 -13 10 -21 10 -8 0
-14 -4 -14 -10z"></path><path d="M5270 6360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5470 6354 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M1833 6338 c-29 -14 -30 -28 -2 -28 l20 0 -6 10 -6 10 21 0 21 0 -6
10 c-7 12 -13 12 -42 -2z"></path><path d="M4050 6340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4670 6340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4140 6320 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4640 6320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4820 6320 c0 -8 -12 -25 -27 -37 -14 -13 -24 -26 -20 -29 3 -3 19 7
36 23 l30 29 -9 14 -10 15 0 -15z"></path><path d="M5250 6320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5450 6310 c-8 -5 -12 -10 -8 -10 4 0 3 -12 -3 -26 -5 -15 -8 -29 -5
-31 6 -7 38 56 34 67 l-3 9 -15 -9z"></path><path d="M1770 6300 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4164 6301 c4 -5 0 -11 -6 -14 -7 -2 -10 -8 -6 -12 8 -9 38 12 38 26
0 5 -7 9 -16 9 -8 0 -13 -4 -10 -9z"></path><path d="M4055 6280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M1685 6261 c-22 -10 -49 -26 -60 -35 l-20 -15 35 0 c19 0 29 3 22 6
l-13 5 18 14 c10 7 25 14 34 14 9 0 22 7 29 15 16 20 4 19 -45 -4z"></path><path d="M1803 6273 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5310 6260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4071 6234 c-7 -8 -11 -18 -8 -20 6 -7 47 18 47 28 0 13 -24 8 -39
-8z"></path><path d="M3990 6220 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M1580 6200 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -13 11 -12
33 4 19 15 22 26 7 26 -5 0 -10 -4 -10 -10z"></path><path d="M4030 6200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4470 6202 c0 -4 9 -13 20 -20 l20 -12 -12 20 c-13 20 -28 27 -28 12z"></path><path d="M4700 6200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4645 6190 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M3975 6160 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4585 6149 c-11 -16 -1 -19 13 -3 l13 14 -10 0 c-5 0 -13 -5 -16 -11z"></path><path d="M1463 6129 c-32 -20 -43 -39 -23 -39 6 0 10 5 10 10 0 6 7 10 15 10
8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 15 -11 12 -47 -11z"></path><path d="M4621 6134 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M4390 6126 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M3892 6119 c2 -7 10 -15 17 -17 l14 -4 -5 13 c-2 7 -10 15 -17 17
l-13 5 4 -14z"></path><path d="M5369 6100 c-6 -16 -17 -30 -25 -30 -8 0 -14 -4 -14 -10 0 -14 19
-12 35 4 15 15 31 66 21 66 -4 0 -12 -13 -17 -30z"></path><path d="M4330 6100 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M3850 6080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1350 6055 c-7 -9 -9 -19 -5 -23 4 -4 10 -1 12 6 3 6 11 12 19 12 9
0 12 5 9 10 -9 14 -21 12 -35 -5z"></path><path d="M1470 6060 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4270 6060 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M4522 6050 l-12 -20 15 0 c8 0 15 2 15 4 0 2 3 11 6 20 9 23 -9 20
-24 -4z"></path><path d="M4220 6020 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M1293 6013 c-17 -6 -16 -23 1 -23 8 0 16 7 20 15 6 15 1 17 -21 8z"></path><path d="M1460 6000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3735 6000 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M3683 5965 c-24 -26 -28 -35 -15 -35 11 0 62 43 62 53 0 15 -26 5
-47 -18z"></path><path d="M4910 5980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5110 5980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1220 5963 c0 -5 -14 -17 -30 -28 -32 -21 -40 -45 -15 -45 8 0 15 4
15 10 0 5 11 20 25 34 27 27 31 36 15 36 -5 0 -10 -3 -10 -7z"></path><path d="M4350 5960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4390 5960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4940 5960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4088 5927 c-16 -13 -28 -26 -28 -30 0 -4 9 -7 21 -7 l20 0 -6 10
c-3 5 3 15 15 22 20 13 27 28 13 28 -5 -1 -20 -11 -35 -23z"></path><path d="M5060 5940 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3490 5920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3630 5920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4260 5920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4376 5914 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M5190 5924 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M3591 5894 c-7 -8 -10 -18 -7 -21 7 -7 36 16 36 28 0 13 -13 10 -29
-7z"></path><path d="M4230 5900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4340 5880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3536 5854 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M4300 5840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4750 5840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5290 5842 c0 -12 51 -55 57 -48 5 5 -42 56 -52 56 -3 0 -5 -4 -5 -8z"></path><path d="M3490 5820 c0 -5 -5 -10 -11 -10 -16 0 -43 -30 -34 -38 4 -4 10 -1
12 6 3 6 9 12 15 12 10 0 38 25 38 34 0 3 -4 6 -10 6 -5 0 -10 -4 -10 -10z"></path><path d="M4190 5820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4790 5820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4243 5805 c2 -5 11 -11 20 -13 l17 -3 0 13 0 14 -20 0 c-11 0 -19
-5 -17 -11z"></path><path d="M3910 5785 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"></path><path d="M1010 5780 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M5345 5780 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M3405 5760 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M3870 5750 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M4641 5744 c-12 -14 -20 -28 -16 -32 4 -4 10 -1 12 6 3 6 13 12 22
12 10 0 20 3 24 6 7 7 -2 34 -12 33 -3 0 -17 -11 -30 -25z"></path><path d="M3366 5734 c-4 -9 -4 -19 -1 -22 6 -6 35 18 35 29 0 16 -28 10 -34
-7z"></path><path d="M4166 5741 c-7 -11 22 -23 35 -15 5 3 9 9 9 15 0 12 -37 12 -44 0z"></path><path d="M5350 5740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3250 5720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4150 5700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4567 5673 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"></path><path d="M3280 5660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4670 5660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5482 5650 c13 -20 28 -27 28 -12 0 10 -22 32 -32 32 -5 0 -3 -9 4
-20z"></path><path d="M3746 5646 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 l-12 4 6 -16z"></path><path d="M4090 5640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M850 5620 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M3201 5614 c-7 -8 -11 -18 -8 -20 7 -8 47 15 47 26 0 15 -23 11 -39
-6z"></path><path d="M5526 5605 l6 -15 -12 0 c-6 0 -8 -4 -5 -10 3 -5 11 -10 16 -10 6 0
8 4 5 9 -4 5 -1 11 6 13 l12 4 -8 12 c-10 17 -26 15 -20 -3z"></path><path d="M4507 5584 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M3645 5580 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4011 5574 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M797 5563 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"></path><path d="M3140 5560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5570 5564 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M3110 5540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3590 5540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M760 5520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3570 5520 c0 -5 -5 -10 -11 -10 -15 0 -43 -30 -35 -37 3 -4 16 3 29
15 13 12 29 22 36 22 7 0 9 5 6 10 -3 6 -10 10 -16 10 -5 0 -9 -4 -9 -10z"></path><path d="M800 5500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3040 5500 c0 -5 -7 -10 -16 -10 l-15 0 6 -10 c3 -5 1 -10 -4 -10 -6
0 -11 -4 -11 -10 0 -16 30 -12 36 5 3 8 14 17 25 20 25 8 24 25 -1 25 -11 0
-20 -4 -20 -10z"></path><path d="M3676 5494 c-9 -23 -8 -24 13 -24 11 0 21 -6 24 -12 2 -7 8 -10 12
-6 4 4 -4 18 -18 32 l-25 25 -6 -15z"></path><path d="M3940 5500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4435 5500 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4387 5484 c6 -18 19 -18 26 -1 l5 13 -19 1 -18 2 6 -15z"></path><path d="M5580 5490 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M705 5450 c-3 -11 -11 -20 -16 -20 -5 0 -9 -4 -9 -10 0 -5 9 -10 19
-10 19 0 31 18 31 46 0 21 -18 17 -25 -6z"></path><path d="M4340 5460 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3550 5440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3850 5439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4295 5429 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"></path><path d="M5676 5433 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"></path><path d="M2950 5420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3436 5415 c-11 -8 -17 -17 -14 -21 7 -6 48 17 48 28 0 12 -13 9 -34
-7z"></path><path d="M660 5395 l0 -14 15 5 c8 4 12 10 9 15 -8 14 -24 10 -24 -6z"></path><path d="M4251 5394 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M5703 5393 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M2881 5374 c-7 -8 -11 -18 -8 -20 6 -7 47 18 47 28 0 13 -24 8 -39
-8z"></path><path d="M3382 5374 l-13 -14 15 -16 16 -15 0 14 c0 9 3 22 6 31 8 20 -7 20
-24 0z"></path><path d="M2794 5356 l-5 -14 -19 5 c-10 3 -21 0 -24 -5 l-6 -10 55 1 c30 1 55
3 55 5 0 7 -34 31 -43 32 -4 0 -10 -6 -13 -14z"></path><path d="M3782 5334 c-19 -20 -32 -38 -29 -41 6 -5 77 61 77 72 0 12 -17 1
-48 -31z"></path><path d="M3730 5340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4197 5333 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"></path><path d="M2635 5320 l-6 -10 30 0 c17 0 31 5 31 10 0 6 -11 10 -24 10 -14 0
-28 -4 -31 -10z"></path><path d="M2600 5300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3283 5287 c-13 -13 -23 -26 -23 -30 0 -4 9 -7 21 -7 11 0 18 4 14 9
-3 5 2 16 10 24 8 9 15 18 15 21 0 12 -15 4 -37 -17z"></path><path d="M2520 5280 c0 -5 9 -10 19 -10 11 0 23 5 26 10 l6 10 -25 0 c-14 0
-26 -4 -26 -10z"></path><path d="M3690 5281 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M560 5260 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M4113 5255 c-7 -8 -13 -20 -13 -27 l1 -13 13 18 c7 9 18 17 24 17 6
0 14 5 17 10 l6 10 -18 0 c-10 -1 -24 -7 -30 -15z"></path><path d="M4166 5254 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24 -2z"></path><path d="M2435 5240 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M3230 5240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3650 5246 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M537 5223 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M2380 5220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4195 5220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2345 5200 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M5850 5201 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M4057 5193 c-13 -12 -7 -23 12 -23 11 0 21 -6 24 -12 2 -7 7 -10 11
-6 4 3 -1 16 -11 27 -19 22 -26 25 -36 14z"></path><path d="M2282 5174 c-7 -8 -17 -12 -23 -9 -5 4 -9 1 -9 -4 0 -6 6 -11 13 -11
17 1 50 27 40 33 -4 3 -14 -1 -21 -9z"></path><path d="M3193 5173 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M490 5155 l0 -14 15 5 c8 4 12 10 9 15 -8 14 -24 10 -24 -6z"></path><path d="M3593 5158 c2 -7 10 -13 17 -13 7 0 15 6 18 13 l4 12 -22 0 -22 0 5
-12z"></path><path d="M3115 5149 c-4 -6 -4 -13 0 -17 4 -4 10 -2 12 5 l4 12 17 -6 17 -5
-15 11 c-19 14 -27 14 -35 0z"></path><path d="M5890 5145 c0 -13 25 -55 32 -55 6 0 7 6 4 13 -3 6 -9 20 -12 30 -4
9 -10 17 -15 17 -5 0 -9 -2 -9 -5z"></path><path d="M2180 5120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3071 5114 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"></path><path d="M3570 5115 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M457 5103 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M4200 5104 c0 -10 34 -33 49 -34 l13 0 -12 15 c-7 8 -16 12 -21 9 -5
-3 -9 0 -9 5 0 6 -4 11 -10 11 -5 0 -10 -3 -10 -6z"></path><path d="M2120 5080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3010 5054 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2060 5040 c0 -5 -8 -10 -17 -10 l-17 0 13 -12 14 -13 4 13 c3 6 11
12 19 12 8 0 14 5 14 10 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -10z"></path><path d="M4288 5038 l12 -12 12 12 12 12 -24 0 -24 0 12 -12z"></path><path d="M480 5010 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M5913 5013 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2005 5000 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15
0 6 -10z"></path><path d="M3430 5001 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M4350 5000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5970 5001 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2937 4993 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M5810 4980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5935 4980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M366 4938 l-16 -43 25 29 c25 29 33 56 17 56 -5 0 -16 -19 -26 -42z"></path><path d="M1960 4960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3418 4948 c-10 -12 -15 -24 -13 -27 5 -5 45 34 45 44 0 12 -16 3
-32 -17z"></path><path d="M4400 4960 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M2890 4946 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"></path><path d="M1934 4942 l7 -12 -15 0 c-9 0 -16 -4 -16 -10 0 -14 16 -12 29 4 l12
14 -12 8 -12 7 7 -11z"></path><path d="M4430 4939 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6000 4934 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2860 4920 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M1870 4881 c0 -5 -9 -11 -20 -14 -11 -3 -20 -13 -20 -22 l0 -16 10 6
c6 4 7 11 4 17 l-7 11 12 -7 11 -7 15 15 c17 17 19 26 5 26 -5 0 -10 -4 -10
-9z"></path><path d="M2810 4880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4490 4884 c0 -9 31 -37 36 -32 9 9 -8 38 -22 38 -8 0 -14 -3 -14 -6z"></path><path d="M4540 4840 c0 -6 7 -13 15 -16 8 -4 15 -11 15 -18 0 -6 9 -17 20 -24
l20 -12 0 10 c0 6 -9 18 -20 27 -11 10 -20 23 -20 30 0 7 -7 13 -15 13 -8 0
-15 -5 -15 -10z"></path><path d="M2777 4824 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 l-15 6 6 -15z"></path><path d="M460 4820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1722 4734 c-30 -31 -52 -58 -49 -61 3 -3 25 15 49 39 54 55 73 78
62 77 -5 0 -33 -25 -62 -55z"></path><path d="M3270 4780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2696 4714 c-9 -22 -8 -24 9 -24 l15 0 0 20 c0 24 -15 27 -24 4z"></path><path d="M4650 4720 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M280 4700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3080 4700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4670 4700 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3166 4674 c-9 -22 3 -32 14 -14 12 19 12 30 1 30 -5 0 -12 -7 -15
-16z"></path><path d="M2617 4663 c6 -14 43 -18 43 -4 0 5 -11 11 -24 13 l-24 5 5 -14z"></path><path d="M2740 4660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6090 4660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M260 4618 l0 -33 10 15 c13 20 13 50 0 50 -5 0 -10 -15 -10 -32z"></path><path d="M1635 4630 c-3 -11 -13 -20 -21 -20 -8 0 -12 -4 -9 -10 3 -5 1 -10
-6 -10 -7 0 -10 -3 -6 -6 3 -4 0 -13 -8 -21 -17 -16 -20 -33 -6 -33 5 0 12 9
16 19 3 11 19 34 36 52 29 32 37 49 20 49 -5 0 -13 -9 -16 -20z"></path><path d="M2560 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4720 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6150 4626 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M206 4595 c-18 -43 -20 -59 -6 -50 10 6 32 76 25 83 -2 2 -11 -13
-19 -33z"></path><path d="M2500 4580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3086 4574 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M2456 4534 c-8 -21 -8 -24 5 -24 5 0 7 5 4 10 -3 6 -1 10 4 10 6 0
11 5 11 10 0 16 -17 12 -24 -6z"></path><path d="M3050 4540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M176 4513 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6190 4525 c0 -2 4 -14 9 -27 5 -13 12 -32 15 -42 4 -10 9 -17 12
-14 7 7 -19 88 -28 88 -5 0 -8 -2 -8 -5z"></path><path d="M3030 4505 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1535 4492 c-16 -12 -16 -32 0 -32 l15 0 0 20 c0 22 -1 23 -15 12z"></path><path d="M160 4474 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2920 4480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4830 4480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2940 4440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4860 4439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M140 4414 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2926 4374 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M127 4363 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M6150 4350 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6250 4341 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M1445 4320 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2860 4321 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M6210 4310 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M2852 4290 c7 -11 16 -20 20 -20 15 0 8 15 -12 28 l-20 12 12 -20z"></path><path d="M6277 4263 c-3 -5 0 -19 8 -29 15 -20 18 -11 6 20 l-8 20 -6 -11z"></path><path d="M160 4240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M88 4214 c-12 -11 -10 -44 2 -44 6 0 10 -9 10 -20 0 -11 4 -20 9 -20
5 0 8 11 7 24 0 13 -4 22 -8 20 -4 -2 -8 7 -10 22 -2 14 -6 22 -10 18z"></path><path d="M1396 4211 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4983 4199 c4 -13 7 -27 7 -31 0 -15 20 -8 20 7 0 8 -5 15 -11 15 -5
0 -8 4 -4 9 3 5 0 13 -6 17 l-12 7 6 -24z"></path><path d="M2783 4195 c4 -8 2 -17 -3 -20 -13 -8 -13 -25 0 -25 6 0 10 7 10 15
0 9 7 18 15 21 22 9 18 24 -6 24 l-21 0 5 -15z"></path><path d="M2135 4180 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15
0 6 -10z"></path><path d="M2220 4180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1375 4171 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M2080 4140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1366 4131 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M6310 4128 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 l-10 15 0 -17z"></path><path d="M2740 4120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M60 4094 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M2706 4074 c-9 -22 3 -32 14 -14 12 19 12 30 1 30 -5 0 -12 -7 -15
-16z"></path><path d="M5030 4076 c0 -23 11 -46 20 -41 10 6 0 55 -11 55 -5 0 -9 -6 -9 -14z"></path><path d="M2600 4060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1331 4042 c-13 -25 -14 -42 -2 -42 10 0 25 50 17 57 -2 3 -9 -4 -15
-15z"></path><path d="M1980 4020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2620 4020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M46 4013 c-3 -3 -6 -19 -6 -35 l0 -29 15 15 c8 8 15 21 15 30 0 17
-14 29 -24 19z"></path><path d="M5050 4004 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M1937 3973 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"></path><path d="M2636 3971 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M5064 3960 c3 -14 6 -31 6 -37 0 -7 5 -13 11 -13 l11 0 -7 28 c-3 15
-11 32 -17 37 l-11 10 7 -25z"></path><path d="M6340 3966 c0 -8 4 -17 9 -20 l9 -6 -5 20 c-6 23 -13 26 -13 6z"></path><path d="M1297 3913 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13 18z"></path><path d="M2595 3908 c-4 -10 -13 -18 -20 -18 -15 0 -28 -27 -19 -41 l5 -9 15
14 c8 8 14 19 14 24 0 6 5 12 12 14 6 2 9 11 6 19 l-6 14 -7 -17z"></path><path d="M6350 3908 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 l-10 15 0 -17z"></path><path d="M1920 3900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M26 3891 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M1853 3875 c-7 -8 -13 -19 -13 -25 0 -5 -4 -10 -10 -10 -5 0 -10 -5
-10 -11 0 -7 -10 -20 -22 -30 -11 -11 -19 -22 -15 -25 3 -3 16 3 28 15 13 12
26 21 31 21 4 0 8 9 8 19 0 11 7 26 15 35 8 8 15 17 15 20 0 11 -15 6 -27 -9z"></path><path d="M1945 3860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M6360 3820 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1277 3813 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M1180 3800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M11 3718 c0 -9 -4 -25 -7 -35 l-7 -18 12 11 c7 7 11 22 8 35 l-4 24
-2 -17z"></path><path d="M2456 3691 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M5120 3676 l0 -27 9 5 c10 7 9 31 -2 42 -4 4 -7 -5 -7 -20z"></path><path d="M2400 3680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1100 3658 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 l-10 15 0 -17z"></path><path d="M1166 3654 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M1682 3649 c-7 -12 -11 -23 -8 -26 3 -3 8 2 12 11 3 9 12 16 20 16 8
0 14 5 14 10 0 18 -25 11 -38 -11z"></path><path d="M1136 3611 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M2396 3591 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M6387 3505 c3 -55 7 -102 9 -104 7 -8 3 114 -6 159 l-8 45 5 -100z"></path><path d="M1626 3571 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M1090 3555 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1750 3540 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M3 3465 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"></path><path d="M5130 3520 l0 -24 12 12 12 12 -12 12 -12 12 0 -24z"></path><path d="M1586 3511 l-7 -11 15 -15 c27 -27 36 -13 13 20 l-14 18 -7 -12z"></path><path d="M2340 3500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1000 3480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2280 3480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1020 3440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1537 3443 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M2296 3411 c-18 -35 -19 -41 -7 -41 11 0 36 59 28 66 -3 3 -13 -8
-21 -25z"></path><path d="M997 3403 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M5130 3374 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M940 3361 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M1472 3345 c-14 -31 -15 -35 -4 -35 5 0 14 11 20 25 14 31 15 35 4
35 -5 0 -14 -11 -20 -25z"></path><path d="M2170 3360 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2240 3358 c0 -19 18 -43 25 -36 9 9 -4 48 -16 48 -5 0 -9 -6 -9 -12z"></path><path d="M880 3325 c0 -11 51 -57 57 -52 3 3 -6 17 -20 31 -25 25 -37 32 -37
21z"></path><path d="M0 3246 c0 -19 5 -38 10 -41 l10 -6 0 35 c0 19 -4 38 -10 41 l-10 6
0 -35z"></path><path d="M903 3253 c-16 -6 -17 -33 -3 -33 11 0 26 28 19 34 -2 2 -10 1 -16
-1z"></path><path d="M2205 3240 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M1396 3213 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M5120 3214 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M2160 3201 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M1372 3174 c-7 -8 -12 -19 -10 -25 2 -5 11 -1 20 10 20 23 10 36 -10
15z"></path><path d="M6377 3174 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M2131 3091 l-13 -8 12 -8 c13 -8 24 -1 19 14 l-4 10 -14 -8z"></path><path d="M806 3074 c-9 -22 3 -32 14 -14 12 19 12 30 1 30 -5 0 -12 -7 -15
-16z"></path><path d="M1400 3060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5097 3063 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M20 3046 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M2040 3040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2080 3040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6310 3041 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2107 3034 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M680 3000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6353 2985 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"></path><path d="M1260 2974 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M5080 2974 l0 -15 10 6 c6 4 8 11 5 16 -9 14 -15 11 -15 -7z"></path><path d="M42 2919 c2 -19 8 -33 13 -32 17 3 17 32 0 49 l-16 17 3 -34z"></path><path d="M1240 2934 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M746 2931 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M1300 2900 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6337 2894 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M160 2880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1260 2880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M60 2826 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M5030 2810 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M1180 2794 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M6317 2794 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M680 2775 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1160 2744 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"></path><path d="M82 2707 c2 -12 8 -20 13 -18 6 1 9 7 8 12 -2 5 2 9 7 9 19 0 10 16
-11 17 l-20 2 3 -22z"></path><path d="M620 2710 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M6244 2714 c3 -9 6 -18 6 -20 0 -2 5 -4 10 -4 6 0 10 9 10 20 l0 20
-16 0 -16 0 6 -16z"></path><path d="M1976 2691 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M6297 2693 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M1131 2668 c-17 -45 -22 -68 -16 -68 7 0 29 52 27 63 -1 4 3 7 8 7 6
0 10 5 10 10 0 19 -20 10 -29 -12z"></path><path d="M160 2660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M646 2651 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M6281 2634 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M100 2626 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M4946 2614 c-11 -28 -6 -39 9 -20 8 11 15 23 15 28 0 14 -18 9 -24
-8z"></path><path d="M6256 2585 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11
-7 -15 -15z"></path><path d="M1917 2576 c-19 -15 -19 -33 0 -53 16 -17 23 -16 23 3 l0 15 -10 -6
-10 -6 0 21 0 22 15 -6 15 -5 0 14 c0 18 -10 19 -33 1z"></path><path d="M160 2560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6210 2560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M120 2546 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M4891 2505 c-12 -25 -21 -47 -21 -50 0 -3 5 -5 11 -5 5 0 7 5 4 10
-3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -2 10 3 10 5 0 14 11 20 25 14 31 15 35 3
35 -5 0 -18 -20 -30 -45z"></path><path d="M611 2522 c-13 -25 -14 -42 -2 -42 10 0 25 50 17 57 -2 3 -9 -4 -15
-15z"></path><path d="M1085 2526 c-3 -9 -7 -23 -10 -31 -2 -8 -2 -15 1 -15 7 0 25 52 20
58 -3 3 -8 -3 -11 -12z"></path><path d="M140 2486 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M6227 2453 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M160 2421 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M1080 2410 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M540 2400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1040 2384 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M1885 2387 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 l-4 18 -8 -18z"></path><path d="M6205 2392 c-11 -8 -20 -52 -10 -52 6 0 27 51 22 56 -1 2 -7 0 -12
-4z"></path><path d="M181 2373 c0 -5 11 -30 24 -58 l24 -50 -6 30 c-6 32 -30 85 -38 85
-3 0 -5 -3 -4 -7z"></path><path d="M4792 2345 c-15 -32 -15 -35 0 -35 6 0 9 3 5 6 -3 4 0 13 8 21 17 16
20 33 7 33 -5 0 -14 -11 -20 -25z"></path><path d="M1866 2311 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M6175 2312 c-6 -4 -11 -18 -13 -32 l-2 -25 16 28 c16 30 16 41 -1 29z"></path><path d="M1020 2294 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M4750 2280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4730 2260 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M4707 2233 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"></path><path d="M6141 2231 l-13 -8 10 -7 c5 -3 12 1 16 9 6 17 6 18 -13 6z"></path><path d="M280 2200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6127 2193 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M4670 2180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1000 2159 l0 -20 10 6 c6 3 10 13 10 21 0 8 -4 14 -10 14 -5 0 -10
-9 -10 -21z"></path><path d="M4627 2135 c-4 -8 -1 -17 5 -21 l11 -7 -7 11 -7 12 10 0 c6 0 11 5
11 10 0 15 -17 12 -23 -5z"></path><path d="M6090 2125 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M1040 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4590 2100 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M460 2080 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6065 2081 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"></path><path d="M4552 2060 c-15 -24 -15 -30 -1 -30 5 0 7 5 4 10 -3 6 0 10 8 10 8 0
17 7 21 15 l5 15 -12 0 c-7 0 -18 -9 -25 -20z"></path><path d="M320 2059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4510 2020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4481 1994 c-7 -8 -10 -18 -6 -22 4 -4 10 -1 12 6 3 6 11 12 19 12 9
0 12 5 9 10 -8 14 -18 12 -34 -6z"></path><path d="M370 1961 c0 -7 46 -97 57 -110 2 -2 6 -1 9 2 8 8 -46 117 -58 117
-4 0 -8 -4 -8 -9z"></path><path d="M6010 1954 l0 -15 10 6 c6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10 -7
-10 -16z"></path><path d="M4420 1940 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M5976 1891 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M480 1880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5850 1880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M580 1866 c0 -8 5 -18 10 -21 l10 -6 0 20 c0 12 -4 21 -10 21 -5 0
-10 -6 -10 -14z"></path><path d="M5830 1840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5895 1820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M5926 1814 l-6 -16 12 4 c15 5 23 28 10 28 -5 0 -13 -7 -16 -16z"></path><path d="M460 1799 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4220 1800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M512 1770 c12 -19 28 -27 28 -14 0 7 -27 34 -34 34 -4 0 -1 -9 6 -20z"></path><path d="M4180 1780 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M1040 1750 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"></path><path d="M585 1737 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 l-4 18 -8 -18z"></path><path d="M1000 1740 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4120 1740 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4080 1720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M520 1701 c0 -5 7 -14 15 -21 16 -14 18 -10 9 14 -6 17 -24 22 -24 7z"></path><path d="M1000 1696 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M620 1686 c0 -8 5 -18 10 -21 l10 -6 0 20 c0 12 -4 21 -10 21 -5 0
-10 -6 -10 -14z"></path><path d="M2310 1680 c0 -11 4 -20 9 -20 5 0 11 9 14 20 l5 20 -14 0 -14 0 0
-20z"></path><path d="M3995 1680 c-3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -17 30
-11 52 10 12 11 17 20 10 20 -6 0 -14 -4 -17 -10z"></path><path d="M5810 1646 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M3910 1640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M640 1626 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M3845 1620 l-6 -10 25 0 c14 0 26 5 26 10 0 6 -9 10 -19 10 -11 0
-23 -4 -26 -10z"></path><path d="M2326 1607 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M5779 1603 c-1 -5 -1 -12 0 -17 1 -5 -4 -13 -11 -17 -7 -4 -9 -11 -5
-15 8 -9 36 25 37 44 0 13 -19 17 -21 5z"></path><path d="M646 1585 l-5 -15 14 0 c8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0 -11 -7
-15 -15z"></path><path d="M1020 1586 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M2595 1580 l-6 -10 25 0 c14 0 26 5 26 10 0 6 -9 10 -19 10 -11 0
-23 -4 -26 -10z"></path><path d="M3740 1580 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M2675 1560 c3 -5 17 -10 31 -10 13 0 24 -4 24 -10 0 -5 5 -10 10 -10
15 0 7 24 -10 32 -8 4 -25 7 -38 7 l-23 1 6 -10z"></path><path d="M2775 1540 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
l-20 0 6 -10z"></path><path d="M3570 1540 c0 -5 11 -10 24 -10 14 0 28 5 31 10 l6 10 -30 0 c-17 0
-31 -4 -31 -10z"></path><path d="M2885 1520 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M3450 1520 c0 -5 9 -10 19 -10 11 0 23 5 26 10 l6 10 -25 0 c-14 0
-26 -4 -26 -10z"></path><path d="M1040 1505 c0 -20 17 -28 23 -11 l6 14 -15 6 -14 5 0 -14z"></path><path d="M5727 1513 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M2920 1480 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"></path><path d="M5674 1478 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M5490 1440 c0 -5 5 -10 10 -10 6 0 10 -9 10 -20 l0 -20 15 0 c20 0
19 12 -4 38 -20 24 -31 28 -31 12z"></path><path d="M5656 1434 c-4 -9 -4 -19 -1 -22 6 -6 35 18 35 29 0 16 -28 10 -34
-7z"></path><path d="M1800 1431 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M5610 1419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2340 1406 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M2350 1366 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M1125 1360 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M1100 1335 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2400 1320 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"></path><path d="M5522 1274 c-19 -20 -32 -38 -29 -41 6 -5 77 61 77 72 0 12 -17 1
-48 -31z"></path><path d="M5470 1280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1140 1253 c0 -17 22 -63 30 -63 14 0 18 12 9 24 -5 6 -16 20 -24 31
-8 11 -15 15 -15 8z"></path><path d="M1180 1175 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M5430 1180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2405 1153 c7 -29 21 -50 29 -42 9 9 -7 58 -21 63 l-15 6 7 -27z"></path><path d="M5330 1140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1210 1121 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2460 1120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2510 1124 c0 -3 7 -12 15 -20 l15 -15 0 20 0 21 -15 0 c-8 0 -15 -3
-15 -6z"></path><path d="M5190 1100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5300 1073 c0 -13 5 -23 10 -23 13 0 13 11 0 30 l-10 15 0 -22z"></path><path d="M1240 1060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2460 1021 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M5200 1001 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M1820 980 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1860 980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2520 980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5170 960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2490 951 c0 -17 12 -41 20 -41 14 0 7 39 -7 44 -7 3 -13 1 -13 -3z"></path><path d="M5132 941 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z"></path><path d="M1340 924 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M2520 880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5025 877 c-12 -14 -8 -47 6 -47 l10 0 -6 20 -6 20 9 0 c6 0 12 -6
15 -12 2 -7 8 -10 12 -6 4 4 0 14 -8 23 -9 8 -17 15 -19 15 -2 -1 -8 -6 -13
-13z"></path><path d="M1436 844 c-8 -21 -4 -25 14 -14 l15 9 -11 11 -11 11 -7 -17z"></path><path d="M2540 846 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M1920 820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2680 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4730 800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2570 786 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M1497 776 l-6 -15 15 6 c7 3 14 9 14 14 0 14 -17 10 -23 -5z"></path><path d="M4790 780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4855 740 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 l-20
0 6 -10z"></path><path d="M2665 720 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4811 714 c-7 -8 -10 -18 -6 -22 4 -4 10 -1 12 6 3 6 13 12 24 12 10
0 19 5 19 10 0 15 -33 11 -49 -6z"></path><path d="M1560 680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1600 680 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2020 681 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2640 681 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M4760 680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4725 660 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15 0
6 -10z"></path><path d="M1610 640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2725 640 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M4690 640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2050 626 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10 6
0 -15z"></path><path d="M1665 620 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M2690 622 c0 -14 40 -56 46 -49 4 3 -1 13 -10 22 -9 9 -16 21 -16 26
0 5 -4 9 -10 9 -5 0 -10 -4 -10 -8z"></path><path d="M4410 620 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4650 620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1980 600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4370 600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4620 600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4510 584 c0 -10 31 -36 37 -31 3 3 -2 13 -10 22 -16 15 -27 19 -27
9z"></path><path d="M4575 580 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13 -4
-16 -10z"></path><path d="M1720 540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1960 540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2100 543 c0 -14 19 -38 25 -32 8 8 -5 39 -16 39 -5 0 -9 -3 -9 -7z"></path><path d="M1777 516 l-6 -15 15 6 c15 6 19 23 5 23 -5 0 -11 -7 -14 -14z"></path><path d="M2760 525 c0 -3 9 -15 19 -26 l19 -21 6 10 c10 17 -5 42 -25 42 -10
0 -19 -2 -19 -5z"></path><path d="M4435 520 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13 -4
-16 -10z"></path><path d="M4400 500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1817 484 c-4 -4 -4 -10 -2 -14 6 -10 45 0 45 11 0 10 -33 12 -43 3z"></path><path d="M2140 470 l0 -20 15 0 c17 0 18 2 9 24 -9 23 -24 20 -24 -4z"></path><path d="M4210 480 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M4295 460 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 l-15 0
6 -10z"></path><path d="M2100 440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4240 440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M4171 416 l-11 -13 25 5 c14 2 25 8 25 13 0 14 -26 11 -39 -5z"></path><path d="M4125 400 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13 -4
-16 -10z"></path><path d="M3890 380 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M4055 380 l-6 -10 20 0 c12 0 21 5 21 10 0 6 -6 10 -14 10 -8 0 -18
-4 -21 -10z"></path><path d="M1972 358 c3 -7 11 -14 20 -16 l15 -3 0 16 0 15 -19 0 -20 0 4 -12z"></path><path d="M3950 360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2120 320 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2920 324 c0 -9 43 -54 52 -54 4 0 6 9 5 20 -1 11 -7 20 -15 20 -7 0
-10 5 -7 10 l6 10 -20 0 c-12 0 -21 -3 -21 -6z"></path><path d="M3780 320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M2220 302 c0 -5 7 -9 15 -9 8 0 15 4 15 9 0 4 -7 8 -15 8 -8 0 -15
-4 -15 -8z"></path><path d="M2280 300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3640 300 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"></path><path d="M3555 280 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13 -4
-16 -10z"></path><path d="M2130 260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2200 260 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M2320 261 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2990 260 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"></path><path d="M2160 240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2190 220 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M2230 200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2393 182 c10 -10 14 -22 10 -25 -3 -4 3 -7 15 -7 26 0 28 -12 7 -30
-19 -16 -19 -30 1 -30 l15 0 -7 12 -7 11 18 13 18 14 -34 30 c-37 33 -62 41
-36 12z"></path><path d="M2335 140 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"></path><path d="M2450 80 c0 -5 7 -10 16 -10 l15 0 -6 10 c-3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"></path><path d="M2505 80 c3 -5 1 -10 -5 -10 -6 0 -8 -4 -5 -10 3 -5 11 -10 16 -10 6
0 7 5 4 10 -3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -11 10 -16 10 -6 0 -7 -4 -4
-10z"></path><path d="M2535 51 c-5 -9 24 -31 39 -31 13 0 5 28 -10 34 -20 8 -22 8 -29 -3z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#95c341" stroke="none"><path d="M5773 7243 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5814 7183 c-1 -13 -10 -33 -20 -44 l-18 -20 17 7 c9 3 17 7 17 9 0
1 6 18 14 37 l15 35 -12 0 c-7 0 -13 -11 -13 -24z"></path><path d="M5560 7180 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"></path><path d="M5493 7163 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5423 7143 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5283 7103 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5213 7083 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5155 7060 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5765 7049 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M5000 7020 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"></path><path d="M5740 7005 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M4852 6982 l-13 -9 30 0 c17 0 28 4 25 9 -7 10 -25 10 -42 0z"></path><path d="M4730 6940 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M5694 6888 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M4630 6876 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z"></path><path d="M5675 6850 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5657 6803 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M4775 6780 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4810 6759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5637 6743 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M4900 6701 c0 -5 7 -12 16 -15 l17 -6 -13 15 c-14 17 -20 19 -20 6z"></path><path d="M5615 6700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4970 6661 c0 -5 7 -12 16 -15 l17 -6 -13 15 c-14 17 -20 19 -20 6z"></path><path d="M5596 6645 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"></path><path d="M2994 6622 c5 -8 66 -4 66 4 0 2 -16 4 -36 4 -19 0 -33 -4 -30 -8z"></path><path d="M3333 6623 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 l-30 0 18 -5z"></path><path d="M2773 6603 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"></path><path d="M3613 6603 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5021 6579 c-13 -11 -22 -21 -20 -23 2 -3 15 4 28 15 13 11 22 21 20
23 -2 3 -15 -4 -28 -15z"></path><path d="M2638 6583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M3740 6579 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5555 6560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2505 6550 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 l-15 0 6 -10z"></path><path d="M2435 6540 c-8 -13 5 -13 25 0 l15 10 -17 0 c-9 0 -20 -4 -23 -10z"></path><path d="M3933 6543 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5120 6543 c1 -12 39 -79 40 -70 0 12 -28 77 -34 77 -4 0 -6 -3 -6
-7z"></path><path d="M2363 6523 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4013 6523 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5537 6508 c-9 -31 -2 -33 11 -3 6 14 8 25 3 25 -5 0 -11 -10 -14
-22z"></path><path d="M2293 6503 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4084 6498 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4150 6480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4880 6480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2160 6460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4210 6460 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"></path><path d="M5010 6459 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4830 6450 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M5167 6439 c3 -13 9 -28 13 -34 4 -5 17 -36 30 -67 12 -32 26 -58 31
-58 4 0 -3 25 -17 55 -14 30 -26 63 -27 72 0 10 -9 27 -18 37 l-18 18 6 -23z"></path><path d="M4273 6443 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2053 6423 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4310 6419 c0 -4 6 -9 13 -12 l14 -5 0 12 c0 7 -6 13 -14 13 -7 0
-13 -4 -13 -8z"></path><path d="M4950 6415 c-7 -8 -17 -12 -23 -8 l-10 6 7 -11 c3 -5 0 -12 -6 -15
-7 -2 -11 -7 -8 -10 3 -3 18 6 34 19 15 14 26 27 24 30 -3 2 -11 -2 -18 -11z"></path><path d="M5495 6410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2000 6400 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M4263 6359 c-24 -17 -43 -32 -43 -35 0 -6 48 21 81 47 40 31 7 21
-38 -12z"></path><path d="M4853 6373 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4845 6322 c-27 -25 -41 -40 -30 -34 27 15 89 71 84 76 -2 2 -26 -16
-54 -42z"></path><path d="M1865 6340 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5465 6329 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M4630 6310 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M1785 6300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M5445 6300 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4576 6274 c-11 -7 -18 -16 -15 -18 2 -2 13 2 23 10 11 7 18 16 15
18 -2 2 -13 -2 -23 -10z"></path><path d="M1710 6270 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M4110 6255 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M5246 6247 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M4750 6239 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5415 6220 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M1570 6190 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5270 6186 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M4000 6182 c0 -12 -80 -63 -89 -57 -4 2 -8 -2 -8 -10 l0 -15 26 17
c14 9 41 26 58 37 33 21 42 36 23 36 -5 0 -10 -4 -10 -8z"></path><path d="M4660 6165 c-30 -32 -21 -32 16 0 16 14 24 25 18 25 -6 0 -21 -11
-34 -25z"></path><path d="M1530 6170 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M5397 6163 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M4401 6151 c-7 -5 -12 -14 -10 -20 l4 -11 13 20 c14 22 13 23 -7 11z"></path><path d="M5290 6143 c1 -5 8 -19 16 -33 l15 -25 -5 18 c-3 10 -6 25 -6 33 0 8
-4 14 -10 14 -5 0 -10 -3 -10 -7z"></path><path d="M4360 6119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1416 6094 c-26 -18 -17 -24 12 -8 13 6 20 14 15 17 -4 3 -17 -1 -27
-9z"></path><path d="M4345 6100 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4580 6095 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M4296 6074 c-11 -8 -16 -14 -10 -14 13 0 39 18 34 24 -3 2 -14 -2
-24 -10z"></path><path d="M3838 6068 c-33 -32 -89 -69 -96 -64 -5 3 -9 -1 -9 -9 0 -8 2 -15 3
-15 6 0 134 92 134 96 0 9 -19 4 -32 -8z"></path><path d="M5355 6071 c-3 -6 -11 -8 -17 -5 l-11 7 5 -13 c7 -21 25 -23 32 -4 8
20 1 30 -9 15z"></path><path d="M1375 6060 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M4993 6053 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M5032 6044 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 l-23 12 17 -18z"></path><path d="M1340 6040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M4235 6030 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4475 6001 c-22 -21 -49 -48 -59 -59 -36 -41 -8 -22 55 38 35 33 60
60 54 60 -5 0 -28 -18 -50 -39z"></path><path d="M4930 6000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4168 5982 c-16 -9 -28 -20 -28 -25 0 -5 14 1 30 13 17 12 30 23 30
26 0 6 -2 5 -32 -14z"></path><path d="M4842 5920 c-24 -22 -27 -30 -13 -30 5 0 16 11 25 25 9 14 15 25 13
25 -2 0 -13 -9 -25 -20z"></path><path d="M3630 5915 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M5233 5886 c21 -21 41 -35 44 -32 7 7 -14 28 -52 51 l-30 19 38 -38z"></path><path d="M4077 5899 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z"></path><path d="M1135 5889 c-11 -17 1 -21 15 -4 l12 15 -10 0 c-6 0 -14 -5 -17 -11z"></path><path d="M3987 5849 c-26 -18 -45 -34 -43 -36 4 -5 96 56 96 64 0 6 -4 3 -53
-28z"></path><path d="M1082 5841 c-22 -19 -28 -31 -14 -31 7 1 46 50 39 50 -2 0 -14 -8
-25 -19z"></path><path d="M3505 5826 c-17 -13 -33 -22 -36 -20 -4 2 -9 -3 -13 -11 -3 -8 -3
-15 0 -15 3 0 22 12 42 27 20 15 39 28 41 30 2 2 2 5 0 8 -3 2 -18 -7 -34 -19z"></path><path d="M4270 5809 c0 -6 -6 -9 -13 -6 l-14 5 0 -14 c0 -19 11 -18 32 6 l18
20 -11 0 c-7 0 -12 -5 -12 -11z"></path><path d="M4720 5810 l-15 -9 13 -11 c6 -5 10 -7 7 -4 -3 4 0 13 6 21 13 15 10
16 -11 3z"></path><path d="M1025 5780 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5371 5763 c18 -23 31 -33 44 -33 6 0 -3 11 -19 25 -34 29 -44 32
-25 8z"></path><path d="M980 5755 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"></path><path d="M3841 5739 c-13 -11 -21 -22 -17 -26 3 -4 12 2 20 12 l13 18 11 -7
12 -7 0 15 c0 21 -10 20 -39 -5z"></path><path d="M4162 5708 c-29 -28 -51 -52 -48 -55 2 -3 28 18 56 47 29 28 51 52
49 55 -2 2 -28 -19 -57 -47z"></path><path d="M5354 5738 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4610 5710 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M3326 5694 c-11 -7 -18 -16 -15 -18 2 -2 13 2 23 10 11 7 18 16 15
18 -2 2 -13 -2 -23 -10z"></path><path d="M3780 5690 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M4580 5680 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M5460 5675 c0 -8 7 -15 16 -15 l16 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"></path><path d="M4560 5660 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3242 5635 l-24 -13 11 -6 c6 -4 21 2 33 13 24 23 15 26 -20 6z"></path><path d="M3668 5602 c-66 -52 -60 -62 7 -11 30 23 55 43 55 45 0 10 -19 0 -62
-34z"></path><path d="M844 5608 l-19 -23 21 18 c11 9 24 14 29 10 l8 -8 -7 13 c-4 6 -8 12
-10 12 -2 0 -11 -10 -22 -22z"></path><path d="M5540 5596 c0 -2 7 -9 15 -16 l15 -12 0 10 c0 5 -7 12 -15 16 -8 3
-15 4 -15 2z"></path><path d="M3160 5575 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M4020 5575 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M805 5571 c-3 -5 -1 -12 4 -15 5 -3 11 1 15 9 6 16 -9 21 -19 6z"></path><path d="M4422 5537 c-17 -18 -32 -35 -32 -38 0 -3 7 -6 15 -6 8 0 12 4 10 8
-3 4 8 19 25 32 16 14 30 28 30 31 0 13 -17 3 -48 -27z"></path><path d="M780 5540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3972 5530 c-20 -19 -27 -30 -19 -30 9 0 50 41 45 45 -2 3 -14 -4
-26 -15z"></path><path d="M3076 5515 l-6 -15 20 10 c23 13 26 20 6 20 -8 0 -16 -7 -20 -15z"></path><path d="M3549 5500 c-13 -10 -20 -19 -16 -20 4 0 17 9 29 20 28 26 17 26 -13
0z"></path><path d="M5629 5496 c14 -15 26 -26 28 -26 9 0 -24 40 -37 45 l-15 6 24 -25z"></path><path d="M3010 5486 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"></path><path d="M2991 5453 c-18 -20 -6 -29 14 -10 8 9 12 18 8 20 -5 3 -14 -2 -22
-10z"></path><path d="M3475 5445 c-15 -14 -26 -27 -24 -30 3 -2 18 7 34 20 15 14 26 27 24
30 -3 2 -18 -7 -34 -20z"></path><path d="M3900 5455 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M5660 5456 c0 -2 7 -9 15 -16 l15 -12 0 10 c0 5 -7 12 -15 16 -8 3
-15 4 -15 2z"></path><path d="M705 5420 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2920 5395 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M660 5380 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3830 5379 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M627 5353 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M3350 5340 c-13 -11 -21 -22 -18 -25 2 -3 15 6 28 20 28 30 22 33
-10 5z"></path><path d="M4205 5340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5755 5340 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5740 5320 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M586 5294 l-18 -26 12 -11 13 -12 -6 11 c-4 7 3 23 14 38 11 14 17
26 12 26 -4 0 -16 -12 -27 -26z"></path><path d="M4175 5309 c-11 -17 1 -21 15 -4 l12 15 -10 0 c-6 0 -14 -5 -17 -11z"></path><path d="M5767 5313 c-4 -3 -7 -11 -7 -17 l0 -11 9 13 8 13 27 -41 c31 -47 45
-54 19 -9 -22 38 -47 61 -56 52z"></path><path d="M4140 5275 l-12 -15 16 0 c9 0 16 7 16 15 0 8 -2 15 -4 15 -2 0 -9
-7 -16 -15z"></path><path d="M3654 5200 c-27 -28 -51 -56 -52 -63 -2 -7 22 14 53 46 31 32 55 61
52 63 -2 3 -26 -18 -53 -46z"></path><path d="M3215 5230 l-20 -9 17 0 c9 -1 20 4 23 9 7 11 7 11 -20 0z"></path><path d="M4085 5220 c-10 -11 -16 -22 -13 -25 3 -3 13 4 23 15 10 11 16 22 13
25 -3 3 -13 -4 -23 -15z"></path><path d="M520 5199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3163 5195 c-19 -21 -16 -27 7 -13 11 7 20 16 20 20 0 12 -14 9 -27
-7z"></path><path d="M5860 5194 c0 -6 7 -19 15 -30 8 -10 14 -14 14 -9 0 6 -6 19 -14 29
-8 11 -15 15 -15 10z"></path><path d="M3125 5160 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M486 5151 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M3574 5123 c3 -4 -10 -24 -31 -45 -20 -21 -33 -38 -28 -38 11 0 75
69 75 81 0 5 -5 9 -12 9 -6 0 -8 -3 -4 -7z"></path><path d="M5905 5120 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M3035 5080 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M437 5067 l-5 -14 12 0 c7 0 13 6 13 14 0 7 -4 13 -8 13 -4 0 -9 -6
-12 -13z"></path><path d="M2990 5040 c-30 -26 -22 -34 10 -10 14 10 22 21 19 24 -3 4 -16 -3
-29 -14z"></path><path d="M5950 5036 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M400 5001 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M2945 5000 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3470 5000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5980 4991 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M2864 4925 c-22 -19 -34 -35 -29 -35 10 0 75 57 75 66 0 9 -9 3 -46
-31z"></path><path d="M6004 4928 l-7 -11 10 6 11 7 11 -32 c6 -17 14 -29 17 -26 5 6 -23
68 -31 68 -2 0 -7 -5 -11 -12z"></path><path d="M3390 4915 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"></path><path d="M336 4873 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M2790 4860 c-13 -11 -21 -22 -18 -25 2 -3 15 6 28 20 28 30 22 33
-10 5z"></path><path d="M3331 4849 c-12 -12 -20 -24 -16 -27 3 -3 15 4 27 16 28 28 18 38
-11 11z"></path><path d="M1835 4850 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M6067 4824 c-3 -3 0 -13 8 -20 8 -8 12 -21 9 -28 -3 -8 -1 -17 5 -21
l11 -6 0 18 c-1 24 -24 65 -33 57z"></path><path d="M300 4800 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3 0
-10 -9 -16 -20z"></path><path d="M276 4753 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3230 4735 l-12 -15 10 0 c12 0 26 19 19 26 -2 2 -10 -2 -17 -11z"></path><path d="M2645 4720 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4664 4718 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3152 4650 c-24 -22 -27 -30 -13 -30 5 0 16 11 25 25 9 14 15 25 13
25 -2 0 -13 -9 -25 -20z"></path><path d="M6135 4660 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M227 4653 c-4 -3 -7 -13 -7 -22 l1 -16 10 18 c11 19 8 33 -4 20z"></path><path d="M1605 4600 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M200 4580 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3095 4580 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M3055 4540 c-4 -6 -11 -8 -16 -5 -5 4 -9 -1 -9 -9 0 -9 5 -16 10 -16
6 0 10 5 10 10 0 6 7 10 15 10 8 0 15 5 15 10 0 13 -17 13 -25 0z"></path><path d="M6190 4531 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M166 4483 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3010 4475 l-12 -15 10 0 c12 0 26 19 19 26 -2 2 -10 -2 -17 -11z"></path><path d="M2976 4434 c-9 -24 -7 -27 9 -10 17 17 19 26 6 26 -5 0 -12 -7 -15
-16z"></path><path d="M150 4430 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6225 4421 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M2303 4373 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"></path><path d="M2912 4349 c-19 -30 -8 -34 12 -4 9 14 13 25 9 25 -5 0 -14 -9 -21
-21z"></path><path d="M4930 4319 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2245 4312 c-5 -4 -37 -36 -70 -70 l-59 -62 18 0 c11 0 17 3 13 6 -3
3 4 16 16 28 73 73 116 124 82 98z"></path><path d="M2840 4248 l-24 -33 18 14 c16 13 41 51 33 51 -2 0 -14 -15 -27 -32z"></path><path d="M6290 4198 c0 -16 5 -28 10 -28 13 0 13 20 0 40 l-10 15 0 -27z"></path><path d="M2787 4183 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M2100 4159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2065 4120 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M66 4103 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M2726 4094 c-10 -14 -16 -28 -13 -30 2 -3 12 7 21 22 10 14 16 28 13
30 -2 3 -12 -7 -21 -22z"></path><path d="M2019 4063 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M1975 4017 c-25 -29 -26 -40 -2 -18 15 14 27 26 27 28 0 7 -16 1 -25
-10z"></path><path d="M6341 3984 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M2636 3965 c-9 -14 -12 -25 -7 -25 12 0 35 39 28 46 -2 3 -12 -7 -21
-21z"></path><path d="M1890 3915 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"></path><path d="M2596 3905 c-9 -14 -12 -25 -7 -25 12 0 35 39 28 46 -2 3 -12 -7 -21
-21z"></path><path d="M27 3873 c-4 -3 -7 -16 -6 -27 l0 -21 10 24 c10 24 8 36 -4 24z"></path><path d="M6356 3852 c-2 -4 -1 -14 5 -22 l9 -15 0 23 c0 23 -5 28 -14 14z"></path><path d="M1286 3841 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M2544 3825 c-9 -14 -15 -28 -12 -30 2 -2 12 9 22 25 21 36 13 40 -10
5z"></path><path d="M1840 3820 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1807 3813 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"></path><path d="M1270 3795 c-7 -9 -10 -18 -7 -21 3 -3 10 4 17 15 13 25 8 27 -10 6z"></path><path d="M2505 3760 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1760 3750 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M10 3730 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2484 3728 l-7 -11 11 7 c7 4 12 9 12 12 0 8 -9 4 -16 -8z"></path><path d="M1200 3695 l-12 -15 10 0 c12 0 26 19 19 26 -2 2 -10 -2 -17 -11z"></path><path d="M2467 3703 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M1700 3670 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14 -2
3 -11 1 -19 -4z"></path><path d="M2435 3649 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1147 3623 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M2390 3575 c-12 -20 -20 -38 -17 -41 2 -3 15 11 27 31 12 20 20 38
17 41 -2 3 -15 -11 -27 -31z"></path><path d="M1636 3584 l-6 -17 15 13 c17 14 19 20 6 20 -5 0 -12 -7 -15 -16z"></path><path d="M2 3560 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"></path><path d="M1070 3515 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M2347 3503 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M1560 3480 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2321 3452 c-17 -31 -13 -40 5 -14 9 13 14 26 11 29 -3 2 -10 -4 -16
-15z"></path><path d="M1015 3429 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M6392 3395 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"></path><path d="M3 3365 l0 -40 4 23 c2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"></path><path d="M985 3380 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M960 3339 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"></path><path d="M1451 3309 c-17 -28 -30 -53 -28 -56 3 -2 19 19 37 47 18 28 30 53
28 55 -3 3 -19 -18 -37 -46z"></path><path d="M2261 3342 c-17 -31 -13 -40 5 -14 9 13 14 26 11 29 -3 2 -10 -4 -16
-15z"></path><path d="M906 3246 c-36 -62 -33 -71 5 -12 16 27 28 50 26 53 -3 2 -16 -16
-31 -41z"></path><path d="M6382 3225 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"></path><path d="M2195 3209 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M11 3194 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1387 3203 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M2160 3139 c-12 -23 -19 -43 -17 -46 3 -2 13 13 23 34 9 21 19 41 21
46 2 4 2 7 -1 7 -3 0 -15 -18 -26 -41z"></path><path d="M1351 3139 c-7 -12 -10 -23 -8 -26 3 -3 11 5 17 17 15 28 8 35 -9 9z"></path><path d="M847 3143 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M1327 3093 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M20 3080 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2123 3069 c-1 -10 2 -19 8 -19 11 0 12 27 1 34 -4 2 -9 -4 -9 -15z"></path><path d="M28 3023 l6 -28 4 19 c2 10 0 22 -6 28 l-10 10 6 -29z"></path><path d="M1291 3023 l-11 -28 15 19 c8 11 15 23 15 28 0 15 -7 8 -19 -19z"></path><path d="M6361 3034 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M2110 3030 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1256 2953 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M753 2945 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"></path><path d="M2075 2952 c-13 -9 -17 -32 -6 -32 5 0 11 9 14 20 6 22 6 22 -8 12z"></path><path d="M728 2888 c-9 -13 -19 -38 -23 -57 l-7 -34 11 7 c6 4 11 14 11 22 0
8 7 29 16 45 19 37 13 49 -8 17z"></path><path d="M1226 2893 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6340 2900 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2030 2839 c-6 -11 -10 -25 -7 -32 2 -6 9 3 15 21 12 36 8 42 -8 11z"></path><path d="M60 2828 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 l-10 15 0 -17z"></path><path d="M1192 2816 c-17 -37 -15 -45 4 -18 8 12 14 27 12 33 -2 6 -9 -1 -16
-15z"></path><path d="M6315 2811 c-3 -5 -1 -12 5 -16 l10 -6 0 15 c0 18 -6 21 -15 7z"></path><path d="M1166 2754 c-8 -21 -8 -24 5 -24 5 0 8 4 5 9 -4 5 -1 11 6 13 l12 4
-11 7 -11 7 -6 -16z"></path><path d="M667 2713 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M1975 2683 c-8 -20 -13 -38 -10 -41 4 -4 11 11 30 66 2 6 2 12 0 12
-3 0 -12 -16 -20 -37z"></path><path d="M6300 2710 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1140 2690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M6290 2670 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1130 2660 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M641 2628 c-14 -40 -23 -83 -15 -75 9 9 33 97 27 97 -2 0 -7 -10 -12
-22z"></path><path d="M1120 2630 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1950 2604 c-12 -31 -12 -34 -1 -34 5 0 11 14 13 30 3 17 3 30 1 30
-2 0 -8 -12 -13 -26z"></path><path d="M1106 2594 c-9 -23 -2 -28 13 -10 l12 14 -9 6 c-6 3 -13 -1 -16 -10z"></path><path d="M6260 2580 l-15 -10 18 0 c9 0 17 5 17 10 0 12 -1 12 -20 0z"></path><path d="M120 2560 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6260 2550 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M130 2530 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M617 2523 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M1080 2510 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6243 2505 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"></path><path d="M155 2455 c7 -33 12 -41 20 -33 3 3 -2 18 -11 34 l-16 29 7 -30z"></path><path d="M1907 2474 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M1060 2440 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M597 2423 c-4 -3 -7 -13 -7 -22 l1 -16 10 18 c11 19 8 33 -4 20z"></path><path d="M1050 2400 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6205 2400 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M6195 2380 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M587 2374 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M1040 2350 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6185 2329 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1030 2310 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1867 2303 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M6165 2300 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M1021 2264 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M226 2257 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M571 2254 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1850 2230 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M6135 2220 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M1847 2203 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M6114 2172 c-15 -10 -25 -40 -15 -46 4 -3 13 6 20 21 13 28 11 36 -5
25z"></path><path d="M285 2130 c22 -45 29 -52 20 -20 -4 14 -14 32 -22 40 l-15 15 17 -35z"></path><path d="M1834 2145 c-3 -8 -4 -20 -2 -27 3 -7 7 -1 10 15 6 29 1 36 -8 12z"></path><path d="M1000 2120 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1827 2094 c-4 -4 -7 -17 -7 -28 l1 -21 10 18 c11 19 8 43 -4 31z"></path><path d="M6065 2081 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"></path><path d="M346 2007 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M572 1975 l0 -30 5 18 c2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"></path><path d="M1811 1974 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M382 1945 c2 -5 10 -22 17 -37 14 -28 31 -37 31 -18 0 6 -5 10 -11
10 -5 0 -8 3 -6 8 3 4 -5 16 -16 27 -11 11 -18 16 -15 10z"></path><path d="M5972 1880 c-25 -33 -30 -56 -6 -25 18 24 36 55 31 55 -2 0 -13 -13
-25 -30z"></path><path d="M1808 1894 c-5 -4 -8 -19 -8 -33 l0 -25 11 11 c6 6 9 21 7 33 -1 12
-6 18 -10 14z"></path><path d="M430 1853 c13 -28 27 -37 27 -18 0 8 -3 13 -5 10 -3 -3 -11 3 -19 13
l-14 17 11 -22z"></path><path d="M590 1826 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M5911 1782 c-17 -31 -13 -40 5 -14 9 13 14 26 11 29 -3 2 -10 -4 -16
-15z"></path><path d="M503 1783 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M605 1759 c10 -35 23 -45 18 -15 -3 15 -10 29 -15 32 l-10 7 7 -24z"></path><path d="M1000 1730 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5859 1693 l-14 -18 18 14 c16 13 21 21 13 21 -2 0 -10 -8 -17 -17z"></path><path d="M630 1670 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5830 1659 l0 -10 -12 7 -13 7 12 -13 c6 -7 16 -9 22 -6 14 9 14 26
1 26 -5 0 -10 -5 -10 -11z"></path><path d="M576 1627 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M1020 1599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5780 1600 l-15 -10 23 0 c12 0 22 5 22 10 0 13 -11 13 -30 0z"></path><path d="M630 1570 l-15 -9 17 -17 16 -16 -1 26 c-1 14 -2 26 -2 26 0 0 -7 -5
-15 -10z"></path><path d="M5760 1555 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M677 1520 l-5 -20 11 7 10 6 -6 -16 -6 -17 13 0 13 0 -5 25 c-6 33
-18 40 -25 15z"></path><path d="M1040 1520 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5735 1520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5700 1475 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M1800 1446 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M1060 1441 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M5607 1365 c-46 -54 -39 -60 9 -8 29 32 42 53 31 53 -1 0 -20 -20
-40 -45z"></path><path d="M1080 1390 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1810 1368 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 l-10 15 0 -17z"></path><path d="M1096 1347 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M2360 1340 c0 -14 5 -32 10 -40 l10 -15 0 25 c0 14 -5 32 -10 40
l-10 15 0 -25z"></path><path d="M1136 1255 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"></path><path d="M2381 1254 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1835 1220 c3 -19 10 -39 16 -45 l10 -10 -7 30 c-4 17 -11 37 -16 45
l-9 15 6 -35z"></path><path d="M5460 1205 c-13 -14 -21 -25 -18 -25 10 0 51 40 46 45 -2 3 -15 -6
-28 -20z"></path><path d="M2396 1197 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M5410 1155 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M1850 1150 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1190 1146 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M2420 1121 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M1860 1106 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"></path><path d="M5320 1075 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M1240 1060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M1886 1030 c3 -14 10 -29 15 -34 l9 -10 0 10 c-1 5 -7 20 -16 34
l-15 25 7 -25z"></path><path d="M2450 1042 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 l-15 12 0 -10z"></path><path d="M5260 1019 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1290 1010 c-8 -5 -11 -12 -7 -16 4 -4 13 -2 19 4 15 15 7 24 -12 12z"></path><path d="M5226 994 c-11 -7 -18 -16 -15 -18 2 -2 13 2 23 10 11 7 18 16 15 18
-2 2 -13 -2 -23 -10z"></path><path d="M2477 982 c3 -12 9 -25 14 -28 14 -8 11 10 -6 32 l-14 19 6 -23z"></path><path d="M1305 970 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2505 940 c-3 -5 -2 -18 4 -27 l9 -17 -1 27 c-1 15 -3 27 -4 27 -1 0
-5 -4 -8 -10z"></path><path d="M5132 920 c-7 -11 -11 -20 -9 -20 2 0 14 9 27 20 l23 20 -14 0 c-8 0
-20 -9 -27 -20z"></path><path d="M1360 903 c0 -7 3 -13 8 -13 4 0 18 -16 31 -35 13 -19 27 -35 31 -35
4 0 11 7 14 16 l6 15 -12 -7 -11 -7 -34 39 c-18 21 -33 33 -33 27z"></path><path d="M1930 901 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M5033 873 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2544 843 c9 -17 21 -35 27 -39 l11 -6 -10 18 c-6 11 -18 28 -27 39
l-16 20 15 -32z"></path><path d="M1950 840 c6 -11 12 -20 14 -20 2 0 2 9 -1 20 -3 11 -9 20 -15 20
l-9 0 11 -20z"></path><path d="M4963 799 c-24 -17 -43 -32 -43 -35 0 -6 48 21 81 47 40 31 7 21 -38
-12z"></path><path d="M1450 802 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 l-15 12 0 -10z"></path><path d="M1970 801 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M1480 764 c0 -9 142 -144 152 -144 4 0 8 7 8 16 l0 15 -11 -7 -11 -6
-63 66 c-59 62 -75 74 -75 60z"></path><path d="M2600 741 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M4855 740 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M2010 705 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M2620 701 c0 -5 7 -12 16 -15 l17 -6 -13 15 c-14 17 -20 19 -20 6z"></path><path d="M2030 671 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M4650 615 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M1650 605 c0 -8 38 -42 65 -56 l20 -11 -25 23 c-39 36 -60 52 -60 44z"></path><path d="M2720 582 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 l-15 12 0 -10z"></path><path d="M4573 573 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M4480 540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M2110 531 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M1740 526 c0 -2 12 -12 28 -21 l27 -18 -19 21 c-17 20 -36 29 -36 18z"></path><path d="M2784 518 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M4425 510 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M2140 485 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 l-10 0 12 -15z"></path><path d="M2790 486 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M1800 476 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"></path><path d="M4280 466 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z"></path><path d="M1850 443 c0 -5 14 -17 30 -26 17 -10 30 -16 30 -13 0 2 -13 14 -30
26 -16 12 -30 18 -30 13z"></path><path d="M2171 445 c0 -13 38 -59 44 -54 2 3 -7 18 -20 34 -13 17 -24 26 -24
20z"></path><path d="M2840 421 c0 -5 7 -11 14 -14 l15 -6 -6 15 c-6 15 -23 19 -23 5z"></path><path d="M4195 420 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4123 393 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2245 347 c15 -21 30 -35 32 -33 3 3 -9 20 -27 38 l-32 33 27 -38z"></path><path d="M4053 373 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M1985 360 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M2895 360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M3944 348 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3880 340 l-15 -10 18 0 c9 0 17 5 17 10 0 12 -1 12 -20 0z"></path><path d="M2030 323 c0 -8 65 -53 75 -53 3 0 5 5 5 10 0 6 -6 10 -13 10 -8 0
-22 9 -32 20 -18 20 -35 26 -35 13z"></path><path d="M3640 300 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2300 280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2960 282 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 l-15 12 0 -10z"></path><path d="M3533 273 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2130 261 c0 -5 7 -12 16 -15 l17 -6 -13 15 c-14 17 -20 19 -20 6z"></path><path d="M2330 253 c0 -5 9 -18 20 -28 11 -10 20 -14 20 -8 0 5 -9 18 -20 28
-11 10 -20 14 -20 8z"></path><path d="M2200 216 c0 -2 7 -7 16 -10 l15 -6 -6 10 c-6 10 -25 14 -25 6z"></path><path d="M2380 200 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2310 159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2343 133 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2450 136 c0 -2 7 -9 15 -16 l15 -12 0 10 c0 5 -7 12 -15 16 -8 3
-15 4 -15 2z"></path><path d="M2380 120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2421 116 l-12 -14 11 -7 c6 -4 14 -4 17 -1 4 3 1 6 -7 6 l-12 0 12
15 c7 8 10 15 7 15 -2 0 -10 -6 -16 -14z"></path><path d="M2490 103 c0 -6 11 -18 25 -27 l26 -17 -7 -12 -7 -12 13 12 c8 7 12
14 9 15 -2 2 -16 14 -31 27 -16 14 -28 20 -28 14z"></path><path d="M2463 73 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M2503 53 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path></g><g transform="translate(0.000000,727.000000) scale(0.100000,-0.100000)" fill="#8fb557" stroke="none"><path d="M5834 7234 c-2 -28 -1 -28 15 4 l12 22 -13 0 -13 0 -1 -26z"></path><path d="M5760 7240 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5690 7220 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M5620 7200 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5550 7180 l-15 -10 18 0 c9 0 17 5 17 10 0 12 -1 12 -20 0z"></path><path d="M5815 7169 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M5480 7160 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5633 7163 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5400 7140 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M5335 7120 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M5787 7113 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M5270 7100 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5195 7080 l-20 -9 17 0 c9 -1 20 4 23 9 7 11 7 11 -20 0z"></path><path d="M5130 7060 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M5060 7040 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M5755 7029 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M4995 7020 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4920 7000 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M4785 6960 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4720 6940 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M4655 6920 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"></path><path d="M4602 6898 c2 -6 10 -14 16 -16 l12 -4 -6 16 c-7 18 -28 22 -22 4z"></path><path d="M5690 6880 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4655 6860 c3 -5 8 -10 11 -10 3 0 2 5 -1 10 -3 6 -8 10 -11 10 -3 0
-2 -4 1 -10z"></path><path d="M5655 6789 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M4805 6760 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M4880 6710 c14 -10 27 -17 29 -14 6 5 -31 33 -44 33 -5 1 1 -8 15
-19z"></path><path d="M4965 6660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"></path><path d="M2915 6620 l-30 -8 27 -1 c15 0 30 4 33 9 3 6 5 9 3 9 -2 -1 -16 -5
-33 -9z"></path><path d="M3405 6620 c3 -5 29 -10 57 -10 28 0 49 2 47 4 -2 2 -28 6 -57 10
l-54 7 7 -11z"></path><path d="M5025 6620 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"></path><path d="M2720 6600 l-25 -8 27 -1 c15 -1 30 4 33 9 7 11 0 11 -35 0z"></path><path d="M3635 6600 c3 -5 16 -10 28 -9 l22 0 -20 9 c-28 12 -37 12 -30 0z"></path><path d="M3777 6579 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 l-18 7 12 -12z"></path><path d="M5560 6559 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"></path><path d="M2510 6560 l-15 -10 22 0 c12 0 25 5 28 10 8 13 -15 13 -35 0z"></path><path d="M4995 6560 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3958 6538 c5 -5 16 -8 23 -6 l13 4 -23 7 -24 6 11 -11z"></path><path d="M2340 6520 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M4035 6520 c3 -5 14 -10 23 -10 l17 0 -15 10 c-20 13 -33 13 -25 0z"></path><path d="M5054 6505 c-38 -36 -26 -39 16 -4 19 16 29 29 23 29 -7 0 -24 -11
-39 -25z"></path><path d="M2270 6500 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M4095 6500 c3 -5 14 -10 23 -10 l17 0 -15 10 c-20 13 -33 13 -25 0z"></path><path d="M4910 6499 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2210 6480 l-15 -10 17 0 c9 0 20 5 23 10 8 13 -5 13 -25 0z"></path><path d="M4160 6480 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"></path><path d="M4860 6469 c-30 -22 -23 -25 15 -5 14 8 23 16 18 19 -5 3 -20 -3 -33
-14z"></path><path d="M2145 6460 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M4225 6460 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"></path><path d="M5515 6449 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M2090 6440 l-15 -10 12 0 c6 0 15 5 18 10 8 12 4 12 -15 0z"></path><path d="M4800 6430 c-14 -11 -20 -20 -15 -19 13 0 50 28 44 33 -2 3 -15 -4
-29 -14z"></path><path d="M2035 6420 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4960 6419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4330 6405 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M1961 6394 c-18 -8 -32 -17 -30 -19 7 -6 79 19 79 27 0 11 -9 9 -49
-8z"></path><path d="M5490 6399 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4725 6380 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M4270 6359 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4890 6359 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1845 6340 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M1805 6320 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M1765 6300 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4180 6299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4615 6300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5230 6280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1685 6260 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4120 6259 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4555 6260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5420 6219 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"></path><path d="M4720 6220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M1545 6180 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M1500 6150 c-14 -11 -20 -20 -15 -19 13 0 50 28 44 33 -2 3 -15 -4
-29 -14z"></path><path d="M4415 6160 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3950 6139 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1455 6120 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3893 6123 c4 -3 10 -3 14 0 3 4 0 7 -7 7 -7 0 -10 -3 -7 -7z"></path><path d="M5375 6109 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1425 6100 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1395 6080 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4553 6075 c-15 -16 -16 -25 -5 -25 4 0 13 9 20 20 14 23 3 26 -15 5z"></path><path d="M1364 6058 l-7 -11 11 7 c12 7 16 16 8 16 -3 0 -8 -5 -12 -12z"></path><path d="M3835 6060 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M5005 6060 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M5346 6055 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"></path><path d="M1335 6040 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4245 6040 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4965 6030 l-18 -20 11 0 c7 0 12 4 12 9 0 5 8 11 18 13 l17 3 -11 7
-11 8 -18 -20z"></path><path d="M1305 6020 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4195 6000 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4480 5999 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5090 6000 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M3708 5976 c-26 -19 -22 -26 7 -13 14 7 25 15 25 20 0 11 -13 8 -32
-7z"></path><path d="M4165 5980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4910 5979 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4875 5950 c-10 -11 -13 -20 -8 -20 13 0 38 29 31 35 -3 3 -13 -4
-23 -15z"></path><path d="M1166 5915 c-11 -8 -16 -18 -11 -22 4 -5 10 -2 12 5 3 6 9 10 14 6 5
-3 9 2 9 10 0 9 -1 16 -2 16 -2 0 -12 -7 -22 -15z"></path><path d="M3640 5919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4070 5910 c-14 -11 -20 -20 -15 -19 13 0 50 28 44 33 -2 3 -15 -4
-29 -14z"></path><path d="M4390 5919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3610 5899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5220 5900 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M1125 5880 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4785 5870 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"></path><path d="M3543 5855 c-19 -21 -16 -27 7 -13 11 7 20 16 20 20 0 12 -14 9 -27
-7z"></path><path d="M5265 5860 l-6 -10 16 0 16 0 -6 10 c-3 6 -8 10 -10 10 -2 0 -7 -4
-10 -10z"></path><path d="M1045 5810 l-18 -20 11 0 c7 0 12 4 12 9 0 5 8 11 18 13 l17 3 -11 7
-11 8 -18 -20z"></path><path d="M3500 5819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M5310 5826 c0 -2 7 -9 15 -16 l15 -13 -6 17 c-3 9 -10 16 -15 16 -5
0 -9 -2 -9 -4z"></path><path d="M4710 5800 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3895 5780 l-6 -10 15 0 c9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0 -13
-4 -16 -10z"></path><path d="M4240 5779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3390 5743 l-25 -26 33 19 c17 10 32 22 32 26 0 14 -15 7 -40 -19z"></path><path d="M4210 5760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M5381 5754 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M970 5740 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M950 5720 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M930 5700 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4600 5700 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5440 5700 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M910 5680 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3765 5680 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M890 5660 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4525 5630 c-22 -22 -36 -40 -32 -40 10 0 80 70 75 75 -2 3 -22 -13
-43 -35z"></path><path d="M866 5633 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M5500 5645 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"></path><path d="M3695 5620 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4070 5619 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3665 5600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M815 5580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3170 5579 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4405 5520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5610 5520 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M745 5500 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3030 5480 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M4345 5470 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"></path><path d="M5650 5466 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 l-10
6 0 -15z"></path><path d="M696 5441 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 l-4 13 -8 -13z"></path><path d="M4295 5430 l-18 -20 11 0 c7 0 12 5 12 11 0 5 5 7 10 4 l10 -6 0 15
c0 9 -2 16 -3 16 -2 0 -12 -9 -22 -20z"></path><path d="M3445 5420 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M5690 5420 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2930 5399 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3850 5399 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2862 5355 c-13 -7 -31 -23 -39 -35 l-16 -23 13 8 c8 5 21 15 30 22
8 7 24 19 35 27 24 19 9 19 -23 1z"></path><path d="M4225 5360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3350 5340 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M605 5320 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3325 5320 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M4175 5310 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"></path><path d="M5770 5320 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M3721 5274 c-7 -8 -11 -17 -9 -20 3 -2 13 5 23 16 10 11 14 20 9 20
-5 -1 -15 -8 -23 -16z"></path><path d="M3260 5265 l-12 -15 10 0 c5 0 15 7 22 15 l12 15 -10 0 c-5 0 -15 -7
-22 -15z"></path><path d="M2737 5256 l-6 -15 15 6 c7 3 14 9 14 14 0 14 -17 10 -23 -5z"></path><path d="M5810 5260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M546 5233 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M4076 5211 l-17 -19 10 -7 11 -6 0 15 c0 8 6 16 13 19 6 2 9 7 6 11
-3 3 -14 -2 -23 -13z"></path><path d="M2670 5200 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M3640 5179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2609 5143 l-24 -26 27 19 c15 10 28 22 28 27 0 12 -5 8 -31 -20z"></path><path d="M3105 5140 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3996 5124 c-23 -23 -20 -30 4 -9 11 10 20 20 20 22 0 7 -9 2 -24
-13z"></path><path d="M3580 5119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2539 5083 l-14 -18 18 14 c16 13 21 21 13 21 -2 0 -10 -8 -17 -17z"></path><path d="M5950 5040 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2460 5015 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M3490 5019 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M392 4985 c-6 -14 -10 -25 -8 -25 2 0 11 11 20 25 9 14 13 25 8 25
-5 0 -14 -11 -20 -25z"></path><path d="M2925 4980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M3450 4979 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M366 4933 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3400 4919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M346 4893 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6030 4901 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M3360 4879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M322 4845 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
-21z"></path><path d="M2279 4853 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M6050 4866 c0 -11 30 -66 36 -66 2 0 -3 15 -12 33 -19 36 -24 44 -24
33z"></path><path d="M3321 4834 c-7 -8 -11 -17 -9 -20 3 -2 13 5 23 16 10 11 14 20 9 20
-5 -1 -15 -8 -23 -16z"></path><path d="M2730 4800 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M286 4773 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M2685 4760 c-16 -17 -25 -30 -21 -30 10 0 59 50 54 56 -3 2 -17 -10
-33 -26z"></path><path d="M3695 4740 c21 -24 26 -25 19 -6 -3 8 -13 17 -22 21 l-16 6 19 -21z"></path><path d="M266 4733 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3240 4740 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -16 15 -12
35 10 l18 20 -11 0 c-7 0 -12 -4 -12 -10z"></path><path d="M2090 4715 l-13 -15 17 6 c9 3 16 10 16 15 0 13 -6 11 -20 -6z"></path><path d="M6110 4722 c0 -11 39 -82 45 -82 5 0 -30 78 -38 87 -4 3 -7 1 -7 -5z"></path><path d="M2605 4680 c-16 -17 -25 -30 -21 -30 10 0 59 50 54 56 -3 2 -17 -10
-33 -26z"></path><path d="M2029 4663 l-24 -26 27 19 c15 10 28 22 28 27 0 12 -5 8 -31 -20z"></path><path d="M3180 4679 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M3810 4646 c0 -2 8 -10 18 -17 l17 -14 -14 18 c-13 16 -21 21 -21 13z"></path><path d="M2536 4614 c-11 -7 -17 -17 -13 -20 3 -4 14 1 23 10 21 22 15 28 -10
10z"></path><path d="M3130 4619 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M206 4593 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6170 4581 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M2480 4560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M3080 4559 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M186 4533 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M3041 4526 l-12 -14 11 -7 c5 -3 13 1 16 10 9 22 -1 29 -15 11z"></path><path d="M2411 4489 c-18 -21 -30 -39 -27 -39 8 0 76 67 76 75 0 13 -18 0 -49
-36z"></path><path d="M3976 4465 c4 -8 11 -15 16 -15 l10 0 -12 15 c-7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"></path><path d="M3000 4459 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6220 4441 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M2350 4420 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4010 4422 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 l-15 12 0 -10z"></path><path d="M2322 4398 c-7 -7 -12 -16 -12 -21 0 -5 7 -1 16 8 9 9 14 18 12 20
-2 3 -10 -1 -16 -7z"></path><path d="M6230 4405 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"></path><path d="M2940 4379 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6250 4350 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M107 4281 l-6 -24 11 11 c5 5 8 16 6 23 l-4 13 -7 -23z"></path><path d="M4120 4271 c11 -22 30 -37 30 -24 -1 11 -26 43 -34 43 -4 0 -2 -9 4
-19z"></path><path d="M6270 4280 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1589 4173 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M4990 4171 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"></path><path d="M2765 4149 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M6310 4140 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M70 4120 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2695 4049 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M6330 4026 c0 -8 4 -17 9 -20 l9 -6 -5 20 c-6 23 -13 26 -13 6z"></path><path d="M4246 4015 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"></path><path d="M1430 3995 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M2660 3999 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1935 3970 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"></path><path d="M1379 3933 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M2620 3939 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6350 3920 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M30 3900 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1876 3893 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M1326 3874 l-6 -17 15 13 c17 14 19 20 6 20 -5 0 -12 -7 -15 -16z"></path><path d="M2572 3874 c-7 -8 -12 -20 -10 -26 2 -6 12 1 21 16 19 28 11 35 -11
10z"></path><path d="M1287 3834 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M1813 3818 c-6 -7 -13 -20 -17 -28 l-7 -15 14 17 c8 10 17 15 20 11
4 -3 7 1 7 10 0 20 -4 21 -17 5z"></path><path d="M2540 3819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1220 3719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1737 3723 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M6372 3710 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path><path d="M1190 3679 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1711 3674 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M2447 3673 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M1160 3639 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M6380 3600 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1117 3580 c-19 -22 -22 -35 -7 -25 10 6 34 45 28 45 -2 0 -11 -9
-21 -20z"></path><path d="M2380 3559 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4361 3544 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1605 3540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M2360 3519 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"></path><path d="M1060 3499 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1576 3493 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M4371 3464 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M4381 3374 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M960 3340 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M875 3189 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1377 3183 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3
-9 2 -12 -2z"></path><path d="M6372 3130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"></path><path d="M800 3046 c-27 -65 -24 -71 6 -13 14 27 23 51 20 54 -3 3 -15 -15
-26 -41z"></path><path d="M1316 3073 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M4361 3034 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1290 3020 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M765 2969 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M4351 2954 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M2060 2919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M4326 2855 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"></path><path d="M6331 2854 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M67 2799 l7 -23 4 13 c2 7 -1 18 -6 23 l-11 11 6 -24z"></path><path d="M687 2773 c-4 -3 -7 -11 -7 -17 l0 -10 12 12 c6 6 9 14 7 17 -3 3 -9
2 -12 -2z"></path><path d="M2005 2769 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1166 2753 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6310 2760 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4286 2715 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"></path><path d="M1146 2704 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"></path><path d="M1126 2653 l-7 -18 12 12 c7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path><path d="M6270 2605 l-12 -15 10 0 c5 0 12 7 16 15 3 8 4 15 2 15 -2 0 -9 -7
-16 -15z"></path><path d="M4210 2505 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"></path><path d="M607 2494 c-4 -4 -7 -14 -7 -23 l0 -15 11 11 c6 6 10 16 7 23 -2 6
-7 8 -11 4z"></path><path d="M1066 2464 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"></path><path d="M6235 2469 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M6220 2430 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4050 2230 c-6 -11 -7 -20 -3 -20 5 0 14 9 21 20 l12 20 -10 0 c-5 0
-14 -9 -20 -20z"></path><path d="M6135 2209 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M1008 2187 l-6 -29 10 10 c6 6 8 18 6 28 l-4 19 -6 -28z"></path><path d="M6100 2139 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M356 1987 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M3799 1943 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M390 1921 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M5990 1919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M580 1881 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M5955 1849 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M3650 1830 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"></path><path d="M450 1825 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25 -5
8 -10 11 -10 5z"></path><path d="M5920 1795 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"></path><path d="M600 1781 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M492 1750 c7 -11 16 -20 20 -20 5 0 3 9 -4 20 -7 11 -16 20 -20 20
-5 0 -3 -9 4 -20z"></path><path d="M2300 1705 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"></path><path d="M1007 1679 l7 -23 4 13 c2 7 -1 18 -6 23 l-11 11 6 -24z"></path><path d="M2430 1691 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"></path><path d="M3338 1678 c-16 -5 -28 -13 -28 -18 0 -5 14 -3 30 3 17 6 30 15 30
19 0 4 -1 8 -2 7 -2 0 -15 -5 -30 -11z"></path><path d="M5850 1680 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M565 1640 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M5813 1635 c-9 -24 1 -27 15 -5 l12 20 -10 0 c-6 0 -13 -7 -17 -15z"></path><path d="M2310 1625 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M2628 1623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M2660 1620 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"></path><path d="M3168 1623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M3118 1613 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M650 1603 c0 -5 7 -18 15 -29 l14 -19 -6 23 c-5 22 -23 41 -23 25z"></path><path d="M2728 1603 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"></path><path d="M3048 1603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M2968 1593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 l-19 -1 13 -5z"></path><path d="M605 1580 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M1030 1560 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M5750 1539 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M666 1507 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M2330 1485 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"></path><path d="M5690 1460 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M2341 1434 l1 -19 5 13 c3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"></path><path d="M1066 1427 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M5645 1409 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"></path><path d="M2350 1390 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1106 1327 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M5570 1319 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1820 1301 c0 -6 4 -13 10 -16 l10 -6 -6 15 c-7 18 -14 21 -14 7z"></path><path d="M1130 1280 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M1170 1194 c0 -5 7 -19 15 -30 8 -10 15 -14 15 -8 0 5 -7 19 -15 30
-8 10 -15 14 -15 8z"></path><path d="M1210 1125 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"></path><path d="M5351 1104 c-12 -14 -21 -27 -19 -30 3 -2 17 10 33 26 16 17 25 30
19 29 -5 0 -20 -11 -33 -25z"></path><path d="M2424 1111 c3 -4 10 -21 16 -36 6 -15 13 -25 16 -22 6 6 -23 67 -32
67 -3 0 -3 -4 0 -9z"></path><path d="M1266 1027 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 l-12 12 7 -18z"></path><path d="M5185 960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"></path><path d="M1325 940 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M1910 941 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M5110 899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M1940 860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M1455 790 c10 -11 20 -18 23 -16 5 6 -23 36 -34 36 -4 0 1 -9 11 -20z"></path><path d="M2570 800 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4970 799 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2580 771 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"></path><path d="M4809 703 l-14 -18 18 14 c9 7 17 15 17 17 0 8 -8 3 -21 -13z"></path><path d="M2640 680 c0 -7 3 -10 7 -7 3 4 3 10 0 14 -4 3 -7 0 -7 -7z"></path><path d="M4750 669 l-25 -20 33 16 c17 8 32 18 32 20 0 10 -16 4 -40 -16z"></path><path d="M1615 630 c10 -11 20 -20 22 -20 1 0 3 7 3 16 l0 15 -10 -6 c-5 -3
-10 -1 -10 4 0 6 -5 11 -12 11 l-11 0 18 -20z"></path><path d="M2670 642 c0 -5 7 -15 15 -22 8 -7 15 -9 15 -6 0 4 -7 14 -15 22 -8
9 -15 11 -15 6z"></path><path d="M4695 640 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4660 620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"></path><path d="M4625 600 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M2080 584 c0 -6 7 -19 15 -30 8 -10 14 -14 14 -9 0 6 -6 19 -14 29
-8 11 -15 15 -15 10z"></path><path d="M2735 560 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M4535 560 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4485 540 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"></path><path d="M4445 520 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M2770 511 c0 -6 5 -13 10 -16 l10 -6 0 15 c0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"></path><path d="M4405 500 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M2810 462 c0 -5 7 -15 15 -22 8 -7 15 -9 15 -6 0 4 -7 14 -15 22 -8
9 -15 11 -15 6z"></path><path d="M4305 460 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M4255 440 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M1905 400 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M4135 400 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"></path><path d="M4075 380 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M1980 350 c14 -10 27 -17 29 -14 6 5 -31 33 -44 33 -5 1 1 -8 15 -19z"></path><path d="M3970 360 c0 -5 4 -10 9 -10 6 0 13 5 16 10 l6 10 -15 0 c-9 0 -16
-4 -16 -10z"></path><path d="M3890 339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"></path><path d="M2025 320 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"></path><path d="M3795 320 c-8 -13 5 -13 25 0 l15 10 -17 0 c-9 0 -20 -4 -23 -10z"></path><path d="M2945 300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M3670 300 l-15 -10 20 1 c11 0 29 4 40 9 l20 9 -25 0 c-14 0 -32 -4
-40 -9z"></path><path d="M2085 280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M3565 280 l-6 -10 15 6 c18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"></path><path d="M2125 260 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M2975 260 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"></path><path d="M3045 260 l-6 -10 138 1 c76 1 167 5 203 9 l65 7 -197 1 -197 2 -6
-10z"></path><path d="M2155 240 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"></path><path d="M2360 220 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2225 200 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"></path><path d="M2265 180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"></path><path d="M2400 180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"></path><path d="M2290 166 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"></path></g></svg>
    );
}

export default Logo;
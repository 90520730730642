import React from 'react';

function ArrowRight(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="136" height="32" viewBox="0 0 136 32" fill="none">
                <path id="Vector 1" d="M135.004 1.05858C134.946 0.509364 134.454 0.111273 133.904 0.169418L124.955 1.11695C124.405 1.1751 124.007 1.66746 124.065 2.21668C124.123 2.76589 124.616 3.16399 125.165 3.10584L133.121 2.26358L133.963 10.2191C134.021 10.7683 134.513 11.1664 135.063 11.1083C135.612 11.0501 136.01 10.5578 135.952 10.0086L135.004 1.05858ZM133.232 0.535128C124.807 10.9553 108.106 23.7697 85.4688 28.1952C62.8715 32.6129 34.2611 28.6907 1.93235 5.49776L0.766517 7.12282C33.4987 30.6052 62.6628 34.6916 85.8526 30.1581C109.002 25.6325 126.101 12.5359 134.787 1.79259L133.232 0.535128Z" fill="white" />
            </svg>
        </div>
    );
}

export default ArrowRight;
import React, { useContext } from 'react';
import classes from './Facing.module.css';
import { VscGlobe } from 'react-icons/vsc';
import { FaChevronDown } from 'react-icons/fa';
import { LanguageContext } from '../LanguageContext/LanguageContext';

function LanguagePicker(props) {
    const [clicked, setClicked] = React.useState(false);
    const { language, changeLanguage } = useContext(LanguageContext);

    const handleClicked = () => setClicked(!clicked);

    function getLang() {
        switch (language) {
            case 'ru': return "RU";
            case 'en': return "EN";
            case 'zh': return "中文";
            default: return "EN";
        }
    }

    const setLangQueryParam = (lang) => {
        const newUrl = `${window.location.pathname}?lang=${lang}`;
        window.history.replaceState({}, '', newUrl);
    };

    const setLang = (language) => {
        setClicked(false);
        changeLanguage(language);
        setLangQueryParam(language);
    }

    return (
        <div className={classes.languageContainer}>
            <div onClick={handleClicked} className={classes.languagePicker}>
                <span className={classes.langTitle}>{getLang()}</span>
            </div>
            <div className={`${clicked ? classes.picker : classes.pickerNone}`}>
                <div className={classes.langItem} onClick={() => setLang('ru')}>Русский</div>
                <div className={classes.langItem} onClick={() => setLang('en')}>English</div>
                <div className={classes.langItem} onClick={() => setLang('zh')}>中文</div>
            </div>
        </div>
    );
}

export default LanguagePicker;